import React from "react";
// import { StyleSheet, Text, View,TouchableOpacity } from "react-native";

const CategoriesButton = (props) => {

      let clicked = () =>{

          props.handle()
      }

    return (
        
          <div onClick={clicked} style={{width:props.buttonTitle == 'All' ? 70 : 'auto',paddingLeft:10,paddingRight:10,paddingTop:4, marginRight:12,alignItems:'center',height:38,textAlign:'center',backgroundColor:props.buttonColor ? props.buttonColor :'#ffffff',border:'solid 1px #e0e0e0',borderRadius:7,cursor:'pointer'}}>
               <text  style={{ textAlign:'center',fontSize:12,fontWeight: "normal",fontStyle: "normal",color:props.buttonTextColor ?props.buttonTextColor :null,textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap' }}>{props.buttonTitle}</text>
          </div>
       )
}




export default CategoriesButton;