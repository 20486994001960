import React, { useContext, useEffect, useState } from 'react'
import { globalState } from '../../../GlobalState/globalState'
import '../../../CSS/UI Components/ServiceDetails.css'
import ShareIcon from '../../../Assets/vectorShareIconColored.png'
import WishlistIcon from '../../../Assets/ph-heart-boldColored.png'
import StarIcon from '../../../Assets/StarIconBlack.png'
import DistanceIcon from '../../../Assets/gis-routeBlack.png'
import customFetchData from '../customFetchData'
import numeral from 'numeral'
import DurationIcon from '../../../Assets/asicon-outline-timer.png'
import momenttz from 'moment-timezone'
import moment from 'moment';
import { colors } from './Colors'
import RatingComponent from './RatingComponent'
import RatingsAndReviews from './RatingsAndReviews'
import { addToCart, removeFromCart ,storeDateAndTime} from '../../../GlobalState/globalStateActions'
import '../../../CSS/ProfessionalPage.css'
import { FaArrowRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'
import { Carousel, CarouselIndicators, CarouselItem } from 'reactstrap'
import WishlistYes from '../../../Assets/iconFavorite.png'
import { ToastContainer, toast } from 'react-toastify'
import config from '../../../../config'
const DeepClone = require("rfdc/default")
export default function ServiceDetails({proData,serviceDetails,wishlisted,onClickWishChild}) {
    // console.log('props',proData,serviceDetails)
    // const [proData,setProData]=useState()
    // const [serviceDetails,setServiceDetails]=useState()
    let navigate=useNavigate()
    const [current,setCurrent]=useState(0)
    const [index,setIndex]=useState(0)
    const [couponDetails,setCouponDetails]=useState([])
    const [ratingDetails,setRatingDetails]=useState([])
    const [ratings1,setRatings1]=useState(0)
    const [ratings2,setRatings2]=useState(0)
    const [ratings3,setRatings3]=useState(0)
    const [ratings4,setRatings4]=useState(0)
    const [ratings5,setRatings5]=useState(0)
    const [totalRatings,setTotalRatings]=useState(0)
    const [avgRating,setAvgRating]=useState(0)
    const [wishlistPress,setWishlistPress]=useState(false)
    const [isAddedToCart, setIsAddedToCart] = useState(false)
    const [addCheck,setAddCheck]=useState(false)
    const [loading,setLoading]=useState(false)
    const [cartAmount, setCartAmount] = useState(0)
    const [state,dispatch]=useContext(globalState)
    const [activeIndex, setActiveIndex] = useState(0);
    useEffect(()=>{
        ratingsFetchHandler()
    },[])
    const navToLoginOrCheckout=()=>{
        navigate('/checkout',{state:{
            categories: DeepClone(state.categories), subCategories: DeepClone(state.subcategories),
            addressDetails:
            {
              totalAddress: state.address.totalAddress,
              addressLine1: state.address.addressLine1,
              country: state.address.country,
              city: state.address.city,
              stateOrProvince: state.address.stateOrProvince,
              zipcode: state.address.zipcode,
              district: state.address.district,
              latitude: state.address.latitude,
              longitude: state.address.longitude,
              currentAddress: state.address.currentAddress,
              searchAddress: state.address.searchAddress,
              currentLocationDenied: state.address.currentLocationDenied
            },
            allServices: DeepClone(state.services)
        }})
    }
    const ratingsFetchHandler = async() =>{
        let body1={
            m_id:proData.QF_MERCHANT_PRID,
            // loc_prid:route.params.serviceDetails.QF_MERCHANT_LOCATION_PRID
        }
        let url1='/website/serviceDetailsCoupons/sv2'
        let method1='POST'
        let couponDetails=await customFetchData(method1,body1,url1,state,dispatch)
        console.log('coupons',couponDetails)
        let check=couponDetails.fetchedData
        if(couponDetails.fetch){
            if(check.data){
                if(check.couponRes.length!==0){
                    setCouponDetails(check.couponRes)
                }else{
                    setCouponDetails([])  
                }
            }
        }
        let body={
            mp_id:proData.QF_MERCHANT_PERSONNEL_PRID,
            m_serviceId:serviceDetails.QF_MERCHANT_SERVICE_PRID
        }
        let url='/website/serviceRatingsFetch/sv2'
        let method='POST'
        let ratingFetch=await customFetchData(method,body,url,state,dispatch)
        console.log('ratings',ratingFetch)
        let check1=ratingFetch.fetchedData
        if(ratingFetch.fetch){
            if(check1.data){
                if(check1.ratingsResult.length !==0){
                    setRatingDetails(check1.ratingsResult)
                    setTotalRatings(check1.totalRatings)
                    setRatings1(check1.ratings1)
                    setRatings2(check1.ratings2)
                    setRatings3(check1.ratings3)
                    setRatings4(check1.ratings4)
                    setRatings5(check1.ratings5)
                    setAvgRating(check1.avgRating)
                }else{
                    setRatingDetails([])
                }
            }
        }
    }
    useEffect(()=>{
        // console.log("serviceDetails",serviceDetails)
        let cart=DeepClone(state.cart)

        let addCheck=cart.some((item)=>{
            return item.QF_MERCHANT_SERVICE_PRID===serviceDetails.QF_MERCHANT_SERVICE_PRID
        })
        // console.log("addCheck",addCheck,data.QF_MERCHANT_SERVICE_PRID)
        setIsAddedToCart(addCheck)
    })
    useEffect(() => {
        // console.log("state cart count in use effect",state.cartCount)
        let cart = [...state.cart], tempCartAmount = 0
        cart.map(item => {
            tempCartAmount += item.service_offer_price
        })
        setCartAmount(tempCartAmount)
    }, [state.cart])

    useEffect(()=>{
        let fromDateTime,toDateTime;
        if(Object.keys(state.fromDateTime).length===0)
        {
            //// setting from date in global state if already not set
            // dispatch({type:'SET_FROM_DATE_TIME',fromDateTime:moment().tz(state.country.timezone).set({ 'hour': 0, 'minute': 0 })})
            fromDateTime=moment().tz(state.country.timezone).set({ 'hour': 0, 'minute': 0 })
        }
        if(Object.keys(state.toDateTime).length===0)
        {
            //// setting to date in global state if already not set
            // dispatch({type:'SET_TO_DATE_TIME',toDateTime:moment().tz(state.country.timezone).set({ 'hour': 23, 'minute': 59 }).add(14, "days")})
            toDateTime=moment().tz(state.country.timezone).set({ 'hour': 23, 'minute': 59 }).add(14, "days")
        }
        if( fromDateTime && toDateTime ){
            storeDateAndTime(fromDateTime,toDateTime,dispatch)
        }


    },[])

    const onAddToCart = (data) => {
        console.log("on click add ot cart")
        // onClickCart(!cartClicked)
        addToCart(data,state,dispatch)
    }

    const onRemoveFromCart = (data)=>{
        console.log("on click add ot cart")
        // onClickCart(!cartClicked)
        removeFromCart(data,state,dispatch)
    }
    const WishlistPressOnService = async() =>{
        setWishlistPress(!wishlistPress)
        let body = {
            // cprid:item.QF_CONSUMER_PRID,
            cprid: state.user.id,
            mpprid: serviceDetails.QF_MERCHANT_PERSONNEL_PRID,
            wishLstType: 'service',
            servicePrid: serviceDetails.QF_MERCHANT_SERVICE_PRID
        }
        let WishlistInsert = await customFetchData('POST', body, '/website/add/wishList/sv2', state, dispatch)
        console.log('wish list response',WishlistInsert)
        onClickWishChild(!wishlistPress)

    }
    let CouponSlides;
    if(couponDetails.length!==0){
        CouponSlides = couponDetails.map((item) => {
          return (
                  <CarouselItem
                      // onExiting={this.onExiting}
                      // onExited={this.onExited}
                      key={item.QF_MERCHANT_COUPON_PRID}
                  >
                        <div style={{background:'linear-gradient(86deg, #8c52ff 3%, #f7f2ff 98%)',height:80,borderRadius:8,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',padding:10}}>
                          <text style={{color:'#fff'}}>{item.MERCHANT_COUPON_CODE}</text>
                          <text style={{color:'#fff'}}>{item.MERCHANT_COUPON_PERCENT_OFF ? item.MERCHANT_COUPON_PERCENT_OFF + "% OFF" : (proData.MERCHANT_PERSONNEL_ADDRESS_COUNTRY== "India" ? ("\u20B9") : "$") + item.MERCHANT_COUPON_VALUE_OFF + " OFF"}</text>
                        </div>
                  </CarouselItem>
          );
        });
    }
    const next = () => {
      // if (animating) return;
      const nextIndex = activeIndex === couponDetails.length - 1 ? 0 : activeIndex + 1;
      setActiveIndex(nextIndex);
    };
    
    const previous = () => {
      // if (animating) return;
      const nextIndex = activeIndex === 0 ? couponDetails.length - 1 : activeIndex - 1;
      setActiveIndex(nextIndex);
    };
    const goToIndex = (newIndex) => {
        //   console.log("newIndex",newIndex)
        // if (this.animating) return;
        setActiveIndex(newIndex);

    }
    const copyClipHandler = () => {
        // let code=paramsLocation.pathname + paramsLocation.search
        let urlFullPath;
        if(config.localhost_url=='http://ec2-54-149-62-15.us-west-2.compute.amazonaws.com:8089' || config.localhost_url == 'http://ec2-54-149-62-15.us-west-2.compute.amazonaws.com:8089')
        {
            urlFullPath=`http://qf-test-website.s3-website-us-west-2.amazonaws.com/servicedetails/${serviceDetails.SERVICE_NAME.replaceAll(/[&\/\\#, +()$~%.'":*?<>{}]/g, "")}?proId=${serviceDetails.QF_MERCHANT_PERSONNEL_PRID}&serviceId=${serviceDetails.QF_MERCHANT_SERVICE_PRID}`
        }
        else if(config.localhost_url=="https://load.goslash.com")
        {
            urlFullPath=`https://www.goslash.com/servicedetails/${serviceDetails.SERVICE_NAME.replaceAll(/[&\/\\#, +()$~%.'":*?<>{}]/g, "")}?proId=${serviceDetails.QF_MERCHANT_PERSONNEL_PRID}&serviceId=${serviceDetails.QF_MERCHANT_SERVICE_PRID}`
        }else{
            urlFullPath=`http://localhost:3000/servicedetails/${serviceDetails.SERVICE_NAME.replaceAll(/[&\/\\#, +()$~%.'":*?<>{}]/g, "")}?proId=${serviceDetails.QF_MERCHANT_PERSONNEL_PRID}&serviceId=${serviceDetails.QF_MERCHANT_SERVICE_PRID}`
        }
        // let code=config.localhost_url+`/servicedetails/${serviceDetails.SERVICE_NAME.replaceAll(" ","","g")}?proId=${serviceDetails.QF_MERCHANT_PERSONNEL_PRID}&serviceId=${serviceDetails.QF_MERCHANT_SERVICE_PRID}`
        navigator.clipboard.writeText(urlFullPath)
        toast(
          <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            <text>Copied to clipboard</text> 
          </div>,
          {
            position:'bottom-center',
            hideProgressBar: true,
            progress: undefined,
            closeButton:false,
            style:{backgroundColor:'#E0D1FF'}
        });
      }
  return (
    <div style={{paddingBottom:20}}>
        {
        (state.cartCount && state.cart.length !== 0 ) ?
        <div onClick={navToLoginOrCheckout} style={{height:80,padding:16,marginBottom:20,marginLeft:-16,marginRight:-16,backgroundImage:' linear-gradient(97deg, #1a67db -2%, #02c5d1 41%, #31b12f 100%)',cursor:'pointer'}}>
            <div style={{ display:'flex',flexDirection: 'row', justifyContent: 'space-between',alignItems:'center' }}>
              <div style={{display:'flex',flexDirection: 'column',justifyContent:'center'}}>
                <text  style={{maxWidth:180}} className='serviceNameText_InCart'>{state.cart[0].SERVICE_NAME + "" + (state.cartCount > 1 ? (" + " + (state.cartCount - 1) + " more") : "")}</text>
                <text style={{ marginTop:3, fontSize:12 }} className='serviceNameText_InCart'>{proData.MERCHANT_PERSONNEL_ADDRESS_COUNTRY === 'India' ? '\u20B9' : '$'}{cartAmount}</text>
              </div>
              <div style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                <text style={{fontWeight:600}} className='serviceNameText_InCart'>Checkout</text>
                <FaArrowRight  style={{marginLeft:5}} name='arrow-right' color='#fff' size={25} />
              </div>
            </div>
        </div>
        : null
        }
        <div className='serviceDetails_View'>
            <div style={{display:'flex',flexDirection:'column'}}>
                <div style={{position:'relative'}}>
                    <img src={serviceDetails.image_link} style={{width:'100%',height:'100%',borderRadius:15}}/>
                    <div style={{display:'flex',flexDirection:'row',position:'absolute',bottom:15,right:15}}>
                        <div className='wishlistBg_ServiceDetails' style={{cursor:'pointer'}} onClick={()=>copyClipHandler()}>
                            <img src={ShareIcon} style={{ width: 18, height: 18 }}/>
                        </div>
                        {
                            state.token &&
                            <div className='wishlistBg_ServiceDetails' style={{marginLeft:10,cursor:'pointer'}} onClick={WishlistPressOnService}>
                                {serviceDetails.WISHLIST_DELETED_FLAG == 0 || wishlisted || wishlistPress ? <img src={WishlistYes} style={{ width: 20, height: 20 }}/> : <img src={WishlistIcon} style={{ width: 20, height: 20 }}/>}
                            </div>
                        }
                    </div>
                </div>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:20}}>
                    <div style={{display:'flex',flexDirection:'column'}}>
                        <text className='serviceName_ServiceDet'>
                            {serviceDetails.SERVICE_NAME}
                        </text>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',paddingTop:10}}>
                            <img src={DistanceIcon} style={{marginRight:2}}/>
                            <text className='distanceText_serviceDet'>{proData.SERVICE_CURRENCY=='Rupees' ? numeral(proData.distance).divide(1000).format('0.00') + ' km away' : numeral(proData.distance).divide(1609.344).format('0.00') + ' mi away'}</text>
                        </div>
                        {ratingDetails.length !==0 &&<div style={{display:'flex',flexDirection:'row',paddingTop:10,alignItems:'center'}}>
                            <img src={StarIcon} style={{width:15,height:15}}/>
                            <text className='distanceText_serviceDet'>{numeral(avgRating).format('0.0')}</text>
                        </div>}
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',paddingTop:10}}>
                            <img src={DurationIcon} style={{width:16,height:16}}/>
                            <text className='distanceText_serviceDet'>{moment.utc(moment.duration(serviceDetails.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("H") == 0 ? null : moment.utc(moment.duration(serviceDetails.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("H") + " hr"}{' '}{moment.utc(moment.duration(serviceDetails.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("mm") == 0 ? null : moment.utc(moment.duration(serviceDetails.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("mm") + " mins"}</text>
                        </div>
                        <div style={{paddingTop:10}}>
                            <text className='servicePrice_serviceDet'>{serviceDetails.SERVICE_CURRENCY === 'Rupees' ? ('\u20B9') : '$'}{numeral(serviceDetails.service_offer_price).format('0.00')}</text>
                        </div>
                    </div>
                    <div>
                        {
                        isAddedToCart
                        ?
        
                        <div className='addToCart_Btn_Service'  style={{cursor:'pointer'}} onClick={() => onRemoveFromCart(serviceDetails)}>
                            <text className='addToCart_Btn_Service_Text'>Remove From Cart</text>
                        </div>
                        :
                        <div className='addToCart_Btn_Service' style={{cursor:'pointer'}} onClick={() => onAddToCart(serviceDetails)}>
                            <text className='addToCart_Btn_Service_Text'>Add To Cart</text>
                        </div>
                        }
                    </div>
                </div>
                <div>
                    <text className='descText_ServiceDet'>{serviceDetails.SERVICE_DETAIL_DESC ? serviceDetails.SERVICE_DETAIL_DESC : 'No Description'}</text>
                </div>
            </div>
        </div>
         { couponDetails.length!==0 &&
         <div style={{ marginTop: 20, marginBottom: 10}}>
              <Carousel
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                  ride="carousel"
                  interval={2000}
              >
                  {couponDetails.length > 1 ?
                        <CarouselIndicators style={{bottom:-8}} items={couponDetails} activeIndex={activeIndex} onClickHandler={goToIndex}/>
                      : null}
                  {CouponSlides}
              </Carousel>
          </div>}
        <div className='serviceDetails_PriceView'>
            <div className='linearGradient_ServiceId'>
                <div style={{flexDirection:'row',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                    <text className='serviceId_Text'>Service ID</text>
                    <text className='serviceId_ValueText'>{serviceDetails.QF_MERCHANT_SERVICE_PRID}</text>
                </div>
            </div>
            <div style={{padding:15}}>
              <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: 8 }}>
                  <text className='priceText_ServiceDet'>Regular Price</text>
                  <text className='serviceId_ValueText'>{serviceDetails.SERVICE_CURRENCY === 'Rupees' ? ('\u20B9') : '$'} {numeral(serviceDetails.service_list_price).format('0.00')}</text>
              </div>
                {
                      serviceDetails.cpPrid
                          ?
                          <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: 8}}>
                              {serviceDetails.multiplier_diff_price > 0 ? <text className='priceText_ServiceDet'>Slash Fee</text> : <text className='priceText_ServiceDet'>Slash Discount</text>}
                              {
                                  serviceDetails.SERVICE_CURRENCY === 'Rupees'
                                      ?
                                      <text className='serviceId_ValueText'>{'\u20B9'}{serviceDetails.multiplier_diff_price ? Math.abs(numeral(serviceDetails.multiplier_diff_price).value().toFixed(2)) : '0.00'}</text>
                                      :
                                      <text className='serviceId_ValueText'>${serviceDetails.multiplier_diff_price ? Math.abs(serviceDetails.multiplier_diff_price).toFixed(2) : '0.00'}</text>
                              }
                          </div>
                          :
                          null
                  }
              <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: 8 }}>
                  <text className='priceText_ServiceDet'>Service Discount Percent</text>
                  <text className='serviceId_ValueText'>{serviceDetails.service_discount}%</text>
              </div>
              <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: 8 }}>
                  <text className='priceText_ServiceDet'>Service Discount Price</text>
                  <text className='serviceId_ValueText'>{serviceDetails.SERVICE_CURRENCY === 'Rupees' ? ('\u20B9') : '$'} {numeral(serviceDetails.service_list_price).subtract(serviceDetails.SERVICE_OFFER_PRICE).value().toFixed(2)}</text>
              </div>
              <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: 8 }}>
                  <text className='priceText_ServiceDet' style={{  color: 'rgba(140, 82, 255, 0.85)'}}>Professional Price</text>
                  <text className='serviceId_ValueText'>{serviceDetails.SERVICE_CURRENCY === 'Rupees' ? ('\u20B9') : '$'} {numeral(serviceDetails.service_offer_price).format('0.00')}</text>
              </div>
              <hr/>
              <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: 8 }}>
                  <text className='totalPrice_ServiceDet'>Total Price</text>
                  <text className='totalPriceValueText_ServiceDet'>{serviceDetails.SERVICE_CURRENCY === 'Rupees' ? ('\u20B9') : '$'} {numeral(serviceDetails.service_offer_price).format('0.00')}</text>
              </div>
            </div>
        </div>
        <div className='cancellationPolicy_View'>
            <text className='cancellationDisclaimer_Text'>Cancellation Disclaimer</text>
              {
                  serviceDetails.SERVICE_CANCEL_ALLOWED === 1 ?
                      <>
                            <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: 8 }}>
                              <text className='priceText_ServiceDet'>Cancellation Allowed</text>
                              <text className='priceText_ServiceDet'>Yes</text>
                          </div>
                          <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: 8 }}>
                              <text className='priceText_ServiceDet'>Cancellation Day</text>
                              <text className='priceText_ServiceDet'>{serviceDetails.SERVICE_CANCEL_ALLOWED_DAYS + (serviceDetails.SERVICE_CANCEL_ALLOWED_DAYS === 0 || 1 ? " day" : " days")}</text>
                          </div>
                          <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: 8 }}>
                              <text className='priceText_ServiceDet'>Cancellation Fee</text>
                              <text className='priceText_ServiceDet' style={{ color: colors.ERROR_RED_2 }}>-{serviceDetails.SERVICE_CURRENCY === 'Rupees' ? ('\u20B9') : '$'} {serviceDetails.SERVICE_CANCEL_FEE}</text>
                          </div>
                      </>
                      :
                      <div>
                          <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: 8 }}>
                              <text className='priceText_ServiceDet'>Cancellation Allowed</text>
                              <text className='priceText_ServiceDet' style={{ color: colors.ERROR_RED_2 }}>No</text>
                          </div>
                      </div>
              }
        </div>
          {
              ratingDetails.length !== 0 ?
              <div className='ratingAndReview_Box'>
                    <div>
                        <RatingsAndReviews serviceDetails={true} rating={avgRating} ratings1={ratings1} ratings2={ratings2} ratings3={ratings3} ratings4={ratings4} ratings5={ratings5} totalNofRatings={totalRatings} reviews={50} />
                    </div>
                </div>
                : null
          }
        <ToastContainer/>
    </div>
  )
}
