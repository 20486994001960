import { Container, Row, Col,Card,CardBody, CarouselItem, Carousel, Navbar,CarouselIndicators, } from 'reactstrap'
import React,{useEffect, useState} from 'react'
import '../../CSS/UI Components/ProfessionalUnderCategory.css'
// import '../../CSS/UI Components/
import '../../CSS/UI Components/BlogsArticles.css'
import { BsFileEarmarkCheck } from 'react-icons/bs';
import {useNavigate } from 'react-router-dom';
import autoLog from '../../Assets/carblog.png'
import perLog from '../../Assets/personalblog.png'
import prod from '../../Assets/4dots.png'
import productblog from '../../Assets/productblog.png'
import storyblog from '../../Assets/storyblog.png'





function BlogsArticles(){

let navigate=useNavigate()

  const [ activeIndex,setActiveIndex ]=useState(0);
  const [options,setOPtions] = useState([{name:'All',id:1,image:prod,height:20,width:20},{name:'Auto Care',id:2,image:autoLog,height:20,width:20},{name:'Personal Care',id:3,image:perLog,height:17,width:14},{name:'Products',id:4,image:productblog,height:15,width:11},{name:'Stories@Slash',id:5,image:storyblog,height:14,width:14}])
  const [selected,setSelected] = useState([1])
  let date = new Date().toUTCString().slice(5, 16);


  let blogItems =[
    {
        key: 1,src:{},
        altText: (
          <div><div style={{top:'45%',left:'6%',zIndex:10,right:'15%',position:'absolute',display:'flex',flexDirection:'column'}}>
                <text style={{}} className='blogText'>Blogs & Articles</text>
                <text className='blogText2'>All you need to know</text>
                </div>
          </div>
        ),
    },
    // {
    //     key: 2,
    //     src:{
    //     },
    //     altText: (
    //         <div style={{top:'45%',left:'6%',right:'15%',position:'absolute',display:'flex',flexDirection:'column'}}>
    //                 <text className='blogText'>Blogs & Articles</text>
    //                 <text className='blogText2'>All you need to know</text>
    //         </div>
    //     ),
    // }
  ]

            let blogSlides = blogItems.map((item) => {
             
            return (
       
            <CarouselItem key={item.key}>
                <div className="CarousalContainer_Sv2_Blog" style={{width:'100%',background: 'linear-gradient(to top, rgba(0, 0, 0) 100%, rgba(0, 0, 0) 0%)'}}>
                    {item.altText}
                    <img className='imgStylesv2_Blog'  src="https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684421293381-lg.png"/>
                    {/* <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1666875231964-lg.jpg'} className='Carousal_policy_mobile'/> */}
                </div>
            </CarouselItem>
        
    );
  });


  const next = () => {
    // if (animating) return;
    const nextIndex = activeIndex === blogItems.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  
  const previous = () => {
    // if (animating) return;
    const nextIndex = activeIndex === 0 ? blogItems.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    //   console.log("newIndex",newIndex)
    // if (this.animating) return;
    setActiveIndex(newIndex);

  }

  const optionChooseHandler = (id) =>{


    setSelected(id)

  }



  let cards =[]

  if(selected == 2){

    cards =(
      <>
      
    <div style={{width:'45%',height:'46%',padding:18,position:'relative',backgroundColor:'#fff',borderRadius:15,cursor:'pointer',border: 'solid 1px #e8e8e8'}} onClick={()=>navigate('/blogdetails/is-your-car-ready-to-hit-the-road', { state:{image:'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635954071929-lg.jpg',id:2}})}>             
    <div style={{display:'flex',width:'100%',height:'100%',flexDirection:'column'}}>
    <img   height='50%' width='100%' src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635954071929-lg.jpg'}/>
       <div style={{marginTop:'5%',color:'#747474',fontSize:'1.1vw',fontWeight:'600',fontFamily:'Inter',textAlign:'left',width:'18vw'}}>Is your Car ready to hit the road?</div> 
       <div style={{color:'#747474',fontSize:'1.1vw',fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left',width:'16vw'}}>Most of us enjoy the pleasant drive down the .........</div>
     <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>
      <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
      <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
     </div>
    </div>
   </div>

<div style={{width:'45%',padding:18,position:'relative',backgroundColor:'#fff',borderRadius:15,cursor:'pointer',border: 'solid 1px #e8e8e8'}} onClick={()=>navigate('/blogdetails/the-best-four-wheeler-repair-maintenance', { state:{image:'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1703049806512-lg.jpg',id:67}})}>             
<div style={{display:'flex',width:'100%',height:'100%',flexDirection:'column'}}>
<img   height='70%' width='100%' src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1703049806512-lg.jpg'}/>
   <div style={{marginTop:'5%',color:'#747474',fontSize:'1.1vw',fontWeight:'600',fontFamily:'Inter',textAlign:'left',width:'30vw'}}>We've got your vehicle's back- all the time, every time!</div> 
   <div style={{color:'#747474',fontSize:'1.1vw',fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left',width:'20vw'}}>The Best Four and Two-Wheeler Repair and Maintenance........</div>
 <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>
  <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
  <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
 </div>
</div>
</div>
</>
)
}else if(selected == 1){

  let link1='https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1655708668172-lg.jpg'

  cards =(
  <div style={{display:'flex',flexWrap:'wrap',justifyContent:'space-between',width:'100%',height:'100%', overflow:'hidden',gridTemplateColumns:'repeat(auto-fill,184px)',gap:40}}>


<div onClick={()=>navigate('/blogdetails/onlineservice', { state:{image:'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1705479647109-lg.png',id:6}})} style={{width:'45%',height:'13%',padding:18,position:'relative',border: 'solid 1px #e8e8e8',backgroundColor:'#fff',borderRadius:15,cursor:'pointer'}} >             
    <div className='trendImage'>
    <img height='100%' width='100%' src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1705479647242-lg.png'}/>
    </div>
      <div style={{marginTop:'5%',color:'#747474',fontSize:'1.1vw',fontWeight:'600',fontFamily:'Inter',textAlign:'left',width:'18vw'}}>Boosting Profits with Online Booking</div> 
      <div style={{color:'#747474',fontSize:'1.1vw',fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left',width:'16vw'}}>How Online Service Booking Systems Increase Revenue for Local Businesses..</div>
    <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>
      <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
      <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
    </div>
  </div>
  
  <div onClick={()=>navigate('/blogdetails/trending', { state:{image:'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1677148261662-lg.jpg',id:5}})} style={{width:'45%',height:'46%',padding:18,position:'relative',border: 'solid 1px #e8e8e8',backgroundColor:'#fff',borderRadius:15,cursor:'pointer'}} >             
    <div className='trendImage' >
    <img height='100%' width='100%' src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1677148261662-lg.jpg'}/>
    </div>
      <div style={{marginTop:'5%',color:'#747474',fontSize:'1.1vw',fontWeight:'600',fontFamily:'Inter',textAlign:'left',width:'18vw'}}>Trending Makeup Looks</div> 
      <div style={{color:'#747474',fontSize:'1.1vw',fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left',width:'16vw'}}>Makeup is something that everyone loves to wear .........</div>
    <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>
      <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
      <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
    </div>
  </div>

  <div style={{width:'45%',height:'46%',padding:18,position:'relative',backgroundColor:'#fff',borderRadius:15,cursor:'pointer',border: 'solid 1px #e8e8e8'}} onClick={()=>navigate('/blogdetails/summer-coming', { state:{image:'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1655708668172-lg.jpg',id:1}})}>             
  <div style={{display:'flex',width:'100%',height:'100%',flexDirection:'column'}}>
  <img   height='50%' width='100%' src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1655708668172-lg.jpg'} />
     <div style={{marginTop:'5%',color:'#747474',fontSize:'1.1vw',fontWeight:'600',fontFamily:'Inter',textAlign:'left',width:'18vw'}}>Is your home summer ready?</div> 
     <div style={{color:'#747474',fontSize:'1.1vw',fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left',width:'16vw'}}>summer is coming and you are still.........</div>
   <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>
    <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
    <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
   </div>
  </div>
 </div>
       <div style={{width:'45%',height:'46%',padding:18,position:'relative',cursor:'pointer',
       border: 'solid 1px #e8e8e8',backgroundColor:'#fff',borderRadius:15
     }} onClick={()=>navigate('/blogdetails/is-your-hair-ready-to-be-styled', { state:{image:'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635950993326-lg.jpg',id:12}})}>             
       <div style={{display:'flex',flexDirection:'column', width:'100%',height:'100%'}}>
            <img   height='50%' width='100%' src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635950993326-lg.jpg'}/>
            <div style={{marginTop:'5%',color:'#747474',fontSize:'1.1vw',fontWeight:'600',fontFamily:'Inter',textAlign:'left',width:'18vw'}}>Is your hair ready to be styled?</div> 
            <div style={{color:'#747474',fontSize:'1.1vw',fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left',width:'16vw'}}>Most of us enjoy the pleasant service down the .........</div>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>
           <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
           <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
          </div>
         </div>

     </div>
     <div style={{width:'45%',height:'46%',padding:18,position:'relative',cursor:'pointer',border: 'solid 1px #e8e8e8'
       ,backgroundColor:'#fff',borderRadius:15
     }} onClick={()=>navigate('/blogdetails/skin', { state:{image:'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635954352488-lg.jpg',id:3}})}>             
       <div style={{display:'flex',flexDirection:'column', width:'100%',height:'100%'}}>
            <img   height='50%' width='100%' src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635954352488-lg.jpg'}/>
            <div style={{marginTop:'5%',color:'#747474',fontSize:'1.1vw',fontWeight:'600',fontFamily:'Inter',textAlign:'left',width:'18vw'}}>Skin rituals for healthy skin</div> 
            <div style={{color:'#747474',fontSize:'1.1vw',fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left',width:'16vw'}}> Every woman wants beautiful and healthy skin .........</div>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>                                          
           <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
           <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
          </div>
         </div>

     </div>


     <div onClick={()=>navigate('/blogdetails/5-unique-ways-to-surprise', { state:{image:'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1676007914853-lg.png',id:52}})} style={{width:'45%',height:'13%',padding:18,position:'relative',border: 'solid 1px #e8e8e8',backgroundColor:'#fff',borderRadius:15,cursor:'pointer'}} >             
  <div style={{display:'flex',width:'100%',height:255,flexDirection:'column'}}>
   <img height='100%' width='100%' src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1676007914853-lg.png'}/>
   </div>
     <div style={{marginTop:'5%',color:'#747474',fontSize:'1.1vw',fontWeight:'600',fontFamily:'Inter',textAlign:'left',width:'18vw'}}>5 unique ways to surprise</div> 
     <div style={{color:'#747474',fontSize:'1.1vw',fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left',width:'16vw'}}>Giving surprises to your loved ones is the best .........</div>
   <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>
    <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
    <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
  </div>
 </div>


     <div style={{width:'45%',height:'46%',padding:18,position:'relative',backgroundColor:'#fff',borderRadius:15,cursor:'pointer',border: 'solid 1px #e8e8e8'}} onClick={()=>navigate('/blogdetails/is-your-car-ready-to-hit-the-road', { state:{image:'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635954071929-lg.jpg',id:2}})}>             
    <div style={{display:'flex',width:'100%',height:'100%',flexDirection:'column'}}>
      <img height='50%' width='100%' src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635954071929-lg.jpg'}/>
       <div style={{marginTop:'5%',color:'#747474',fontSize:'1.1vw',fontWeight:'600',fontFamily:'Inter',textAlign:'left',width:'18vw'}}>Is your Car ready to hit the road?</div> 
       <div style={{color:'#747474',fontSize:'1.1vw',fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left',width:'16vw'}}>Most of us enjoy the pleasant drive down the .........</div>
     <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>
      <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
      <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
     </div>
    </div>
   </div>

   <div style={{width:'45%',height:'46%',padding:18,position:'relative',backgroundColor:'#fff',borderRadius:15,cursor:'pointer',border: 'solid 1px #e8e8e8'}}  onClick={()=>navigate('/blogdetails/slash-your-digital-partner', { state:{image:'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1636116649167-lg.jpg',id:4}})}>             
  <div style={{display:'flex',width:'100%',height:'100%',flexDirection:'column'}}>
  <img   height='50%' width='100%' src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1636116649167-lg.jpg'}/>
     <div style={{marginTop:'5%',color:'#747474',fontSize:'1.1vw',fontWeight:'600',fontFamily:'Inter',textAlign:'left',width:'18vw'}}>Slash Your Digital Assistant</div> 
     <div style={{color:'#747474',fontSize:'1.1vw',fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left',width:'16vw'}}>As a service professional, whether as an Individual.........</div>
   <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>
    <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
    <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
   </div>
  </div>
 </div>
       <div style={{width:'45%',height:'46%',padding:18,position:'relative',cursor:'pointer',border: 'solid 1px #e8e8e8',
      backgroundColor:'#fff',borderRadius:15
     }} onClick={()=>navigate('/blogdetails/why-not-slash', { state:{image:'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1638427989315-lg.jpg',id:42}})}>             
       <div style={{display:'flex',flexDirection:'column', width:'100%',height:'100%'}}>
            <img   height='50%' width='100%' src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1638427989315-lg.jpg'}/>
            <div style={{marginTop:'5%',color:'#747474',fontSize:'1.1vw',fontWeight:'600',fontFamily:'Inter',textAlign:'left',width:'18vw'}}>Why not slash ?</div> 
            <div style={{color:'#747474',fontSize:'1.1vw',fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left',width:'16vw'}}>Hey, are you looking for after party home cleaning .........</div>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>
           <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
           <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
          </div>
         </div>

     </div>

      <div style={{width:'45%',padding:18,position:'relative',cursor:'pointer',border: 'solid 1px #e8e8e8',backgroundColor:'#fff',borderRadius:15}} onClick={()=>navigate('/blogdetails/dont-go-on-a-rough-patch-with-dandruff', { state:{image:'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1703139383392-lg.png',id:68}})}>             
        <div style={{display:'flex',flexDirection:'column', width:'100%',height:'100%'}}>
          <img height='70%' width='100%' src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1703139383392-lg.png'}/>
          <div style={{marginTop:'5%',color:'#747474',fontSize:'1.1vw',fontWeight:'600',fontFamily:'Inter',textAlign:'left',width:'28vw'}}>Don’t go on a rough patch with dandruff this winter</div> 
          <div style={{color:'#747474',fontSize:'1.1vw',fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left',width:'16vw'}}> Snowflakes look pretty in the winter, but not dandruff.........</div>
          <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>                                          
            <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
            <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
          </div>
        </div>
      </div>

<div style={{width:'45%',padding:18,position:'relative',backgroundColor:'#fff',borderRadius:15,cursor:'pointer',border: 'solid 1px #e8e8e8'}} onClick={()=>navigate('/blogdetails/the-best-four-wheeler-repair-maintenance', { state:{image:'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1703049806512-lg.jpg',id:67}})}>             
  <div style={{display:'flex',width:'100%',height:'100%',flexDirection:'column'}}>
  <img   height='70%' width='100%' src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1703049806512-lg.jpg'}/>
    <div style={{marginTop:'5%',color:'#747474',fontSize:'1.1vw',fontWeight:'600',fontFamily:'Inter',textAlign:'left',width:'30vw'}}>We've got your vehicle's back- all the time, every time!</div> 
    <div style={{color:'#747474',fontSize:'1.1vw',fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left',width:'20vw'}}>The Best Four and Two-Wheeler Repair and Maintenance........</div>
  <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>
    <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
    <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
  </div>
  </div>
</div>
     
    
    </div>

)
}else if(selected == 3){

  cards =(
   <>
       <div style={{width:'45%',height:'46%',padding:18,position:'relative',cursor:'pointer',border: 'solid 1px #e8e8e8'
       ,backgroundColor:'#fff',borderRadius:15
     }} onClick={()=>navigate('/blogdetails/skin', { state:{image:'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635954352488-lg.jpg',id:3}})}>             
       <div style={{display:'flex',flexDirection:'column', width:'100%',height:'100%'}}>
            <img   height='50%' width='100%' src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635954352488-lg.jpg'}/>
            <div style={{marginTop:'5%',color:'#747474',fontSize:'1.1vw',fontWeight:'600',fontFamily:'Inter',textAlign:'left',width:'18vw'}}>Skin rituals for healthy skin</div> 
            <div style={{color:'#747474',fontSize:'1.1vw',fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left',width:'16vw'}}> Every woman wants beautiful and healthy skin .........</div>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>                                          
           <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
           <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
          </div>
         </div>

     </div>

<div style={{width:'45%',padding:18,position:'relative',cursor:'pointer',border: 'solid 1px #e8e8e8'
,backgroundColor:'#fff',borderRadius:15
}} onClick={()=>navigate('/blogdetails/dont-go-on-a-rough-patch-with-dandruff', { state:{image:'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635954352488-lg.jpg',id:68}})}>             
<div style={{display:'flex',flexDirection:'column', width:'100%',height:'100%'}}>
     <img   height='70%' width='100%' src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1703139383392-lg.png'}/>
     <div style={{marginTop:'5%',color:'#747474',fontSize:'1.1vw',fontWeight:'600',fontFamily:'Inter',textAlign:'left',width:'28vw'}}>Don’t go on a rough patch with dandruff this winter</div> 
     <div style={{color:'#747474',fontSize:'1.1vw',fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left',width:'16vw'}}> Snowflakes look pretty in the winter, but not dandruff.........</div>
     <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>                                          
    <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
    <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
   </div>
  </div>

</div>
</>
    
    // </>

)

}else if(selected == 4){

  cards =(
    <div style={{display:'flex',flexWrap:'wrap',justifyContent:'space-between',width:'100%',height:'100%', overflow:'hidden',gridTemplateColumns:'repeat(auto-fill,184px)',gap:40}}>
      <div style={{width:'45%',height:'46%',padding:18,position:'relative',backgroundColor:'#fff',borderRadius:15,cursor:'pointer',border: 'solid 1px #e8e8e8'}} onClick={()=>navigate('/blogdetails/slash-digital-assistant', { state:{image:'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1636116649167-lg.jpg',id:4}})}>             
        <div style={{display:'flex',width:'100%',height:'100%',flexDirection:'column'}}>
        <img   height='50%' width='100%' src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1636116649167-lg.jpg'}/>
          <div style={{marginTop:'5%',color:'#747474',fontSize:'1.1vw',fontWeight:'600',fontFamily:'Inter',textAlign:'left',width:'18vw'}}>Slash Your Digital Assistant</div> 
          <div style={{color:'#747474',fontSize:'1.1vw',fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left',width:'16vw'}}>As a service professional, whether as an Individual.........</div>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>
          <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
          <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
        </div>
        </div>
      </div>

      <div style={{width:'45%',height:'46%',padding:18,position:'relative',cursor:'pointer',border: 'solid 1px #e8e8e8',backgroundColor:'#fff',borderRadius:15}} onClick={()=>navigate('/blogdetails/why-not-slash', { state:{image:'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1638427989315-lg.jpg',id:42}})}>             
        <div style={{display:'flex',flexDirection:'column', width:'100%',height:'100%'}}>
            <img   height='50%' width='100%' src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1638427989315-lg.jpg'}/>
            <div style={{marginTop:'5%',color:'#747474',fontSize:'1.1vw',fontWeight:'600',fontFamily:'Inter',textAlign:'left',width:'18vw'}}>Why not slash ?</div> 
            <div style={{color:'#747474',fontSize:'1.1vw',fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left',width:'16vw'}}>Hey, are you looking for after party home cleaning .........</div>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>
              <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
              <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
            </div>
          </div>
      </div>

      <div style={{width:'45%',padding:18,position:'relative',backgroundColor:'#fff',borderRadius:15,cursor:'pointer',border: 'solid 1px #e8e8e8'}} onClick={()=>navigate('/blogdetails/the-best-four-wheeler-repair-maintenance', { state:{image:'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1703049806512-lg.jpg',id:67}})}>             
        <div style={{display:'flex',width:'100%',height:'100%',flexDirection:'column'}}>
          <img   height='70%' width='100%' src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1703049806512-lg.jpg'}/>
          <div style={{marginTop:'5%',color:'#747474',fontSize:'1.1vw',fontWeight:'600',fontFamily:'Inter',textAlign:'left',width:'30vw'}}>We've got your vehicle's back- all the time, every time!</div> 
          <div style={{color:'#747474',fontSize:'1.1vw',fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left',width:'20vw'}}>The Best Four and Two-Wheeler Repair and Maintenance........</div>
          <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>
            <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
            <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
          </div>
        </div>
      </div>

    </div>

)

}else if(selected == 5){

  cards =(
    <div style={{display:'flex',flexWrap:'wrap',justifyContent:'space-between',width:'100%',height:'100%', overflow:'hidden',gridTemplateColumns:'repeat(auto-fill,184px)',gap:40}}>

      <div onClick={()=>navigate('/blogdetails/onlineservice ', { state:{image:'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1705479647242-lg.png',id:6}})} style={{width:'45%',height:'13%',padding:18,position:'relative',border: 'solid 1px #e8e8e8',backgroundColor:'#fff',borderRadius:15,cursor:'pointer'}} >             
        <div className='trendImage'>
        <img height='100%' width='100%' src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1705479647242-lg.png'}/>
        </div>
          <div style={{marginTop:'5%',color:'#747474',fontSize:'1.1vw',fontWeight:'600',fontFamily:'Inter',textAlign:'left',width:'18vw'}}>Boosting Profits with Online Booking</div> 
          <div style={{color:'#747474',fontSize:'1.1vw',fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left',width:'16vw'}}>How Online Service Booking Systems Increase Revenue for Local Businesses..</div>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>
          <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
          <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
        </div>
      </div>


      <div onClick={()=>navigate('/blogdetails/trending', { state:{image:'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1677148261662-lg.jpg',id:5}})} style={{width:'45%',height:'46%',padding:18,position:'relative',border: 'solid 1px #e8e8e8',backgroundColor:'#fff',borderRadius:15,cursor:'pointer'}} >             
        <div className='trendImage'>
        <img height='100%' width='100%' src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1677148261662-lg.jpg'}/>
        </div>
          <div style={{marginTop:'5%',color:'#747474',fontSize:'1.1vw',fontWeight:'600',fontFamily:'Inter',textAlign:'left',width:'18vw'}}>Trending Makeup Looks</div> 
          <div style={{color:'#747474',fontSize:'1.1vw',fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left',width:'16vw'}}>Makeup is something that everyone loves to wear .........</div>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>
          <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
          <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
        </div>
      </div>


      <div onClick={()=>navigate('/blogdetails/5-unique-ways-to-surprise', { state:{image:'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1676007914853-lg.png',id:52}})} style={{width:'45%',height:'13%',padding:18,position:'relative',border: 'solid 1px #e8e8e8',backgroundColor:'#fff',borderRadius:15,cursor:'pointer'}} >             
        <div className='trendImage'>
        <img height='100%' width='100%' src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1676007914853-lg.png'}/>
        </div>
          <div style={{marginTop:'5%',color:'#747474',fontSize:'1.1vw',fontWeight:'600',fontFamily:'Inter',textAlign:'left',width:'18vw'}}>5 unique ways to surprise</div> 
          <div style={{color:'#747474',fontSize:'1.1vw',fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left',width:'16vw'}}>Giving surprises to your loved ones is the best .........</div>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>
          <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
          <text style={{fontSize:'0.9vw',fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
        </div>
      </div>   
    
    </div>

)

}

    return(

    
    <>

        <div style={{backgroundColor:'#fff',width:'100%'}}>
        <div className='corosol_div'>
            <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
                ride="carousel"
                interval={2000}>
                {blogItems.length > 1 ? <CarouselIndicators className="carousel-indicators_Landing" items={blogItems} activeIndex={activeIndex} onClickHandler={goToIndex} /> : null}
                {blogSlides}
            </Carousel>
        </div>
        </div>

       <div style={{display:'flex',flexDirection:'row',justifyContent:'center',width:'100%',marginTop:44,marginBottom:44}} >
      
         {/* <div style={{display:'flex',flexDirection:'row', position:'relative',width:'20%'}}> */}
             
              <div className='b_optionsdiv'>
              {options.length != 0  ?
              options.map((each)=>{
                return(
                  <div  onClick={()=>optionChooseHandler(each.id)} style={{margin:24,backgroundColor: selected == each.id ? "#d8c5fc" :null,width:'100%',marginLeft:0,borderRadius:10,paddingTop:14,paddingBottom:15,fontFamily:'Inter',fontWeight:500,fontStyle:'normal',paddingLeft:8,cursor:'pointer'}}>
                  <img src={each.image} height={each.height} width={each.width} style={{marginRight:14,backgroundColor:'#fff',flexGrow:0,padding:2}}/>
                  <text key={each.id} style={{marginBottom:20,fontSize:16,width:'100%',color: selected == each.id ? '#8c52ff' : '#747474'}}>{each.name}</text>
                  </div>
                )
              }):null}
              
              </div>
              

              

              <div style={{width:'70%'}}>
                  <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start'}}>    
                  <text style={{fontSize:'1.7vw',fontWeight:'bold',fontFamily:'Inter'}}>Latest Blogs</text> 

                  </div>
                  <h2 style={{ textAlign: 'center', borderBottom: '1px solid #b8b8b8', lineHeight: '0.1em', opacity: 0.6, height: 7, flexGrow: 0,marginBottom:43 }} />
                 
                  <div  style={{display:'flex',flexDirection:'row',gap:'6vw',position:'relative',width:'100%'}}>

                   {cards}

                    {/* <div style={{fontSize:60}}>Is your Car ready to hit the road?</div> */}
                  
                  </div>
              </div>

        
          
        </div>
        {/* </div>   */}
        </> 
           
    )



}

export default BlogsArticles



