import React,{Component, useState,useLayoutEffect,useContext,useEffect} from "react";
import {Input,Modal,ModalBody,ModalHeader,ModalFooter, Button} from 'reactstrap'
import {useNavigate } from 'react-router-dom';

export default function BlogsArticles_mobile(){

   const [selectedV,setSelectedV] = useState(['All'])
   let date = new Date().toUTCString().slice(5, 16);

   let navigate=useNavigate()

   const seletionhandler = (event) =>{

         setSelectedV(event.target.value)
}

  let cards;

  if(selectedV == 'All'){

    cards =(
       <>

      <div onClick={()=>navigate('/blogdetails/onlineservice', { state:{image:'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1707473574716-lg.png',id:6}})} style={{width:'100%',height:'100%',padding:18,position:'relative',border: 'solid 1px #e8e8e8',backgroundColor:'#fff',borderRadius:15,cursor:'pointer'}} >             
        <div style={{display:'flex',width:'100%',height:150,flexDirection:'column'}}>
          <img height='100%' width='100%' src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1705479647242-lg.png'}/>
        </div>
          <div style={{marginTop:'5%',color:'#747474',fontSize:16,fontWeight:'600',fontFamily:'Inter',textAlign:'left'}}>Boosting Profits with Online Booking</div> 
          <div style={{color:'#747474',fontSize:16,fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left'}}>How Online Service Booking Systems Increase Revenue for Local Businesses..</div>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>
          <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
          <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
        </div>
      </div>

      <div onClick={()=>navigate('/blogdetails/the-best-four-wheeler-repair-maintenance', { state:{image:'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1703049806512-lg.jpg',id:67}})} style={{width:'100%',height:'100%',padding:18,position:'relative',border: 'solid 1px #e8e8e8',backgroundColor:'#fff',borderRadius:15,cursor:'pointer'}} >             
        <div style={{display:'flex',width:'100%',height:150,flexDirection:'column'}}>
          <img height='100%' width='100%' src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1703049806512-lg.jpg'}/>
        </div>
          <div style={{marginTop:'5%',color:'#747474',fontSize:16,fontWeight:'600',fontFamily:'Inter',textAlign:'left'}}>We've got your vehicle's back- all the time, every time!</div> 
          <div style={{color:'#747474',fontSize:16,fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left'}}>The Best Four and Two-Wheeler Repair and Maintenance........</div>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>
          <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
          <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
        </div>
      </div>

      <div onClick={()=>navigate('/blogdetails/dont-go-on-a-rough-patch-with-dandruff', { state:{image:'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1703139383392-lg.png',id:68}})} style={{width:'100%',height:'100%',padding:18,position:'relative',border: 'solid 1px #e8e8e8',backgroundColor:'#fff',borderRadius:15,cursor:'pointer'}} >             
        <div style={{display:'flex',width:'100%',height:150,flexDirection:'column'}}>
          <img height='100%' width='100%' src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1703139383392-lg.png'}/>
        </div>
          <div style={{marginTop:'5%',color:'#747474',fontSize:16,fontWeight:'600',fontFamily:'Inter',textAlign:'left'}}>Don’t go on a rough patch with dandruff this winter</div> 
          <div style={{color:'#747474',fontSize:16,fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left'}}>Snowflakes look pretty in the winter, but not dandruff.........</div>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>
          <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
          <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
        </div>
      </div>
      
      <div onClick={()=>navigate('/blogdetails/trending', { state:{image:'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1677148261662-lg.jpg',id:5}})} style={{width:'100%',height:'100%',padding:18,position:'relative',border: 'solid 1px #e8e8e8',backgroundColor:'#fff',borderRadius:15,cursor:'pointer'}} >             
      <div style={{display:'flex',width:'100%',height:150,flexDirection:'column'}}>
      <img height='100%' width='100%' src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1677148261662-lg.jpg'}/>
      </div>
         <div style={{marginTop:'5%',color:'#747474',fontSize:16,fontWeight:'600',fontFamily:'Inter',textAlign:'left'}}>Trending Makeup Looks</div> 
         <div style={{color:'#747474',fontSize:16,fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left'}}>Makeup is something that everyone loves to wear .........</div>
       <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>
        <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
        <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
      </div>
     </div>
    
      <div onClick={()=>navigate('/blogdetails/summer-coming', { state:{image:'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1655708668172-lg.jpg',id:1}})} style={{width:'100%',height:'100%',padding:18,position:'relative',backgroundColor:'#fff',borderRadius:15,cursor:'pointer',border: 'solid 1px #e8e8e8'}}>             
      <div style={{display:'flex',width:'100%',height:150,flexDirection:'column'}}>
      <img   height='100%' width='100%' src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1655708668172-lg.jpg'} />
      </div>
         <div style={{marginTop:'5%',color:'#747474',fontSize:16,fontWeight:'600',fontFamily:'Inter',textAlign:'left'}}>Is your home summer ready?</div> 
         <div style={{color:'#747474',fontSize:16,fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left'}}>summer is coming and you are still.........</div>
       <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>
        <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
        <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
       </div>
      </div>

        <div style={{width:'100%',height:'100%',padding:18,position:'relative',cursor:'pointer',border: 'solid 1px #e8e8e8',backgroundColor:'#fff',borderRadius:15}} onClick={()=>navigate('/blogdetails/is-your-hair-ready-to-be-styled', { state:{image:'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635950993326-lg.jpg',id:12}})}>             
        <div style={{display:'flex',flexDirection:'column', width:'100%',height:150}}>
            <img   height='100%' width='100%' src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635950993326-lg.jpg'}/>
            </div>
            <div style={{marginTop:'5%',color:'#747474',fontSize:16,fontWeight:'600',fontFamily:'Inter',textAlign:'left'}}>Is your hair ready to be styled?</div> 
            <div style={{color:'#747474',fontSize:16,fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left'}}>Most of us enjoy the pleasant service down the .........</div>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>
            <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
            <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
          </div>
      </div>

         <div style={{width:'100%',height:'100%',padding:18,position:'relative',cursor:'pointer',border: 'solid 1px #e8e8e8',backgroundColor:'#fff',borderRadius:15}} onClick={()=>navigate('/blogdetails/skin', { state:{image:'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635954352488-lg.jpg',id:3}})}>             
           <div style={{display:'flex',flexDirection:'column', width:'100%',height:150}}>
                <img   height='100%' width='100%' src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635954352488-lg.jpg'}/>
              </div>
                <div style={{marginTop:'5%',color:'#747474',fontSize:16,fontWeight:'600',fontFamily:'Inter',textAlign:'left'}}>Skin rituals for healthy skin</div> 
                <div style={{color:'#747474',fontSize:16,fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left'}}> Every woman wants beautiful and healthy skin .........</div>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>                                          
               <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
               <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
              </div>
    
         </div>
    
    
         <div onClick={()=>navigate('/blogdetails/5-unique-ways-to-surprise', { state:{image:'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1676007914853-lg.png',id:52}})} style={{width:'100%',height:'100%',padding:18,position:'relative',border: 'solid 1px #e8e8e8',backgroundColor:'#fff',borderRadius:15,cursor:'pointer'}} >             
      <div style={{display:'flex',width:'100%',height:150,flexDirection:'column'}}>
       <img height='100%' width='100%' src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1676007914853-lg.png'}/>
       </div>
         <div style={{marginTop:'5%',color:'#747474',fontSize:16,fontWeight:'600',fontFamily:'Inter',textAlign:'left'}}>5 unique ways to surprise</div> 
         <div style={{color:'#747474',fontSize:16,fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left'}}>Giving surprises to your loved ones is the best .........</div>
       <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>
        <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
        <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
      </div>
     </div>
    
    
         <div onClick={()=>navigate('/blogdetails/is-your-car-ready-to-hit-the-road', { state:{image:'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635954071929-lg.jpg',id:2}})} style={{width:'100%',height:'100%',padding:18,position:'relative',backgroundColor:'#fff',borderRadius:15,cursor:'pointer',border: 'solid 1px #e8e8e8'}}>             
        <div style={{display:'flex',width:'100%',height:150,flexDirection:'column'}}>
          <img height='100%' width='100%' src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635954071929-lg.jpg'}/>
        </div>
           <div style={{marginTop:'5%',color:'#747474',fontSize:16,fontWeight:'600',fontFamily:'Inter',textAlign:'left'}}>Is your Car ready to hit the road?</div> 
           <div style={{color:'#747474',fontSize:16,fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left'}}>Most of us enjoy the pleasant drive down the .........</div>
         <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>
          <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
          <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
         </div>
        </div>
       
    
      <div style={{width:'100%',height:'100%',padding:18,position:'relative',backgroundColor:'#fff',borderRadius:15,cursor:'pointer',border: 'solid 1px #e8e8e8'}} onClick={()=>navigate('/blogdetails/digital-assistant', { state:{image:'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1636116649167-lg.jpg',id:4}})}>             
        <div style={{display:'flex',width:'100%',height:150,flexDirection:'column'}}>
          <img   height='100%' width='100%' src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1636116649167-lg.jpg'}/>
        </div>
        <div style={{marginTop:'5%',color:'#747474',fontSize:16,fontWeight:'600',fontFamily:'Inter',textAlign:'left'}}>Slash Your Digital Assistant</div> 
        <div style={{color:'#747474',fontSize:16,fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left'}}>As a service professional, whether as an Individual.........</div>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>
          <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
          <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
        </div>
      </div>
     
      <div style={{width:'100%',height:'100%',padding:18,position:'relative',cursor:'pointer',border: 'solid 1px #e8e8e8',backgroundColor:'#fff',borderRadius:15}} onClick={()=>navigate('/blogdetails/why-not-slash', { state:{image:'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1638427989315-lg.jpg',id:42}})}>             
          <div style={{display:'flex',flexDirection:'column', width:'100%',height:150}}>
            <img height='100%' width='100%' src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1638427989315-lg.jpg'}/>
          </div>
          <div style={{marginTop:'5%',color:'#747474',fontSize:16,fontWeight:'600',fontFamily:'Inter',textAlign:'left'}}>Why not slash ?</div> 
          <div style={{color:'#747474',fontSize:16,fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left'}}>Hey, are you looking for after party home cleaning .........</div>
          <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>
            <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
            <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
          </div>
      </div>
         
        
        </>
    
    )

  }
  else if(selectedV == 'Auto Care'){

    cards =(

      <>

      <div onClick={()=>navigate('/blogdetails/the-best-four-wheeler-repair-maintenance', { state:{image:'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1703049806512-lg.jpg',id:67}})} style={{width:'100%',height:'100%',padding:18,position:'relative',border: 'solid 1px #e8e8e8',backgroundColor:'#fff',borderRadius:15,cursor:'pointer'}} >             
        <div style={{display:'flex',width:'100%',height:150,flexDirection:'column'}}>
          <img height='100%' width='100%' src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1703049806512-lg.jpg'}/>
        </div>
          <div style={{marginTop:'5%',color:'#747474',fontSize:16,fontWeight:'600',fontFamily:'Inter',textAlign:'left'}}>We've got your vehicle's back- all the time, every time!</div> 
          <div style={{color:'#747474',fontSize:16,fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left'}}>The Best Four and Two-Wheeler Repair and Maintenance........</div>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>
          <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
          <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
        </div>
      </div>

      <div style={{width:'100%',height:'100%',padding:18,position:'relative',backgroundColor:'#fff',borderRadius:15,cursor:'pointer',border: 'solid 1px #e8e8e8'}} onClick={()=>navigate('/blogdetails/is-your-car-ready-to-hit-the-road', { state:{image:'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635954071929-lg.jpg',id:2}})}>             
        <div style={{display:'flex',width:'100%',height:150,flexDirection:'column'}}>
        <img height='100%' width='100%' src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635954071929-lg.jpg'}/>
      </div>
           <div style={{marginTop:'5%',color:'#747474',fontSize:16,fontWeight:'600',fontFamily:'Inter',textAlign:'left'}}>Is your Car ready to hit the road?</div> 
           <div style={{color:'#747474',fontSize:16,fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left'}}>Most of us enjoy the pleasant drive down the .........</div>
         <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>
          <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
          <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
         </div>
        </div>

      </>
      
    )

  }
  else if(selectedV == 'Personal Care'){

    cards =(
    <>
        <div style={{width:'100%',height:'100%',padding:18,position:'relative',cursor:'pointer',border: 'solid 1px #e8e8e8',backgroundColor:'#fff',borderRadius:15}} onClick={()=>navigate('/blogdetails/skin', { state:{image:'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635954352488-lg.jpg',id:3}})}>             
          <div style={{display:'flex',flexDirection:'column', width:'100%',height:150}}>
            <img   height='100%' width='100%' src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635954352488-lg.jpg'}/>
          </div>
            <div style={{marginTop:'5%',color:'#747474',fontSize:16,fontWeight:'600',fontFamily:'Inter',textAlign:'left'}}>Skin rituals for healthy skin</div> 
            <div style={{color:'#747474',fontSize:16,fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left'}}> Every woman wants beautiful and healthy skin .........</div>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>                                          
              <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
              <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
            </div>
          </div>
        

        <div onClick={()=>navigate('/blogdetails/dont-go-on-a-rough-patch-with-dandruff', { state:{image:'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1703139383392-lg.png',id:68}})} style={{width:'100%',height:'100%',padding:18,position:'relative',border: 'solid 1px #e8e8e8',backgroundColor:'#fff',borderRadius:15,cursor:'pointer'}} >             
          <div style={{display:'flex',width:'100%',height:150,flexDirection:'column'}}>
            <img height='100%' width='100%' src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1703139383392-lg.png'}/>
          </div>
          <div style={{marginTop:'5%',color:'#747474',fontSize:16,fontWeight:'600',fontFamily:'Inter',textAlign:'left'}}>Don’t go on a rough patch with dandruff this winter</div> 
          <div style={{color:'#747474',fontSize:16,fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left'}}>Snowflakes look pretty in the winter, but not dandruff.........</div>
          <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>
            <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
            <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
          </div>
        </div>
     
    </>
 
 )
     
  }
  else if(selectedV == 'Products'){


    cards =(
        <>
      <div style={{width:'100%',height:'100%',padding:18,position:'relative',backgroundColor:'#fff',borderRadius:15,cursor:'pointer',border: 'solid 1px #e8e8e8'}} onClick={()=>navigate('/blogdetails/digital-assistant', { state:{image:'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1636116649167-lg.jpg',id:4}})}>             
        <div style={{display:'flex',width:'100%',height:150,flexDirection:'column'}}>
        <img height='100%' width='100%' src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1636116649167-lg.jpg'}/>
        </div>
          <div style={{marginTop:'5%',color:'#747474',fontSize:16,fontWeight:'600',fontFamily:'Inter',textAlign:'left'}}>Slash Your Digital Assistant</div> 
          <div style={{color:'#747474',fontSize:16,fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left'}}>As a service professional, whether as an Individual.........</div>
          <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>
            <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
            <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
          </div>
        </div>

      <div style={{width:'100%',height:'100%',padding:18,position:'relative',cursor:'pointer',border: 'solid 1px #e8e8e8', backgroundColor:'#fff',borderRadius:15}} onClick={()=>navigate('/blogdetails/why-not-slash', { state:{image:'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1638427989315-lg.jpg',id:42}})}>             
        <div style={{display:'flex',flexDirection:'column', width:'100%',height:150}}>
          <img height='100%' width='100%' src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1638427989315-lg.jpg'}/>
        </div>
        <div style={{marginTop:'5%',color:'#747474',fontSize:16,fontWeight:'600',fontFamily:'Inter',textAlign:'left'}}>Why not slash ?</div> 
        <div style={{color:'#747474',fontSize:16,fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left'}}>Hey, are you looking for after party home cleaning .........</div>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>
          <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
          <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
        </div>
      </div>

      <div onClick={()=>navigate('/blogdetails/the-best-four-wheeler-repair-maintenance', { state:{image:'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1703049806512-lg.jpg',id:67}})} style={{width:'100%',height:'100%',padding:18,position:'relative',border: 'solid 1px #e8e8e8',backgroundColor:'#fff',borderRadius:15,cursor:'pointer'}} >             
        <div style={{display:'flex',width:'100%',height:150,flexDirection:'column'}}>
          <img height='100%' width='100%' src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1703049806512-lg.jpg'}/>
        </div>
          <div style={{marginTop:'5%',color:'#747474',fontSize:16,fontWeight:'600',fontFamily:'Inter',textAlign:'left'}}>We've got your vehicle's back- all the time, every time!</div> 
          <div style={{color:'#747474',fontSize:16,fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left'}}>The Best Four and Two-Wheeler Repair and Maintenance........</div>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>
          <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
          <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
        </div>
      </div>
        
        </>
    
    )
    
  }
  else {

    cards =(
        <>

      <div onClick={()=>navigate('/blogdetails/onlineservice', { state:{image:'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1707473574716-lg.png',id:6}})} style={{width:'100%',height:'100%',padding:18,position:'relative',border: 'solid 1px #e8e8e8',backgroundColor:'#fff',borderRadius:15,cursor:'pointer'}} >             
        <div style={{display:'flex',width:'100%',height:150,flexDirection:'column'}}>
          <img height='100%' width='100%' src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1705479647242-lg.png'}/>
        </div>
          <div style={{marginTop:'5%',color:'#747474',fontSize:16,fontWeight:'600',fontFamily:'Inter',textAlign:'left'}}>Boosting Profits with Online Booking</div> 
          <div style={{color:'#747474',fontSize:16,fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left'}}>How Online Service Booking Systems Increase Revenue for Local Businesses..</div>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>
          <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
          <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
        </div>
      </div>
      
      <div onClick={()=>navigate('/blogdetails/trending', { state:{image:'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1677148261662-lg.jpg',id:5}})} style={{width:'100%',height:'100%',padding:18,position:'relative',border: 'solid 1px #e8e8e8',backgroundColor:'#fff',borderRadius:15,cursor:'pointer'}} >             
        <div style={{display:'flex',width:'100%',height:150,flexDirection:'column'}}>
        <img height='100%' width='100%' src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1677148261662-lg.jpg'}/>
        </div>
          <div style={{marginTop:'5%',color:'#747474',fontSize:16,fontWeight:'600',fontFamily:'Inter',textAlign:'left'}}>Trending Makeup Looks</div> 
          <div style={{color:'#747474',fontSize:16,fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left',}}>Makeup is something that everyone loves to wear .........</div>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>
          <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
          <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
        </div>
      </div>
    
    
     <div onClick={()=>navigate('/blogdetails/5-unique-ways-to-surprise', { state:{image:'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1676007914853-lg.png',id:52}})} style={{width:'100%',height:'100%',padding:18,position:'relative',border: 'solid 1px #e8e8e8',backgroundColor:'#fff',borderRadius:15,cursor:'pointer'}} >             
      <div style={{display:'flex',width:'100%',height:150,flexDirection:'column'}}>
       <img height='100%' width='100%' src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1676007914853-lg.png'}/>
       </div>
         <div style={{marginTop:'5%',color:'#747474',fontSize:16,fontWeight:'600',fontFamily:'Inter',textAlign:'left'}}>5 unique ways to surprise</div> 
         <div style={{color:'#747474',fontSize:16,fontWeight:'normal',fontFamily:'Inter',  letterSpacing: 0.08,textAlign:'left'}}>Giving surprises to your loved ones is the best .........</div>
       <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5%'}}>
        <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>{date}</text>
        <text style={{fontSize:12,fontFamily:'Inter',fontWeight:'500',color:'#747474',lineHeight:'normal',letterSpacing:'normal'}}>2 mins read</text>
      </div>
     </div>
  
  </>)
    
  }


 
    return(
       <>
       <div>
       <img style={{width:'100%',height:250}}  src="https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684421293381-lg.png"/>
       </div>
       <div style={{  marginLeft: '7%', marginRight: '7%',paddingTop:22  }}>
         <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>    
             <text style={{fontSize:24,fontWeight:'bold',fontFamily:'Inter',}}>Latest Blogs</text>
             <Input
                                                style={{ color: '#8c52ff',fontSize:14,fontWeight:'normal',fontFamily:'Inter',width:140,border:0 }}
                                                onChange={seletionhandler}
                                                placeholder="Subject"
                                                value={selectedV}
                                                type="select">
                                                <option value='All'>All</option>
                                                <option value='Auto Care'>Auto Care</option>
                                                <option value='Personal Care'>Personal Care</option>
                                                <option value='Products'>Products</option>
                                                <option value='Slash@stories'>Slash@stories</option>

                                       </Input>
         </div>
         <h2 style={{ textAlign: 'center', borderBottom: '1px solid #b8b8b8', lineHeight: '0.1em', opacity: 0.6, height: 7, flexGrow: 0,marginBottom:43 }} />
         </div>
         <div style={{  marginLeft: '7%', marginRight: '7%'}}>
         <div style={{display:'flex',flexWrap:'wrap',justifyContent:'space-between',width:'100%',height:'100%', overflow:'hidden',gridTemplateColumns:'repeat(auto-fill,184px)',gap:40,marginBottom:40}}>
         {cards}
         </div>
         </div>
        </>
    )
}