import React, { Component, Text, useState, useRef } from 'react'

import mobImage  from '../../Assets/mobImage.png'                 
import listImage  from '../../Assets/listImage.png'
import receiveImage  from '../../Assets/recieveImage.png'
import deliverImage  from '../../Assets/deliverImage.png'
import { Container, Row, Col,Card,CardBody, CarouselItem, Carousel, Navbar,CarouselIndicators, } from 'reactstrap'
import AreYouPro_benfitCards from './AreYouPro_benfitCards'
import CitySpecificBannerHome from './CitySpecificBannerHome';
import DownloadOurApp from './DownloadOurApp';
import DownloadOurApp_Mobile from './DownloadOurApp_Mobile';
import {Helmet} from 'react-helmet';
import autoLog from '../../Assets/autoAreupro.png'
 



function AreYoupro(){

  const [ activeIndex,setActiveIndex ]=useState(0);

  let blogItems =[
    {
        key: 1,src:{},
        altText: (
          <div><div style={{top:'45%',left:'6%',zIndex:10,right:'15%',position:'absolute',display:'flex',flexDirection:'column'}}>
                <text className='blogText'>Are you a service professional ?</text>
                    <text className='blogText2'>With Slash app in your hand, you can easily expand your business and earn more money without any hassle. Join us now and experience the power of being connected with potential clients in your area. Try it today!</text>
                </div>
          </div>
        ),
    },
    
  ]
  
  let blogSlides = blogItems.map((item) => {
               
    return (
  
    <CarouselItem key={item.key}>
        <div className="CarousalContainer_Sv2" style={{width:'100%',background: 'linear-gradient(to top, rgba(0, 0, 0) 100%, rgba(0, 0, 0) 0%)'}}>
            {item.altText}
            <img className='imgStylesv2_HS'  src="https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684392573279-lg.png"/>
            {/* <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1666875231964-lg.jpg'} className='Carousal_policy_mobile'/> */}
        </div>
    </CarouselItem>
  
  );
  });
  
  const next = () => {
    // if (animating) return;
    const nextIndex = activeIndex === blogItems.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  
  const previous = () => {
    // if (animating) return;
    const nextIndex = activeIndex === 0 ? blogItems.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    //   console.log("newIndex",newIndex)
    // if (this.animating) return;
    setActiveIndex(newIndex);
  
  }
  


    return(
      <>

        <Helmet>
            <title>Slash Pro | Grow your business with Slash & become a Pro</title>
            <meta name="description" content="Slash connects you with customers in your area & offers exclusive discounts on the platform. Promote and elevate your business to next level. Download SlashPro" />
        </Helmet>
        <Helmet>
            <title>Promote your services effectively | Join Slash Professionals</title>
            <meta name="description" content="Looking ways to promote & take your business to next level ? Download Slash. Connect with different customers everyday near your area. " />
        </Helmet>

        <div style={{backgroundColor:'#fff',width:'100%'}}>
        <div className='corosol_div'>
            <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
                ride="carousel"
                interval={2000}>
                {/* {blogItems.length > 1 ? <CarouselIndicators className="carousel-indicators_Landing" items={blogItems} activeIndex={activeIndex} onClickHandler={goToIndex} /> : null} */}
                {blogSlides}
            </Carousel>
        </div>
        </div>

        <div style={{marginTop:44}}>
          

            <div style={{textAlign:'center',display:'flex',flexDirection:'column'}}>
                <text style={{fontFamily:'Inter',fontSize:24,fontWeight:'bold',color:'#1c1b1f',fontStyle:'normal',lineHeight:'normal'}}>Why Slash?</text>
                <text style={{fontSize:14,fontWeight:'normal',color:'#9f9f9f'}}>An ultimate destination for service professionals looking to grow their business.</text>
            </div>

            <div  style={{marginTop:160}}> 

            <AreYouPro_benfitCards/>
            </div>
            
            {/* <div style={{textAlign:'center',display:'flex',flexDirection:'row',justifyContent:'space-between',marginLeft:138,marginRight:138,gap:'1%',marginTop:44,marginBottom:120}}>
          
          
            <div style={{position:'relative',width:'18vw'}}>
              <div style={{ height:'13vw',borderRadius: 15,border:'solid 2px #fff',position:'relative',textAlign:'center'}}/>
              <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginTop:-160}}>
              <img height='150' width='150' src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684382387720-lg.png'}/>
              </div>
              <div  style={{display:'flex',flexDirection:'column',justifyContent:'center',position:'absolute',marginTop:16}}>
                <text style={{fontSize:16,color: '#000',fontWeight:600,marginBottom:10}} >Attract new Customers</text>
                <text style={{fontSize:14}}>Showcase your services and attract new customers effortlessly</text>    
              </div>
            </div>

            <div style={{position:'relative',width:'18vw'}}>
            <div style={{ height:'13vw',borderRadius: 15,border:'solid 2px #fff',position:'relative',textAlign:'center'}}/>
              <img style={{position:'absolute',top:0,left:0,right:0}} height='191' width='240' src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684382387720-lg.png'}/>
              <div  style={{display:'flex',flexDirection:'column',justifyContent:'center',position:'absolute',marginTop:16}}>
                <text style={{fontSize:16,color: '#000',fontWeight:600,marginBottom:10}}>Free marketing</text>
                <text style={{fontSize:14}}>Our free marketing for you will get you seen by potential clients in no time.</text>    
              </div>
            </div>

            <div style={{position:'relative',width:'18vw'}}>
            <div style={{ height:'13vw',borderRadius: 15,border:'solid 2px #fff',position:'relative',textAlign:'center'}}/>
              <img style={{position:'absolute',top:0,left:0,right:0}} height='191' width='240' src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684382387720-lg.png'}/>
              <div  style={{display:'flex',flexDirection:'column',justifyContent:'center',position:'absolute',marginTop:16}}>
                <text style={{fontSize:16,color: '#000',fontWeight:600,marginBottom:10}}>Fill up your low demand hours</text>
                <text style={{fontSize:14}}>Boosting customers with specials  discounts and filling up those hours when demand is low</text>    
              </div>
            </div>

            <div style={{position:'relative',width:'18vw'}}>
            <div style={{ height:'13vw',borderRadius: 15,border:'solid 2px #fff',position:'relative',textAlign:'center'}}/>
              <img style={{position:'absolute',top:0,left:0,right:0}} height='191' width='240' src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684382387720-lg.png'}/>
              <div  style={{display:'flex',flexDirection:'column',justifyContent:'center',position:'absolute',marginTop:16}}>
                <text style={{fontSize:16,color: '#000',fontWeight:600,marginBottom:10}}>Save money with special discounts</text>
                <text style={{fontSize:14}}>Opportunity to increase earnings by getting extra bookings</text>    
              </div>
            </div>
            </div> */}
            <div style={{height:228,backgroundColor:'#d8c5fc',marginTop:180,marginBottom:80,textAlign:'center',paddingTop:44}}>
            <text style={{fontSize:24,color:'#1c1b1f',fontWeight:'bold'}}>How it works ?</text>

            <div style={{ display:'flex',flexDirection:'row',justifyContent:'center ',alignItems:'center'}} >
                    <div >
                       <text  style={{color:'#461c99',fontSize:30,fontWeight:'bold'}} className='oneSt'>1</text>
                    </div>
                    <hr style={{ borderTop:'1px solid black',marginBottom:10,marginTop: 60,marginLeft:0,marginRight: 0,height: 50,width:'13%'}}></hr>
                    <div>
                    <text style={{color:'#461c99',fontSize:30,fontWeight:'bold'}} className='twoSt'>2</text>
                    </div>
                    <hr style={{ borderTop:'1px solid black',marginBottom:10,marginTop: 60,marginLeft:0,marginRight: 0,height: 50,width:'13%'}}></hr>
                    <div>
                      <text  style={{color:'#461c99',fontSize:30,fontWeight:'bold'}} className='threeSt'>3</text>
                    </div>
                    <hr style={{ borderTop:'1px solid black',marginBottom:10,marginTop: 60,marginLeft:0, marginRight: 0,height: 50,width:'13%'}}></hr>
                    
                    
                    <div>
                      <text style={{color:'#461c99',fontSize:30,fontWeight:'bold'}} className='threeSt'>4</text>
                    </div>
                </div>
         <div style={{display:'flex',flexDirection:'row',justifyContent:'center',gap:'7.5%',alignItems:'center',marginTop:-5,width:'100%'}}>
           
            <div style={{position:'relative'}}>
            <div style={{height:100,width:100,backgroundColor:'#fff',borderRadius:'50%',border:'solid 1px #461c99'}}>
                <img style={{position:'absolute',top:'50%',left:'50%',transform:'translate(-50%,-50%)'}} src={mobImage} />
            </div> 
            <div  style={{display:'flex',flexDirection:'column',justifyContent:'center',position:'absolute',marginTop:16,marginLeft:-25}}>
                <text style={{fontSize:16,color: '#000',fontWeight:600,marginBottom:10,width:150}} >Download the app</text>
                <text style={{fontSize:14,marginLeft:-10}}>Download Slash Pro app and register</text>    
            </div>  
            </div>
           
           
            <div style={{position:'relative'}}>
            <div style={{height:100,width:100,backgroundColor:'#fff',borderRadius:'50%',position:'relative',border:'solid 1px #461c99'}}>
                <img style={{position:'absolute',top:'50%',left:'50%',transform:'translate(-50%,-50%)'}} src={listImage} />
            </div>  
            <div  style={{display:'flex',flexDirection:'column',justifyContent:'center',position:'absolute',marginTop:16,marginLeft:-25}}>
                <text style={{fontSize:16,color: '#000',fontWeight:600,marginBottom:10,width:150}} >List your services</text>
                <text style={{fontSize:14,marginLeft:-10}}>List your service,and update business hours</text>    
            </div>  
            </div>


            <div style={{position:'relative'}}>
            <div style={{height:100,width:100,backgroundColor:'#fff',borderRadius:'50%',position:'relative',border:'solid 1px #461c99'}}>
                <img style={{position:'absolute',top:'50%',left:'50%',transform:'translate(-50%,-50%)'}} src={receiveImage} />
            </div>  
            <div  style={{display:'flex',flexDirection:'column',justifyContent:'center',position:'absolute',marginTop:16,marginLeft:-25}}>
                <text style={{fontSize:16,color: '#000',fontWeight:600,marginBottom:10,width:150}} >Receive booking</text>
                <text style={{fontSize:14,marginLeft:-10}}>Receive booking appointments</text>    
            </div>  
            </div>



            <div style={{position:'relative'}}>
            <div style={{height:100,width:100,backgroundColor:'#fff',borderRadius:'50%',position:'relative',border:'solid 1px #461c99'}}>
                <img style={{position:'absolute',top:'50%',left:'50%',transform:'translate(-50%,-50%)'}} height={50} src={deliverImage} />
            </div>   
            <div  style={{display:'flex',flexDirection:'column',justifyContent:'center',position:'absolute',marginTop:16,marginLeft:-25}}>
                <text style={{fontSize:16,color: '#000',fontWeight:600,marginBottom:10,width:150}} >Deliver services</text>
                <text style={{fontSize:14,marginLeft:-10}}>Deliver bookings to millions of customers with ease</text>    
            </div>  
            </div>
      
      
      
        </div>


       </div>

       <div style={{textAlign:'center',display:'flex',flexDirection:'column',marginTop:300,color:'#1c1b1f',marginBottom:44}}>
          <text style={{fontSize:24,fontWeight:'bold'}} >Our pricing plan</text>
          <text style={{fontSize:14,color:'#9f9f9f',fontWeight:'normal'}}>Book services at best price in your neighbourhood</text>
      </div>
          
  
  
      <div style={{display:'flex',flexDirection:'row',justifyContent:'center',marginLeft:'12%',marginRight:'12%',gap:'1%'}}>
      
      <div style={{display:'flex',flexDirection:'row',  border: 'solid 0.5px #eee',backgroundColor:'#fff',borderRadius:12,boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.15)',marginBottom:100,justifyContent:'center',alignItems:'center',padding:14}}>
      {/* <div style={{height:36,width:36,backgroundColor:'#b8b8b8',borderRadius:'50%',position:'relative',marginRight:13}}/> */}
      <img src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684808267789-lg.png'} />
      <div style={{display:'flex',flexDirection:'column',paddingLeft:5}}>
      <text style={{color:'#461c99',fontWeight:500,fontSize:12}}>Platform fee</text>
      <text style={{fontSize:12,width:'15vw'}}>Percentage of net price paid by customer to purchase your offer (%)</text>
      </div> 
      </div>

      <div style={{display:'flex',flexDirection:'row',  border: 'solid 0.5px #eee',backgroundColor:'#fff',borderRadius:12,boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.15)',marginBottom:100,justifyContent:'center',alignItems:'center',padding:14,}}>
      {/* <div style={{height:36,width:36,backgroundColor:'#fff',borderRadius:'50%',position:'relative',backgroundColor:'#b8b8b8',marginRight:13}}/> */}
      <img src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684808247094-lg.png'}/>
      <div style={{display:'flex',flexDirection:'column',paddingLeft:5}}>
      <text style={{color:'#461c99',fontWeight:500,fontSize:12}}>Your earnings</text>
      <text style={{fontSize:12,width:'15vw'}}>Net price of the service - Our Platform fee</text>
      </div> 
      </div>

      <div style={{display:'flex',flexDirection:'row',  border: 'solid 0.5px #eee',backgroundColor:'#fff',borderRadius:12,boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.15)',marginBottom:100,justifyContent:'center',alignItems:'center',padding:14}}>
      {/* <div style={{height:36,width:36,backgroundColor:'#fff',borderRadius:'50%',position:'relative',backgroundColor:'#b8b8b8',marginRight:13}}/> */}
      <img src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684807988425-lg.png'}/>
      <div style={{display:'flex',flexDirection:'column',paddingLeft:5}}>
      <text style={{color:'#461c99',fontWeight:500,fontSize:12}}>Payment duration</text>
      <text style={{fontSize:12,width:'15vw'}}>Payment is credited in your account within 1-2 business days of service</text>
      </div> 
      </div>
      </div>

      <div style={{textAlign:'center',display:'flex',flexDirection:'column',marginTop:-70,color:'#1c1b1f',marginBottom:44}}>
          <a href="/FAQs"><text style={{fontSize:16,fontWeight:600,color:'#461c99',textDecoration:'underline'}} >Want to know more ?</text></a>
      </div>

      {/* City Specific Banner*/}
      <div className="MainReusable_Container_Home">
            <CitySpecificBannerHome title={'Ready to grow with us ?'} desc={"With our platform, you'll receive free marketing, an influx of new clients during low-demand hours, and the opportunity to earn more money.Don't wait any longer to elevate your business- join the Slash community today!"} />
         </div>

         {/* Download our App */}
         <div className="MainReusable_Container_Home">
             <DownloadOurApp link={'https://play.google.com/store/apps/details?id=com.quickfindmerchant&utm_campaign=Merchant_Guide&utm_source=Website&utm_medium=DigitalMarketingSources'} link2={'https://apps.apple.com/us/app/slash-merchant/id1489211413?ls=1/?utm_campaign=Merchant_Guide&utm_source=Website&utm_medium=DigitalMarketingSources'}/> 
         </div>



        </div>
      </>
        
    )


}

export default AreYoupro;