import React,{Component, useState,useLayoutEffect,useContext,useEffect,} from "react";
import { resolvePath } from "react-router-dom";
import {Input,Modal,ModalBody,ModalHeader,ModalFooter, Button} from 'reactstrap'
import deleteIcon from '../../../Assets/iconsDelete24Px.png'
import cross from '../../../Assets/crossIcon.png'
import RatingComponent from "./RatingComponent";
import SubmitButtonSmall from "./submitButtonSmall";
import CategoriesButton from "../../CategoriesButton";
import customFetchData from '../customFetchData';
import {globalState} from '../../../GlobalState/globalState';
import ServiceDetails from '../../../../New_Website_V2/Components/Custom/UI/ServiceDetails'
import CloseIcon from '../../../../New_Website_V2/Assets/icons-closeModal.png'
import { useNavigate, NavLink } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { translate } from "react-range/lib/utils";
import { ToastContainer } from "react-toastify";
import Close_Cross from "../../../../New_Website_V2/Components/Custom/UI/Close_Cross";
import moment from "moment";
import FetchCurrentLocation from "../FetchCurrentLocation";



function WishList(props){ 

   const navigate = useNavigate()

   let [state,dispatch]=useContext(globalState)
   const [error,setErrorMsg] = useState('')
   const [loading,setLoading]=useState(false)
   const [selected, setSelected] = useState(99980) 
   const [selected1, setSelected1] = useState(99980) 
   const [categoryButtons,setcategoryButtons] = useState([])
   const [categoryButtonsServices,setcategoryButtonsServices] = useState([])
   const [professionalData ,setProfessionalData] = useState([])
   const [serivcesData ,setServicesData] = useState([])
   const [serivcesDataCopy ,setServicesDataCopy] = useState([])
   const [professionalDataCopy ,setProfessionalDataCopy] = useState([])
   const [showModal,setShowModal]= useState({Modal:false,wshLstPrid:null})
   const [showModal1,setShowModal1]= useState({Modal1:false,wshLstPrid1:null})
   const [ProOrSer,setProOrSer] = useState(['Services'])
   const [serviceDetailsModal,setServiceDetailsModal]=useState(false)
   const [serviceDetails,setServiceDetails]=useState()
   const [serviceModalData,setServiceModalData]=useState([])
   const [allDupCategories,setAllDupCategories]=useState([])
   const [ProData,setProData] = useState([]) 
   const [DataSer, setDataser]= useState([]) 
   const DeepClone = require("rfdc/default")
  const [city,setCity]=useState('')
  const [latitude,setLatitude]=useState()
  const [longitude,setLongitude]=useState()
  const [stateOrProvince,setStateOrProvince]=useState('')
  const [AtHomeToggle,setAtHomeToggle] = useState(true)



   useEffect(()=>{
    fetchCurrentLocationHandler()
    setLoading(true)
    // fetchCategories()
    fetchWishListData()
    fetchWishListDataServices()
    fetchCategoriesServices()

  },[])
const fetchCurrentLocationHandler=async()=>{
  if(state.address.latitude === null && state.address.longitude === null){
    let addressDetails= await FetchCurrentLocation(state,dispatch)
    console.log('address details',addressDetails)
    let {city,country,curr_location_latitude,curr_location_longitude,stateOrProvince}=addressDetails
    setLatitude(curr_location_latitude)
    setLongitude(curr_location_longitude)
    setCity(city)
    setStateOrProvince(stateOrProvince)
  }else{
    setLatitude(state.address.latitude)
    setLongitude(state.address.longitude)
    setCity(state.address.city)
    setStateOrProvince(state.address.stateOrProvince)
  }
}
  const fetchCategoriesServices = async () => {
    let method="POST",body={cPrid:state.user.id},url="/website/city/avail/categories/sv2"
    let {fetch,fetchedData}= await customFetchData(method,body,url,state,dispatch)   
    if(fetch){  
    let cats = fetchedData.catResults
        if(fetchedData.catResults.length){
           cats.unshift({QF_SERVICE_CATEGORY_PRID:99980,category_name:'All'})
           setcategoryButtonsServices(cats)
        }else{
          setcategoryButtonsServices([])
        }
    }else{
      setcategoryButtonsServices([])
      console.log('error')
    }
}

// console.log('servicccse',serviceDetailsModal)

const fetchWishListDataServices = async ()=>{
  let  method="POST",body={wishLstType:'service',cPrid:state.user.id},url="/website/fetch/wishList/sv2"
  let {fetch,fetchedData}= await customFetchData(method,body,url,state,dispatch)
  console.log('fectcha',fetchedData)   
  if(fetch){  
    if(fetchedData.wishListData.length){
       
       setServicesData(fetchedData.wishListData)
       setServicesDataCopy(fetchedData.wishListData) 
       setErrorMsg('')
       setLoading(false)
    }else{
      setErrorMsg('No wishlisted services found!')
    }
  }else{
    console.log('error')
  }
}


//   const fetchCategories = async ()=>{
//     let  method="POST",body={cPrid:state.user.id},url="/website/wishlist/pro/categories/sv2"
//     let {fetch,fetchedData}= await customFetchData(method,body,url,state,dispatch)   
//     if(fetch){  
//      let cats = fetchedData.procatResults
//      if(cats.length !== 0){
//       setAllDupCategories(cats)
//       console.log('cats.length',cats )
//       cats.unshift({qf_service_category_prid:99980,category_name:'All'})
//       const uniqueProCatshow = cats.filter((obj, index) => cats.findIndex((item) => item.category_name === obj.category_name) === index)
//        //  setData(cats)
//        //  setData1(cats)
//        setcategoryButtons(uniqueProCatshow)
//        console.log('uniqueProCatshow',uniqueProCatshow)

//      }else{
//       setcategoryButtons([])
//      }
//     //  console.log('procatResults',cats)
    

//     }else{
//       console.log('error')
//     }
// }



  const fetchWishListData = async () => {

    let  method="POST",body={wishLstType:'professional',cPrid:state.user.id},url="/website/fetch/wishList/sv2"
    let {fetch,fetchedData}= await customFetchData(method,body,url,state,dispatch)
    console.log('fectch dataPRO',fetchedData)   
    if(fetch){  

      let cats = fetchedData.wishListData

      if(fetchedData.wishListData.length){
        
          setAllDupCategories(cats)
          
          // setProfessionalDataCopy(fetchedData.wishListData)
          const uniquePro= cats.filter((obj, index) => cats.findIndex((item) => item.QF_MERCHANT_PERSONNEL_PRID === obj.QF_MERCHANT_PERSONNEL_PRID) === index)
          
          cats.unshift({QF_SERVICE_CATEGORY_PRID:99980,category_name:'All'})
          
          const uniqueProCatshow = cats.filter((obj, index) => cats.findIndex((item) => item.category_name === obj.category_name) === index)
           
          setProfessionalData(uniquePro)
          setErrorMsg('')
          setLoading(false)
          setcategoryButtons(uniqueProCatshow)
      }else{
        setProfessionalData([])
        setLoading(false)
        setcategoryButtons([])

        setErrorMsg('No wishlisted services found!')
      }
    }else{
      console.log('error')
    }
  }


  

  async function removeFrmWshLst(id,MPRID){

    let  method="POST",body={id:id},url="/website/wishList/delete/sv2"
    let {fetch,fetchedData}= await customFetchData(method,body,url,state,dispatch)  
    if(fetch){  

      let UpdateprofessionalData = professionalData.filter((each) => {return each.QF_WISHLIST_PRID != id})
       
      if(!UpdateprofessionalData.length){

        setProfessionalDataCopy([])
        setcategoryButtons([])
        setAllDupCategories([])
        setProfessionalData([])
       }
       else{
       let UpdateAllDupCategories = allDupCategories.filter((each) => {return each.QF_WISHLIST_PRID != id})
       const deletedProDupcategoriesRemoved  = UpdateAllDupCategories.filter((obj, index) => UpdateAllDupCategories.findIndex((item) => item.category_name === obj.category_name) === index)
       //  let UpdatecategoryButtons = categoryButtons.filter((each) => {return each.QF_WISHLIST_PRID != id})
       setAllDupCategories(UpdateAllDupCategories)
       setcategoryButtons(deletedProDupcategoriesRemoved)
       setProfessionalDataCopy(UpdateAllDupCategories)
       setProfessionalData(UpdateprofessionalData)
       setSelected(99980)


       }

       setShowModal({...showModal,Modal:false})
       setLoading(false)
    }
    else{
      console.log('error')
    }
 }



   async function removeFrmWshLstServices(id){


    // return console.log(id);
      let  method="POST",body={id:id},url="/website/wishList/delete/sv2"
      let {fetch,fetchedData}= await customFetchData(method,body,url,state,dispatch)  
      // console.log(fetch,fetchedData)
      if(fetch){  
         let afterRemoveItems = serivcesData.filter((each) => {return each.QF_WISHLIST_PRID != showModal1.wshLstPrid1})

          if(!afterRemoveItems.length){
            setServicesDataCopy([])
           }
          
            // setServicesDataCopy(removedpro)
        //  setdupRemovedData(uniqueProCatshow)
            setServicesData(afterRemoveItems)
            setServicesDataCopy(afterRemoveItems)
        //  setCopyCards(afterRemoveItems)
           setShowModal1({...showModal,Modal:false})
           setLoading(false)
      }
      else{
        console.log('error')
      }
   }

   const handleButtonClick = (id,cat) => {



    if(id!=99980){
        setSelected(id) 
        let catSelectedOnly = allDupCategories.filter((each) => {
            return each.category_name == cat} )

        const uniquePro= catSelectedOnly.filter((obj, index) => catSelectedOnly.findIndex((item) => item.QF_MERCHANT_PERSONNEL_PRID === obj.QF_MERCHANT_PERSONNEL_PRID) === index)    

      // return console.log('catSelectedOnlynew',uniquePro)

            if(catSelectedOnly.length){
             setProfessionalDataCopy(uniquePro)  
            }else{
              setProfessionalDataCopy([])
            }

    }else{
      setErrorMsg('')
      setSelected(id)
      // setCards(copyCards)
    }

   }

     const handleButtonClickServices  = (enabledOrNot) => {

      // return console.log(enabledOrNot);


        
     setAtHomeToggle(AtHomeToggle => !AtHomeToggle )

        const copyserivcesData = [...serivcesDataCopy]

        if(enabledOrNot){
            const atHomeMinusFilter = copyserivcesData.filter(each => each.SERVICE_AT_HOME_FLAG != 1)
            setServicesData(atHomeMinusFilter)
        }else{
            setServicesData(copyserivcesData)
        }

     }


      const PorShandler = (event) =>{

        setProOrSer(event.target.value)
       
    }



    const onPressProExplore = (item) =>{
      // console.log('explore',item.QF_MERCHANT_PERSONNEL_PRID)
          let offers = state.services
          // console.log('explore1',offers)

          // let filteredServices,proData;
          // filteredServices = offers.filter(outerItem => {
          //     return outerItem[0].QF_MERCHANT_PERSONNEL_PRID == item.QF_MERCHANT_PERSONNEL_PRID
          // })
          // console.log('services', filteredServices)
          // if (filteredServices.length !== 0) {
              navigate({pathname:`/allservices/${item.MERCHANT_NAME.replaceAll(" ","","g")}`,search:`?proId=${item.QF_MERCHANT_PERSONNEL_PRID}`}
              // , 
            //   { state: { proData: filteredServices[0][0],offers: filteredServices[0],proChecked:true,fromWihslist:true,
            //     categories:DeepClone(state.categories),subCategories:DeepClone(state.subcategories),
            //     addressDetails:
            //  {
            //     totalAddress: state.address.totalAddress,
            //     addressLine1: state.address.addressLine1,
            //     country: state.address.country,
            //     city: state.address.city,
            //     stateOrProvince: state.address.stateOrProvince,
            //     zipcode: state.address.zipcode,
            //     district: state.address.district,
            //     latitude: state.address.latitude,
            //     longitude: state.address.longitude,
            //     currentAddress: state.address.currentAddress,
            //     searchAddress: state.address.searchAddress,
            //     currentLocationDenied: state.address.currentLocationDenied
            //  },allServices:DeepClone(state.services)} }
             )
          // }
     }

    //  let serviceDetailsDisplayModal;

     const serviceExplorePress = (data) =>{

      let offers=[...serivcesData]
      let filteredPro =[]
      filteredPro = offers.filter((each)=>{

           return each.QF_MERCHANT_SERVICE_PRID == data.QF_MERCHANT_SERVICE_PRID
      })


      // return console.log('trace',filteredPro)
    
            
      // console.log('pro data',filteredPro)
      if(filteredPro.length!==0){

        setProData(filteredPro[0])
        setDataser(filteredPro[0])
        setServiceDetailsModal(true)


      }
    
    }   

    console.log('data',serivcesData)

    let data=[]
   

    if(ProOrSer == 'Services'){

      if(serivcesData.length !== 0  && selected1 == 99980 ){ 


        data =  <div style={{display:'grid',justifyContent: 'space-between', marginTop:44,width:'100%',gridTemplateColumns:'repeat(auto-fill,184px)',overflow:'hidden',height:'100%'}}> 
        {serivcesData.map((each,index)=>{

          return(
  
            <div  key={index} onClick={()=>serviceExplorePress(each)} style={{borderRadius: 4.2,shadowColor: "rgba(0, 0, 0, 0.04)", shadowOffset: {width: 0,height: 0 },shadowRadius: 31,shadowOpacity: 0,float: 'left',marginBottom:42,position:'relative'}}>
              <div> 
                 <img style={{height:174,width:184,borderTopLeftRadius:15,borderTopRightRadius:15}} src={each.image_link}></img>
                 <div style={{backgroundColor:'#ffffff',height:26,width:26,position:'absolute',top:16,right:'8%',zIndex:100,borderRadius:8,}}>
                  <img  onClick={(e)=>{
                         e.stopPropagation()
                         return setShowModal1({...showModal1,Modal:true,wshLstPrid1:each.QF_WISHLIST_PRID})}} style={{margin:4,cursor:'pointer',zIndex:100}} src={deleteIcon} />
                 </div>
              { each.MERCHANT_SERVICE_RATING ? <div style={{backgroundColor:'#ffffff',position:'absolute',top:16,left:'8%',zIndex:10,borderRadius:8}}>
                          <RatingComponent size={9} rating={each.MERCHANT_SERVICE_RATING} webUsage={true} style={{fontsize:10}} width={45} height={26} foNsize={10}/>
                        </div> : null}
             </div>
              
              <div style={ each.SERVICE_AT_HOME_FLAG == 1 ? {borderRadius: 15,backgroundColor:  each.SERVICE_AT_HOME_FLAG == 1 ? "#FFFFFF" : "#8c52ff",marginTop:-12,paddingTop:16,zIndex:10,width:'100%',position:'relative',overflow:'hidden',shadowColor: "rgba(103, 52, 204, 0.1)",shadowOffset: {width: 0,height: 2},shadowRadius: 4,shadowOpacity: 1, borderColor: "#ffffff",  borderStyle: "solid",borderWidth: 1,} : {borderRadius: 15,backgroundColor:  each.SERVICE_AT_HOME_FLAG ==1 ? "#FFFFFF" : "#8c52ff",marginTop:-12,paddingTop:16,zIndex:10,width:'100%',position:'relative',overflow:'hidden'}}>
              
  
  


                <div style={{height : each.WISHLIST_TYPE == 'professional' ? 90: null,width:'100%',height:103,flexDirection:'column',alignItems:'center',display:'flex'}}>
                <div style={{height: each.WISHLIST_TYPE == 'professional' ? 50: 80 ,width:'80%'}}> 
                     <p style={{ fontFamily: "Inter",lineHeight:'normal',fontStyle: "normal", letterSpacing: "normal",color: each.SERVICE_AT_HOME_FLAG == 1 ? '#2a7ff2' : "#fff",fontSize:14,textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap',width:'100%',marginBottom:0,fontWeight:'600'}}>{each.SERVICE_NAME}</p>
                     <p style={{ fontFamily: "Inter",fontStyle: "normal", letterSpacing: 0,color: each.SERVICE_AT_HOME_FLAG == 1  ?'#2a7ff2': "#FFFFFF",fontSize:14,letterSpacing:0,textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap',width:'100%',marginBottom:0}}><span style={{fontSize:10,fontFamily:'Inter',fontStretch:'normal',fontStyle:'normal',color: each.SERVICE_AT_HOME_FLAG ==1 ? '#b8b8b8': '#e0e0e0',textDecorationLine:'line-through'}}>{each.SERVICE_CURRENCY === 'Rupees' ? ('\u20B9') : '$'}{each.service_list_price}</span><span style={{fontSize:10,fontWeight:'600',fontFamily:'Inter',marginLeft:2.5,fontStretch:'normal',fontStyle:'normal',lineHeight:"normal",letterSpacing:'normal'}}>{each.SERVICE_CURRENCY === 'Rupees' ? ('\u20B9') : '$'}{each.SERVICE_OFFER_PRICE}</span> {each.SERVICE_AT_HOME_FLAG != 1 ? <span style={{fontSize:10,color:'#f29837',fontFamily:'Inter',fontStretch:'normal',fontStyle:'normal',lineHeight:"normal",letterSpacing:'normal',fontWeight:'normal'}}>({each.service_discount}%)</span>:null}</p>
                     <p style={{ fontFamily: "Inter",fontStyle: "normal", letterSpacing: 0,color: "#FFFFFF",fontSize:10,letterSpacing:0,width:'100%',marginBottom:0,color:each.SERVICE_AT_HOME_FLAG == 1 ? '#2a7ff2' :'#e0e0e0',lineHeight:'normal',letterSpacing:'normal',fontStretch:'normal',fontStyle:'normal',fontWeight:'normal'}}>{moment.utc(moment.duration(each.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("H") == 0 ? null : moment.utc(moment.duration(each.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("H") + " hr"}{' '}{moment.utc(moment.duration(each.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("mm") == 0 ? null : moment.utc(moment.duration(each.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("mm") + " mins"}</p>

                 {/* <p style={{ fontFamily: "Inter",fontStyle: "normal",letterSpacing: 0,color: "#e0e0e0",fontSize:10,letterSpacing:0,marginBottom:0,display:'-webkit-box',WebkitLineClamp:2,lineClamp:2,WebkitBoxOrient:'vertical',textOverflow:'ellipsis',overflow:'hidden'}}>{each.SERVICE_DETAIL_DESC ? each.SERVICE_DETAIL_DESC :null}</p> */}
              </div>
              <div style={{ width:'80%',marginTop:-25}}>
                   <SubmitButtonSmall onPress={()=>serviceExplorePress(each)} Radius={5} topMar={true}  width={'100%'} marginTop={0} buttonTitle={'Explore service'} height={30} buttonColor={each.SERVICE_AT_HOME_FLAG == 1 ? '#2a7ff2' : '#FFFFFF'} buttonTextColor={each.SERVICE_AT_HOME_FLAG == 1 ? '#FFFFFF' :'#8c52ff'} />
              </div>
            </div>
             </div>

            </div> 
          )
        })}
        </div>
       
      }else{


        if(serivcesDataCopy.length != 0){


          data =  <div style={{display:'grid',justifyContent: 'space-between', marginTop:44,width:'100%',gridTemplateColumns:'repeat(auto-fill,184px)',overflow:'hidden',height:'100%'}}> 
          {serivcesDataCopy.map((each,index)=>{
  
            return(
    
              <div key={index} onClick={()=>serviceExplorePress(each)} style={{borderRadius: 4.2,shadowColor: "rgba(0, 0, 0, 0.04)", shadowOffset: {width: 0,height: 0 },shadowRadius: 31,shadowOpacity: 0,float: 'left',marginBottom:42,position:'relative'}}>
                <div> 
                   <img style={{height:174,width:184,borderTopLeftRadius:15,borderTopRightRadius:15}} src={each.image_link}></img>
                   <div style={{backgroundColor:'#ffffff',height:26,width:26,position:'absolute',top:16,right:'8%',zIndex:100,borderRadius:8,}}>
                    {/* <img onClick={()=>setShowModal1({...showModal1,Modal:true,wshLstPrid1:each.QF_WISHLIST_PRID})} style={{margin:4,cursor:'pointer',zIndex:100}} src={deleteIcon} /> */}
                    <img  onClick={(e)=>{
                         e.stopPropagation()
                         return setShowModal1({...showModal1,Modal:true,wshLstPrid1:each.QF_WISHLIST_PRID})}} style={{margin:4,cursor:'pointer',zIndex:100}} src={deleteIcon} />
                   </div>
                { each.MERCHANT_SERVICE_RATING ? <div style={{backgroundColor:'#ffffff',position:'absolute',top:16,left:'8%',zIndex:10,borderRadius:8}}>
                            <RatingComponent size={9} rating={each.MERCHANT_SERVICE_RATING} webUsage={true} style={{fontsize:10}} width={45} height={26} foNsize={10}/>
                          </div> : null}
               </div>
                
                <div style={{borderRadius: 15,backgroundColor: "#8c52ff",marginTop:-12,paddingTop:16,zIndex:10,width:'100%',position:'relative',overflow:'hidden'}}>
                
                          <div style={{height : each.WISHLIST_TYPE == 'professional' ? 90: null,width:'100%',height:103,flexDirection:'column',alignItems:'center',display:'flex'}}>
                <div style={{height: each.WISHLIST_TYPE == 'professional' ? 50: 80 ,width:'80%'}}> 
                   <p style={{ fontFamily: "Inter",lineHeight:'normal',fontStyle: "normal", letterSpacing: "normal",color: "#fff",fontSize:14,textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap',width:'100%',marginBottom:0,fontWeight:'600'}}>{each.SERVICE_NAME}</p>
                       <p style={{ fontFamily: "Inter",fontStyle: "normal", letterSpacing: 0,color: "#FFFFFF",fontSize:14,letterSpacing:0,textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap',width:'100%',marginBottom:0}}><span style={{fontSize:10,fontFamily:'Inter',fontStretch:'normal',fontStyle:'normal',color:'#e0e0e0',textDecorationLine:'line-through'}}>{each.SERVICE_CURRENCY === 'Rupees' ? ('\u20B9') : '$'}{each.service_list_price}</span><span style={{fontSize:10,fontWeight:'600',fontFamily:'Inter',marginLeft:2.5,fontStretch:'normal',fontStyle:'normal',lineHeight:"normal",letterSpacing:'normal'}}>{each.SERVICE_CURRENCY === 'Rupees' ? ('\u20B9') : '$'}{each.SERVICE_OFFER_PRICE}</span> <span style={{fontSize:10,color:'#f29837',fontFamily:'Inter',fontStretch:'normal',fontStyle:'normal',lineHeight:"normal",letterSpacing:'normal',fontWeight:'normal'}}>({each.service_discount}%)</span></p>
                       <p style={{ fontFamily: "Inter",fontStyle: "normal", letterSpacing: 0,color: "#FFFFFF",fontSize:10,letterSpacing:0,width:'100%',marginBottom:0,color:'#e0e0e0',lineHeight:'normal',letterSpacing:'normal',fontStretch:'normal',fontStyle:'normal',fontWeight:'normal'}}>{moment.utc(moment.duration(each.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("H") == 0 ? null : moment.utc(moment.duration(each.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("H") + " hr"}{' '}{moment.utc(moment.duration(each.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("mm") == 0 ? null : moment.utc(moment.duration(each.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("mm") + " mins"}</p>
  
                   {/* <p style={{ fontFamily: "Inter",fontStyle: "normal",letterSpacing: 0,color: "#e0e0e0",fontSize:10,letterSpacing:0,marginBottom:0,display:'-webkit-box',WebkitLineClamp:2,lineClamp:2,WebkitBoxOrient:'vertical',textOverflow:'ellipsis',overflow:'hidden'}}>{each.SERVICE_DETAIL_DESC ? each.SERVICE_DETAIL_DESC :null}</p> */}
                </div>
                <div style={{ width:'80%',marginTop:-25}}>
                     <SubmitButtonSmall onPress={()=>serviceExplorePress(each)} Radius={5} topMar={true}  width={'100%'} marginTop={0} buttonTitle={'Explore service'} height={30} buttonColor={'#FFFFFF'} buttonTextColor={'#8c52ff'} />
                </div>
              </div>
               </div>
  
              </div> 
            )
          })}
          </div>
         


      
      }else{

           data = <div style={{textAlign:'center',display:'flex',flexDirection:'row',justifyContent:'center',marginTop:'10%',paddingBottom:'85%'}}>
           <text style={{}}>No wishlisted services found</text>
           </div>
      }


      }


    }
    else{

    if(professionalData.length !== 0  && selected == 99980 ){  

      data = <div style={{display:'grid',justifyContent: 'space-between', marginTop:44,width:'100%',position:'relative',gridTemplateColumns:'repeat(auto-fill,184px)' }}> 
      
      {professionalData.map((each,index)=>{
        return(
          <div key={index} style={{borderRadius: 4.2,shadowColor: "rgba(0, 0, 0, 0.04)", shadowOffset: {width: 0,height: 0 },shadowRadius: 31,shadowOpacity: 0,float: 'left',marginBottom:42,position:'relative',overflow:'hidden'}}>
           
              
            <div> 
               <img style={{height:174,width:184,borderTopLeftRadius:15,borderTopRightRadius:15}} src={each.image_link}></img>
               <div style={{backgroundColor:'#ffffff',height:26,width:26,position:'absolute',top:16,right:'8%',zIndex:100,borderRadius:8}}>
                <img onClick={()=>setShowModal({...showModal,Modal:true,wshLstPrid:each.QF_WISHLIST_PRID})} style={{margin:4,cursor:'pointer',zIndex:100}} src={deleteIcon} />
            </div>
            { each.MERCHANT_PERSONNEL_RATING ? <div style={{backgroundColor:'#ffffff',position:'absolute',top:16,left:'8%',zIndex:10,borderRadius:8}}>
              <RatingComponent rating={each.MERCHANT_PERSONNEL_RATING} webUsage={true} style={{fontsize:10}} width={45} height={26} foNsize={10} />
                      </div> : null}
           </div>
            {/* second part */}
            <div style={{borderRadius: 15,backgroundColor: "#8c52ff",marginTop:-12,paddingTop:16,zIndex:10,width:'100%',position:'relative',overflow:'hidden'}}>
           
              <div style={{height : each.WISHLIST_TYPE == 'professional' ? 90: null,width:'100%',height:103,flexDirection:'column',alignItems:'center',display:'flex'}}>
              <div style={{height: each.WISHLIST_TYPE == 'professional' ? 50: 80,width:'80%'}}> 
              <p style={{ fontFamily: "Inter",lineHeight:'normal',fontStyle: "normal", letterSpacing: "normal",color: "#fff",fontSize:14,textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap',width:'100%',marginBottom:0,fontWeight:'600'}}>{each.MERCHANT_NAME}</p>
              <p style={{ fontFamily: "Inter",fontStyle: "normal",letterSpacing: 0,color: "#FFFFFF",fontSize:10,letterSpacing:0,marginBottom:0,display:'-webkit-box',WebkitLineClamp:1,lineClamp:1,WebkitBoxOrient:'vertical',textOverflow:'ellipsis',overflow:'hidden'}}>{each.MERCHANT_PERSONNEL_ADDRESS_LINE2 ? each.MERCHANT_PERSONNEL_ADDRESS_LINE2 :null}</p>
              </div>
                <div style={{ width:'80%',marginTop:5}}>
                     <SubmitButtonSmall onPress={()=>onPressProExplore(each)} topMar={true}  Radius={5} height={30}  width={'100%'} marginTop={0} buttonTitle={'Explore services'} buttonColor={'#FFFFFF'} buttonTextColor={'#8c52ff'} />
                </div>
              </div>
           </div>
   
          </div> 
         
        )

     

  




      })}
      </div>
     }else{

      if(professionalDataCopy.length!==0){

        data =  <div style={{display:'grid',justifyContent: 'space-between', marginTop:44,width:'100%',position:'relative',gridTemplateColumns:'repeat(auto-fill,184px)' }}> 
        {professionalDataCopy.map((each,index)=>{
         return(
        <div key={index} style={{borderRadius: 4.2,shadowColor: "rgba(0, 0, 0, 0.04)", shadowOffset: {width: 0,height: 0 },shadowRadius: 31,shadowOpacity: 0,float: 'left',marginBottom:42,position:'relative',overflow:'hidden'}}>
        <div> 
           <img style={{height:174,width:184,borderTopLeftRadius:15,borderTopRightRadius:15}} src={each.image_link}></img>
       <div style={{backgroundColor:'#ffffff',height:26,width:26,position:'absolute',top:16,right:'8%',zIndex:100,borderRadius:8,}}>
            <img onClick={()=>setShowModal({...showModal,Modal:true,wshLstPrid:each.QF_WISHLIST_PRID})} style={{margin:4,cursor:'pointer',zIndex:100}} src={deleteIcon} />
        </div>
        { each.MERCHANT_PERSONNEL_RATING ? <div style={{backgroundColor:'#ffffff',position:'absolute',top:16,left:'8%',zIndex:10,borderRadius:8}}>
                    <RatingComponent rating={each.MERCHANT_PERSONNEL_RATING} webUsage={true} style={{fontsize:10}} width={45} height={26} foNsize={10}  />
                  </div> : null}
       </div>
       <div style={{borderRadius: 15,backgroundColor: "#8c52ff",marginTop:-12,paddingTop:16,zIndex:10,width:'100%',position:'relative',overflow:'hidden'}}>
         
         <div style={{height : each.WISHLIST_TYPE == 'professional' ? 90: null,width:'100%',height:103,flexDirection:'column',alignItems:'center',display:'flex'}}>
         <div style={{height: each.WISHLIST_TYPE == 'professional' ? 50: 80,width:'80%'}}> 
         <p style={{ fontFamily: "Inter",lineHeight:'normal',fontStyle: "normal", letterSpacing: "normal",color: "#fff",fontSize:14,textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap',width:'100%',marginBottom:0,fontWeight:'600'}}>{each.MERCHANT_NAME}</p>
         <p style={{ fontFamily: "Inter",fontStyle: "normal",letterSpacing: 0,color: "#FFFFFF",fontSize:10,letterSpacing:0,marginBottom:0,display:'-webkit-box',WebkitLineClamp:1,lineClamp:1,WebkitBoxOrient:'vertical',textOverflow:'ellipsis',overflow:'hidden'}}>{each.MERCHANT_PERSONNEL_ADDRESS_LINE2 ? each.MERCHANT_PERSONNEL_ADDRESS_LINE2 :'---'}</p>
         </div>
           <div style={{ width:'80%',marginTop:5}}>
                <SubmitButtonSmall onPress={()=>onPressProExplore(each)} topMar={true}  Radius={5} height={30}  width={'100%'} marginTop={0} buttonTitle={'Explore services'} buttonColor={'#FFFFFF'} buttonTextColor={'#8c52ff'} />
           </div>
         </div>
      </div>
   
        </div> 
      )
    })}
    </div>
    }else{

      data = 
      <div style={{textAlign:'center',display:'flex',flexDirection:'row',justifyContent:'center',marginTop:'10%',paddingBottom:'85%'}}>
      <text style={{}}>No wishlisted professionals found!</text>
     </div>


    }

     }
    }
  
    



    const serviceModalHandler =(id)=>{
        
         setServiceModalData([])
         serivcesData.map((item)=>{
          if(item.QF_MERCHANT_PERSONNEL_PRID === id){
              // console.log('dsds',item.QF_MERCHANT_PERSONNEL_PRID===id)
              setServiceModalData(item)
             
          }
          setServiceDetailsModal(true)
      })

  }


    const serviceDetailsModalClose =()=>{
      setServiceDetailsModal(false)
      setServiceDetails()
    }


   let serviceDetailsDisplayModal=(
      <Modal isOpen={serviceDetailsModal} size={'md'}  toggle={()=>serviceDetailsModalClose()} centered>
        <div style={{padding:16}}>
          <div style={{justifyContent:'flex-end',display:'flex',flexDirection:'row'}}>
            <div className='crossIcon_ViewPro' style={{cursor:'pointer'}}>
              <img src={CloseIcon} style={{width:18,height:18}} onClick={()=>serviceDetailsModalClose()}/>
            </div>
          </div>
          <div style={{marginTop:20}}>
            <ServiceDetails proData={ProData} serviceDetails={DataSer}/>
          </div>
        </div>
      </Modal>
     )



     let proModal=(
      <Modal isOpen={showModal.Modal} size={"md"} centered>
          <div style={{padding:20,backgroundColor:'#fff', borderRadius:10}}>
              <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}}>
                  <text className='are_you_wantText'>Remove from cart ?</text>
                  <div onClick={()=>setShowModal({...showModal,Modal:false})} style={{cursor:'pointer',cboxShadow: '0 2px 4px 0 rgba(103, 52, 204, 0.1)',width:22,height:22,borderRadius:20,backgroundColor: '#fff',display:'flex',justifyContent:'center',flexDirection:'row',alignItems:'center'}}>
                      <img src={CloseIcon} style={{width:16,height:16}}/>
                  </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:20}}>
                  <div className='yes_Logout_Btn' onClick={()=>setShowModal({...showModal,Modal:false})}>
                      <text className='yes_Text_Logout'>No</text>
                  </div>
                  <div className='cancel_Logout_Btn' onClick={()=>removeFrmWshLst(showModal.wshLstPrid)} style={{marginLeft:20}}>
                      <text className='cancel_Text_Logout'>Remove</text>
                  </div>
              </div>
          </div>
      </Modal>
    )


let serModal=(
  <Modal isOpen={showModal1.Modal} size={"md"} centered>
      <div style={{padding:20,backgroundColor:'#fff', borderRadius:10}}>
          <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}}>
              <text className='are_you_wantText'>Remove from cart ?</text>
              <div onClick={()=>setShowModal1({...showModal1,Modal:false})} style={{cursor:'pointer',cboxShadow: '0 2px 4px 0 rgba(103, 52, 204, 0.1)',width:22,height:22,borderRadius:20,backgroundColor: '#fff',display:'flex',justifyContent:'center',flexDirection:'row',alignItems:'center'}}>
                  <img src={CloseIcon} style={{width:16,height:16}}/>
              </div>
          </div>
          <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:20}}>
              <div className='yes_Logout_Btn' onClick={()=>setShowModal1({...showModal1,Modal:false})}>
                  <text className='yes_Text_Logout'>No</text>
              </div>
              <div className='cancel_Logout_Btn' onClick={()=>removeFrmWshLstServices(showModal1.wshLstPrid1)} style={{marginLeft:20}}>
                  <text className='cancel_Text_Logout'>Remove</text>
              </div>
          </div>
      </div>
  </Modal>
)
    return(
        <div style={{backgroundColor:'#fff'}}>
        <div style={{  marginLeft: '7%', marginRight: '7%'}}>
         <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>    
             <text style={{fontSize:24,fontWeight:'bold',fontFamily:'Inter',marginTop:144}}>Wishlist</text>

                                       <Input
                                                style={{ color: '#8c52ff',fontSize:14,fontWeight:'normal',fontFamily:'Inter',width:140,border:0,marginTop:144,border:'solid 1px #e0e0e0' }}
                                                onChange={PorShandler}
                                                placeholder="Subject"
                                                value={ProOrSer}
                                                type="select">
                                                <option value='Services'>Services</option>
                                                <option value='Professionals'>Professionals</option>
                                       </Input>
         </div>
         <h2 style={{ textAlign: 'center', borderBottom: '1px solid #b8b8b8', lineHeight: '0.1em', opacity: 0.6, height: 7, flexGrow: 0,marginBottom:23 }} />

         <div style={{display:'flex',overflowX:'scroll',overflowY:'hidden',scrollSnapType:'x',width:'100%',flexDirection:'row'}}> 
         {categoryButtons.length !== 0  &&  !loading  && ProOrSer == 'Professionals' ?

            
            categoryButtons.map((each,index)=>{

            return(
                  <div style={{display:'inline-block',marginTop:20}}>
                  <CategoriesButton key={index}  handle={()=>handleButtonClick(each.QF_SERVICE_CATEGORY_PRID,each.category_name)} buttonColor={each.QF_SERVICE_CATEGORY_PRID === selected ?'#8c52ff':'#ffffff' } buttonTextColor={each.QF_SERVICE_CATEGORY_PRID != selected ?'#461c99':'#ffffff'} buttonTitle={each.category_name}/>
                  
                  </div>
          )
          }) :null}
          </div>

          

          {ProOrSer == 'Services' ?

           
                <div style={{display:'inline-block',marginTop:20}}>
                <CategoriesButton  handle={()=>handleButtonClickServices(AtHomeToggle ? true :false)} buttonColor={AtHomeToggle ?'#2a7ff2':'#ffffff' } buttonTextColor={!AtHomeToggle ?'#2a7ff2':'#ffffff'} buttonTitle={'At Home'}/>
                </div>
          
           
          
          : null}


        {/* <div  style={{display:'grid',justifyContent: 'space-between', marginTop:44,width:'100%',position:'relative',gridTemplateColumns:'repeat(auto-fill,184px)' }}> */}
          { loading ?
              <div style={{textAlign:'center' ,paddingBottom:'63%',display:'flex',flexDirection:'row',justifyContent:'center',marginTop:'10%'}}>
               <ClipLoader widthUnit={"100%"} size={40} loadings={loading} color="#8c52ff" />
              </div>
            :
            data }
        {/* </div> */}
        {serviceDetailsModal && serviceDetailsDisplayModal}
        {showModal.Modal && proModal}
        {showModal1.Modal && serModal}
        <ToastContainer />
        </div>
        </div>)

}

export default WishList




// import React,{Component, useState,useLayoutEffect,useContext,useEffect,} from "react";
// import { resolvePath } from "react-router-dom";
// import {Input,Modal,ModalBody,ModalHeader,ModalFooter, Button} from 'reactstrap'
// import deleteIcon from '../../../Assets/iconsDelete24Px.png'
// import cross from '../../../Assets/crossIcon.png'
// import RatingComponent from "./RatingComponent";
// import SubmitButtonSmall from "./submitButtonSmall";
// import CategoriesButton from "../../CategoriesButton";
// import customFetchData from '../customFetchData';
// import {globalState} from '../../../GlobalState/globalState';
// import ServiceDetails from '../../../../New_Website_V2/Components/Custom/UI/ServiceDetails'
// import CloseIcon from '../../../../New_Website_V2/Assets/icons-closeModal.png'
// import { useNavigate, NavLink } from 'react-router-dom';
// import { ClipLoader } from 'react-spinners';
// import { translate } from "react-range/lib/utils";
// import { ToastContainer } from "react-toastify";
// import Close_Cross from "../../../../New_Website_V2/Components/Custom/UI/Close_Cross";
// import moment from "moment";
// import FetchCurrentLocation from "../FetchCurrentLocation";


// function WishList(props){ 

//    const navigate = useNavigate()

//    let [state,dispatch]=useContext(globalState)
//    const [error,setErrorMsg] = useState('')
//    const [loading,setLoading]=useState(false)
//    const [selected, setSelected] = useState(99980) 
//    const [selected1, setSelected1] = useState(99980) 
//    const [categoryButtons,setcategoryButtons] = useState([])
//    const [categoryButtonsServices,setcategoryButtonsServices] = useState([])
//    const [professionalData ,setProfessionalData] = useState([])
//    const [serivcesData ,setServicesData] = useState([])
//    const [serivcesDataCopy ,setServicesDataCopy] = useState([])
//    const [professionalDataCopy ,setProfessionalDataCopy] = useState([])
//    const [showModal,setShowModal]= useState({Modal:false,wshLstPrid:null})
//    const [showModal1,setShowModal1]= useState({Modal1:false,wshLstPrid1:null})
//    const [ProOrSer,setProOrSer] = useState(['Services'])
//    const [serviceDetailsModal,setServiceDetailsModal]=useState(false)
//    const [serviceDetails,setServiceDetails]=useState()
//    const [serviceModalData,setServiceModalData]=useState([])
//    const [allDupCategories,setAllDupCategories]=useState([])
//    const [ProData,setProData] = useState([]) 
//    const [DataSer, setDataser]= useState([]) 
//    const DeepClone = require("rfdc/default")
//   const [city,setCity]=useState('')
//   const [latitude,setLatitude]=useState()
//   const [longitude,setLongitude]=useState()
//   const [stateOrProvince,setStateOrProvince]=useState('')



//    useEffect(()=>{
//     fetchCurrentLocationHandler()
//     setLoading(true)
//     // fetchCategories()
//     fetchWishListData()
//     fetchWishListDataServices()
//     fetchCategoriesServices()

//   },[])
// const fetchCurrentLocationHandler=async()=>{
//   if(state.address.latitude === null && state.address.longitude === null){
//     let addressDetails= await FetchCurrentLocation(state,dispatch)
//     console.log('address details',addressDetails)
//     let {city,country,curr_location_latitude,curr_location_longitude,stateOrProvince}=addressDetails
//     setLatitude(curr_location_latitude)
//     setLongitude(curr_location_longitude)
//     setCity(city)
//     setStateOrProvince(stateOrProvince)
//   }else{
//     setLatitude(state.address.latitude)
//     setLongitude(state.address.longitude)
//     setCity(state.address.city)
//     setStateOrProvince(state.address.stateOrProvince)
//   }
// }
//   const fetchCategoriesServices = async () => {
//     let method="POST",body={cPrid:state.user.id},url="/website/city/avail/categories/sv2"
//     let {fetch,fetchedData}= await customFetchData(method,body,url,state,dispatch)   
//     if(fetch){  
//     let cats = fetchedData.catResults
//         if(fetchedData.catResults.length){
//            cats.unshift({QF_SERVICE_CATEGORY_PRID:99980,category_name:'All'})
//            setcategoryButtonsServices(cats)
//         }else{
//           setcategoryButtonsServices([])
//         }
//     }else{
//       setcategoryButtonsServices([])
//       console.log('error')
//     }
// }

// // console.log('servicccse',serviceDetailsModal)

// const fetchWishListDataServices = async ()=>{
//   let  method="POST",body={wishLstType:'service',cPrid:state.user.id},url="/website/fetch/wishList/sv2"
//   let {fetch,fetchedData}= await customFetchData(method,body,url,state,dispatch)
//   console.log('fectcha',fetchedData)   
//   if(fetch){  
//     if(fetchedData.wishListData.length){
       
//        setServicesData(fetchedData.wishListData)
//        setServicesDataCopy(fetchedData.wishListData) 
//        setErrorMsg('')
//        setLoading(false)
//     }else{
//       setErrorMsg('No wishlisted services found!')
//     }
//   }else{
//     console.log('error')
//   }
// }


// //   const fetchCategories = async ()=>{
// //     let  method="POST",body={cPrid:state.user.id},url="/website/wishlist/pro/categories/sv2"
// //     let {fetch,fetchedData}= await customFetchData(method,body,url,state,dispatch)   
// //     if(fetch){  
// //      let cats = fetchedData.procatResults
// //      if(cats.length !== 0){
// //       setAllDupCategories(cats)
// //       console.log('cats.length',cats )
// //       cats.unshift({qf_service_category_prid:99980,category_name:'All'})
// //       const uniqueProCatshow = cats.filter((obj, index) => cats.findIndex((item) => item.category_name === obj.category_name) === index)
// //        //  setData(cats)
// //        //  setData1(cats)
// //        setcategoryButtons(uniqueProCatshow)
// //        console.log('uniqueProCatshow',uniqueProCatshow)

// //      }else{
// //       setcategoryButtons([])
// //      }
// //     //  console.log('procatResults',cats)
    

// //     }else{
// //       console.log('error')
// //     }
// // }



//   const fetchWishListData = async () => {

//     let  method="POST",body={wishLstType:'professional',cPrid:state.user.id},url="/website/fetch/wishList/sv2"
//     let {fetch,fetchedData}= await customFetchData(method,body,url,state,dispatch)
//     console.log('fectch dataPRO',fetchedData)   
//     if(fetch){  

//       let cats = fetchedData.wishListData

//       if(fetchedData.wishListData.length){
        
//           setAllDupCategories(cats)
          
//           // setProfessionalDataCopy(fetchedData.wishListData)
//           const uniquePro= cats.filter((obj, index) => cats.findIndex((item) => item.QF_MERCHANT_PERSONNEL_PRID === obj.QF_MERCHANT_PERSONNEL_PRID) === index)
          
//           cats.unshift({QF_SERVICE_CATEGORY_PRID:99980,category_name:'All'})
          
//           const uniqueProCatshow = cats.filter((obj, index) => cats.findIndex((item) => item.category_name === obj.category_name) === index)
           
//           setProfessionalData(uniquePro)
//           setErrorMsg('')
//           setLoading(false)
//           setcategoryButtons(uniqueProCatshow)
//       }else{
//         setProfessionalData([])
//         setLoading(false)
//         setcategoryButtons([])

//         setErrorMsg('No wishlisted services found!')
//       }
//     }else{
//       console.log('error')
//     }
//   }


  

//   async function removeFrmWshLst(id,MPRID){

//     let  method="POST",body={id:id},url="/website/wishList/delete/sv2"
//     let {fetch,fetchedData}= await customFetchData(method,body,url,state,dispatch)  
//     if(fetch){  

//       let UpdateprofessionalData = professionalData.filter((each) => {return each.QF_WISHLIST_PRID != id})
       
//       if(!UpdateprofessionalData.length){

//         setProfessionalDataCopy([])
//         setcategoryButtons([])
//         setAllDupCategories([])
//         setProfessionalData([])
//        }
//        else{
//        let UpdateAllDupCategories = allDupCategories.filter((each) => {return each.QF_WISHLIST_PRID != id})
//        const deletedProDupcategoriesRemoved  = UpdateAllDupCategories.filter((obj, index) => UpdateAllDupCategories.findIndex((item) => item.category_name === obj.category_name) === index)
//        //  let UpdatecategoryButtons = categoryButtons.filter((each) => {return each.QF_WISHLIST_PRID != id})
//        setAllDupCategories(UpdateAllDupCategories)
//        setcategoryButtons(deletedProDupcategoriesRemoved)
//        setProfessionalDataCopy(UpdateAllDupCategories)
//        setProfessionalData(UpdateprofessionalData)
//        setSelected(99980)


//        }

//        setShowModal({...showModal,Modal:false})
//        setLoading(false)
//     }
//     else{
//       console.log('error')
//     }
//  }



//    async function removeFrmWshLstServices(id){


//     // return console.log(id);
//       let  method="POST",body={id:id},url="/website/wishList/delete/sv2"
//       let {fetch,fetchedData}= await customFetchData(method,body,url,state,dispatch)  
//       // console.log(fetch,fetchedData)
//       if(fetch){  
//          let afterRemoveItems = serivcesData.filter((each) => {return each.QF_WISHLIST_PRID != showModal1.wshLstPrid1})

//           if(!afterRemoveItems.length){
//             setServicesDataCopy([])
//            }
          
//             // setServicesDataCopy(removedpro)
//         //  setdupRemovedData(uniqueProCatshow)
//             setServicesData(afterRemoveItems)
//             setServicesDataCopy(afterRemoveItems)
//         //  setCopyCards(afterRemoveItems)
//            setShowModal1({...showModal,Modal:false})
//            setLoading(false)
//       }
//       else{
//         console.log('error')
//       }
//    }

//    const handleButtonClick = (id,cat) => {



//     if(id!=99980){
//         setSelected(id) 
//         let catSelectedOnly = allDupCategories.filter((each) => {
//             return each.category_name == cat} )

//         const uniquePro= catSelectedOnly.filter((obj, index) => catSelectedOnly.findIndex((item) => item.QF_MERCHANT_PERSONNEL_PRID === obj.QF_MERCHANT_PERSONNEL_PRID) === index)    

//       // return console.log('catSelectedOnlynew',uniquePro)

//             if(catSelectedOnly.length){
//              setProfessionalDataCopy(uniquePro)  
//             }else{
//               setProfessionalDataCopy([])
//             }

//     }else{
//       setErrorMsg('')
//       setSelected(id)
//       // setCards(copyCards)
//     }

//    }

//      const handleButtonClickServices = (id) => {

//       if(id!=99980){  
//           setSelected1(id)
//           let catSelectedOnly = serivcesData.filter((each) => {
//               return each.QF_SERVICE_CATEGORY_PRID == id} )
//               if(catSelectedOnly.length){
//                 setServicesDataCopy(catSelectedOnly)
//                 setErrorMsg('')
//               }else{
//                 setErrorMsg('No wishlisted services found!')
//               }

//       }else{
//         setErrorMsg('')
//         setSelected1(id)
//         // setCards(copyCards)
//       }

//      }


//       const PorShandler = (event) =>{

//         setProOrSer(event.target.value)
       
//     }



//     const onPressProExplore = (item) =>{
//       // console.log('explore',item.QF_MERCHANT_PERSONNEL_PRID)
//           let offers = state.services
//           // console.log('explore1',offers)

//           // let filteredServices,proData;
//           // filteredServices = offers.filter(outerItem => {
//           //     return outerItem[0].QF_MERCHANT_PERSONNEL_PRID == item.QF_MERCHANT_PERSONNEL_PRID
//           // })
//           // console.log('services', filteredServices)
//           // if (filteredServices.length !== 0) {
//               navigate({pathname:`/allservices/${item.MERCHANT_NAME.replaceAll(" ","","g")}`,search:`?proId=${item.QF_MERCHANT_PERSONNEL_PRID}`}
//               // , 
//             //   { state: { proData: filteredServices[0][0],offers: filteredServices[0],proChecked:true,fromWihslist:true,
//             //     categories:DeepClone(state.categories),subCategories:DeepClone(state.subcategories),
//             //     addressDetails:
//             //  {
//             //     totalAddress: state.address.totalAddress,
//             //     addressLine1: state.address.addressLine1,
//             //     country: state.address.country,
//             //     city: state.address.city,
//             //     stateOrProvince: state.address.stateOrProvince,
//             //     zipcode: state.address.zipcode,
//             //     district: state.address.district,
//             //     latitude: state.address.latitude,
//             //     longitude: state.address.longitude,
//             //     currentAddress: state.address.currentAddress,
//             //     searchAddress: state.address.searchAddress,
//             //     currentLocationDenied: state.address.currentLocationDenied
//             //  },allServices:DeepClone(state.services)} }
//              )
//           // }
//      }

//     //  let serviceDetailsDisplayModal;

//      const serviceExplorePress = (data) =>{

//       let offers=[...serivcesData]
//       let filteredPro =[]
//       filteredPro = offers.filter((each)=>{

//            return each.QF_MERCHANT_SERVICE_PRID == data.QF_MERCHANT_SERVICE_PRID
//       })


//       // return console.log('trace',filteredPro)
    
            
//       // console.log('pro data',filteredPro)
//       if(filteredPro.length!==0){

//         setProData(filteredPro[0])
//         setDataser(filteredPro[0])
//         setServiceDetailsModal(true)


//       }
    
//     }   

//     // console.log('data',serivcesData,latitude,longitude)

//     let data=[]
   

//     if(ProOrSer == 'Services'){

//       if(serivcesData.length !== 0  && selected1 == 99980 ){ 


//         data =  <div style={{display:'grid',justifyContent: 'space-between', marginTop:44,width:'100%',gridTemplateColumns:'repeat(auto-fill,184px)',overflow:'hidden',height:'100%'}}> 
//         {serivcesData.map((each,index)=>{

//           return(
  
//             <div  key={index} onClick={()=>serviceExplorePress(each)} style={{borderRadius: 4.2,shadowColor: "rgba(0, 0, 0, 0.04)", shadowOffset: {width: 0,height: 0 },shadowRadius: 31,shadowOpacity: 0,float: 'left',marginBottom:42,position:'relative'}}>
//               <div> 
//                  <img style={{height:174,width:184,borderTopLeftRadius:15,borderTopRightRadius:15}} src={each.image_link}></img>
//                  <div style={{backgroundColor:'#ffffff',height:26,width:26,position:'absolute',top:16,right:'8%',zIndex:100,borderRadius:8,}}>
//                   <img  onClick={(e)=>{
//                          e.stopPropagation()
//                          return setShowModal1({...showModal1,Modal:true,wshLstPrid1:each.QF_WISHLIST_PRID})}} style={{margin:4,cursor:'pointer',zIndex:100}} src={deleteIcon} />
//                  </div>
//               { each.MERCHANT_SERVICE_RATING ? <div style={{backgroundColor:'#ffffff',position:'absolute',top:16,left:'8%',zIndex:10,borderRadius:8}}>
//                           <RatingComponent size={9} rating={each.MERCHANT_SERVICE_RATING} webUsage={true} style={{fontsize:10}} width={45} height={26} foNsize={10}/>
//                         </div> : null}
//              </div>
              
//               <div style={{borderRadius: 15,backgroundColor: "#8c52ff",marginTop:-12,paddingTop:16,zIndex:10,width:'100%',position:'relative',overflow:'hidden'}}>
              
//                         <div style={{height : each.WISHLIST_TYPE == 'professional' ? 90: null,width:'100%',height:103,flexDirection:'column',alignItems:'center',display:'flex'}}>
//               <div style={{height: each.WISHLIST_TYPE == 'professional' ? 50: 80 ,width:'80%'}}> 
//                  <p style={{ fontFamily: "Inter",lineHeight:'normal',fontStyle: "normal", letterSpacing: "normal",color: "#fff",fontSize:14,textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap',width:'100%',marginBottom:0,fontWeight:'600'}}>{each.SERVICE_NAME}</p>
//                      <p style={{ fontFamily: "Inter",fontStyle: "normal", letterSpacing: 0,color: "#FFFFFF",fontSize:14,letterSpacing:0,textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap',width:'100%',marginBottom:0}}><span style={{fontSize:10,fontFamily:'Inter',fontStretch:'normal',fontStyle:'normal',color:'#e0e0e0',textDecorationLine:'line-through'}}>{each.SERVICE_CURRENCY === 'Rupees' ? ('\u20B9') : '$'}{each.service_list_price}</span><span style={{fontSize:10,fontWeight:'600',fontFamily:'Inter',marginLeft:2.5,fontStretch:'normal',fontStyle:'normal',lineHeight:"normal",letterSpacing:'normal'}}>{each.SERVICE_CURRENCY === 'Rupees' ? ('\u20B9') : '$'}{each.SERVICE_OFFER_PRICE}</span> <span style={{fontSize:10,color:'#f29837',fontFamily:'Inter',fontStretch:'normal',fontStyle:'normal',lineHeight:"normal",letterSpacing:'normal',fontWeight:'normal'}}>({each.service_discount}%)</span></p>
//                      <p style={{ fontFamily: "Inter",fontStyle: "normal", letterSpacing: 0,color: "#FFFFFF",fontSize:10,letterSpacing:0,width:'100%',marginBottom:0,color:'#e0e0e0',lineHeight:'normal',letterSpacing:'normal',fontStretch:'normal',fontStyle:'normal',fontWeight:'normal'}}>{moment.utc(moment.duration(each.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("H") == 0 ? null : moment.utc(moment.duration(each.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("H") + " hr"}{' '}{moment.utc(moment.duration(each.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("mm") == 0 ? null : moment.utc(moment.duration(each.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("mm") + " mins"}</p>

//                  {/* <p style={{ fontFamily: "Inter",fontStyle: "normal",letterSpacing: 0,color: "#e0e0e0",fontSize:10,letterSpacing:0,marginBottom:0,display:'-webkit-box',WebkitLineClamp:2,lineClamp:2,WebkitBoxOrient:'vertical',textOverflow:'ellipsis',overflow:'hidden'}}>{each.SERVICE_DETAIL_DESC ? each.SERVICE_DETAIL_DESC :null}</p> */}
//               </div>
//               <div style={{ width:'80%',marginTop:-25}}>
//                    <SubmitButtonSmall onPress={()=>serviceExplorePress(each)} Radius={5} topMar={true}  width={'100%'} marginTop={0} buttonTitle={'Explore service'} height={30} buttonColor={'#FFFFFF'} buttonTextColor={'#8c52ff'} />
//               </div>
//             </div>
//              </div>

//             </div> 
//           )
//         })}
//         </div>
       
//       }else{


//         if(serivcesDataCopy.length != 0){


//           data =  <div style={{display:'grid',justifyContent: 'space-between', marginTop:44,width:'100%',gridTemplateColumns:'repeat(auto-fill,184px)',overflow:'hidden',height:'100%'}}> 
//           {serivcesDataCopy.map((each,index)=>{
  
//             return(
    
//               <div key={index} onClick={()=>serviceExplorePress(each)} style={{borderRadius: 4.2,shadowColor: "rgba(0, 0, 0, 0.04)", shadowOffset: {width: 0,height: 0 },shadowRadius: 31,shadowOpacity: 0,float: 'left',marginBottom:42,position:'relative'}}>
//                 <div> 
//                    <img style={{height:174,width:184,borderTopLeftRadius:15,borderTopRightRadius:15}} src={each.image_link}></img>
//                    <div style={{backgroundColor:'#ffffff',height:26,width:26,position:'absolute',top:16,right:'8%',zIndex:100,borderRadius:8,}}>
//                     {/* <img onClick={()=>setShowModal1({...showModal1,Modal:true,wshLstPrid1:each.QF_WISHLIST_PRID})} style={{margin:4,cursor:'pointer',zIndex:100}} src={deleteIcon} /> */}
//                     <img  onClick={(e)=>{
//                          e.stopPropagation()
//                          return setShowModal1({...showModal1,Modal:true,wshLstPrid1:each.QF_WISHLIST_PRID})}} style={{margin:4,cursor:'pointer',zIndex:100}} src={deleteIcon} />
//                    </div>
//                 { each.MERCHANT_SERVICE_RATING ? <div style={{backgroundColor:'#ffffff',position:'absolute',top:16,left:'8%',zIndex:10,borderRadius:8}}>
//                             <RatingComponent size={9} rating={each.MERCHANT_SERVICE_RATING} webUsage={true} style={{fontsize:10}} width={45} height={26} foNsize={10}/>
//                           </div> : null}
//                </div>
                
//                 <div style={{borderRadius: 15,backgroundColor: "#8c52ff",marginTop:-12,paddingTop:16,zIndex:10,width:'100%',position:'relative',overflow:'hidden'}}>
                
//                           <div style={{height : each.WISHLIST_TYPE == 'professional' ? 90: null,width:'100%',height:103,flexDirection:'column',alignItems:'center',display:'flex'}}>
//                 <div style={{height: each.WISHLIST_TYPE == 'professional' ? 50: 80 ,width:'80%'}}> 
//                    <p style={{ fontFamily: "Inter",lineHeight:'normal',fontStyle: "normal", letterSpacing: "normal",color: "#fff",fontSize:14,textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap',width:'100%',marginBottom:0,fontWeight:'600'}}>{each.SERVICE_NAME}</p>
//                        <p style={{ fontFamily: "Inter",fontStyle: "normal", letterSpacing: 0,color: "#FFFFFF",fontSize:14,letterSpacing:0,textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap',width:'100%',marginBottom:0}}><span style={{fontSize:10,fontFamily:'Inter',fontStretch:'normal',fontStyle:'normal',color:'#e0e0e0',textDecorationLine:'line-through'}}>{each.SERVICE_CURRENCY === 'Rupees' ? ('\u20B9') : '$'}{each.service_list_price}</span><span style={{fontSize:10,fontWeight:'600',fontFamily:'Inter',marginLeft:2.5,fontStretch:'normal',fontStyle:'normal',lineHeight:"normal",letterSpacing:'normal'}}>{each.SERVICE_CURRENCY === 'Rupees' ? ('\u20B9') : '$'}{each.SERVICE_OFFER_PRICE}</span> <span style={{fontSize:10,color:'#f29837',fontFamily:'Inter',fontStretch:'normal',fontStyle:'normal',lineHeight:"normal",letterSpacing:'normal',fontWeight:'normal'}}>({each.service_discount}%)</span></p>
//                        <p style={{ fontFamily: "Inter",fontStyle: "normal", letterSpacing: 0,color: "#FFFFFF",fontSize:10,letterSpacing:0,width:'100%',marginBottom:0,color:'#e0e0e0',lineHeight:'normal',letterSpacing:'normal',fontStretch:'normal',fontStyle:'normal',fontWeight:'normal'}}>{moment.utc(moment.duration(each.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("H") == 0 ? null : moment.utc(moment.duration(each.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("H") + " hr"}{' '}{moment.utc(moment.duration(each.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("mm") == 0 ? null : moment.utc(moment.duration(each.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("mm") + " mins"}</p>
  
//                    {/* <p style={{ fontFamily: "Inter",fontStyle: "normal",letterSpacing: 0,color: "#e0e0e0",fontSize:10,letterSpacing:0,marginBottom:0,display:'-webkit-box',WebkitLineClamp:2,lineClamp:2,WebkitBoxOrient:'vertical',textOverflow:'ellipsis',overflow:'hidden'}}>{each.SERVICE_DETAIL_DESC ? each.SERVICE_DETAIL_DESC :null}</p> */}
//                 </div>
//                 <div style={{ width:'80%',marginTop:-25}}>
//                      <SubmitButtonSmall onPress={()=>serviceExplorePress(each)} Radius={5} topMar={true}  width={'100%'} marginTop={0} buttonTitle={'Explore service'} height={30} buttonColor={'#FFFFFF'} buttonTextColor={'#8c52ff'} />
//                 </div>
//               </div>
//                </div>
  
//               </div> 
//             )
//           })}
//           </div>
         


      
//       }else{

//            data = <div style={{textAlign:'center',display:'flex',flexDirection:'row',justifyContent:'center',marginTop:'10%',paddingBottom:'85%'}}>
//            <text style={{}}>No wishlisted services found</text>
//            </div>
//       }


//       }


//     }
//     else{

//     if(professionalData.length !== 0  && selected == 99980 ){  

//       data = <div style={{display:'grid',justifyContent: 'space-between', marginTop:44,width:'100%',position:'relative',gridTemplateColumns:'repeat(auto-fill,184px)' }}> 
      
//       {professionalData.map((each,index)=>{
//         return(
//           <div key={index} style={{borderRadius: 4.2,shadowColor: "rgba(0, 0, 0, 0.04)", shadowOffset: {width: 0,height: 0 },shadowRadius: 31,shadowOpacity: 0,float: 'left',marginBottom:42,position:'relative',overflow:'hidden'}}>
           
              
//             <div> 
//                <img style={{height:174,width:184,borderTopLeftRadius:15,borderTopRightRadius:15}} src={each.image_link}></img>
//                <div style={{backgroundColor:'#ffffff',height:26,width:26,position:'absolute',top:16,right:'8%',zIndex:100,borderRadius:8}}>
//                 <img onClick={()=>setShowModal({...showModal,Modal:true,wshLstPrid:each.QF_WISHLIST_PRID})} style={{margin:4,cursor:'pointer',zIndex:100}} src={deleteIcon} />
//             </div>
//             { each.MERCHANT_PERSONNEL_RATING ? <div style={{backgroundColor:'#ffffff',position:'absolute',top:16,left:'8%',zIndex:10,borderRadius:8}}>
//               <RatingComponent rating={each.MERCHANT_PERSONNEL_RATING} webUsage={true} style={{fontsize:10}} width={45} height={26} foNsize={10} />
//                       </div> : null}
//            </div>
//             {/* second part */}
//             <div style={{borderRadius: 15,backgroundColor: "#8c52ff",marginTop:-12,paddingTop:16,zIndex:10,width:'100%',position:'relative',overflow:'hidden'}}>
           
//               <div style={{height : each.WISHLIST_TYPE == 'professional' ? 90: null,width:'100%',height:103,flexDirection:'column',alignItems:'center',display:'flex'}}>
//               <div style={{height: each.WISHLIST_TYPE == 'professional' ? 50: 80,width:'80%'}}> 
//               <p style={{ fontFamily: "Inter",lineHeight:'normal',fontStyle: "normal", letterSpacing: "normal",color: "#fff",fontSize:14,textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap',width:'100%',marginBottom:0,fontWeight:'600'}}>{each.MERCHANT_NAME}</p>
//               <p style={{ fontFamily: "Inter",fontStyle: "normal",letterSpacing: 0,color: "#FFFFFF",fontSize:10,letterSpacing:0,marginBottom:0,display:'-webkit-box',WebkitLineClamp:1,lineClamp:1,WebkitBoxOrient:'vertical',textOverflow:'ellipsis',overflow:'hidden'}}>{each.MERCHANT_PERSONNEL_ADDRESS_LINE2 ? each.MERCHANT_PERSONNEL_ADDRESS_LINE2 :null}</p>
//               </div>
//                 <div style={{ width:'80%',marginTop:5}}>
//                      <SubmitButtonSmall onPress={()=>onPressProExplore(each)} topMar={true}  Radius={5} height={30}  width={'100%'} marginTop={0} buttonTitle={'Explore services'} buttonColor={'#FFFFFF'} buttonTextColor={'#8c52ff'} />
//                 </div>
//               </div>
//            </div>
   
//           </div> 
         
//         )

     

  




//       })}
//       </div>
//      }else{

//       if(professionalDataCopy.length!==0){

//         data =  <div style={{display:'grid',justifyContent: 'space-between', marginTop:44,width:'100%',position:'relative',gridTemplateColumns:'repeat(auto-fill,184px)' }}> 
//         {professionalDataCopy.map((each,index)=>{
//          return(
//         <div key={index} style={{borderRadius: 4.2,shadowColor: "rgba(0, 0, 0, 0.04)", shadowOffset: {width: 0,height: 0 },shadowRadius: 31,shadowOpacity: 0,float: 'left',marginBottom:42,position:'relative',overflow:'hidden'}}>
//         <div> 
//            <img style={{height:174,width:184,borderTopLeftRadius:15,borderTopRightRadius:15}} src={each.image_link}></img>
//        <div style={{backgroundColor:'#ffffff',height:26,width:26,position:'absolute',top:16,right:'8%',zIndex:100,borderRadius:8,}}>
//             <img onClick={()=>setShowModal({...showModal,Modal:true,wshLstPrid:each.QF_WISHLIST_PRID})} style={{margin:4,cursor:'pointer',zIndex:100}} src={deleteIcon} />
//         </div>
//         { each.MERCHANT_PERSONNEL_RATING ? <div style={{backgroundColor:'#ffffff',position:'absolute',top:16,left:'8%',zIndex:10,borderRadius:8}}>
//                     <RatingComponent rating={each.MERCHANT_PERSONNEL_RATING} webUsage={true} style={{fontsize:10}} width={45} height={26} foNsize={10}  />
//                   </div> : null}
//        </div>
//        <div style={{borderRadius: 15,backgroundColor: "#8c52ff",marginTop:-12,paddingTop:16,zIndex:10,width:'100%',position:'relative',overflow:'hidden'}}>
         
//          <div style={{height : each.WISHLIST_TYPE == 'professional' ? 90: null,width:'100%',height:103,flexDirection:'column',alignItems:'center',display:'flex'}}>
//          <div style={{height: each.WISHLIST_TYPE == 'professional' ? 50: 80,width:'80%'}}> 
//          <p style={{ fontFamily: "Inter",lineHeight:'normal',fontStyle: "normal", letterSpacing: "normal",color: "#fff",fontSize:14,textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap',width:'100%',marginBottom:0,fontWeight:'600'}}>{each.MERCHANT_NAME}</p>
//          <p style={{ fontFamily: "Inter",fontStyle: "normal",letterSpacing: 0,color: "#FFFFFF",fontSize:10,letterSpacing:0,marginBottom:0,display:'-webkit-box',WebkitLineClamp:1,lineClamp:1,WebkitBoxOrient:'vertical',textOverflow:'ellipsis',overflow:'hidden'}}>{each.MERCHANT_PERSONNEL_ADDRESS_LINE2 ? each.MERCHANT_PERSONNEL_ADDRESS_LINE2 :'---'}</p>
//          </div>
//            <div style={{ width:'80%',marginTop:5}}>
//                 <SubmitButtonSmall onPress={()=>onPressProExplore(each)} topMar={true}  Radius={5} height={30}  width={'100%'} marginTop={0} buttonTitle={'Explore services'} buttonColor={'#FFFFFF'} buttonTextColor={'#8c52ff'} />
//            </div>
//          </div>
//       </div>
   
//         </div> 
//       )
//     })}
//     </div>
//     }else{

//       data = 
//       <div style={{textAlign:'center',display:'flex',flexDirection:'row',justifyContent:'center',marginTop:'10%',paddingBottom:'85%'}}>
//       <text style={{}}>No wishlisted professionals found!</text>
//      </div>


//     }

//      }
//     }
  
    



//     const serviceModalHandler =(id)=>{
        
//          setServiceModalData([])
//          serivcesData.map((item)=>{
//           if(item.QF_MERCHANT_PERSONNEL_PRID === id){
//               // console.log('dsds',item.QF_MERCHANT_PERSONNEL_PRID===id)
//               setServiceModalData(item)
             
//           }
//           setServiceDetailsModal(true)
//       })

//   }


//     const serviceDetailsModalClose =()=>{
//       setServiceDetailsModal(false)
//       setServiceDetails()
//     }


//    let serviceDetailsDisplayModal=(
//       <Modal isOpen={serviceDetailsModal} size={'md'}  toggle={()=>serviceDetailsModalClose()} centered>
//         <div style={{padding:16}}>
//           <div style={{justifyContent:'flex-end',display:'flex',flexDirection:'row'}}>
//             <div className='crossIcon_ViewPro' style={{cursor:'pointer'}}>
//               <img src={CloseIcon} style={{width:18,height:18}} onClick={()=>serviceDetailsModalClose()}/>
//             </div>
//           </div>
//           <div style={{marginTop:20}}>
//             <ServiceDetails proData={ProData} serviceDetails={DataSer}/>
//           </div>
//         </div>
//       </Modal>
//      )



//      let proModal=(
//       <Modal isOpen={showModal.Modal} size={"md"} centered>
//           <div style={{padding:20,backgroundColor:'#fff', borderRadius:10}}>
//               <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}}>
//                   <text className='are_you_wantText'>Remove from cart ?</text>
//                   <div onClick={()=>setShowModal({...showModal,Modal:false})} style={{cursor:'pointer',cboxShadow: '0 2px 4px 0 rgba(103, 52, 204, 0.1)',width:22,height:22,borderRadius:20,backgroundColor: '#fff',display:'flex',justifyContent:'center',flexDirection:'row',alignItems:'center'}}>
//                       <img src={CloseIcon} style={{width:16,height:16}}/>
//                   </div>
//               </div>
//               <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:20}}>
//                   <div className='yes_Logout_Btn' onClick={()=>setShowModal({...showModal,Modal:false})}>
//                       <text className='yes_Text_Logout'>No</text>
//                   </div>
//                   <div className='cancel_Logout_Btn' onClick={()=>removeFrmWshLst(showModal.wshLstPrid)} style={{marginLeft:20}}>
//                       <text className='cancel_Text_Logout'>Remove</text>
//                   </div>
//               </div>
//           </div>
//       </Modal>
//     )


// let serModal=(
//   <Modal isOpen={showModal1.Modal} size={"md"} centered>
//       <div style={{padding:20,backgroundColor:'#fff', borderRadius:10}}>
//           <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}}>
//               <text className='are_you_wantText'>Remove from cart ?</text>
//               <div onClick={()=>setShowModal1({...showModal1,Modal:false})} style={{cursor:'pointer',cboxShadow: '0 2px 4px 0 rgba(103, 52, 204, 0.1)',width:22,height:22,borderRadius:20,backgroundColor: '#fff',display:'flex',justifyContent:'center',flexDirection:'row',alignItems:'center'}}>
//                   <img src={CloseIcon} style={{width:16,height:16}}/>
//               </div>
//           </div>
//           <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:20}}>
//               <div className='yes_Logout_Btn' onClick={()=>setShowModal1({...showModal1,Modal:false})}>
//                   <text className='yes_Text_Logout'>No</text>
//               </div>
//               <div className='cancel_Logout_Btn' onClick={()=>removeFrmWshLstServices(showModal1.wshLstPrid1)} style={{marginLeft:20}}>
//                   <text className='cancel_Text_Logout'>Remove</text>
//               </div>
//           </div>
//       </div>
//   </Modal>
// )
//     return(
//         <div style={{backgroundColor:'#fff'}}>
//         <div style={{  marginLeft: '7%', marginRight: '7%'}}>
//          <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>    
//              <text style={{fontSize:24,fontWeight:'bold',fontFamily:'Inter',marginTop:144}}>Wishlist</text>

//                                        <Input
//                                                 style={{ color: '#8c52ff',fontSize:14,fontWeight:'normal',fontFamily:'Inter',width:140,border:0,marginTop:144,border:'solid 1px #e0e0e0' }}
//                                                 onChange={PorShandler}
//                                                 placeholder="Subject"
//                                                 value={ProOrSer}
//                                                 type="select">
//                                                 <option value='Services'>Services</option>
//                                                 <option value='Professionals'>Professionals</option>
//                                        </Input>
//          </div>
//          <h2 style={{ textAlign: 'center', borderBottom: '1px solid #b8b8b8', lineHeight: '0.1em', opacity: 0.6, height: 7, flexGrow: 0,marginBottom:23 }} />

//          <div style={{display:'flex',overflowX:'scroll',overflowY:'hidden',scrollSnapType:'x',width:'100%',flexDirection:'row'}}> 
//          {categoryButtons.length !== 0  &&  !loading  && ProOrSer == 'Professionals' ?

            
//             categoryButtons.map((each,index)=>{

//             return(
//                   <div style={{display:'inline-block',marginTop:20}}>
//                   <CategoriesButton key={index}  handle={()=>handleButtonClick(each.QF_SERVICE_CATEGORY_PRID,each.category_name)} buttonColor={each.QF_SERVICE_CATEGORY_PRID === selected ?'#8c52ff':'#ffffff' } buttonTextColor={each.QF_SERVICE_CATEGORY_PRID != selected ?'#461c99':'#ffffff'} buttonTitle={each.category_name}/>
                  
//                   </div>
//           )
//           }) :null}
//           </div>

          

//           {categoryButtonsServices.length !== 0  && ProOrSer == 'Services' ?

                      
//            categoryButtonsServices.map((each,index)=>{

//           return(
//                 <div style={{display:'inline-block',marginTop:20}}>
//                 <CategoriesButton key={index} handle={()=>handleButtonClickServices(each.QF_SERVICE_CATEGORY_PRID)} buttonColor={each.QF_SERVICE_CATEGORY_PRID === selected1 ?'#8c52ff':'#ffffff' } buttonTextColor={each.QF_SERVICE_CATEGORY_PRID != selected1 ?'#461c99':'#ffffff'} buttonTitle={each.category_name}/>
//                 </div>
//           )
//           }) : null}


//         {/* <div  style={{display:'grid',justifyContent: 'space-between', marginTop:44,width:'100%',position:'relative',gridTemplateColumns:'repeat(auto-fill,184px)' }}> */}
//           { loading ?
//               <div style={{textAlign:'center' ,paddingBottom:'63%',display:'flex',flexDirection:'row',justifyContent:'center',marginTop:'10%'}}>
//                <ClipLoader widthUnit={"100%"} size={40} loadings={loading} color="#8c52ff" />
//               </div>
//             :
//             data }
//         {/* </div> */}
//         {serviceDetailsModal && serviceDetailsDisplayModal}
//         {showModal.Modal && proModal}
//         {showModal1.Modal && serModal}
//         <ToastContainer />
//         </div>
//         </div>)

// }

// export default WishList
         

         
            