import React from "react";
import '../../../CSS/CheckoutStack/SlotSelectionPage.css'

const TimeListContainer = ({slotItem,onPress,selectedSlots,serviceItem}) => {
    // console.log("slotItem in timelist",selectedSlots)
    return (
            <div className='timeListContainer' onClick={() => onPress()} style={{backgroundColor: (selectedSlots[serviceItem.QF_MERCHANT_SERVICE_PRID]?selectedSlots[serviceItem.QF_MERCHANT_SERVICE_PRID][slotItem.id]:false) ? 'rgba(216, 197, 252, 0.6)' : '#ffffff', borderColor: (selectedSlots[serviceItem.QF_MERCHANT_SERVICE_PRID]?selectedSlots[serviceItem.QF_MERCHANT_SERVICE_PRID][slotItem.id]:false)?'#8c52ff':(slotItem.inc_or_dec === 'inc' ? '#ff5d5d' : slotItem.inc_or_dec === 'dec' ? '#5ba85a' : '#8c52ff')}}>
                <text style={(selectedSlots[serviceItem.QF_MERCHANT_SERVICE_PRID]?selectedSlots[serviceItem.QF_MERCHANT_SERVICE_PRID][slotItem.id]:false)? selectedSlotTextStyle: timeTextStyle}>{slotItem.startTime +" - "+ slotItem.endTime}</text>
                {slotItem.tsPrid ?
                    <div className='demandPriceStyle'  style={{backgroundColor: slotItem.inc_or_dec === 'inc' ? '#ff5d5d' : (slotItem.inc_or_dec === 'dec' ? '#5ba85a' : '#8c52ff')}}>
                        <text className='demandPriceText'>{(slotItem.inc_or_dec==="inc"?"+ ":"- ")+(serviceItem.SERVICE_CURRENCY === "Rupees" ? ("\u20B9") : "$")+ (Math.abs(slotItem.inc_or_dec_val))}</text>
                    </div>
                : null}
            </div>
        )
}

    let timeTextStyle = {
        // fontFamily: "Inter",
        fontSize: 12,
        fontWeight: "normal",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#747474",
        // marginBottom:3
    };
    
    let selectedSlotTextStyle={
        fontFamily: "Inter",
        fontSize: 12,
        fontWeight: "500",
        fontStyle: "normal",
        letterSpacing: 0,
        textAlign: "left",
        color: "#000000"
    };

export default TimeListContainer;