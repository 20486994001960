import React from "react";
import '../../../CSS/CheckoutStack/SlotSelectionPage.css'

const DateListContainer = (props) => {
    // console.log("props in DateListContainer", props)
    return (
        <div className="unSelectedDateDiv" onClick={props.onPress} style={{backgroundColor: props.isSelected ? 'rgba(140, 82, 255, 1)' : null}}>
            <text className="unDateListTextStyle" style={{marginBottom:2,color: props.isSelected ? "#ffffff" : "#747474"}}>{props.month}</text>
            <text className="unDateListTextStyle" style={{color: props.isSelected ? "#ffffff" : "#747474"}}>{props.date}</text>
            <text className="unDateListTextStyle" style={{marginVertical:3,color: props.isSelected ? "#ffffff" : "#747474"}}>{props.day}</text>
            <div style={{width: 5,height: 5,backgroundColor: props.isSelected ?  "#ffffff" : '#f1f1f1',borderRadius:20}}></div>
        </div>
    )
}

export default DateListContainer;