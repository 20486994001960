import React, { useEffect, useState } from 'react'
import downArrow from '../../../New_WebPage_Design/Assets/Commons/Path 8 Copy.png'
import upArrow from '../../../New_WebPage_Design/Assets/Commons/Path 8.png'
import { Container, Row, Col, Card, CardText, Collapse } from "reactstrap";
import { Nav, NavItem } from "reactstrap";
import { NavLink } from "react-router-dom";
import '../../CSS/FAQ.css'
const FaqConsumer = () => {
    let [faqs_profileRegistration, setFaqs_profileRegistration] = useState(
        [
            {
                id: 1,
                ques: 'How do I download the Slash app?',
                ans: (
                    <div>
                        You can follow below mentioned steps for downloading Slash App on iOS devices –
                        <ol type="a">
                            <li>You can download and install the Slash App on devices with iOS 10 and newer. Visit App store on your iPhone.</li>
                            <li>Select Search from the menu, type Quickfind Inc in the search bar, then tap Search.</li>
                            <li>Select the Slash icon and Tap “Get” your device may require you to enter your App Store password.</li>
                            <li>When download is complete, “Get” will change to “Install”. Tap “Install” to start the installation.</li>
                            <li>When the installation is complete, “Install” will change to “Open”. Tap on it.</li>
                            <li>In the Slash app, click on “Register” to create a new account or click on “Login” if you already have an account.</li>
                        </ol>
                        You can download and install the Slash app on android devices play store version 8 & above. Head to the Google play store and follow these steps:
                        <ol type="a">
                            <li>Tap the Google Play icon.</li>
                            <li>Tap the search bar, type Quickfind Inc and then tap Search.</li>
                            <li>Select the Slash icon and tap “Install”.</li>
                            <li>After app installation is complete, tap “Open”.</li>
                            <li>In the Slash app, click on “Register” to create a new account or click on “Login” if you already have an account.</li>
                        </ol>
                    </div>
                )
            },
            {
                id: 2,
                ques: 'How do I register myself?',
                ans: (
                    <div>
                        To register yourself follow below mentioned steps -
                        <ol type="a">
                            <li>Open the Slash app and tap on “Profile Icon” on the top right of the Home page.</li>
                            <li>Click on the “Login” tab and click on register at the bottom of the “Login” page.</li>
                            <li>Enter your Name, Email, Password, and Phone Number (Optional).</li>
                            <li>A Verification Code will be sent to your Mobile Number and a verification email will be sent to your email id.</li>
                            <li>Enter verification Code to verify your Mobile Number and Click on Verification link sent to your email id.</li>
                            <li>You are successfully registered. Now Login with your email id or mobile number and password to explore offers.</li>
                        </ol>
                        You can also register while booking any service:
                        <ol type="a">
                            <li>Select any offer as a Guest User.</li>
                            <li>Before buying a booking you will be asked to enter email id and set password (optional).</li>
                            <li>Enter and tap on “continue as guest”. An email with a verification link will be sent to your email id.</li>
                            <li>Click on verification link in email and become a registered Customer.</li>
                        </ol>
                    </div>
                )
            },
            {
                id: 3,
                ques: 'Can I buy an offer as a Guest User?',
                ans: (
                    <div>
                        You can follow below steps to buy any offer as a guest user –
                        <ol type="a">
                            <li>Select any offer and tap on the “Buy now” option.</li>
                            <li>Before buying a booking, you will be asked to enter email id and password (optional).</li>
                            <li>Provide your email id and complete the payment.</li>
                            <li>Booking details will be sent to your email address.</li>
                        </ol>
                    </div>
                )
            },
            {
                id: 4,
                ques: 'Can I register using only email?',
                ans: (
                    <div>
                        Registration can be done using only an email address. Mobile Number is optional during registration. Although you can update your mobile number to get the latest communication on your bookings
                    </div>
                )
            },
            {
                id: 5,
                ques: 'How can I verify/update my Mobile Number?',
                ans: (
                    <div>
                        You can verify/update your mobile number following below steps -
                        <ol type="a">
                            <li>Visit your profile and click on the edit icon on the top right corner.</li>
                            <li>Change your “Mobile number” and click on “Update”.</li>
                            <li>Click on send to get verification code sent to your number.</li>
                            <li>Enter the verification code and click on verify.</li>
                        </ol>
                    </div>
                )
            },
            {
                id: 6,
                ques: 'How can I verify/update my email?',
                ans: (
                    <div>
                        You can follow below mentioned steps to change your email address -
                        <ol type="a">
                            <li>Visit your profile and click on “Edit icon” on the top right corner.</li>
                            <li>Now click on the “Change email” tab on business detail page.</li>
                            <li>Enter the new email address and click on “Submit”.</li>
                            <li>Enter the verification code sent to your new email address and click on “Verify”.</li>
                            <li>Your new email address will be updated successfully.</li>
                        </ol>
                    </div>
                )
            },
            {
                id: 7,
                ques: 'How can I update my name?',
                ans: 'To edit your name visit your profile and click on “Edit icon” on the top right corner. Enter your name in the input field and click on update.'
            },
            {
                id: 8,
                ques: 'How do I change my password?',
                ans: (
                    <div>
                        To change your password visit your profile and follow below steps –
                        <ol>
                            <li>Click on the “edit icon” on the top right.</li>
                            <li>Click on change password.</li>
                            <li>Enter your old password.</li>
                            <li>Now enter the new password (should be of minimum 8 characters) and confirm it.</li>
                            <li>Click on “Update”.</li>
                            <li>Your password will be changed successfully.</li>
                        </ol>
                    </div>
                )
            },
            {
                id: 9,
                ques: 'How do I log out?',
                ans: (
                    <div>
                        To logout from your profile, follow the steps below:
                        <ol type="a">
                            <li>Go to your profile.</li>
                            <li>Click on logout in the menu.</li>
                            <li>You will be successfully logged out from your account.</li>
                        </ol>
                    </div>
                )
            },
            {
                id: 10,
                ques: 'How can I share the app link with my friend?',
                ans: 'Go to your profile and click on the share icon at the top right.'
            }

        ]
    )
    let [faqs_bookingCancellation, setFaqs_bookingCancellation] = useState(
        [
            {
                id: 1,
                ques: 'How do I find offers?',
                ans: (
                    <div>
                        To find offers follow below steps -
                        <ol type="a">
                            <li>Enter the location (city name or specific address) in the search bar on Home page. By default, your current location is used to find offers near to you.</li>
                            <li>You may add filters to shortlist your options of services. You can select multiple subcategories. Set the date and time on which you want to book.</li>
                            <li>Select Price range, distance and ratings to narrow down your search as per your preferences.</li>
                            <li>For better experience you can also sort the list of Professionals as per Price, Distance, ratings and Discount.</li>
                        </ol>
                    </div>
                )
            },
            {
                id: 2,
                ques: 'How do I locate the Professional on the Map?',
                ans: (
                    <div>
                        To register yourself follow below mentioned steps -
                        <ol type="a">
                            <li>Click on the Map icon on top right corner of home page.</li>
                            <li>Balloon icon will show you the Professionals available in the area and the discount offered by them on their services.</li>
                            <li> You can click on each of these balloon icons to know more about the Professional.</li>
                            <li> Tap on the display card at the bottom of the map which appears once you tap on the balloon.</li>.
                            <li> You will be redirected to the Professional detail page which will show all offers available from the Professional.</li>
                        </ol>
                    </div>
                )
            },
            {
                id: 3,
                ques: 'How can I filter offers based on my preferences?',
                ans: (
                    <div>
                        To filter offers go to home page and follow below steps –
                        <ol type="a">
                            <li>Click on filter icon at the bottom left of the page.</li>
                            <li>Apply your choice of filters based on subcategory, Price Range, Distance, Ratings, Date and Time to narrow the search.</li>
                        </ol>
                    </div>
                )
            },
            {
                id: 4,
                ques: 'How can I see all the offers from a single professional?',
                ans: (
                    <div>
                        To view all offers available with a single professional, click on the offer display card of any professional. You will be redirected to the Professional detail page which will have the list of all offers. Now you can click on each offer to view details.
                    </div>
                )
            },
            {
                id: 5,
                ques: 'How do I buy/book an offer?',
                ans: (
                    <div>
                        To buy any offer near your area follow the below mentioned steps –
                        <ol type="a">
                            <li>On Home Page, you will see multiple offers from different professionals.</li>
                            <li>Based on your interest you can tap on any offer.</li>
                            <li>Tap on “Buy Now” below the offer name.</li>
                            <li>Select the date and time when you want to book the offer and click on proceed.</li>
                            <li>In the “Checkout detail page” apply coupon if you have any and click on “Proceed to Pay”.</li>
                            <li>Make the payment. Once the booking is confirmed you will be redirected to the “My Bookings” page where you can see the recent booking under the “Upcoming” tab.</li>
                        </ol>
                    </div>
                )
            },
            {
                id: 6,
                ques: 'Can I buy multiple offers?',
                ans: (
                    <div>
                        You can buy any number of offers based on the Offer availability for the selected date and time.

                    </div>
                )
            },
            {
                id: 7,
                ques: 'Where can I check my booking details?',
                ans: (
                    <div>
                        <div>
                            You can check all upcoming and past bookings in the “My Bookings” page. Visit your profile and get to “My Bookings”.  In addition to this, all the information related to your bookings will be sent to your registered email and mobile number.
                        </div>
                        <div>
                            Registered users can visit their “profile” and click on “My Bookings” to view their upcoming, past and cancelled bookings.
                        </div>
                    </div>
                )
            },
            {
                id: 8,
                ques: 'How can I cancel my booking?',
                ans: (
                    <div>
                        As a registered user you can follow below steps to cancel your booking if cancellation is allowed for the service you booked –
                        <ol>
                            <li>Visit your “Profile” and go to “My Bookings” page.</li>
                            <li>Click on the offer you want to cancel under “Upcoming tab”. (Check if cancellation is allowed).</li>
                            <li>Click at the “Cancel Booking” button at the bottom of the Offer detail page. </li>
                        </ol>
                        You can also follow the below steps to cancel –
                        <ol>
                            <li>Visit your “Profile” and go to “My Bookings” page.</li>
                            <li>Under “Upcoming tab” click on “three dots” at the top right of any booking. Click on “Cancel” and give your confirmation.</li>
                            <li>As a Guest user, you can follow below steps to cancel your booking if cancellation is allowed by the Professional.</li>
                            <li>Check for the “Booking confirmation” email in your Inbox.</li>
                            <li>Click on “Cancel Booking” link at the bottom of the mail body.</li>
                            <li>You will receive a cancellation mail confirming your booking has been cancelled.</li>
                            Please note:  Certain offers from Slash Pro carry cancellation fees. These cancellation fees will be applied and deducted from your booking amount if you choose to cancel. The refund amount will be credited back to your method of payment.
                        </ol>
                    </div>
                )
            },
            {
                id: 9,
                ques: 'What is booking Validation?',
                ans: (
                    <div>
                        In the offer detail page of any upcoming booking you will find a PIN number and QR code. After the service has been delivered by the Service Professional, this PIN number/QR code needs to be shared with them to validate the booking. This step ensures release of payment to the professional for providing the service you booked with them.
                    </div>
                )
            },
            {
                id: 10,
                ques: 'Should I provide a booking pin and QR code both at the time of booking validation?',
                ans: 'Either the PIN number or QR code can be used to validate your bookings.'
            }

        ],

    )

    let [faqs_paymentRefund, setFaqs_paymentRefund] = useState(

        [
            {
                id: 1,
                ques: 'What are the payment methods available on the app?',
                ans: (
                    <div>
                        As of now, you can use either Debit or credit card to complete the payment during booking of any offer.
                    </div>
                )
            },
            {
                id: 2,
                ques: 'How secure is my credit card/debit card info?',
                ans: (
                    <div>
                        Our payments are powered and secured by Stripe to keep your information safe. We don’t store your payment method details with us.
                    </div>
                )
            },
            {
                id: 3,
                ques: 'Where can I see my payments done against any booking?',
                ans: (
                    <div>

                        <ol type="a">
                            <li>To view all your payment transactions visit your “Profile”.</li>
                            <li>Click on “Payment transactions”.</li>
                            <li>You can view all your transactions under “Payments” tab.</li>
                        </ol>
                    </div>
                )
            },
            {
                id: 4,
                ques: 'Where can I see my refunds?',
                ans: (
                    <div>
                        <ol>
                            <li>To view refunds, visit your “Profile”.</li>
                            <li>Click on “Payment transactions”.</li>
                            <li>You can view all refund details under “Refunds” tab.</li>
                        </ol>
                    </div>
                )
            },
            {
                id: 5,
                ques: 'Why have I not received my refund amount?',
                ans: (
                    <div>
                        After cancellation, the outstanding refund amount will be credited to your payment method in 5-7 business days. In case you haven’t received the refund in 5-7 business days, please email to support@goslash.com.
                    </div>
                )
            },
            {
                id: 6,
                ques: 'I have missed an appointment. Will I receive a refund?',
                ans: (
                    <div>
                        If you have missed your appointment, you won’t be eligible for a refund. You can reach out to us through email (support@goslash.com) for any booking related queries.

                    </div>
                )
            },
            {
                id: 7,
                ques: 'My transaction timed out while booking?',
                ans: (
                    <div>
                        Transaction timeout happens due to Inactivity or slow internet while booking. If the amount is debited, it will be credited to the source account within 5-7 business days.
                    </div>
                )
            },
            {
                id: 8,
                ques: 'While booking, my credit card/debit card transaction failed?',
                ans: (
                    <div>
                        <div>
                            Transactions may fail due to incorrect card details submitted while making payments. Kindly try again with correct details.
                        </div>
                        <div>
                            Please note: If the amount was debited from your account it will be credited back within 5-7 business days.
                        </div>
                    </div>
                )
            },
            {
                id: 9,
                ques: 'I have saved my card. Where can I see it?',
                ans: (
                    <div>
                        To view your saved cards details, visit your profile and click on saved cards.
                    </div>
                )
            },
            {
                id: 10,
                ques: 'Can I save more than one card details for future payments?',
                ans: (
                    <div>
                        In order to add another card details, follow the below steps –
                        <ol>
                            <li>Choose your desired offer from the list and click on “Buy Now” tab.</li>
                            <li>While going through the process of booking an offer you will land on the payment page.</li>
                            <li>Now you will be prompted to pay through an already saved card or you can add another card detail for payment and click on “Save card for future payments”.</li>
                            <li>You can see the newly added card in “Saved Card” page in “Profile” section.</li>
                        </ol>
                    </div>
                )
            },
            {
                id: 11,
                ques: 'Can I delete my saved cards?',
                ans: (
                    <div>

                        <ol>
                            <li>To delete your saved cards visit your profile and go to “Saved cards” page.</li>
                            <li>To delete a particular card click on the “delete” icon beside that card.</li>
                            <li> A notification will ask for your confirmation. Click on “Yes” to successfully delete the card.</li>
                        </ol>
                    </div>
                )
            }

        ],
    )

    let [faqs_couponReferral, setFaqs_couponReferral] = useState(

        [
            {
                id: 1,
                ques: 'How do I apply for a coupon?',
                ans: (
                    <div>
                        <ol>
                            <li>You can apply the coupon code available with you in the “Checkout detail page.</li>
                            <li> coupon code in the input field and click on "Apply" button.</li>
                            <li> If the coupon is valid, discounted price is visible in under “Bill Details” in the “Checkout Details” page.</li>
                        </ol>
                    </div>
                )
            },
            {
                id: 2,
                ques: 'Can I apply the coupon code mentioned in my scratch card?',
                ans: (
                    <div>
                        The coupon code mentioned in the scratch card can be applied in the coupon field in the “Checkout details” page while booking an offer.
                    </div>
                )
            },
            {
                id: 3,
                ques: 'How can I refer to my friend?',
                ans: (
                    <div>
                        You can follow below mentioned steps for referring your friend -
                        <ol type="a">
                            <li>Go to profile page and click on “Refer & Earn”.</li>
                            <li>On “Refer & Earn” page, copy the referral code and share with your friends or click on “Invite Now” to share the referral code along with Slash app link.</li>
                        </ol>
                        You can also refer Professionals by clicking on “Refer them” below the “Invite Now” button.
                    </div>
                )
            },
            {
                id: 4,
                ques: 'How can my friend use the referral code?',
                ans: (
                    <div>
                        Referral code can be used while registration. Please follow the below mentioned steps-
                        <ol>
                            <li>On the Login page, click on Register button at the bottom. On the registration Page, click on “Have a Referral code”.</li>
                            <li>Enter Name, Email Address and Password, Mobile No (optional). Enter the shared referral code and click on Register.</li>
                        </ol>
                        Please note: Professionals can also follow the above mentioned steps to register themselves using a referral code.
                    </div>
                )
            },
            {
                id: 5,
                ques: 'Will my friend receive any scratch card?',
                ans: (
                    <div>
                        Scenarios for receiving scratch cards:
                        <ol type="a">
                            <li>Customer to Customer: Customer A refers to Customer B. Customer B will get an unlocked scratch card and Customer A will get a locked scratch card. As soon as Customer B books a service, Customer A’s scratch card will be unlocked.</li>
                            <li>Customer to Professional: Customer A refers to Professional B. Customer A will get the locked scratch card. As soon as Professional B verifies his/her account, Customer A’s scratch card will be unlocked.</li>
                            <li>Professional to Professional: Professional A refers to Professional B. No scratch card earned.</li>
                            <li>Professional to Customer: Professional A refers Customer B. Only Customer B will get the unlocked scratch card.</li>

                        </ol>
                        Please note: Professionals do not receive any scratch card for referring someone or getting referred by someone (customers/professionals). Only customers will receive the scratch card.
                    </div>
                )
            },
            {
                id: 6,
                ques: 'Where can I see my rewards/scratch cards?',
                ans: (
                    <div>
                        After referring any friend (customer/professional) you will receive a scratch card that can be viewed in the “Refer & Earn” Page.

                    </div>
                )
            },
            {
                id: 7,
                ques: 'How many friends can I refer to?',
                ans: (
                    <div>
                        There is no limitation on the number of referrals.
                    </div>
                )
            }

        ],
    )

    let [faqs_chatSupport, setFaqs_chatSupport] = useState(
        [
            {
                id: 1,
                ques: 'How can I communicate with professionals?',
                ans: (
                    <div>
                        After you book any offer, you can connect with the professional via the “Chat” feature available inside the booking.

                    </div>
                )
            },
            {
                id: 2,
                ques: 'Can I chat if my booking is cancelled?',
                ans: (
                    <div>
                        The Chat feature will let you talk to your professionals for all upcoming bookings only. When the service is cancelled or completed, you cannot communicate with them.
                    </div>
                )
            },
            {
                id: 3,
                ques: 'Where can I find the chat option?',
                ans: (
                    <div>
                        To chat with any professional, you can go to the “My Bookings” page. Under the “Upcoming” tab, click on any booking and click on the chat icon at the bottom.
                    </div>
                )
            },
            {
                id: 4,
                ques: 'Where can I see my notifications/messages received from professionals?',
                ans: (
                    <div>
                        You can follow below mentioned process to view/respond to the chat -
                        <ol>
                            <li>You will be notified when you receive a message from any Professional. Press on the “bell icon” in Home Page to check/respond to any message that you have received.</li>
                            <li>Or else click on any booking under the “Upcoming” tab in “My Booking page”, you will find the chat icon at the bottom of the page with new notifications count.</li>
                            <li>Tap on the icon to view new messages or communicate with the professional.</li>
                        </ol>

                    </div>
                )
            },
            {
                id: 5,
                ques: 'How can I provide a rating for the service provided by the professional?',
                ans: (
                    <div>
                        To provide rating to your professional, follow the below steps -
                        <ol type="a">

                            <li> Visit your Profile and click on “My bookings”.</li>
                            <li> Select any booking in the “Past” tab.</li>
                            <li>At the bottom of booking display card you will find ratings.</li>
                            <li>You can give 0-5 star rating to any professional for completed booking (i.e. after service delivery and booking validation).</li>
                        </ol>

                    </div>
                )
            },
            {
                id: 6,
                ques: 'Is it mandatory to provide a rating?',
                ans: (
                    <div>
                        Ratings are not mandatory, but we strongly request your rating to help us understand the quality of service provided to you, so that we can serve you better.

                    </div>
                )
            },
            {
                id: 7,
                ques: 'Where can I learn more about the Slash app?',
                ans: (
                    <div>
                        To know more about Slash app you can click on “A Guide to slash” on the login page or you can follow below mentioned steps-
                        <ol>
                            <li>Go to your Profile Page.</li>
                            <li>Click on “A Guide to Slash” to know more about Slash and view demo videos on details of various features.</li>
                        </ol>
                    </div>
                )
            },
            {
                id: 8,
                ques: ' How can I reach Slash support?',
                ans: (
                    <div>
                        You can email us to our email address support@goslash.com or call us on our number +1 650-739-8103.
                    </div>
                )
            }

        ],
    )
    let [collapse_Consumer1, setCollapse_Consumer1] = useState({})
    const [prevId, setPrevId] = useState()
    let [show, setShow] = useState(5)
    let [docs, setDocs] = useState('Profile & Registration')
    const [activeItem, setActiveItem] = useState('Profile & Registration');
    const navItem = ['Profile & Registration', 'Booking & Cancellations', 'Payments & Refunds', 'Coupons & Referrals', "Chat & Support"]

    const faqCollapseHandlerC1 = (id) => {
        let collapse_Consumer = {};
        console.log('id', id)
        if (collapse_Consumer1['collapse_' + id]) {
            collapse_Consumer = {
                ...collapse_Consumer1,
                ['collapse_' + id]: false
            }
            setCollapse_Consumer1(collapse_Consumer)
        } else {
            collapse_Consumer = {
                ...collapse_Consumer1,
                // ['collapse_' + prevId]: false,
                ['collapse_' + id]: true,
            }
            setCollapse_Consumer1(collapse_Consumer)
        }
    }


    const profileHandler = () => {
        let collapse_Consumer = {};

        collapse_Consumer = {
            ...collapse_Consumer1,
            ['collapse_' + prevId]: false
        }
        setShow(5)
        setCollapse_Consumer1(collapse_Consumer)
        setDocs('Profile')
    }

    const bookingsHandler = () => {
        let collapse_Consumer = {};

        collapse_Consumer = {
            ...collapse_Consumer1,
            ['collapse_' + prevId]: false
        }
        setShow(5)
        setCollapse_Consumer1(collapse_Consumer)
        setDocs('Booking')
    }
    const paymentHandler = () => {
        let collapse_Consumer = {};

        collapse_Consumer = {
            ...collapse_Consumer1,
            ['collapse_' + prevId]: false
        }
        setShow(5)
        setCollapse_Consumer1(collapse_Consumer)
        setDocs('Payments')
    }
    const couponsHandler = () => {
        let collapse_Consumer = {};

        collapse_Consumer = {
            ...collapse_Consumer1,
            ['collapse_' + prevId]: false
        }
        setShow(5)
        setCollapse_Consumer1(collapse_Consumer)
        setDocs('Coupon')
    }
    const chatHandler = () => {
        let collapse_Consumer = {};

        collapse_Consumer = {
            ...collapse_Consumer1,
            ['collapse_' + prevId]: false
        }
        setShow(5)
        setCollapse_Consumer1(collapse_Consumer)
        setDocs('Support')
    }


    const showMore = () => {

        // this.setState({show: this.state.show+5})
        setShow(show = show + 5)
    }

    const showLess = () => {
        // this.setState({show: this.state.show-5})
        setShow(show = show - 5)
    }
    function handleItemClick(item) {
        setActiveItem(item);
    }
    const activeNavHeader = {
        borderBottom: '4px solid #a5d028',
        color: "#261640",
        fontWeight: 900,
        fontFamily: 'Mulish',
        // border:'1px solid black',
    }

    let FAQ_Profile;
    if (faqs_profileRegistration.length !== 0) {

        FAQ_Profile = faqs_profileRegistration.slice(0, show).map((ques, i) => {
            return (
                <div style={{ transition: 'opacity 0.3s ease' }}>
                    <Row className='question'>

                        <Col sm={{ size: 11 }} style={{ cursor: 'pointer' }} onClick={() => faqCollapseHandlerC1(ques.id)}>
                            <CardText style={{ padding: 0, fontFamily: "Mulish", fontSize: 15, fontWeight: "600", fontStyle: "normal", letterSpacing: 0, color: "#484148" }}> {ques.ques} </CardText>
                        </Col>
                        {collapse_Consumer1['collapse_' + ques.id] ?
                            <Col sm={{ size: 'auto' }} style={{ cursor: 'pointer' }} onClick={() => faqCollapseHandlerC1(ques.id)}>
                                <img src={upArrow} width={15} />
                            </Col>
                            :
                            <Col sm={{ size: 'auto' }} style={{ cursor: 'pointer' }} onClick={() => faqCollapseHandlerC1(ques.id)}>
                                <img src={downArrow} width={15} />
                            </Col>
                        }
                    </Row>
                    <Row className='ansdiv'>
                        <Col sm={{ size: 11 }}>
                            <Collapse isOpen={collapse_Consumer1['collapse_' + ques.id]}>
                                <CardText style={{ fontFamily: "Mulish", fontSize: 14, fontWeight: "normal", fontStyle: "normal", lineHeight: 'normal', letterSpacing: 0, color: "#7e757e" ,padding:20}} >{ques.ans}</CardText>
                            </Collapse>
                        </Col>
                    </Row>
                    {show == i + 1 || faqs_profileRegistration.length == i + 1 ? null : <div style={{ marginTop: 10 }}></div>}
                </div>
            )
        })
    }

    let FAQ_Booking;
    if (faqs_bookingCancellation.length !== 0) {

        FAQ_Booking = faqs_bookingCancellation.slice(0, show).map((ques, i) => {
            return (
                <div style={{ transition: 'opacity 0.3s ease' }}>
                    <Row className='question'>

                        <Col sm={{ size: 11 }} style={{ cursor: 'pointer' }} onClick={() => faqCollapseHandlerC1(ques.id)}>
                            <CardText style={{ padding: 0, fontFamily: "Mulish", fontSize: 15, fontWeight: "600", fontStyle: "normal", letterSpacing: 0, color: "#484148" }}> {ques.ques} </CardText>
                        </Col>
                        {collapse_Consumer1['collapse_' + ques.id] ?
                            <Col sm={{ size: 'auto' }} style={{ cursor: 'pointer' }} onClick={() => faqCollapseHandlerC1(ques.id)}>
                                <img src={upArrow} width={15} />
                            </Col>
                            :
                            <Col sm={{ size: 'auto' }} style={{ cursor: 'pointer' }} onClick={() => faqCollapseHandlerC1(ques.id)}>
                                <img src={downArrow} width={15} />
                            </Col>
                        }
                    </Row>
                    <Row className='ansdiv'>
                        <Col sm={{ size: 11 }}>
                            <Collapse isOpen={collapse_Consumer1['collapse_' + ques.id]}>
                                <CardText style={{ fontFamily: "Mulish", fontSize: 14, fontWeight: "normal", fontStyle: "normal", lineHeight: 'normal', letterSpacing: 0, color: "#7e757e",padding:20 }} >{ques.ans}</CardText>
                            </Collapse>
                        </Col>
                    </Row>
                    {show == i + 1 || faqs_bookingCancellation.length == i + 1 ? null : <div style={{ marginTop: 10 }}></div>}
                </div>
            )
        })
    }

    let FAQ_Payment;
    if (faqs_paymentRefund.length !== 0) {

        FAQ_Payment = faqs_paymentRefund.slice(0, show).map((ques, i) => {
            return (
                <div style={{ transition: 'opacity 0.3s ease' }}>
                    <Row className='question'>

                        <Col sm={{ size: 11 }} style={{ cursor: 'pointer' }} onClick={() => faqCollapseHandlerC1(ques.id)}>
                            <CardText style={{ padding: 0, fontFamily: "Mulish", fontSize: 15, fontWeight: "600", fontStyle: "normal", letterSpacing: 0, color: "#484148",paddingTop:10 }}> {ques.ques} </CardText>
                        </Col>
                        {collapse_Consumer1['collapse_' + ques.id] ?
                            <Col sm={{ size: 'auto' }} style={{ cursor: 'pointer' }} onClick={() => faqCollapseHandlerC1(ques.id)}>
                                <img src={upArrow} width={15} />
                            </Col>
                            :
                            <Col sm={{ size: 'auto' }} style={{ cursor: 'pointer' }} onClick={() => faqCollapseHandlerC1(ques.id)}>
                                <img src={downArrow} width={15} />
                            </Col>
                        }
                    </Row>
                    <Row className='ansdiv'>
                        <Col sm={{ size: 11 }}>
                            <Collapse isOpen={collapse_Consumer1['collapse_' + ques.id]}>
                                <CardText style={{ fontFamily: "Mulish", fontSize: 14, fontWeight: "normal", fontStyle: "normal", lineHeight: 'normal', letterSpacing: 0, color: "#7e757e",padding:20 }} >{ques.ans}</CardText>
                            </Collapse>
                        </Col>
                    </Row>
                    {show == i + 1 || faqs_paymentRefund.length == i + 1 ? null : <div style={{ marginTop: 10 }}></div>}
                </div>
            )
        })
    }



    let FAQ_Refer;
    if (faqs_couponReferral.length !== 0) {

        FAQ_Refer = faqs_couponReferral.slice(0, show).map((ques, i) => {
            return (
                <div style={{ transition: 'opacity 0.3s ease' }}>
                    <Row className='question'>

                        <Col sm={{ size: 11 }} style={{ cursor: 'pointer' }} onClick={() => faqCollapseHandlerC1(ques.id)}>
                            <CardText style={{ padding: 0, fontFamily: "Mulish", fontSize: 15, fontWeight: "600", fontStyle: "normal", letterSpacing: 0, color: "#484148" }}> {ques.ques} </CardText>
                        </Col>
                        {collapse_Consumer1['collapse_' + ques.id] ?
                            <Col sm={{ size: 'auto' }} style={{ cursor: 'pointer' }} onClick={() => faqCollapseHandlerC1(ques.id)}>
                                <img src={upArrow} width={15} />
                            </Col>
                            :
                            <Col sm={{ size: 'auto' }} style={{ cursor: 'pointer' }} onClick={() => faqCollapseHandlerC1(ques.id)}>
                                <img src={downArrow} width={15} />
                            </Col>
                        }
                    </Row>
                    <Row className='ansdiv'>
                        <Col sm={{ size: 11 }}>
                            <Collapse isOpen={collapse_Consumer1['collapse_' + ques.id]}>
                                <CardText style={{ fontFamily: "Mulish", fontSize: 14, fontWeight: "normal", fontStyle: "normal", lineHeight: 'normal', letterSpacing: 0, color: "#7e757e",padding:20 }} >{ques.ans}</CardText>
                            </Collapse>
                        </Col>
                    </Row>
                    {show == i + 1 || faqs_couponReferral.length == i + 1 ? null : <div style={{ marginTop: 10 }}></div>}
                </div>
            )
        })
    }



    let FAQ_Support;
    if (faqs_chatSupport.length !== 0) {

        FAQ_Support = faqs_chatSupport.slice(0, show).map((ques, i) => {
            return (
                <div style={{ transition: 'opacity 0.3s ease' }}>
                    <Row className='question'>

                        <Col sm={{ size: 11 }} style={{ cursor: 'pointer' }} onClick={() => faqCollapseHandlerC1(ques.id)}>
                            <CardText style={{ padding: 0, fontFamily: "Mulish", fontSize: 15, fontWeight: "600", fontStyle: "normal", letterSpacing: 0, color: "#484148" }}> {ques.ques} </CardText>
                        </Col>
                        {collapse_Consumer1['collapse_' + ques.id] ?
                            <Col sm={{ size: 'auto' }} style={{ cursor: 'pointer' }} onClick={() => faqCollapseHandlerC1(ques.id)}>
                                <img src={upArrow} width={15} />
                            </Col>
                            :
                            <Col sm={{ size: 'auto' }} style={{ cursor: 'pointer' }} onClick={() => faqCollapseHandlerC1(ques.id)}>
                                <img src={downArrow} width={15} />
                            </Col>
                        }
                    </Row>
                    <Row className='ansdiv'>
                        <Col sm={{ size: 11 }}>
                            <Collapse isOpen={collapse_Consumer1['collapse_' + ques.id]}>
                                <CardText style={{ fontFamily: "Mulish", fontSize: 14, fontWeight: "normal", fontStyle: "normal", lineHeight: 'normal', letterSpacing: 0, color: "#7e757e",padding:20 }} >{ques.ans}</CardText>
                            </Collapse>
                        </Col>
                    </Row>
                    {show == i + 1 || faqs_chatSupport.length == i + 1 ? null : <div style={{ marginTop: 10 }}></div>}
                </div>
            )
        })
    }
    let sectionsHeader;
    if (navItem.length !== 0) {
        sectionsHeader = (
            // <div style={{position:'absolute',left:'auto',}}>
            <div className='faqScrool'>
                {
                    navItem.map((item, index) => {
                        return (
                            <div style={{ marginTop: 15, marginBottom: 15, marginLeft: 20, marginRight: 20 }}>
                                <text className={item === activeItem ? "mybooking_navbarItem_active_style1" : "mybooking_navbarItem_style1"} onClick={() => handleItemClick(item)} >{item}</text>
                            </div>
                        )

                    })
                }
            </div>
            // </div>
        )
    }

    // console.log('jdhjhdfj',collapse_Consumer1)
    return (

        <div>
            {/* <Nav tabs className="mybooking_navbar_style1">
                {navItem.map((item) => (
                    <NavItem  className={item === activeItem ? "mybooking_navbarItem_active_style1" : "mybooking_navbarItem_style1"}>
                        <NavLink key={item} onClick={() => handleItemClick(item)} className={item === activeItem ? 'mybooking_navItem_active_style1' : 'mybooking_navItem_inactive_style1'}>{item}</NavLink>
                    </NavItem>
                ))}
            </Nav> */}
            <div>
                {sectionsHeader}
            </div>
            <Container fluid style={{ padding: 0, display: 'flex', justifyContent: 'center' }}>
                <div style={{ padding: 10, textAlign: 'left', width: '100%' }}>
                    {activeItem == 'Profile & Registration' ? FAQ_Profile : null}
                    {activeItem == 'Booking & Cancellations' ? FAQ_Booking : null}
                    {activeItem == 'Payments & Refunds' ? FAQ_Payment : null}
                    {activeItem == 'Coupons & Referrals' ? FAQ_Refer : null}
                    {activeItem == 'Chat & Support' ? FAQ_Support : null}
                </div>



            </Container>
            {show <= 5 ?

                <div className='showMore' onClick={() => showMore()}>
                    <text style={{ textDecorationLine: 'underline', color: '#461c99' }}>Show More</text>
                    {/* <img src={downArrow} width={15} /> */}
                </div>

                : show >= faqs_profileRegistration.length && docs == 'Profile' ?
                    <div className='showMore' onClick={() => showLess()}>
                        <text style={{ textDecorationLine: 'underline', color: '#461c99' }}>Show Less</text>
                        {/* <img src={upArrow} width={15} /> */}
                    </div>

                    : show >= faqs_bookingCancellation.length && docs == 'Booking' ?
                        <div className='showMore' onClick={() => showLess()}>
                            <text style={{ textDecorationLine: 'underline', color: '#461c99' }}>Show Less</text>
                            {/* <img src={upArrow} width={15} /> */}
                        </div>

                        : show >= faqs_paymentRefund.length && docs == 'Payments' ?
                            <div className='showMore' onClick={() => showLess()}>
                                <text style={{ textDecorationLine: 'underline', color: '#461c99' }}>Show Less</text>
                                {/* <img src={upArrow} width={15} /> */}
                            </div>

                            : show >= faqs_couponReferral.length && docs == 'Coupon' ?
                                <div className='showMore' onClick={() => showLess()}>
                                    <text style={{ textDecorationLine: 'underline', color: '#461c99' }}>Show Less</text>
                                    {/* <img src={upArrow} width={15} /> */}
                                </div>

                                : show >= faqs_chatSupport.length && docs == 'Support' ?
                                    <div className='showMore' onClick={() => showLess()}>
                                        <text style={{ textDecorationLine: 'underline', color: '#461c99' }}>Show Less</text>
                                        {/* <img src={upArrow} width={15} /> */}
                                    </div>

                                    :
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: 400, marginRight: 'auto', marginLeft: 'auto' }}>
                                        {/* <div className='showMore' onClick={() => showMore()}>
                                            <text style={{ textDecorationLine: 'underline', color: '#461c99' }}>Show More</text>
                                           
                                        </div> */}
                                        <div className='showMore' onClick={() => showLess()}>
                                            <text style={{ textDecorationLine: 'underline', color: '#461c99' }}>Show Less</text>
                                           
                                        </div>
                                    </div>
            }
        </div>
    );
}

export default FaqConsumer;
