
import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Button, Container, Form, FormGroup, Label, Input, FormFeedback, Modal, ModalBody, ModalFooter, CardBody, Card, CardImg, ButtonGroup, } from 'reactstrap'
import '../CSS/OfferPage.css'
import Carousel from "react-multi-carousel";
import OfferImg from '../Assets/offer.png'
import numeral from 'numeral';
import { useMediaQuery } from "react-responsive"
import { ClipLoader } from 'react-spinners';
import RightArrow from '../Assets/ic-round-arrow-back-ios.png'
import Arrow from '../Assets/arrow.png'
import customFetchData from '../Components/Custom/customFetchData';
import OfferImg1 from '../Assets/group-1000005381.png'
import OfferImg2 from '../Assets/group-1000005381.png'
import { AiFillStar } from "react-icons/ai";
import MobileImg from '../Assets/group-1000005352.png'
import Down from '../Assets/down.png'
import { globalState } from '../GlobalState/globalState';
import { ToastContainer, toast } from 'react-toastify';
import NearByProCards from '../Components/Custom/UI/NearByProCards';
import 'react-toastify/dist/ReactToastify.css';
import ServiceDetails from '../../New_Website_V2/Components/Custom/UI/ServiceDetails';
import WishlistIcon from '../../New_Website_V2/Assets/ph-heart-bold.png'
import ServiceCard from '../Components/Custom/UI/ServiceCard';
import { useNavigate, NavLink, useLocation } from 'react-router-dom';
import CloseIcon from '../../New_Website_V2/Assets/icons-closeModal.png'
import ArrowBack from '../Assets/arrowBack.png'
import FetchCurrentLocation from '../Components/Custom/FetchCurrentLocation';
import { clearServices, clearAddress, storeServices } from '../GlobalState/globalStateActions';
import {Helmet} from 'react-helmet';
const DeepClone = require("rfdc/default")

const Offers = ({ navigation, route }) => {


    const useMobileAndBelowMediaQuery = () => useMediaQuery({ query: "(max-width: 760px)" })
    const useTabletMediaQuery = () => useMediaQuery({ minWidth: 768, maxWidth: 991 })
    const isMobileView = useMobileAndBelowMediaQuery()
    const isTabView = useTabletMediaQuery()
    const [icon, setIcon] = useState(false)
    let [state, dispatch] = useContext(globalState)
    const [proData, setProData] = useState([])
    const [flat50Services, setFlat50Services] = useState([])
    const [checked, setChecked] = useState([])
    const [couponData, setCouponData] = useState([])
    const [payments, setPayments] = useState([1])
    const [proloading, setproloading] = useState(true)
    const [loading, setLoading] = useState(true)
    const [mpidArr, setMpidArr] = useState([])
    const [serviceModalData, setServiceModalData] = useState([])
    const [serviceDetails, setServiceDetails] = useState()
    const [serviceDetailsModal, setServiceDetailsModal] = useState(false)
    const [serviceloader, setServiceloader] = useState(false)
    const [offersPro, setoffersPro] = useState([1, 2, 3])
    const [nearByPros, setNearByPros] = useState([{ locationMain: 'Gachibowli', professionalName: 'Rejoice Salon', distance: 1.25, rating: 4.7, numberOfRatings: 122, uri: 'https://img.grouponcdn.com/metro_draft_service/2RNUjJeARqth1toKMJViVnWhHqjr/2R-1152x648/v1/c870x524.jpg' }, { locationMain: 'Gachibowli', professionalName: 'Rejoice Salon', distance: 1.25, rating: 4.7, numberOfRatings: 122, uri: 'https://img.grouponcdn.com/metro_draft_service/2RNUjJeARqth1toKMJViVnWhHqjr/2R-1152x648/v1/c870x524.jpg' }, { locationMain: 'Gachibowli', professionalName: 'Rejoice Salon', distance: 1.25, rating: 4.7, numberOfRatings: 122, uri: 'https://img.grouponcdn.com/metro_draft_service/2RNUjJeARqth1toKMJViVnWhHqjr/2R-1152x648/v1/c870x524.jpg' }])
    const [whatsNew, setWhatsNew] = useState([{ uri: 'https://images.unsplash.com/photo-1600948836101-f9ffda59d250?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8c2Fsb258ZW58MHx8MHx8&w=1000&q=80' }, { uri: 'https://img4.nbstatic.in/tr:w-500/5e68abef652205000b880d61.jpg' }])
    const [trending, setTrending] = useState([{ serviceName: 'Hair Colouring', professionalName: "Rejoice Salon" }, { serviceName: 'Hair Colouring', professionalName: "Rejoice Salon" }, { serviceName: 'Hair Colouring', professionalName: "Rejoice Salon" }, { serviceName: 'Hair Colouring', professionalName: "Rejoice Salon" }])
    const [serviceLoading, setServiceLoading] = useState(true)
    const [defaultProps, setDefaultProps] = useState({
        center: {
            lat: 0,
            lng: 0
        },
        zoom: 10
    })
    const [totalAddress, setTotalAddress] = useState('')
    const [latitude, setLatitude] = useState()
    const [longitude, setLongitude] = useState()
    const [stateOrProvince, setStateOrProvince] = useState('')
    const [city, setCity] = useState('')
    const [zipcode, setZipcode] = useState()
    const [country, setCountry] = useState('')
    const [district, setDistrict] = useState('')
    const [addressLine1, setAddressLine1] = useState('')
    const [merchantData, setMerchantData] = useState('')
    const [loadingL, setloadingL] = useState(false)
    const [loadingL1, setloadingL1] = useState(false)
    const [addressUpDated, setAddressUpDated] = useState(false)
    const [services, setServices] = useState('')
    const paramsLocation = useLocation()
    useEffect(() => {

        // console.log('params',paramsLocation.state.length==0)
        // console.log('params',paramsLocation.state.length=0)
        // if (state.services.length!==0&&Object.keys(paramsLocation.state).length==0) {
        //     // if(paramsLocation.state.nearByPros.length!==0){
        //     //     // console.log('no need to fetch from backend',paramsLocation.state.nearByPros)
        //     //     // storeServices(paramsLocation.state.nearByPros, paramsLocation.state.categories, paramsLocation.state.subcategories, dispatch)
        //     //     setServices(paramsLocation.state.nearByPros)
        //     //     console.log('send',state)
        //     //     fetchHandler()
        //     // }
        //     fetchHandler()
        // }else if(Object.keys(paramsLocation.state).length!=0){
        //     console.log('enterd into params')
        //      if(paramsLocation.state.nearByPros.length!==0){
        //             // console.log('no need to fetch from backend',paramsLocation.state.nearByPros)
        //             // storeServices(paramsLocation.state.nearByPros, paramsLocation.state.categories, paramsLocation.state.subcategories, dispatch)
        //             setServices(paramsLocation.state.nearByPros)
        //             console.log('send',state)

        //         }
        //         fetchHandler()

        // }
        //  else {
        //     // clearServices(dispatch)
        //     // clearAddress(dispatch)
        //     console.log('enterd into else')
        //     let searchUrl = paramsLocation.search
        //     // console.log('searchUrl', searchUrl)
        //     let regex = /[?&]([^=#]+)=([^&#]*)/g, searchParams = {}, match
        //     while ((match = regex.exec(searchUrl))) {
        //         searchParams[match[1]] = match[2]
        //     }
        //     fetchlocationData()
        // }
        offersFetch()
    }, [])

    const offersFetch = () => {
        if (paramsLocation.state) {
            if (state.services.length !== 0) {
                console.log('state')
                if (state.services.length !== 0 && Object.keys(paramsLocation.state).length == 0 || state.services.length !== 0 && Object.keys(paramsLocation.state).length != 0) {
                    console.log('state', Object.keys(paramsLocation.state).length == 0)
                    console.log(' state with noparams')
                    // if(state.services.length !== 0 && Object.keys(paramsLocation.state).length == 0 || state.services.length !== 0 && Object.keys(paramsLocation.state).length != 0){
                    //     // console.log('no need to fetch from backend',paramsLocation.state.nearByPros)
                    //     // storeServices(paramsLocation.state.nearByPros, paramsLocation.state.categories, paramsLocation.state.subcategories, dispatch)
                    //     setServices(paramsLocation.state.nearByPros)
                    //     console.log('send',state)
                    //     fetchHandler()
                    // }
                    fetchHandler()
                }
            }
            else if (state.services.length == 0&&Object.keys(paramsLocation.state).length !== 0) {
                console.log('no state')
                if (state.services.length == 0 && Object.keys(paramsLocation.state).length !== 0) {
                    console.log('params', Object.keys(paramsLocation.state).length != 0)
                    console.log('enterd into params', state.services)
                    if (paramsLocation.state.nearByPros.length !== 0) {
                        console.log('no need to fetch from backend', paramsLocation.state.nearByPros)
                        // storeServices(paramsLocation.state.nearByPros, paramsLocation.state.categories, paramsLocation.state.subcategories, dispatch)
                        setServices(paramsLocation.state.nearByPros)
                        fetchHandler(paramsLocation.state.nearByPros)

                    }

                }
            }
            else{
                console.log('with url ')
                let searchUrl = paramsLocation.search
                // console.log('searchUrl', searchUrl)
                let regex = /[?&]([^=#]+)=([^&#]*)/g, searchParams = {}, match
                while ((match = regex.exec(searchUrl))) {
                    searchParams[match[1]] = match[2]
                }
                fetchlocationData()
            }

        }

        else {
            // clearServices(dispatch)
            // clearAddress(dispatch)
            console.log('with url ', state.services)
            let searchUrl = paramsLocation.search
            // console.log('searchUrl', searchUrl)
            let regex = /[?&]([^=#]+)=([^&#]*)/g, searchParams = {}, match
            while ((match = regex.exec(searchUrl))) {
                searchParams[match[1]] = match[2]
            }
            fetchlocationData()
        }
    }


    let fetchlocationData = async () => {
        console.log('offers data')
        let addressDetails = await FetchCurrentLocation(state, dispatch)
        console.log('address', addressDetails)
        // if (addressDetails.locationFetch) {
            let { addressLine1, city, country, curr_location_latitude, curr_location_longitude, currentLocation, district, stateOrProvince, zipcode, currentAddress } = addressDetails
            let city_data = {
                curr_city: city,
                curr_lat: curr_location_latitude,
                curr_long: curr_location_longitude,
                consumerId: state.user.id,
                stateOrProvince
            }

            let { fetchedData } = await customFetchData('POST', city_data, '/website/offers/nearByproCoupons/sv2', state, dispatch)
            console.log("offer data in offers", fetchedData)
            if (fetchedData.getOff) {
                let offer = [];
                fetchedData.offers.map(item => {
                    offer.push(item[0])
                    // console.log('url', item)

                })
                // console.log('offer result', offer)
                // setProData(offer)
                setproloading(false)
                //wish list showing 
                if (fetchedData.mpidArr.length !== 0) {
                    let body = {
                        mpidArr: fetchedData.mpidArr,
                        consumer_prid: state.user.id,
                        wishlistType: 'professional'
                    }
                    // console.log('body', body)
                    let wishListedData = await customFetchData('POST', body, '/website/wishlistedPros/sv2', state, dispatch)
                    console.log('wish list', wishListedData)
                    if (wishListedData.fetch) {
                        if (wishListedData.fetchedData.wishlistRes.length !== 0) {
                            console.log('entered', wishListedData.fetchedData.wishlistRes)
                            offer.map(item => {
                                // console.log('item',item[0])
                                //   item.map(item11=>{
                                wishListedData.fetchedData.wishlistRes.map(item1 => {
                                    console.log('backed', item1.QF_WISHLIST_PRID, item1.WISHLIST_DELETED_FLAG)
                                    //   console.log('wish list values',item.QF_MERCHANT_PERSONNEL_PRID,)
                                    console.log('wish list 1', item1.QF_MERCHANT_PERSONNEL_PRID,)
                                    if (item.QF_MERCHANT_PERSONNEL_PRID === item1.QF_MERCHANT_PERSONNEL_PRID) {
                                        console.log('match', item.QF_MERCHANT_PERSONNEL_PRID, item1.QF_MERCHANT_PERSONNEL_PRID)
                                        return (
                                            item.wishlistPro_Prid = item1.QF_WISHLIST_PRID,
                                            item.wishlist_Pro = item1.WISHLIST_DELETED_FLAG
                                        )
                                    }
                                })
                                //   })
                            })
                            offer.map(item11 => {

                                // console.log('item',item11)
                                if (item11.wishlist_Pro === 0) {
                                    console.log('checked item', 'checked_' + item11.QF_MERCHANT_PERSONNEL_PRID)
                                    return checked['checked_' + item11.QF_MERCHANT_PERSONNEL_PRID] = true
                                } else {
                                    return checked['checked_' + item11.QF_MERCHANT_PERSONNEL_PRID] = false
                                }
                            })
                            setProData(offer)
                            // setNearByPros(fetchedData.offers)
                            // setFilteredArray(fetchedData.offers)
                            // storeServices(fetchedData.offers,fetchedData.catsFinal,fetchedData.subcatsFinal,dispatch)

                        } else {
                            setProData(offer)
                            // setNearByPros(fetchedData.offers)
                            // setFilteredArray(fetchedData.offers)
                            // storeServices(fetchedData.offers,fetchedData.catsFinal,fetchedData.subcatsFinal,dispatch)

                        }
                    } else {
                        setProData(offer)
                        //   setNearByPros(fetchedData.offers)
                        //   setFilteredArray(fetchedData.offers)
                        //   storeServices(fetchedData.offers,fetchedData.catsFinal,fetchedData.subcatsFinal,dispatch)
                    }
                }


            }
            else {
                setProData([])
            }
        // }
    }


    const navigate = useNavigate();
    const fetchHandler = async (fromFetchnearByPros) => {
        console.log('dsdsdsdsd', fromFetchnearByPros)
        let couponData1 = await customFetchData('POST', {}, '/website/proCoupon/sv2', state, dispatch)
        // console.log("coupondata", couponData1.fetchedData, couponData1.fetch)

        setproloading(true)
        if (couponData1.fetch) {
            if (couponData1.fetchedData.couponData.length !== 0) {

                let singlePro = []
                couponData1.fetchedData.couponData.map(coupon => {
                    // console.log('service',state.services)
                    if (state.services.length != 0) {

                        state.services.map(pro => {
                            // console.log('prooo',pro,coupon)
                            if (pro[0].QF_MERCHANT_PRID == coupon.QF_MERCHANT_PRID) {
                                // console.log('match')
                                return (
                                    pro[0].MERCHANT_COUPON_CODE = coupon.MERCHANT_COUPON_CODE,
                                    pro[0].MERCHANT_COUPON_PERCENT_OFF = coupon.MERCHANT_COUPON_PERCENT_OFF,
                                    pro[0].MERCHANT_COUPON_VALUE_OFF = coupon.MERCHANT_COUPON_VALUE_OFF,
                                    pro[0].QF_MERCHANT_COUPON_PRID = coupon.QF_MERCHANT_COUPON_PRID,
                                    singlePro.push(pro[0])

                                )
                            }
                        })
                    }
                    else {
                        fromFetchnearByPros.map(pro => {
                            console.log('without state')
                            if (pro[0].QF_MERCHANT_PRID == coupon.QF_MERCHANT_PRID) {
                                // console.log('match')
                                return (
                                    pro[0].MERCHANT_COUPON_CODE = coupon.MERCHANT_COUPON_CODE,
                                    pro[0].MERCHANT_COUPON_PERCENT_OFF = coupon.MERCHANT_COUPON_PERCENT_OFF,
                                    pro[0].MERCHANT_COUPON_VALUE_OFF = coupon.MERCHANT_COUPON_VALUE_OFF,
                                    pro[0].QF_MERCHANT_COUPON_PRID = coupon.QF_MERCHANT_COUPON_PRID,
                                    singlePro.push(pro[0])

                                )
                            }
                        })

                    }

                })

                singlePro.map(item11 => {
                    // console.log('item', item11)
                    if (item11.wishlist_Pro === 0) {
                        return checked['checked_' + item11.QF_MERCHANT_PERSONNEL_PRID] = true
                    } else {
                        return checked['checked_' + item11.QF_MERCHANT_PERSONNEL_PRID] = false
                    }
                })

                setProData(singlePro)
                setproloading(false)

                setCouponData(couponData1.fetchedData.couponData)

            }
            else {
                setProData([])
                setproloading(false)

            }


        }
        // console.log('testcoupon',couponData[0],)
        // if (route.params.categories.length !== 0) {
        let singlePro1 = []
        // route.params.categories.map(catItem => {
        let offers = state.services.length != 0 ? state.services : Object.keys(paramsLocation.state).length != 0 ? fromFetchnearByPros : null;
        setServiceloader(true)
        console.log('offers', offers)
        console.log('offers', services)
        offers.map(pro => {
            if (pro.length !== 0) {
                pro.map(innerItem => {
                    // console.log('subcatData',catItem.QF_SERVICE_CATEGORY_PRID, innerItem.QF_SERVICE_CATEGORY_PRID,innerItem.QF_SERVICE_CATEGORY_PRID == catItem.QF_SERVICE_CATEGORY_PRID)
                    // if (innerItem.QF_SERVICE_CATEGORY_PRID == catItem.QF_SERVICE_CATEGORY_PRID) {
                    // console.log('match',innerItem.QF_SERVICE_CATEGORY_PRID == catItem.QF_SERVICE_CATEGORY_PRID)
                    singlePro1.push(innerItem)
                    // }
                })
            }
        })
        // })
        // console.log('singlePro1', singlePro1)
        if (singlePro1.length !== 0) {
            singlePro1 = Object.values(singlePro1.reduce((acc, cur) => Object.assign(acc, { [cur.QF_SERVICE_CATEGORY_PRID]: cur }), {}))
        }
        // console.log('singlePro1 after', singlePro1)
        if (singlePro1.length !== 0) {

            setFlat50Services(singlePro1)
            setServiceloader(false)
        }
    }

    const serviceDetailsModalClose = () => {
        setServiceDetailsModal(false)
        setServiceDetails()
        setServiceModalData([])
        
    }
    let serviceDetailsDisplayModal = (
        <Modal isOpen={serviceDetailsModal} size={'md'} toggle={() => serviceDetailsModalClose()} centered>
            <div style={{ padding: 16 }}>
                <div style={{ justifyContent: 'flex-end', display: 'flex', flexDirection: 'row' }}>
                    <div className='crossIcon_ViewPro' style={{ cursor: 'pointer' }}>
                        <img src={CloseIcon} style={{ width: 18, height: 18 }} onClick={() => serviceDetailsModalClose()} />
                    </div>
                </div>
                <div style={{ marginTop: 20, }}>
                    <ServiceDetails proData={serviceModalData} serviceDetails={serviceModalData} />
                </div>
            </div>
        </Modal>
    )
    const WishlistPressHandler = async (item) => {
        // console.log("pressed", item)
        proData.map(list => {
            // console.log("pressed", list.QF_MERCHANT_PERSONNEL_PRID)
            if (list.QF_MERCHANT_PERSONNEL_PRID === item.QF_MERCHANT_PERSONNEL_PRID) {
                // console.log('match')
                return setChecked((prevState) => {
                    return {
                        ...prevState,
                        ['checked_' + list.QF_MERCHANT_PERSONNEL_PRID]: !checked['checked_' + list.QF_MERCHANT_PERSONNEL_PRID]
                    }
                })
            }
        })

        let body = {
            // cprid:item.QF_CONSUMER_PRID,
            cprid: state.user.id,
            mpprid: item.QF_MERCHANT_PERSONNEL_PRID,
            wishLstType: 'professional'
        }
        let WishlistInsert = await customFetchData('POST', body, '/website/add/wishList/sv2', state, dispatch)


    }
    const ButtonGroup = ({ next, previous, ...rest }) => {
        const {
            carouselState: { currentSlide, totalItems, slidesToShow }
        } = rest;

        return (
            <div>
                <div className="arrowOfferSv2">
                    <Card className={currentSlide === 0 ? "disable_arrowOfferLeftSv2" : "arrowOfferLeftSv2"} onClick={() => previous()}>
                        <CardImg src={RightArrow} style={{ transform: 'rotate(180deg)' }} className={"carousel_arrowOfferSv2"} />
                    </Card>
                    <Card className={currentSlide === totalItems - slidesToShow ? "disable_arrowOfferRightSv2" : "arrowOfferRightSv2"} onClick={() => next()}>
                        <CardImg src={RightArrow} className={"carousel_arrowOfferSv2"} />
                    </Card>
                </div>
            </div>
        );
    };

    const ServiceButtonGroup = ({ next, previous, ...rest }) => {
        const {
            carouselState: { currentSlide, totalItems, slidesToShow }
        } = rest;

        return (
            <div>
                <div className="arrowOffer2Sv2">
                    <Card className={currentSlide === 0 ? "disable_arrowOffer2LeftSv2" : "arrowOffer2LeftSv2"} onClick={() => previous()}>
                        <CardImg src={RightArrow} style={{ transform: 'rotate(180deg)' }} className={"carousel_arrowOffer2Sv2"} />
                    </Card>
                    <Card className={currentSlide === totalItems - slidesToShow ? "disable_arrowOffer2RightSv2" : "arrowOffer2RightSv2"} onClick={() => next()}>
                        <CardImg src={RightArrow} className={"carousel_arrowOffer2Sv2"} />
                    </Card>
                </div>
            </div>
        );
    };


    const copyClipHandler = (code) => {
        navigator.clipboard.writeText(code)
        // setTimeout (()=>{
        //     this.setState({click:''})
        // },1000)
        // alert('Copied to Clipboard')
        // return toast.success("Copied Successfully", { position: toast.POSITION.BOTTOM_CENTER });
        toast("Copied Successfully", {
            position: 'bottom-center',
            hideProgressBar: true,
            progress: undefined,
            style: { backgroundColor: '#E0D1FF' }
        });

    }
    const NavToserviceDetails_Mobile = (item) => {
        navigate('/serviceDetails', { state: { proData: proData, serviceDetails: item, wishlisted: checked['checked_' + item.QF_MERCHANT_SERVICE_PRID] ? true : false } })
    }

    const serviceModalHandler = (id) => {
        // console.log('merchnat', id)
        // console.log('array', serviceModalData)
        setServiceModalData([])
        let serviceData = []
        flat50Services.map((item) => {
            if (item.QF_MERCHANT_SERVICE_PRID === id) {
                return serviceData.push(item)
            }
        })
        if (serviceData.length !== 0) {
            setServiceModalData(serviceData[0])
            // console.log('serviceDate',serviceData)
            if (isMobileView) {
                navigate({ pathname: `/servicedetails/${serviceData[0].SERVICE_NAME.replaceAll(/[&\/\\#, +()$~%.'":*?<>{}]/g, "")}`, search: `proId=${serviceData[0].QF_MERCHANT_PERSONNEL_PRID}&serviceId=${serviceData[0].QF_MERCHANT_SERVICE_PRID}` }, { state: { proData: serviceData[0], serviceDetails: serviceData[0], wishlisted: checked['checked_' + serviceData[0].QF_MERCHANT_SERVICE_PRID] ? true : false,
                categories:DeepClone(state.categories),subCategories:DeepClone(state.subcategories),
                addressDetails:
             {
                totalAddress: state.address.totalAddress,
                addressLine1: state.address.addressLine1,
                country: state.address.country,
                city: state.address.city,
                stateOrProvince: state.address.stateOrProvince,
                zipcode: state.address.zipcode,
                district: state.address.district,
                latitude: state.address.latitude,
                longitude: state.address.longitude,
                currentAddress: state.address.currentAddress,
                searchAddress: state.address.searchAddress,
                currentLocationDenied: state.address.currentLocationDenied
             },allServices:DeepClone(state.services)
             } })

            }
            else { setServiceDetailsModal(true) }

        }
    }
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1100 },
            items: 4,
            slidesToSlide: 1 // optional, default to 1.
        },
        laptop: {
            breakpoint: { max: 1100, min: 900 },
            items: 3,
            slidesToSlide: 2 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 900, min: 600 },
            items: 2.5,
            slidesToSlide: 2 // optional, default to 1.
        },
        TabMobile: {
            breakpoint: { max: 600, min: 340 },
            items: 1.3,
            slidesToSlide: 2
        },
        mobile: {
            breakpoint: { max: 340, min: 0 },
            items: 1.1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };
    const responsive1 = {
        desktop: {
            breakpoint: { max: 3000, min: 1100 },
            items: 5,
            slidesToSlide: 1 // optional, default to 1.
        },
        laptop: {
            breakpoint: { max: 1100, min: 900 },
            items: 4,
            slidesToSlide: 2 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 900, min: 600 },
            items: 3,
            slidesToSlide: 2 // optional, default to 1.
        },
        TabMobile: {
            breakpoint: { max: 600, min: 340 },
            items: 1.5,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { max: 340, min: 0 },
            items: 1.2,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    let nearByProCardDisplay
    if (proData.length !== 0) {
        // setServiceLoading(false)
        nearByProCardDisplay = (
            <Carousel
                infinite={false}
                swipeable={isMobileView ? true : false}
                draggable={false}
                showDots={false}
                arrows={false}
                // focusOnSelect={true}
                // renderButtonGroupOutside={false}
                customButtonGroup={ proData.length > 4 ? <ButtonGroup /> : null}
                // itemClass="image-item"
                responsive={responsive}
                transitionDuration={500}
                containerClass="carousel_container_pros"
                renderArrowsWhenDisabled={true}

            >
                {
                    proData.map((item, index) => {
                        // console.log('dsdd', item)
                        return (
                            <div>
                                {/* <NearByProCards state={state} WishlistPress={()=>WishlistPressHandler(item[0])} data={item[0]} key={index} wishListed={checked['checked_'+ item[0].QF_MERCHANT_PERSONNEL_PRID] ? true : false} onClickCard={()=>navigate('/professionalPage',{ state: {proData:item[0],offers:item,proChecked:checked['checked_'+ item[0].QF_MERCHANT_PERSONNEL_PRID] ? true : false} })}/> */}

                                <NearByProCards state={state} WishlistPress={() => WishlistPressHandler(item)} data={item} key={index} wishListed={checked['checked_' + item.QF_MERCHANT_PERSONNEL_PRID] ? true : false} height={isMobileView ? 209 : 220} onClickCard={() => navigate({ pathname: `/allservices/${item.MERCHANT_NAME.replaceAll(" ", "", "g")}`, search: `?proId=${item.QF_MERCHANT_PERSONNEL_PRID}&${'offers'}=${true}` })} />
                                <div className='prosubtext'>
                                    <hr style={{ marginBottom: 4, marginTop: 4 }} />
                                    {/* <text className='proText' >{item.MERCHANT_COUPON_PERCENT_OFF ? item.MERCHANT_COUPON_PERCENT_OFF + '% OFF on' + ' ' + item.service_sub_category_name : item.SERVICE_CURRENCY == 'Rupees' ? '\u20B9' + item.MERCHANT_COUPON_VALUE_OFF + '  OFF on' + ' ' + item.service_sub_category_name : '$' + item.MERCHANT_COUPON_VALUE_OFF + ' OFF on' + item.service_sub_category_name}</text> */}
                                    {isMobileView ?
                                        <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                                            <text className='proText' style={{ fontSize: 12 }} >{item.MERCHANT_COUPON_PERCENT_OFF ? item.MERCHANT_COUPON_PERCENT_OFF + '% OFF ' : item.SERVICE_CURRENCY == 'Rupees' ? '\u20B9' + item.MERCHANT_COUPON_VALUE_OFF + '  OFF' : '$' + item.MERCHANT_COUPON_VALUE_OFF + ' OFF'}</text>
                                            <text style={{ textDecoration: 'underline', fontSize: 10 }} onClick={() => navigate({ pathname: `/allservices/${item.MERCHANT_NAME.replaceAll(" ", "", "g")}`, search: `?proId=${item.QF_MERCHANT_PERSONNEL_PRID}&${'offers'}=${true}` }, { state: { proData: item, offers: item, fromOffers: true } })}>Tap to know more</text>

                                        </div>
                                        : <text className='proText' >{item.MERCHANT_COUPON_PERCENT_OFF ? item.MERCHANT_COUPON_PERCENT_OFF + '% OFF on' + ' ' + item.service_sub_category_name : item.SERVICE_CURRENCY == 'Rupees' ? '\u20B9' + item.MERCHANT_COUPON_VALUE_OFF + '  OFF on' + ' ' + item.service_sub_category_name : '$' + item.MERCHANT_COUPON_VALUE_OFF + ' OFF on' + item.service_sub_category_name}</text>}

                                </div>
                            </div>
                        )
                    })
                }
            </Carousel>
        )
    }
    else {
        nearByProCardDisplay = (

            <div style={{ flexDirection: 'row', justifyContent: 'center', display: 'flex' }}>
                <text>No Professionals to display</text>
            </div>
        )
    }

    console.log('state', services)
    let trendServices;
    if (flat50Services.length !== 0) {
        trendServices = (
            <Carousel
                infinite={false}
                swipeable={isMobileView ? true : state.country.country == 'IN' ? true : false}
                draggable={false}
                showDots={false}
                arrows={false}
                // focusOnSelect={true}
                // renderButtonGroupOutside={false}
                customButtonGroup={flat50Services.length > 4 ? <ServiceButtonGroup /> : null}
                // itemClass="image-item"
                responsive={responsive1}
                transitionDuration={500}
                containerClass="carousel_container_service1"
                renderArrowsWhenDisabled={true}

            >
                {
                    flat50Services.map((item, index) => {
                        // console.log('dsdd', item)
                        return (
                            <ServiceCard
                                // rate={item.MERCHANT_SERVICE_RATING!=0}
                                uri={item.image_link}
                                serviceName={item.SERVICE_NAME}
                                proName={item.MERCHANT_NAME}
                                rating={item.MERCHANT_SERVICE_RATING ? numeral(item.MERCHANT_SERVICE_RATING).format('0.0') : ''}
                                onclick={() => serviceModalHandler(item.QF_MERCHANT_SERVICE_PRID)}
                            />
                        )
                    })
                }
            </Carousel>
        )
    }
    else {
        trendServices = (
            <div style={{ flexDirection: 'row', justifyContent: 'center', display: 'flex' }}>
                <text>No Services to display</text>
            </div>
        )
    }
    // const _renderTrending = (data) => (

    //     <TrendingCards serviceName={data.item.SERVICE_NAME} onPressCard={() => onPressTrendingCard(data.item)} professionalName={data.item.MERCHANT_NAME} rating={data.item.MERCHANT_SERVICE_RATING ? numeral(data.item.MERCHANT_SERVICE_RATING).format('0.0') : ''} uri={data.item.image_link} />
    // )

    // console.log('country', proData)
    let terms = [];
    if (state.address.country == 'India') {
        terms = [
            'Applicable only in Hyderabad.',
            'Upto 50% Off on 1st 2 bookings & Upto 30% Off on 3rd & 4th Booking.',
            'Other T&Cs may apply.',
            'Offer valid till June 30,2023 11:59PM.'
        ]
    } else {
        terms = [
            'Applicable only in United States.',
            // 'Avail 25% off after 30 days from your last booking.',
            'Other T&Cs may apply.',
            'Offer valid till June 30,2023 11:59PM'
        ]
    }
    // console.log('proOffer', proData)
    return (
        <div>

            <Helmet>
                <title>Exclusive Deals on Auto & Personal Care Services | SlashApp </title>
                <meta name="description" content="Looking for discounts on auto & personal care services? SlashApp brings you unbeatable deals for car repairs, grooming, spa, beauty services & more.Click here" />
            </Helmet>

            <div className='offerGradinet'>
                <img src={isMobileView ? MobileImg : OfferImg} className='offerimg' />
            </div>
            <div className='MainReusable_Container_Home' style={{ borderTopLeftRadius: 30, borderTopRightRadius: 30, backgroundColor: '#ffffff' }}>

                <div className='header'>
                    <div className='testSty' style={{ marginTop: 10, marginBottom: 20 }}>
                        <text>Offers by Professionals</text>
                    </div>
                    {!isMobileView ?
                        <div className='offersSubtext'>
                            <text>All offers and deals, from professionals near you</text>
                        </div>
                        : null}
                </div>
                <div>

                    {!proloading ?
                        nearByProCardDisplay :
                        <div style={{ height: 200, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                            <ClipLoader widthUnit={"%"} size={40} loadings={proloading} color="#8c52ff" />
                        </div>
                    }
                </div>
                <div className='header' style={{ marginTop: 20, marginBottom: 15 }}>
                    <div className='testSty' style={{ marginTop: -5 }} >
                        <text>Offers by Slash</text>
                    </div>
                    {!isMobileView ?
                        <div className='offersSubtext'>
                            <text>All offers and deals from slash</text>
                        </div> : null}
                </div>
                <div className='offerbySlash'>
                    <div style={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex' }}>

                        <div>
                            {icon ? <img src={OfferImg2} className='imgSize' /> : <img src={OfferImg1} className='imgSize1' />}

                        </div>

                        <div className='contentDiv'>
                            <text className='textCopy'>Time to save up big time!</text>
                            <text className='text2' >Hurry up and avail our attractive discounts for a hassle-free service experience. Book any service and get up to 50% OFF. Use the code.</text>
                            {icon ?
                                <div className='textTerm'>
                                    <text >Terms and Conditions</text>

                                    {terms.map(data => {
                                        return (
                                            <div className='text2' style={{ marginTop: 1 }} >

                                                <text>{data}</text>
                                            </div>
                                        )
                                    })}
                                </div>
                                : null}
                            <div className='copydiv'>
                                <div className='btnborder' onClick={() => copyClipHandler(state.address.country == 'India' ? 'SLASH50' : 'SUMMER25')} >
                                    <text className='btntext'>Copy code</text>
                                </div>
                                <div className='copyText'>
                                    <text>{state.address.country == 'India' ? 'SLASH50' : 'SUMMER25'}</text>
                                </div>

                            </div>
                            <div className='offerCopyDiv'>
                                {icon ?
                                    <div onClick={() => setIcon(!icon)} style={{ flexDirection: 'row', cursor: 'pointer' }}>
                                        <text className='text2' style={{ textDecorationLine: 'underline', cursor: 'pointer', color: '#8c52ff' }}>View Less</text>
                                        {isMobileView ?
                                            <img src={Arrow} style={{ paddingLeft: 0, marginBottom: -5, transform: 'rotate(180deg)' }} />
                                            : <img src={ArrowBack} style={{ paddingLeft: 6, transform: 'rotate(360deg)' }} />}

                                    </div> :
                                    <div onClick={() => setIcon(!icon)} style={{ flexDirection: 'row', cursor: 'pointer' }}>
                                        <text className='text2' style={{ textDecorationLine: 'underline', color: '#8c52ff' }}>View more</text>
                                        {isMobileView ?
                                            <img src={Arrow} style={{ paddingLeft: 2, marginBottom: -5, transform: 'rotate(360deg)' }} />
                                            : <img src={ArrowBack} style={{ paddingLeft: 6, transform: 'rotate(180deg)' }} />}
                                    </div>
                                }

                            </div>


                        </div>
                        <div>
                            <ToastContainer />

                        </div>
                    </div>

                    {/* <div>
                        <div className='btnborder' >
                            <text className='btntext' >Copy Code</text>

                        </div>

                        <div>
                            <text className='copyText'>SLASH50</text>
                        </div>
                    </div> */}


                </div>
                <div className='header'>
                    <div className='testSty' style={{ marginTop: 28, marginBottom: 16 }}>
                        <text>Flat 50% Off</text>
                    </div>
                    {!isMobileView ?
                        <div className='offersSubtext'>
                            <text>All services offering a flat 50 % sale</text>
                        </div> : null}
                </div>

                <div className='serviceMargin'>
                    {!serviceloader ?
                        trendServices :
                        <div style={{ height: 200, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                            <ClipLoader widthUnit={"%"} size={40} loadings={serviceloader} color="#8c52ff" />
                        </div>
                    }

                </div>
            </div>
            {serviceDetailsModal && serviceDetailsDisplayModal}


            {/* <View style={{ marginHorizontal: 18, marginTop: 20 }}>
                    <View style={styles.tremCard}>
                        <View style={{ flexDirection: 'row' }}>
                            <View style={{ marginTop: icon ? 20 : 49, marginRight: 5 }}>
                                {icon ? <Image source={OfferImg1} /> : <Image source={OfferImg} />}
                            </View>
                            <View style={{ flexDirection: 'column', justifyContent: 'flex-start' }}>
                                <Text style={styles.textCopy}>Time to save up big time!</Text>
                                <View>

                                    <Text style={[styles.text2, { width: 210 }]}>Hurry up and avail our attractive discounts for a hassle-free service experience. Book any service and get up to 50%OFF</Text>
                                </View>
                                <Collapsible collapsed={icon}>
                                    <Text style={styles.textTerm}>Terms and Conditions</Text>

                                    {terms.map(data => {
                                        return (
                                            <View style={{ marginTop: 1 }} >

                                                <Text style={[styles.text2, { width: 210 }]}>{data}</Text>
                                            </View>
                                        )
                                    })}
                                </Collapsible>
                                <View style={{ flexDirection: 'row', marginVertical: 10, alignItems: 'center' }}>
                                    <TouchableOpacity onPress={() => { Clipboard.setString('SLASH50'); ToastAndroid.show("Copied to Clipboard", ToastAndroid.SHORT); }}>
                                        <View style={styles.btnborder}>
                                            <Text style={styles.btntext}>Copy Code</Text>

                                        </View>
                                    </TouchableOpacity>
                                    <View>
                                        <Text style={styles.copyText}>SLASH50</Text>
                                    </View>
                                    <View style={{ marginLeft: 5, marginRight: 5 }}>

                                        <TouchableOpacity onPress={() => setIcon(!icon)}>
                                            {icon ?
                                                <View style={{ flexDirection: 'row' }}>
                                                    <Text style={[styles.text2, { textDecorationLine: 'underline' }]}>More</Text>
                                                    <Image source={Down} style={{ width: 10, height: 13.3, marginLeft: 2, opacity: 1 }} />
                                                </View> :
                                                <View style={{ flexDirection: 'row' }}>
                                                    <Text style={[styles.text2, { textDecorationLine: 'underline' }]}>Less</Text>
                                                    <Image source={Up} style={{ width: 10, height: 13.3, marginLeft: 2, opacity: 1 }} />
                                                </View>
                                            }

                                        </TouchableOpacity>

                                    </View>


                                </View>

                            </View>
                        </View>



                    </View>

                </View> */}

        </div>
    );
}

export default Offers;
