import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { Button, Card, Col, Container, Input, Modal, ModalBody } from "reactstrap";
import SubmitButtonSmall from "../../Components/Custom/UI/submitButtonSmall";
import SubmitButtonSolid from "../../Components/Custom/UI/submitButtonSolid";
// import FetchData from '../../Components/Custom/FuseFetchData';
import '../../CSS/CheckoutStack/CheckoutPage.css';
import { globalState } from "../../GlobalState/globalState";
import { storeCredentialsLocalStorage, removeFromCart, storeAddress, storeServices,storeDateAndTime } from "../../GlobalState/globalStateActions";
import SlotSelection from "./SlotSelectionPage";
import DeleteIcon from '../../Assets/delete_icon.png';
import InfoIcon from '../../Assets/checkout_info_icon.png';
import LocationIcon from '../../Assets/icon-location-on_checkout.png';
import Cross_Icon from '../../Assets/cross_icon.png';
import EditLocationIcon from "../../Assets/iconsModeEdit24Px.png"
import Navigation_Icon from '../../Assets/icons-navigate-before.png'
import ApplyCouponIcon from '../../Assets/bxsOffer.png';
import { catColors } from "../../Components/Custom/UI/Colors";
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from "react-router-dom";
import numeral from 'numeral';
import moment from 'moment'
import momenttz from 'moment-timezone'
import RegisstrAndLogin_India from '../../../New_Website_V2/Screens/RegisterrAndLogin_India';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Close_Cross from "../../Components/Custom/UI/Close_Cross";
import CouponDiscountPage from "./CouponAppliedPage";
import customFetchData from "../../Components/Custom/customFetchData";
import LoadingSpinner from "../../Components/Custom/UI/LoadingSpinner";
import FetchCurrentLocation from "../../Components/Custom/FetchCurrentLocation";
import arrowBack from '../../Assets/arrowBack.png'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import CurrentLocationIcon from '../../Assets/icon-my-location.png'
import GreyLocIcon from '../../Assets/icon-location-Grey.png'
import searchIcon from '../../Assets/searchIcon.png'
import RecentLocIcon from '../../Assets/material-symbols-history-rounded.png'
import { MdOutlineEdit } from "react-icons/md";
import InputComponent from "../../Components/Custom/UI/InputComponent";
import CurrentLocationIconRed from '../../Assets/icon-my-locationIcon.png'
import SearchCrossIcon from '../../Assets/group-383.png'
import { IoLocationSharp } from "react-icons/io5";
import { ClipLoader } from "react-spinners";
import GoogleMapReact from 'google-map-react';
let localStorage=window.localStorage
const DeepClone = require("rfdc/default")


const useTabletAndBelowMediaQuery = () => useMediaQuery({ query: "(max-width: 768px)" })
const useSmallDesktopAndBelowMediaQuery = () => useMediaQuery({ query: "(max-width: 1024px)" })
const useDesktopAndBelowMediaQuery = () => useMediaQuery({ query: "(max-width: 1440px)" })
const MobileAndBelowMediaQuery = () => useMediaQuery({ query: "(max-width: 425px)" })
    


export default function CheckoutPage(props){

    const navigate = useNavigate();

    const isTab=useTabletAndBelowMediaQuery();
    const isSmallDesktop = useSmallDesktopAndBelowMediaQuery()
    const isMobileView = MobileAndBelowMediaQuery();
    const [modal, setModal] = useState(false)
    const [state, dispatch] = useContext(globalState)
    const route = useLocation()

    const [slotModal, setSlotModal] = useState(false)
    // const [services, setServices] = useState(route.params ? ( route.params.services ? DeepClone(route.params.services): [] ) : [])
    const [services, setServices] = useState([...state.cart])
    const [services2, setServices2] = useState([])
    const [gstDetails, setGSTDetails] = useState([])
    const [proServicesPrice, setProServicesPrice] = useState(0)
    const [yourServicesPrice, setYourServicesPrice] = useState(0)
    const [convenienceFee, setConvenienceFee] = useState(0)
    const [totalGST, setTotalGST] = useState(0)
    const [couponPrice, setCouponPrice] = useState(0)
    const [totalAmount, setTotalAmount] = useState(0)
    const [addressRequired, setAddressRequired] = useState(null)
    const [filteredServices, setFilteredServices] = useState([])
    const [proNames, setProNames] = useState([])
    const [proPersonelIds, setProPersonelIds] = useState([])
    const [proMerchantIds, setProMerchantIds] = useState([])
    const [serviceIds, setServiceIds] = useState([])
    const [modalVisible, setModalVisible] = useState(false)
    const [tempDelItem,setTempDelItem]=useState({})
    // const [fromDateTime, setFromDateTime] = useState(route.params?(route.params.fromDateTime?route.params.fromDateTime:''):'')
    // const [toDateTime, setToDateTime] = useState(route.params?(route.params.toDateTime?route.params.toDateTime:''):'')
    const [country,setCountry]=useState('')
    const [servicesFromDb,setServicesFromDb]=useState([])
    const [margin_prid,setMargin_prid]=useState(0)
    const [gst_calc,setGst_calc]=useState({})
    const [totalCPPrice, setTotalCPPrice] = useState(0)
    const [totalTSPrice, setTotalTSPrice] = useState(0)
    const [proceedToPayCheck,setProceedToPayCheck]=useState(false)
    // const [isEmulator,setIsEmulator]=useState(false)
    const [enteredCoupons, setEnteredCoupons] = useState('')
    const [stateOrProvince,setStateOrProvince]=useState('')
    const [city,setCity]=useState('')
    const [zipcode,setZipcode]=useState()
    const [country_Cust,setCountry_Cust]=useState('')
    const [district,setDistrict]=useState('')
    const [addressLine1,setAddressLine1]=useState('')
    const [clickedValue,setClickedValue]=useState([])
    const [couponDetails,setCouponDetails]=useState([])
    const [cityInValid,setCityInValid]=useState(false)
    const [recentSearchesArray,setRecentSearchesArray]=useState([])
    const [selectedTag,setSelectedTag]=useState('Home');
    let [deviceScenario,setDeviceScenario]=useState(null)
    let [deviceMessage,setDeviceMessage]=useState(null)
    let [couponAppliedModal,setCouponAppliedModal]=useState(false)
    let [couponDiscountModal,setCouponDiscountModal]=useState(false)

    let [modalDataServices,setmodalDataServices]=useState([])
    let [modalDataServiceItem,setmodalDataServiceItem]=useState(null)
    let [modalDataServiceIndex,setmodalDataServiceIndex]=useState(null)
    let [modalDataCouponApplied,setmodalDataCouponApplied]=useState(false)
    let [modalDataCouponCode,setmodalDataCouponCode]=useState('')
    let [modalDataUserPasswordReg,setmodalDataUserPasswordReg]=useState(false)
    let [modalDataUserId,setmodalDataUserId]=useState(null)
    let [showModalProceedToPay,setShowModalProceedToPay]=useState(false)

    const [timezone, setTimezone] = useState(null)
    const placesArray=
    [
        {
            id:1,
            place:'Home'
        },
        {
            id:2,
            place:'Work'
        },
        {
            id:3,
            place:'Hotel'
        },
        {
            id:4,
            place:'Others'
        }
    ]
    const [currentAddress,setCurrentAddress]=useState('')
    const [totalAddress,setTotalAddress]=useState('')
    const input_Ref1 = useRef();
    const input_Ref2 = useRef();
    const [allValues, setAllValues] = useState({
        cmptAddress: '',
        floor: '',
        landmark: '',
        status: '',
        message: '',
        addError: false,
        floorError: false,
        landError: false
    })
    const [cmpltAddModal, setCmpltAddModal] = useState(false)
    const [locationPrid,setLocationPrid]=useState(null)

    let [status,setStatus]=useState('')
    let [systemMessage,setSystemMessage]=useState('')

    const [couponModal, setCouponModal] = useState(false)

    let [couponType1,setCouponType]=useState(null)
    let [couponID1,setCouponID]=useState(null)
    let [couponCode,setCouponCode]=useState('')
    let [prevCouponCode,setPrevCouponCode]=useState('')
    let [CouponData,setCouponData]=useState([])
    let [applyStyle,setApplyStyle]=useState('')
    let [couponMaxCount,setCouponMaxCount]=useState(null)
    let [couponUsedCount,setCouponUsedCount]=useState(null)
    let [couponApplyCount,setCouponApplyCount]=useState(null)
    let [couponMaxreached,setCouponMaxreached]=useState(null)
    let [couponCancelButton,setCouponCancelButton]=useState(false)
    let [couponValid,setCouponValid]=useState(false)
    let [offerPrice,setOfferPrice]=useState(null)
    let [couponDiscount,setCouponDiscount]=useState(0)
    let [couponApplied,setCouponApplied]=useState(false)
    let [applyButton,setApplyButton]=useState(false)
    let [unitDiscountPrice,setUnitDiscountPrice]=useState(null)
    let [unitDiscount1,setUnitDiscount]=useState(null)
    let [totalUnitPrice,setTotalUnitPrice]=useState(null)
    let [cancellationAllow, setCancellationAllow]=useState(false)
    let [discountAppliedServices1, setDiscountAppliedServices]=useState([])

    const [savedAddresses,setSavedAddresses]=useState([])
    const [loading,setLoading]=useState(false)
    const [changeAddressModal,setChangeAddressModal]=useState(false)
    const [searchListDisplay, setSearchListDisplay] = useState(false)
    const [defaultProps,setDefaultProps]=useState({
        center: {
           lat: 0,
           lng: 0
        },
        zoom: 10
     })
    const [data, setData] = useState([])
    const [fetchedDataResponse, setFetchedDataResponse] = useState([])
    const [savedAddLoading, setSavedAddLoading] = useState(false)
    const mapRef = useRef(null);
    const [mapViewModal, setMapViewModal] = useState(false)
    const [editNewAddressModal,setEditNewAddressModal]=useState(false)
    const [choosenLocValue,setChoosenLocValue]=useState({})
    const [editAddress,setEditAddress]=useState({})
    const [searchText,setSearchText]=useState('')
    const [loadingL,setloadingL]=useState(false)
    const [latitude, setLatitude] = useState()
    const [longitude, setLongitude] = useState()
    const [editAddressModal,setEditAddressModal]=useState(false)
    const [notServicable,setNotServicable]=useState(false)
    const [errorMessage,setErrorMessage]=useState(" ")
    const [errorAddress,setErrorAddress]=useState(false)
    const [loadingLC,setLoadingLC]=useState(false)
    const [hidingRecentSavedLocs,setHidingRecentSavedLocs]=useState(true)
    const [curLocationDenied,setCurLocationDenied]=useState(false)
    const [saveAddressModal,setSaveAddressModal]=useState(false)
    const [loadingL1,setloadingL1]=useState(false)
    const [mapViewModalWeb,setMapViewModalWeb]=useState(false)
    const [currentTextClick,setCurrentTextClick]=useState(false)
    const [saveRecentModal,setSaveRecentModal]=useState(false)
    const searchAddRef=useRef(null)
    const params = useLocation()
    // const isFocused=useIsFocused()

    useEffect(()=>{
        let fromDateTime,toDateTime;
        if(Object.keys(state.fromDateTime).length===0)
        {
            //// setting from date in global state if already not set
            // dispatch({type:'SET_FROM_DATE_TIME',fromDateTime:moment().tz(state.country.timezone).set({ 'hour': 0, 'minute': 0 })})
            fromDateTime=moment().tz(state.country.timezone).set({ 'hour': 0, 'minute': 0 })
        }
        if(Object.keys(state.toDateTime).length===0)
        {
            //// setting to date in global state if already not set
            toDateTime=moment().tz(state.country.timezone).set({ 'hour': 23, 'minute': 59 }).add(14, "days")
            // dispatch({type:'SET_TO_DATE_TIME',toDateTime:moment().tz(state.country.timezone).set({ 'hour': 23, 'minute': 59 }).add(14, "days")})
        }
        if( fromDateTime && toDateTime )
        {
          storeDateAndTime(fromDateTime,toDateTime,dispatch)
        }
      },[])

    useEffect(() => {
        console.log("when cart changed",state,services)
        console.log("params location",route,window.history)
        // setTimeout(()=>{navigate('/c/mybookings')},2000)

        if(state.user.id && state.token && state.cart.length!==0)
        {
            let tServices=DeepClone(state.cart),tServicesToCheck=[]
            tServices.map(item=>{
                if(item.deCondition=='' || item.deCondition===null)
                {
                    tServicesToCheck.push(item)
                }
            })
            console.log("tServicesToCheck",tServicesToCheck)
            if(tServicesToCheck.length!==0)
            {
                updateAccountBasedScenarioPricesToOffers(state.user.id)
            }
            else
            {
                setServices(DeepClone(state.cart))
            }
        }
        else
        {
            setServices(DeepClone(state.cart))
        }
       
    },[state.cart])
    
    useEffect(() => {
        // if(state.token){
        //     addressSavedHandler()
        // }
        if(services.length!==0)
        {
            console.log("when cart changed 2nd",services,state)
            let country='';
            country=services[0].MERCHANT_PERSONNEL_ADDRESS_COUNTRY
            country = services.every(eachItem=>eachItem.MERCHANT_PERSONNEL_ADDRESS_COUNTRY===country) ? (country==='India'?'IN':(country==='United States'?'US':'')) : ''
            setCountry(country)

            const filteredProName = groupBy(services, "MERCHANT_NAME");
            const filteredProPersonelIds = groupBy(services, "QF_MERCHANT_PERSONNEL_PRID");
            const filteredProMerchantIds = groupBy(services, "QF_MERCHANT_PRID");
            const filteredServiceIds = groupBy(services, "QF_MERCHANT_SERVICE_PRID");
            const proNames = Object.keys(filteredProName);
            let proPersonelIds = Object.keys(filteredProPersonelIds)
            let proMerchantIds = Object.keys(filteredProMerchantIds)

            if( (services.every(eachItem=>eachItem.hasOwnProperty('timeSlot')) ? true : false))
            {
                    setProceedToPayCheck(true)
            }
            else
            {
                    setProceedToPayCheck(false)
            }


            if(couponApplied){
                // setLoading(true)
                // console.log("Here")
                onPressApplyCoupon(couponCode, CouponData, prevCouponCode)
            } else{
                // setLoading(true)
                serviceRefreshAndGstCalc(false,country)
            }
            

            setFilteredServices(filteredProName)
            setProNames(proNames)
            setProPersonelIds(proPersonelIds)
            setProMerchantIds(proMerchantIds)
            setServiceIds(filteredServiceIds)
        }
        else
        {
            setProceedToPayCheck(false)
        }
    },[services])

    const updateAccountBasedScenarioPricesToOffers = async (consumerId) => {
        // console.log("from updateAccountBasedScenarioPricesToOffers", state.services,)

            let toSendBackArr=[]
            toSendBackArr.push(state.cart)
            console.log("services empty and cid present")
            let body = {
                consumerId,
                countryName: state.address.country === 'United States' ? 'USA' : (state.address.country === 'India' ? 'IN' : ''),
                services: toSendBackArr
            }
            console.log("body before sending else",body)
            let { fetchedData } = await customFetchData('POST', body, '/website/accountscenario/mapservices', state, dispatch)
            console.log("updateAccountBasedScenarioPricesToOffers fetchedData only cart",fetchedData)
            if (fetchedData.getBookingCount) 
            {
                if (fetchedData.services.length !== 0 && state.cart.length !== 0) 
                {
                    let loadedServices = DeepClone(fetchedData.services)
                    let cartServices = DeepClone(state.cart)
                    let newServicesToUpdateInCart = []
                    cartServices.forEach(cartItem => {
                        loadedServices.forEach(proArray => {
                            proArray.forEach(serviceItem => {
                                if (cartItem.QF_MERCHANT_SERVICE_PRID === serviceItem.QF_MERCHANT_SERVICE_PRID) {
                                    newServicesToUpdateInCart.push(serviceItem)
                                }
                            })
                        })
                    })

                    // console.log("newServicesToUpdateInCart",newServicesToUpdateInCart)

                    dispatch({ type: 'ADD_TO_CART', cart: newServicesToUpdateInCart })
                    dispatch({ type: 'UPDATE_CART_COUNT', cartCount: newServicesToUpdateInCart.length })

                }
            }
    }

    const fetchSavedAddresses = async() => {
        setSavedAddLoading(true)
    let body = {
        uid: state.user.id
    }
    let method = 'POST';
    let url = "/website/fetchSavedAddress/sv2"
    let DataResponse = await customFetchData(method, body, url, state, dispatch)
    console.log('fetch data',DataResponse)
    if (DataResponse.fetch) {
        let addressResult = DataResponse.fetchedData.fetchAddressResult
        if (addressResult.length !== 0) {
            // console.log(addressResult,'addressResult')
            let singleItem;
            singleItem=addressResult.filter(eachItem => {
                
                // console.log("location value",eachItem)
                console.log("location value",Number(eachItem.CONSUMER_ADDRESS_LAT) === state.address.latitude,typeof(state.address.latitude),Number(eachItem.CONSUMER_ADDRESS_LAT))
                return Number(eachItem.CONSUMER_ADDRESS_LAT) === state.address.latitude
            })
            // console.log("location singleItem",singleItem)
            if(singleItem.length!==0){
                let dataToSend={
                    merchant_id:services[0].QF_MERCHANT_PRID,
                    addLat:state.address.latitude,
                    addLong:state.address.longitude,
                    consumer_id:state.user.id,
                    merchLat:services[0].MERCHANT_LOCATION_LATITUDE,
                    merchLong:services[0].MERCHANT_LOCATION_LONGITUDE,
                    countryName:services[0].MERCHANT_PERSONNEL_ADDRESS_COUNTRY
                }
                getDistanceCheck(dataToSend,singleItem[0].QF_CONSUMER_LOCATION_PRID)
            }
            setFetchedDataResponse(addressResult)
            setSavedAddLoading(false)
        } else {
            setFetchedDataResponse([])
            setSavedAddLoading(false)
        }
    } else {
        setFetchedDataResponse([])
        setSavedAddLoading(false)
    }
    }

    const localStorageHandler = async() => {
        let getValue=await localStorage.getItem("qf:recent:address")
        // console.log("value in use effect",JSON.parse(getValue))
        setRecentSearchesArray(JSON.parse(getValue) !==null ? JSON.parse(getValue) : [])
     }
    useEffect(()=>{
        if(params.state){
            if (state.services.length === 0) {
                storeServices(params.state.allServices, params.state.categories, params.state.subCategories, dispatch)
            }
            if (state.address.totalAddress === null) {
                console.log('params', params.state.addressDetails)
                let addressDetails = params.state.addressDetails
                if(services.some(eachItem=>(eachItem.category_name=="Home Care") || (eachItem.category_name=="Tutoring"))){

                    if(addressDetails.currentAddress){
                        setCurrentAddress(addressDetails.totalAddress)
                    }
                    setLatitude(addressDetails.latitude)
                    setLongitude(addressDetails.longitude)
                    setTotalAddress(addressDetails.totalAddress)
                    setAddressLine1(addressDetails.addressLine1)
                    setCity(addressDetails.city)
                    setCountry_Cust(addressDetails.country)
                    setZipcode(addressDetails.zipcode)
                    setStateOrProvince(addressDetails.stateOrProvince)
                    setDistrict(addressDetails.district)
                    placesArray.map((item,index)=>{
                        if(item.id === 1){
                            // console.log('place array1',item)
                        return  clickedValue['clickedValue_'+ item.id]=true
                            
                        }else{
                            // console.log('place array2',item)
                        return clickedValue['clickedValue_'+ item.id]=false
                            // console.log('placse array',clickedValue)
                        }
                    })
                    if(state.token){
                        fetchSavedAddresses()
                    }
            
                    // Local storage fetch
                    localStorageHandler()
                }else{
                    console.log('no addresses are fetch')
                }
                storeAddress(addressDetails.totalAddress, addressDetails.addressLine1, addressDetails.country, addressDetails.city, addressDetails.stateOrProvince, addressDetails.zipcode, addressDetails.district, addressDetails.latitude, addressDetails.longitude, addressDetails.currentAddress, addressDetails.searchAddress, addressDetails.currentLocationDenied, dispatch, state)
            }else{
                if(services.some(eachItem=>(eachItem.category_name=="Home Care") || (eachItem.category_name=="Tutoring"))){

                    if(state.address.currentAddress){
                        setCurrentAddress(state.address.totalAddress)
                    }
                    setLatitude(state.address.latitude)
                    setLongitude(state.address.longitude)
                    setTotalAddress(state.address.totalAddress)
                    setAddressLine1(state.address.addressLine1)
                    setCity(state.address.city)
                    setCountry_Cust(state.address.country)
                    setZipcode(state.address.zipcode)
                    setStateOrProvince(state.address.stateOrProvince)
                    setDistrict(state.address.district)
                    // console.log('place array',placesArray)
                    placesArray.map((item,index)=>{
                        if(item.id === 1){
                            // console.log('place array1',item)
                        return  clickedValue['clickedValue_'+ item.id]=true
                            
                        }else{
                            // console.log('place array2',item)
                        return clickedValue['clickedValue_'+ item.id]=false
                            // console.log('placse array',clickedValue)
                        }
                    })
                    if(state.token){
                        fetchSavedAddresses()
                    }
            
                    // Local storage fetch
                    localStorageHandler()
                }else{
                    console.log('no addresses are fetch')
                }
            }
        }else{
            if(state.address.totalAddress === null){
                if(services.some(eachItem=>(eachItem.category_name=="Home Care") || (eachItem.category_name=="Tutoring"))){
                    fetchCurrentLocationHandler()
                    placesArray.map((item,index)=>{
                        if(item.id === 1){
                            // console.log('place array1',item)
                        return  clickedValue['clickedValue_'+ item.id]=true
                            
                        }else{
                            // console.log('place array2',item)
                        return clickedValue['clickedValue_'+ item.id]=false
                            // console.log('placse array',clickedValue)
                        }
                    })
                    if(state.token){
                        fetchSavedAddresses()
                    }
            
                    // Local storage fetch
                    localStorageHandler()
                }else{
                    console.log('no addresses are fetch')
                }
                            
            }else{
                if(services.some(eachItem=>(eachItem.category_name=="Home Care") || (eachItem.category_name=="Tutoring"))){
                    
                    if(state.address.currentAddress){
                        setCurrentAddress(state.address.totalAddress)
                    }
                    setLatitude(state.address.latitude)
                    setLongitude(state.address.longitude)
                    setTotalAddress(state.address.totalAddress)
                    setAddressLine1(state.address.addressLine1)
                    setCity(state.address.city)
                    setCountry_Cust(state.address.country)
                    setZipcode(state.address.zipcode)
                    setStateOrProvince(state.address.stateOrProvince)
                    setDistrict(state.address.district)
                    // console.log('place array',placesArray)
                    placesArray.map((item,index)=>{
                        if(item.id === 1){
                            // console.log('place array1',item)
                        return  clickedValue['clickedValue_'+ item.id]=true
                            
                        }else{
                            // console.log('place array2',item)
                        return clickedValue['clickedValue_'+ item.id]=false
                            // console.log('placse array',clickedValue)
                        }
                    })
                    if(state.token){
                        fetchSavedAddresses()
                    }
            
                    // Local storage fetch
                    localStorageHandler()
                }else{
                    console.log('no addresses are fetch')
                }
            }
        }
        
    },[state.token])
    const fetchCurrentLocationHandler = async() =>{
        let addressDetails= await FetchCurrentLocation(state,dispatch)
        //  console.log('address',addressDetails)
        //  if(addressDetails.locationFetch){
            let {addressLine1,city,country,curr_location_latitude,curr_location_longitude,currentLocation,district,stateOrProvince,zipcode,currentAddress}=addressDetails
            // let region={
            //    center: {
            //        lat:curr_location_latitude,
            //        lng:curr_location_longitude
            //    },
            //    zoom: 15
            // }
            // setDefaultProps(region)
            setLatitude(curr_location_latitude)
            setLongitude(curr_location_longitude)
            if (currentAddress || state.address.currentAddress) {
               setCurrentAddress(currentLocation)
            }
            setTotalAddress(currentLocation)
            setloadingL(false)
            setloadingL1(false)
            setAddressLine1(addressLine1)
            setCity(city)
            setCountry(country)
            setZipcode(zipcode)
            setStateOrProvince(stateOrProvince)
            setDistrict(district)
        // }
    }

    const addressSavedHandler = async() => {
        let body={
            uid:state.user.id
        }
        // console.log("Body", body)
        setLoading(true)
        let addressFetch=await customFetchData('POST',body,'/website/fetchSavedAddress/sv2',state,dispatch)
        // console.log('address saved',addressFetch, state.address.totalAddress)
        if(addressFetch.fetch){
            let dataFetch=addressFetch.fetchedData
            if(dataFetch.data){
                if(dataFetch.fetchAddressResult.length!==0){
                    setLoading(false)
                    setSavedAddresses(dataFetch.fetchAddressResult[0].CONSUMER_ADDRESS)
                    // dataFetch.fetchAddressResult.map((item,index)=>{
                        //     return defaultCheck['defaultCheck_'+ item.QF_CONSUMER_LOCATION_PRID]=true
                        // })
                    }else{
                        setLoading(false)
                        setSavedAddresses([]) 
                    }
                }else{
                    setLoading(false)
                    setSavedAddresses([]) 
                }
            }
            // console.log('address saved state',savedAddresses)
        }

    const serviceRefreshAndGstCalc=async (priceCheck,country, unitDiscount, couponType, couponID, discountAppliedServices)=>{
        try{
            setLoading(true)
            // console.log("serviceRefreshAndGstCalc", priceCheck,country, unitDiscount, couponType, couponID, discountAppliedServices)
            let uniqueId='C09C7655-DB15-47DE-B43E-297206BAABED';
            // let unitCouponDiscountForCal=0;
            // let unitServicePriceForCal=0;
            // let unitUpdateDiscountForCal=0
            let servicesClone=DeepClone(services)
            // if(priceCheck){

            //     servicesClone.map((item) => {
            //         unitServicePriceForCal = item.timeSlot.cp_multiplier_diff_price ? 
            //                                     item.timeSlot.ts_multiplier_diff_price ? 
            //                                         numeral(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.cp_multiplier_diff_price).value()).add(item.timeSlot.ts_multiplier_diff_price).value().toFixed(2) 
            //                                         :
            //                                         numeral(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.cp_multiplier_diff_price).value()).value().toFixed(2) 
            //                                     : 
            //                                     item.timeSlot.ts_multiplier_diff_price ? 
            //                                         numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.ts_multiplier_diff_price).value().toFixed(2) 
            //                                         :
            //                                         numeral(item.SERVICE_OFFER_PRICE).value().toFixed(2)

            //         if(discountAppliedServices.includes(item.QF_MERCHANT_SERVICE_PRID)){
            //             if((Number(unitServicePriceForCal)-Number(unitDiscount)) <= 0){
            //                 unitUpdateDiscountForCal=(Number(unitServicePriceForCal)-Number(0.50))
            //                 unitCouponDiscountForCal=Number(unitUpdateDiscountForCal)
            //                 console.log("Unit discount unitUpdateDiscount", unitDiscount, unitUpdateDiscount, unitCouponDiscountForCal)
            //             } else {
            //                 unitCouponDiscountForCal=Number(unitDiscount)
            //             }
            //         } else {
            //             unitCouponDiscountForCal=Number(unitDiscount)
            //         }

            //     })
            // } else {
            //     unitCouponDiscountForCal=null;
            // }

            // let service_data={services,country,priceCheck,couponDiscount: unitDiscount,uniqueId}
            let service_data={services,country,priceCheck,couponDiscount: unitDiscount,uniqueId:null}
            // console.log("service_data", service_data)

            let proPrice=0;
            let yourPrice=0;
            let totalAmountObj=0;
            let convinencePrice=0;
            let unitServicePrice=0;
            let unitTotalPrice=[];
            let addressRequired=null;
            let final_gst=0;
            let margin_prid=0;
            let finalAmountToPg=0;
            let gst_calc=[];
            let totalCPPrice=0;
            let totalTSPrice=0;
            let timezone='';
            let cancelAllow=false;
            let unitUpdateDiscount=0;
            let finalCouponTotalDiscount=0


            let {fetch, fetchedData}=await customFetchData("POST",service_data,'/website/checkout/check/sv2',state, dispatch)
            // console.log("checkout check",fetchedData)
            if(fetchedData.data)
                {
                    if(priceCheck)
                    {
                        //only if coupon is applied
                        servicesClone.map((item, index) => {
                            let result;
                            let result1;
                            // console.log("Inside serviceRefreshAndGstCalc Price Check", totalTSPrice)
                            // let keys=discountAppliedServices.entries()
                            if(item.hasOwnProperty('timeSlot'))
                            {
                                if(item.SERVICE_TIMEZONE == "Asia/Kolkata")
                                { 
                                        //Common variables
                                        result = (
                                            proPrice = numeral(proPrice).add(item.SERVICE_OFFER_PRICE).value().toFixed(2),
                                            yourPrice = item.timeSlot.cp_multiplier_diff_price ? 
                                                            item.timeSlot.ts_multiplier_diff_price ? 
                                                                numeral(yourPrice).add(numeral(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.cp_multiplier_diff_price).value()).add(item.timeSlot.ts_multiplier_diff_price).value()).value().toFixed(2) 
                                                                :
                                                                numeral(yourPrice).add(numeral(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.cp_multiplier_diff_price).value()).value()).value().toFixed(2) 
                                                            : 
                                                            item.timeSlot.ts_multiplier_diff_price ? 
                                                                numeral(yourPrice).add(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.ts_multiplier_diff_price).value()).value().toFixed(2) 
                                                                :
                                                                numeral(yourPrice).add(numeral(item.SERVICE_OFFER_PRICE).value()).value().toFixed(2),
        
                                            totalCPPrice= numeral(totalCPPrice).add(item.timeSlot.cp_multiplier_diff_price).value().toFixed(2) ,           
                                            totalTSPrice= item.timeSlot.hasOwnProperty('tsPrid') ? numeral(totalTSPrice).add(item.timeSlot.ts_multiplier_diff_price).value().toFixed(2) : numeral(totalTSPrice).value().toFixed(2),
                                            addressRequired = item.address_required,
                                            timezone=item.SERVICE_TIMEZONE,
                                            cancelAllow=item.SERVICE_CANCEL_ALLOWED,
        
                                            //only Indian variables
                                            item.gst_det=fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID],
                                            
                                            // final_gst = numeral(final_gst).add(fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].final_gst).value().toFixed(2),
                                            convinencePrice = numeral(convinencePrice).add(fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].convenienceFee).value().toFixed(2),
                                            margin_prid= fetchedData.margin_prid?fetchedData.margin_prid:0,
                                            unitServicePrice = item.timeSlot.cp_multiplier_diff_price ? 
                                                            item.timeSlot.ts_multiplier_diff_price ? 
                                                                numeral(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.cp_multiplier_diff_price).value()).add(item.timeSlot.ts_multiplier_diff_price).value().toFixed(2) 
                                                                :
                                                                numeral(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.cp_multiplier_diff_price).value()).value().toFixed(2) 
                                                            : 
                                                            item.timeSlot.ts_multiplier_diff_price ? 
                                                                numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.ts_multiplier_diff_price).value().toFixed(2) 
                                                                :
                                                                numeral(item.SERVICE_OFFER_PRICE).value().toFixed(2)
                                        )
        
                                        // discountAppliedServices.map((service) => {
                                            // console.log("Item", service)
                                            if(discountAppliedServices.includes(item.QF_MERCHANT_SERVICE_PRID)){
                                                // console.log("Item in service if", discountAppliedServices.includes(item.QF_MERCHANT_SERVICE_PRID))
        
                                                if((Number(unitServicePrice)-Number(unitDiscount)) < 10){
        
                                                    result = (
        
                                                        unitUpdateDiscount=(Number(unitServicePrice)-Number(10)),
        
                                                        item.unitGst= Number(numeral(10).multiply(numeral(18).divide(100).value()).value().toFixed(2)),
                                                        item.unitAmount= Number(10),
                                                        item.unitTotalAmount= Number(numeral(10).add(numeral(10).multiply(numeral(18).divide(100).value()).value()).value().toFixed(2)),   
                                                        item.couponDiscount=Number(unitUpdateDiscount),
        
                                                        finalCouponTotalDiscount=(numeral(finalCouponTotalDiscount).add(unitUpdateDiscount).value().toFixed(2)),
        
                                                        final_gst = numeral(final_gst).add(numeral(10).multiply(numeral(18).divide(100).value()).value().toFixed(2)).value().toFixed(2),
        
                                                        totalAmountObj= item.timeSlot.cp_multiplier_diff_price ? 
                                                                        item.timeSlot.ts_multiplier_diff_price ? 
                                                                            numeral(totalAmountObj).add(numeral(numeral(numeral(item.SERVICE_OFFER_PRICE).add(numeral(item.timeSlot.cp_multiplier_diff_price).add(item.timeSlot.ts_multiplier_diff_price).value()).value()).add(numeral(10).multiply(numeral(18).divide(100).value()).value().toFixed(2)).value()).subtract(unitUpdateDiscount).value()).value().toFixed(2)
                                                                            :
                                                                            numeral(totalAmountObj).add(numeral(numeral(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.cp_multiplier_diff_price).value()).add(numeral(10).multiply(numeral(18).divide(100).value()).value().toFixed(2)).value()).subtract(unitUpdateDiscount).value()).value().toFixed(2)
                                                                        : 
                                                                        item.timeSlot.ts_multiplier_diff_price ? 
                                                                            numeral(totalAmountObj).add(numeral(numeral(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.ts_multiplier_diff_price).value()).add(numeral(10).multiply(numeral(18).divide(100).value()).value().toFixed(2)).value()).subtract(unitUpdateDiscount).value()).value().toFixed(2)
                                                                            :
                                                                            numeral(totalAmountObj).add(numeral(numeral(item.SERVICE_OFFER_PRICE).add(numeral(10).multiply(numeral(18).divide(100).value()).value().toFixed(2)).value()).subtract(unitUpdateDiscount).value()).value().toFixed(2)
        
                                                    )
                                                } else {
        
                                                    result = (
        
        
                                                    item.unitTotalAmount=fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].finalAmountToPg,
                                                    item.unitAmount=fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].unitOfferPrice,
                                                    item.unitGst=fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].final_gst,
                                                    item.couponDiscount=Number(unitDiscount),
        
                                                    finalCouponTotalDiscount=(Number(finalCouponTotalDiscount)+Number(unitDiscount)),
                                                    
                                                    final_gst = numeral(final_gst).add(fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].final_gst).value().toFixed(2),
        
                                                    totalAmountObj= item.timeSlot.cp_multiplier_diff_price ? 
                                                                    item.timeSlot.ts_multiplier_diff_price ? 
                                                                        numeral(totalAmountObj).add(numeral(numeral(numeral(item.SERVICE_OFFER_PRICE).add(numeral(item.timeSlot.cp_multiplier_diff_price).add(item.timeSlot.ts_multiplier_diff_price).value()).value()).add(fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].final_gst).value()).subtract(unitDiscount).value()).value().toFixed(2)
                                                                        :
                                                                        numeral(totalAmountObj).add(numeral(numeral(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.cp_multiplier_diff_price).value()).add(fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].final_gst).value()).subtract(unitDiscount).value()).value().toFixed(2)
                                                                    : 
                                                                    item.timeSlot.ts_multiplier_diff_price ? 
                                                                        numeral(totalAmountObj).add(numeral(numeral(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.ts_multiplier_diff_price).value()).add(fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].final_gst).value()).subtract(unitDiscount).value()).value().toFixed(2)
                                                                        :
                                                                        numeral(totalAmountObj).add(numeral(numeral(item.SERVICE_OFFER_PRICE).add(fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].final_gst).value()).subtract(unitDiscount).value()).value().toFixed(2)
                                                    )
                                                }
        
            
                                                result = (
                                                    item.couponType=couponType,
                                                    item.couponID=couponID
                                                )
        
                                                return result;
                                            
                                            } else {
                                                // console.log("Item in service else", discountAppliedServices.includes(item.QF_MERCHANT_SERVICE_PRID))
                                                result = (
                                                    item.couponType='',
                                                    item.couponID=null,
                                                    item.couponDiscount=0,
        
                                                    item.unitTotalAmount=fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].finalAmountToPg,
                                                    item.unitAmount=fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].unitOfferPrice,
                                                    item.unitGst=fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].final_gst,
        
                                                    final_gst = numeral(final_gst).add(fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].final_gst).value().toFixed(2),
        
                                                    totalAmountObj= item.timeSlot.cp_multiplier_diff_price ? 
                                                                        item.timeSlot.ts_multiplier_diff_price ? 
                                                                            numeral(totalAmountObj).add(numeral(numeral(item.SERVICE_OFFER_PRICE).add(numeral(item.timeSlot.cp_multiplier_diff_price).add(item.timeSlot.ts_multiplier_diff_price).value()).value()).add(fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].final_gst).value()).value().toFixed(2)
                                                                            :
                                                                            numeral(totalAmountObj).add(numeral(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.cp_multiplier_diff_price).value()).add(fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].final_gst).value()).value().toFixed(2)
                                                                        : 
                                                                        item.timeSlot.ts_multiplier_diff_price ? 
                                                                            numeral(totalAmountObj).add(numeral(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.ts_multiplier_diff_price).value()).add(fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].final_gst).value()).value().toFixed(2)
                                                                            :
                                                                            numeral(totalAmountObj).add(numeral(item.SERVICE_OFFER_PRICE).add(fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].final_gst).value()).value().toFixed(2)
        
                                                )
                                            }
                                        // })
        
                                    
                                } 
                                else 
                                {
                                    // return (
        
                                        //Common variables
                                        result = (
                                            proPrice = numeral(proPrice).add(item.SERVICE_OFFER_PRICE).value().toFixed(2),
                                            yourPrice = item.timeSlot.cp_multiplier_diff_price ? 
                                                            item.timeSlot.ts_multiplier_diff_price ? 
                                                                numeral(yourPrice).add(numeral(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.cp_multiplier_diff_price).value()).add(item.timeSlot.ts_multiplier_diff_price).value()).value().toFixed(2) 
                                                                :
                                                                numeral(yourPrice).add(numeral(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.cp_multiplier_diff_price).value()).value()).value().toFixed(2) 
                                                            : 
                                                            item.timeSlot.ts_multiplier_diff_price ? 
                                                                numeral(yourPrice).add(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.ts_multiplier_diff_price).value()).value().toFixed(2) 
                                                                :
                                                                numeral(yourPrice).add(numeral(item.SERVICE_OFFER_PRICE).value()).value().toFixed(2),
        
                                            totalCPPrice=numeral(totalCPPrice).add(item.timeSlot.cp_multiplier_diff_price).value().toFixed(2),            
                                            totalTSPrice= item.timeSlot.hasOwnProperty('tsPrid') ? numeral(totalTSPrice).add(item.timeSlot.ts_multiplier_diff_price).value().toFixed(2) : numeral(totalTSPrice).value().toFixed(2),
                                            addressRequired = item.address_required,
                                            timezone=item.SERVICE_TIMEZONE,
                                            cancelAllow=item.SERVICE_CANCEL_ALLOWED,
                                            unitServicePrice = item.timeSlot.cp_multiplier_diff_price ? 
                                                            item.timeSlot.ts_multiplier_diff_price ? 
                                                                numeral(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.cp_multiplier_diff_price).value()).add(item.timeSlot.ts_multiplier_diff_price).value().toFixed(2) 
                                                                :
                                                                numeral(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.cp_multiplier_diff_price).value()).value().toFixed(2) 
                                                            : 
                                                            item.timeSlot.ts_multiplier_diff_price ? 
                                                                numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.ts_multiplier_diff_price).value().toFixed(2) 
                                                                :
                                                                numeral(item.SERVICE_OFFER_PRICE).value().toFixed(2)
                                        )
        
        
                                        //only US variables
                                        
                                        // discountAppliedServices.some((service) => {
                                            if(discountAppliedServices.includes(item.QF_MERCHANT_SERVICE_PRID)){
        
                                                // console.log("Unit Price", unitServicePrice)
                                                if((Number(unitServicePrice)-Number(unitDiscount)) < Number(0.50)){
                                                    result = (
                                                        unitUpdateDiscount=(Number(unitServicePrice)-Number(0.50)),
                                                        // console.log("Unit discount unitUpdateDiscount", unitDiscount, unitUpdateDiscount)
        
                                                        // item.unitAmount=numeral(unitServicePrice).subtract(unitUpdateDiscount).value().toFixed(2),
                                                        item.unitAmount=Number(0.50),
                                                        item.unitTotalAmount=Number(0.50),
                                                        item.couponDiscount=Number(unitUpdateDiscount),
                                                        finalCouponTotalDiscount=(Number(finalCouponTotalDiscount)+Number(unitUpdateDiscount)),
                                                        totalAmountObj = item.timeSlot.cp_multiplier_diff_price ? 
                                                                    item.timeSlot.ts_multiplier_diff_price ? 
                                                                        numeral(totalAmountObj).add(numeral(numeral(item.SERVICE_OFFER_PRICE).add(numeral(item.timeSlot.cp_multiplier_diff_price).add(item.timeSlot.ts_multiplier_diff_price).value()).value()).subtract(unitUpdateDiscount).value()).value().toFixed(2)
                                                                        :
                                                                        numeral(totalAmountObj).add(numeral(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.cp_multiplier_diff_price).value()).subtract(unitUpdateDiscount).value()).value().toFixed(2)
                                                                    : 
                                                                    item.timeSlot.ts_multiplier_diff_price ? 
                                                                        numeral(totalAmountObj).add(numeral(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.ts_multiplier_diff_price).value()).subtract(unitUpdateDiscount).value()).value().toFixed(2)
                                                                        :
                                                                        numeral(totalAmountObj).add(numeral(item.SERVICE_OFFER_PRICE).subtract(unitUpdateDiscount).value()).value().toFixed(2)
        
                                                    )
        
                                                } else {
        
                                                    result = (
                                                    
                                                    item.unitTotalAmount=numeral(unitServicePrice).subtract(unitDiscount).value().toFixed(2),
                                                    item.unitAmount=numeral(unitServicePrice).subtract(unitDiscount).value().toFixed(2),
                                                    item.couponDiscount=Number(unitDiscount),
                                                    finalCouponTotalDiscount=(Number(finalCouponTotalDiscount)+Number(unitDiscount)),
                                                    totalAmountObj = item.timeSlot.cp_multiplier_diff_price ? 
                                                                    item.timeSlot.ts_multiplier_diff_price ? 
                                                                        numeral(totalAmountObj).add(numeral(numeral(item.SERVICE_OFFER_PRICE).add(numeral(item.timeSlot.cp_multiplier_diff_price).add(item.timeSlot.ts_multiplier_diff_price).value()).value()).subtract(unitDiscount).value()).value().toFixed(2)
                                                                        :
                                                                        numeral(totalAmountObj).add(numeral(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.cp_multiplier_diff_price).value()).subtract(unitDiscount).value()).value().toFixed(2)
                                                                    : 
                                                                    item.timeSlot.ts_multiplier_diff_price ? 
                                                                        numeral(totalAmountObj).add(numeral(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.ts_multiplier_diff_price).value()).subtract(unitDiscount).value()).value().toFixed(2)
                                                                        :
                                                                        numeral(totalAmountObj).add(numeral(item.SERVICE_OFFER_PRICE).subtract(unitDiscount).value()).value().toFixed(2)
                                                                    
                                                        )
                                                }
        
            
                                                result = (
                                                    item.couponType=couponType,
                                                    item.couponID=couponID
                                                )
        
                                                return result;
            
            
                                            } else {
                                                return (
                                                    item.couponType='',
                                                    item.couponID=null,
                                                    item.couponDiscount=0,
                                                    totalAmountObj = item.timeSlot.cp_multiplier_diff_price ? 
                                                                        item.timeSlot.ts_multiplier_diff_price ? 
                                                                            numeral(totalAmountObj).add(numeral(item.SERVICE_OFFER_PRICE).add(numeral(item.timeSlot.cp_multiplier_diff_price).add(item.timeSlot.ts_multiplier_diff_price).value()).value()).value().toFixed(2)
                                                                            :
                                                                            numeral(totalAmountObj).add(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.cp_multiplier_diff_price).value()).value().toFixed(2)
                                                                        : 
                                                                        item.timeSlot.ts_multiplier_diff_price ? 
                                                                            numeral(totalAmountObj).add(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.ts_multiplier_diff_price).value()).value().toFixed(2)
                                                                            :
                                                                            numeral(totalAmountObj).add(item.SERVICE_OFFER_PRICE).value().toFixed(2)
                                                )
                                            }
                                }

                                return result;
                            } 
                            else 
                            {
                                if(item.SERVICE_TIMEZONE == "Asia/Kolkata")
                                {
                                        result1 = (
                                            proPrice = numeral(proPrice).add(item.SERVICE_OFFER_PRICE).value().toFixed(2),
                                            yourPrice = item.cpPrid ? 
                                                                numeral(yourPrice).add(numeral(numeral(item.SERVICE_OFFER_PRICE).add(item.multiplier_diff_price).value()).value()).value().toFixed(2) 
                                                                : 
                                                                numeral(yourPrice).add(numeral(item.SERVICE_OFFER_PRICE).value()).value().toFixed(2),
                                            totalCPPrice=numeral(totalCPPrice).add(item.multiplier_diff_price).value().toFixed(2),          
                                            totalTSPrice=numeral(totalTSPrice).value().toFixed(2),
                                            addressRequired = item.address_required,
                                            timezone=item.SERVICE_TIMEZONE,
                                    
        
                                            item.gst_det=fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID],
                                            item.unitTotalAmount=fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].finalAmountToPg,
                                            item.unitAmount=fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].unitOfferPrice,
                                            item.unitGst=fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].final_gst,
                                            
                                            final_gst = numeral(final_gst).add(fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].final_gst).value().toFixed(2),
                                            convinencePrice = numeral(convinencePrice).add(fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].convenienceFee).value().toFixed(2),
                                            margin_prid= fetchedData.margin_prid?fetchedData.margin_prid:0
        
                                        )
        
        
                                            if(discountAppliedServices.includes(item.QF_MERCHANT_SERVICE_PRID)){
        
                                                result1 = (
            
                                                    item.couponDet={
                                                        couponType:couponType,
                                                        couponID:couponID,
                                                        couponCode:couponCode,
                                                        couponCount:1,
                                                        couponDiscount:unitDiscount,
                                                        couponMaxreached:false
                                                    },
                                                    totalAmountObj = item.cpPrid ? 
                                                                    numeral(totalAmountObj).add(numeral(numeral(numeral(item.SERVICE_OFFER_PRICE).subtract(unitDiscount).value()).add(item.multiplier_diff_price).value()).add(fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].final_gst).value()).value().toFixed(2)
                                                                    : 
                                                                    numeral(totalAmountObj).add(numeral(numeral(item.SERVICE_OFFER_PRICE).subtract(unitDiscount).value()).add(fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].final_gst).value()).value().toFixed(2)
                                                )
                                            } else {
        
        
                                                result1 = (
            
                                                item.couponDet={},
                                                totalAmountObj = item.cpPrid ? 
                                                                numeral(totalAmountObj).add(numeral(numeral(numeral(item.SERVICE_OFFER_PRICE).subtract(unitDiscount).value()).add(item.multiplier_diff_price).value()).add(fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].final_gst).value()).value().toFixed(2)
                                                                : 
                                                                numeral(totalAmountObj).add(numeral(numeral(item.SERVICE_OFFER_PRICE).subtract(unitDiscount).value()).add(fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].final_gst).value()).value().toFixed(2)
                                                )
                                            }
                                } 
                                else 
                                {
                                        result1 = (
                                            proPrice = numeral(proPrice).add(item.SERVICE_OFFER_PRICE).value().toFixed(2),
                                            yourPrice = item.cpPrid ? 
                                                                numeral(yourPrice).add(numeral(numeral(item.SERVICE_OFFER_PRICE).add(item.multiplier_diff_price).value()).value()).value().toFixed(2) 
                                                                : 
                                                                numeral(yourPrice).add(numeral(item.SERVICE_OFFER_PRICE).value()).value().toFixed(2),
                                            totalCPPrice=numeral(totalCPPrice).add(item.multiplier_diff_price).value().toFixed(2),          
                                            totalTSPrice=numeral(totalTSPrice).value().toFixed(2),
                                            addressRequired = item.address_required,
                                            timezone=item.SERVICE_TIMEZONE,
        
                                            item.unitTotalAmount=numeral(yourPrice).value().toFixed(2),
                                            item.unitAmount=numeral(yourPrice).value().toFixed(2)
                                        )
        
        
                                            if(discountAppliedServices.includes(item.QF_MERCHANT_SERVICE_PRID)){
                                                result1 = (
                
                                                    item.couponDet={
                                                        couponType:couponType,
                                                        couponID:couponID,
                                                        couponCode:couponCode,
                                                        couponCount:1,
                                                        couponDiscount:unitDiscount,
                                                        couponMaxreached:false
                                                    },
                                                    totalAmountObj = item.cpPrid ? 
                                                                        numeral(totalAmountObj).add(numeral(numeral(item.SERVICE_OFFER_PRICE).subtract(unitDiscount).value()).add(item.multiplier_diff_price).value()).value().toFixed(2)
                                                                        : 
                                                                        numeral(totalAmountObj).add(numeral(item.SERVICE_OFFER_PRICE).subtract(unitDiscount).value()).value().toFixed(2)
            
                                                )
                                            } else {
        
                                                result1 = (
            
                                                item.couponDet={
                                                    couponType:couponType,
                                                    couponID:couponID,
                                                    couponCode:couponCode,
                                                    couponCount:1,
                                                    couponDiscount:unitDiscount,
                                                    couponMaxreached:false
                                                },
                                                totalAmountObj = item.cpPrid ? 
                                                                    numeral(totalAmountObj).add(numeral(numeral(item.SERVICE_OFFER_PRICE).subtract(unitDiscount).value()).add(item.multiplier_diff_price).value()).value().toFixed(2)
                                                                    : 
                                                                    numeral(totalAmountObj).add(numeral(item.SERVICE_OFFER_PRICE).subtract(unitDiscount).value()).value().toFixed(2)
            
                                                )
                                            }
                                }
        
                                return result1;
                            }
                        })
        
                        setCouponDiscount(finalCouponTotalDiscount)
                        setProServicesPrice(proPrice)
                        setYourServicesPrice(yourPrice)
                        setTotalAmount(totalAmountObj)
                        setConvenienceFee(convinencePrice)
        
                        setAddressRequired(addressRequired)
                        setTimezone(timezone)
                        setTotalCPPrice(totalCPPrice)
                        setTotalTSPrice(totalTSPrice)
                        
                        setServicesFromDb(fetchedData.det)
                        setMargin_prid(fetchedData.margin_prid?fetchedData.margin_prid:0)
                        setGst_calc(fetchedData.gst_calc)
                        setTotalGST(final_gst);
                    }
                    else
                    {
                        //coupon is not applied
                        servicesClone.map((item) => {
                            // console.log("Inside serviceRefreshAndGstCalc", item)
                            
                            if(item.hasOwnProperty('timeSlot'))
                            {
                                // console.log("Inside serviceRefreshAndGstCalc Price Check", item.QF_MERCHANT_SERVICE_PRID, item.timeSlot.hasOwnProperty('tsPrid'))
                                if(item.SERVICE_TIMEZONE == "Asia/Kolkata")
                                {
                                    return (
        
                                        proPrice = numeral(proPrice).add(item.SERVICE_OFFER_PRICE).value().toFixed(2),
                                        yourPrice = item.timeSlot.cp_multiplier_diff_price ? 
                                                        item.timeSlot.ts_multiplier_diff_price ? 
                                                            numeral(yourPrice).add(numeral(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.cp_multiplier_diff_price).value()).add(item.timeSlot.ts_multiplier_diff_price).value()).value().toFixed(2) 
                                                            :
                                                            numeral(yourPrice).add(numeral(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.cp_multiplier_diff_price).value()).value()).value().toFixed(2) 
                                                        : 
                                                        item.timeSlot.ts_multiplier_diff_price ? 
                                                            numeral(yourPrice).add(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.ts_multiplier_diff_price).value()).value().toFixed(2) 
                                                            :
                                                            numeral(yourPrice).add(numeral(item.SERVICE_OFFER_PRICE).value()).value().toFixed(2),
        
                                        totalCPPrice=numeral(totalCPPrice).add(item.timeSlot.cp_multiplier_diff_price).value().toFixed(2),          
                                        totalTSPrice= item.timeSlot.hasOwnProperty('tsPrid') ? numeral(totalTSPrice).add(item.timeSlot.ts_multiplier_diff_price).value().toFixed(2) : numeral(totalTSPrice).value().toFixed(2),          
                                        addressRequired = item.address_required,
                                        timezone=item.SERVICE_TIMEZONE,
                                        cancelAllow=item.SERVICE_CANCEL_ALLOWED,
        
        
                                        item.gst_det=fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID],
                                        item.unitTotalAmount=fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].finalAmountToPg,
                                        item.unitAmount=fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].unitOfferPrice,
                                        item.unitGst=fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].final_gst,
                                        
                                        totalAmountObj = item.timeSlot.cp_multiplier_diff_price ? 
                                                        item.timeSlot.ts_multiplier_diff_price ? 
                                                            numeral(totalAmountObj).add(numeral(numeral(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.cp_multiplier_diff_price).value()).add(item.timeSlot.ts_multiplier_diff_price).value()).add(fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].final_gst).value()).value().toFixed(2) 
                                                            :
                                                            numeral(totalAmountObj).add(numeral(numeral(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.cp_multiplier_diff_price).value()).value()).add(fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].final_gst).value()).value().toFixed(2) 
                                                        : 
                                                        item.timeSlot.ts_multiplier_diff_price ? 
                                                            numeral(totalAmountObj).add(numeral(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.ts_multiplier_diff_price).value()).add(fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].final_gst).value()).value().toFixed(2) 
                                                            :
                                                            numeral(totalAmountObj).add(numeral(numeral(item.SERVICE_OFFER_PRICE).value()).add(fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].final_gst).value()).value().toFixed(2),
        
                                        final_gst = numeral(final_gst).add(fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].final_gst).value().toFixed(2),
                                        convinencePrice = numeral(convinencePrice).add(fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].convenienceFee).value().toFixed(2),      
                                        margin_prid= fetchedData.margin_prid?fetchedData.margin_prid:0
        
                                    )   
                                } 
                                else 
                                {
                                    return (
        
                                        proPrice = numeral(proPrice).add(item.SERVICE_OFFER_PRICE).value().toFixed(2),
                                        yourPrice = item.timeSlot.cp_multiplier_diff_price ? 
                                                        item.timeSlot.ts_multiplier_diff_price ? 
                                                            numeral(yourPrice).add(numeral(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.cp_multiplier_diff_price).value()).add(item.timeSlot.ts_multiplier_diff_price).value()).value().toFixed(2) 
                                                            :
                                                            numeral(yourPrice).add(numeral(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.cp_multiplier_diff_price).value()).value()).value().toFixed(2) 
                                                        : 
                                                        item.timeSlot.ts_multiplier_diff_price ? 
                                                            numeral(yourPrice).add(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.ts_multiplier_diff_price).value()).value().toFixed(2) 
                                                            :
                                                            numeral(yourPrice).add(numeral(item.SERVICE_OFFER_PRICE).value()).value().toFixed(2),
        
                                        totalCPPrice=numeral(totalCPPrice).add(item.timeSlot.cp_multiplier_diff_price).value().toFixed(2),           
                                        totalTSPrice= item.timeSlot.hasOwnProperty('tsPrid') ? numeral(totalTSPrice).add(item.timeSlot.ts_multiplier_diff_price).value().toFixed(2) : numeral(totalTSPrice).value().toFixed(2),            
                                        addressRequired = item.address_required,
                                        timezone=item.SERVICE_TIMEZONE,
                                        cancelAllow=item.SERVICE_CANCEL_ALLOWED,
        
                                        item.unitTotalAmount=numeral(yourPrice).value().toFixed(2),
                                        item.unitAmount=numeral(yourPrice).value().toFixed(2),
        
                                        totalAmountObj = item.timeSlot.cp_multiplier_diff_price ? 
                                                        item.timeSlot.ts_multiplier_diff_price ? 
                                                            numeral(totalAmountObj).add(numeral(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.cp_multiplier_diff_price).value()).add(item.timeSlot.ts_multiplier_diff_price).value()).value().toFixed(2) 
                                                            :
                                                            numeral(totalAmountObj).add(numeral(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.cp_multiplier_diff_price).value()).value()).value().toFixed(2) 
                                                        : 
                                                        item.timeSlot.ts_multiplier_diff_price ? 
                                                            numeral(totalAmountObj).add(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.ts_multiplier_diff_price).value()).value().toFixed(2)
                                                            :
                                                            numeral(totalAmountObj).add(numeral(item.SERVICE_OFFER_PRICE).value()).value().toFixed(2)
        
                                    )
                                }
                            } 
                            else 
                            {
                                if(item.SERVICE_TIMEZONE == "Asia/Kolkata")
                                {
                                    return (

                                        proPrice = numeral(proPrice).add(item.SERVICE_OFFER_PRICE).value().toFixed(2),
                                        yourPrice = item.cpPrid ? 
                                                            numeral(yourPrice).add(numeral(numeral(item.SERVICE_OFFER_PRICE).add(item.multiplier_diff_price).value()).value()).value().toFixed(2) 
                                                        : 
                                                            numeral(yourPrice).add(numeral(item.SERVICE_OFFER_PRICE).value()).value().toFixed(2),
        
                                        totalCPPrice=numeral(totalCPPrice).add(item.multiplier_diff_price).value().toFixed(2),          
                                        totalTSPrice=numeral(totalTSPrice).value().toFixed(2),
                                        addressRequired = item.address_required,
                                        timezone=item.SERVICE_TIMEZONE,
        
                                        item.gst_det=fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID],
                                        item.unitTotalAmount=fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].finalAmountToPg,
                                        item.unitAmount=fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].unitOfferPrice,
                                        item.unitGst=fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].final_gst,
                                        totalAmountObj = item.cpPrid ? 
                                                            numeral(totalAmountObj).add(numeral(item.SERVICE_OFFER_PRICE).add(numeral(item.multiplier_diff_price).add(fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].final_gst).value()).value()).value().toFixed(2) 
                                                        : 
                                                            numeral(totalAmountObj).add(numeral(item.SERVICE_OFFER_PRICE).add(fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].final_gst).value()).value().toFixed(2),
                                        final_gst = numeral(final_gst).add(fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].final_gst).value().toFixed(2),
                                        convinencePrice = numeral(convinencePrice).add(fetchedData.gst_calc[item.QF_MERCHANT_SERVICE_PRID].convenienceFee).value().toFixed(2),
                                        margin_prid= fetchedData.margin_prid?fetchedData.margin_prid:0
                                    )     
                                } 
                                else 
                                {
                                    return (
        
                                        proPrice = numeral(proPrice).add(item.SERVICE_OFFER_PRICE).value().toFixed(2),
                                        yourPrice = item.cpPrid ? 
                                                            numeral(yourPrice).add(numeral(numeral(item.SERVICE_OFFER_PRICE).add(item.multiplier_diff_price).value()).value()).value().toFixed(2) 
                                                        : 
                                                            numeral(yourPrice).add(numeral(item.SERVICE_OFFER_PRICE).value()).value().toFixed(2),
        
                                        totalCPPrice=numeral(totalCPPrice).add(item.multiplier_diff_price).value().toFixed(2),          
                                        totalTSPrice=numeral(totalTSPrice).value().toFixed(2),
                                        addressRequired = item.address_required,
                                        timezone=item.SERVICE_TIMEZONE,
        
                                        item.unitTotalAmount=numeral(yourPrice).value().toFixed(2),
                                        item.unitAmount=numeral(yourPrice).value().toFixed(2),
                                        totalAmountObj = item.cpPrid ? 
                                                            numeral(totalAmountObj).add(numeral(item.SERVICE_OFFER_PRICE).add(item.multiplier_diff_price).value()).value().toFixed(2) 
                                                        : 
                                                            numeral(totalAmountObj).add(item.SERVICE_OFFER_PRICE).value().toFixed(2)
                                    )
                                }
                            }
        
                        })
        
                        setProServicesPrice(proPrice)
                        setYourServicesPrice(yourPrice)
                        setTotalAmount(totalAmountObj)
                        setConvenienceFee(convinencePrice)
                        setAddressRequired(addressRequired)
                        setTimezone(timezone)
                        setTotalCPPrice(totalCPPrice)
                        setTotalTSPrice(totalTSPrice)
        
        
                        setServicesFromDb(fetchedData.det)
                        setMargin_prid(fetchedData.margin_prid?fetchedData.margin_prid:0)
                        setGst_calc(fetchedData.gst_calc)
                        setTotalGST(final_gst);
                    }
        
                    // console.log("servicesClone", servicesClone, totalTSPrice)
                    setServices2(servicesClone)
                    setCouponAppliedModal(false)
                    setLoading(false)
        
            }
            else
            {
                setLoading(false)
                if(fetchedData.dbError)
                {
                    toast('Internal error. Please try again.', {
                        position: "bottom-center",
                        hideProgressBar: true,
                        progress: undefined,
                        style:{backgroundColor:'#E0D1FF'}
                    })
                } else {
                    toast('Something went wrong!', {
                        position: "bottom-center",
                        hideProgressBar: true,
                        progress: undefined,
                        style:{backgroundColor:'#E0D1FF'}
                    })
                }
            }
        }
        catch(c1)
        {
            console.log(c1)
            setLoading(false)
        }
    }

    const onPressPay=()=>{
        // console.log('loc id',locationPrid)
        setLoading(true)
        let serviceIds=[],servicesTemp=DeepClone(services2),serviceCurrency="",{params}=route
        // console.log("services",servicesTemp,state)
        
        serviceCurrency=servicesTemp[0].SERVICE_CURRENCY
        serviceCurrency = servicesTemp.every(eachItem=>eachItem.SERVICE_CURRENCY===serviceCurrency) ? serviceCurrency : ''
        if(servicesTemp.length!==0)
        {
            servicesTemp.map(serviceItem=>{
                serviceIds.push(serviceItem.QF_MERCHANT_SERVICE_PRID)
                if(serviceItem.hasOwnProperty('timeSlot'))
                {
                    let mul_det={}
                    return (
                        mul_det={
                            cpPrid:serviceItem.timeSlot.cpPrid?serviceItem.timeSlot.cpPrid:null,
                            tsPrid:serviceItem.timeSlot.tsPrid?serviceItem.timeSlot.tsPrid:null,
                            cp_multiplier_diff_price:serviceItem.timeSlot.cp_multiplier_diff_price,
                            ts_multiplier_diff_price:serviceItem.timeSlot.ts_multiplier_diff_price,
                            cpMultiplier:serviceItem.timeSlot.cpMultiplier,
                            tsMultiplier:serviceItem.timeSlot.tsMultiplier,
                            deCondition:serviceItem.timeSlot.deCondition,
                            uniqueId:null
                        },
                        serviceItem.mul_det={...mul_det}
                    )
                }
        })
        // console.log("from on press pay",servicesTemp,serviceIds,totalAmount,totalGST,margin_prid,serviceCurrency)
        if(serviceCurrency==='Rupees')
        {
            setLoading(false)
            navigate("/payments/razorpay/in/v1",{state:{
                totalAmount,margin_prid,
                services:servicesTemp,
                serviceIds,
                c_l_prid:locationPrid?locationPrid:null,
                totalCouponDiscount:couponDiscount,
                couponMaxreached:false,
                couponType:couponType1,
                couponID:couponID1,
                couponCode:couponCode,
                couponCount:1,
            }
            })
        }
        else if(serviceCurrency==='Dollars')
        {
            setLoading(false)
            navigate("/payments/stripe/us/v1",{state:{
                totalAmount,
                margin_prid,
                services:servicesTemp,
                serviceIds,
                userId:state.user.id?state.user.id:null,
                userPasswordReg:false,
                c_l_prid:locationPrid?locationPrid:null,
                totalCouponDiscount:couponDiscount,
                couponMaxreached:false,
                couponType:couponType1,
                couponID:couponID1,
                couponCode:couponCode,
                couponCount:1,
            }})
        }

    }
}

    const showSelectedSlot=(item,index)=>{

            if(item.hasOwnProperty('timeSlot'))
            {
                if(Object.keys(item.timeSlot).length!==0)
                {
                    return (moment(item.timeSlot.date).format("DD MMM YY") +" \n"+ item.timeSlot.startTime +" - " +item.timeSlot.endTime)
                }
                else
                {
                    return 'Select Date \n & Time'
                }
            }
            else
            {
                return 'Select Date \n & Time'
            }
    }

    const groupBy = (array, key) => {
        return services.reduce((result, currentValue) => {
          (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
          return result;
        }, {});
      };

    const onPressApplyCoupon=async(coupon, couponData, prevCouponCode)=>{
        setLoading(true)
        // console.log("Coupon Data", couponData)
        
        let servicesClone=DeepClone(services)
        let check = momenttz().tz(services[0].SERVICE_TIMEZONE).format("YYYY-MM-DD")

        let offer_ids=[],cat_prid=[],subcat_prid=[],service_dates=[],merchant_id=null,merchant_per_id=null,timezone='',servicePrice=0,cpPrice=0, tsPrice=0, gstPrice=0;
        if(couponData.couponValid){

            let finalCouponTotalDiscount=0,unitServicePrice=0,unitUpdateDiscount=0;

            servicesClone.map((item, index) => {
                let result;
                // console.log("Inside serviceRefreshAndGstCalc Price Check", totalTSPrice)
                // let keys=discountAppliedServices.entries()
                if(item.hasOwnProperty('timeSlot'))
                {
                    if(item.SERVICE_TIMEZONE == "Asia/Kolkata")
                    { 
                        result = (
                        unitServicePrice = item.timeSlot.cp_multiplier_diff_price ? 
                                            item.timeSlot.ts_multiplier_diff_price ? 
                                                numeral(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.cp_multiplier_diff_price).value()).add(item.timeSlot.ts_multiplier_diff_price).value().toFixed(2) 
                                                :
                                                numeral(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.cp_multiplier_diff_price).value()).value().toFixed(2) 
                                            : 
                                            item.timeSlot.ts_multiplier_diff_price ? 
                                                numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.ts_multiplier_diff_price).value().toFixed(2) 
                                                :
                                                numeral(item.SERVICE_OFFER_PRICE).value().toFixed(2)
                        )

                        if(couponData.finalServices.includes(item.QF_MERCHANT_SERVICE_PRID)){
                            if((Number(unitServicePrice)-Number(couponData.unitDiscount)) < Number(10)){
                                return (
                                    unitUpdateDiscount=(Number(unitServicePrice)-Number(10)),
                                    finalCouponTotalDiscount=(numeral(finalCouponTotalDiscount).add(unitUpdateDiscount).value().toFixed(2))
                                )
                            } else {

                                return finalCouponTotalDiscount=(Number(finalCouponTotalDiscount)+Number(couponData.unitDiscount))
                            }
                        }

                        return result;
                    } 
                    else 
                    {
                        result = (
                            unitServicePrice = item.timeSlot.cp_multiplier_diff_price ? 
                                            item.timeSlot.ts_multiplier_diff_price ? 
                                                numeral(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.cp_multiplier_diff_price).value()).add(item.timeSlot.ts_multiplier_diff_price).value().toFixed(2) 
                                                :
                                                numeral(numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.cp_multiplier_diff_price).value()).value().toFixed(2) 
                                            : 
                                            item.timeSlot.ts_multiplier_diff_price ? 
                                                numeral(item.SERVICE_OFFER_PRICE).add(item.timeSlot.ts_multiplier_diff_price).value().toFixed(2) 
                                                :
                                                numeral(item.SERVICE_OFFER_PRICE).value().toFixed(2)
                        )

                        if(couponData.finalServices.includes(item.QF_MERCHANT_SERVICE_PRID)){
                            if((Number(unitServicePrice)-Number(couponData.unitDiscount)) < Number(0.50)){
                                return (
                                    unitUpdateDiscount=(Number(unitServicePrice)-Number(0.50)),
                                    finalCouponTotalDiscount=(Number(finalCouponTotalDiscount)+Number(unitUpdateDiscount))
                                )
                            } else {

                                return finalCouponTotalDiscount=(Number(finalCouponTotalDiscount)+Number(couponData.unitDiscount))

                            }
                        }

                        return result;
                    }
                }
            })

            setCouponType(couponData.couponType)
            setCouponID(couponData.couponID)
            setCouponCode(couponData.couponCode)
            setPrevCouponCode(couponData.couponCode)
            // setApplyStyle('deactiveApplyButtonStyle')
            setCouponMaxCount(couponData.couponMaxCount)
            setCouponUsedCount(couponData.couponUsedCount)
            setCouponApplyCount(couponApplyCount)
            setCouponMaxreached(couponMaxreached)
            setCouponCancelButton(true)
            setCouponValid(true)
            // setProServicesPrice(proPrice)
            // setYourServicesPrice(yourPrice)
            // setTotalCPPrice(cpPrice)
            // setTotalTSPrice(tsPrice)
            // setTotalAmount(totalAmountObj)
            // setTotalGST(gstPrice)
            // setOfferPrice(totalAmountObj)
            // setCouponDiscount(Number(fetchedData.result.couponDiscount))
            setCouponDiscount(Number(finalCouponTotalDiscount))
            // setCouponMessage(fetchedData.result.message)
            setCouponApplied(couponData.couponApplied)
            // setApplyButton(fetchedData.result.applybutton)
            // setUnitDiscountPrice(fetchedData.result.unitDiscountPrice)
            setUnitDiscount(Number(couponData.unitDiscount))
            setTotalUnitPrice(numeral(servicePrice).multiply(services.length).value())
            setDiscountAppliedServices(couponData.finalServices)
            setCouponDetails(couponData)

            // console.log("prevCouponCode", prevCouponCode)

            if(prevCouponCode !== null && prevCouponCode !== coupon){
                setCouponDiscountModal(true)
                // setCouponAppliedModal(true)
            } else {
                setCouponDiscountModal(false)
                // setCouponAppliedModal(false)
            }
            
            
            setTimeout(() => {
                serviceRefreshAndGstCalc(true, country, couponData.unitDiscount,couponData.couponType,couponData.couponID,couponData.finalServices?couponData.finalServices:[])
                // setCouponAppliedModal(false)
            }, 2000)
                

        }
        else{

            if(couponData.deviceScenario === false){
                
                setDeviceScenario(couponData.deviceScenario)
                setDeviceMessage(couponData.deviceMessage)
                setCouponAppliedModal(false)

            }else{

                setTotalAmount(numeral(servicePrice).value())
                setOfferPrice(numeral(servicePrice).value())
                setCouponDiscount(0)
                setApplyButton(true)
                setCouponCode(null)
                setCouponID(null)
                setCouponType(null)
                setApplyStyle('deactiveApplyButtonStyle')
                setUnitDiscountPrice(servicePrice)
                setCouponValid(false)
                setCouponCancelButton(true)
                setUnitDiscount(couponData.unitDiscount)
                setTotalUnitPrice(numeral(servicePrice).value())
                setCouponAppliedModal(false)
            }

            return serviceRefreshAndGstCalc(false, country)
        }
            
        
    }

    const onPressCancelCoupon=async()=>{
        setCouponApplied(false)
        setCouponCode(null)
        setCouponID(null)
        setCouponType(null)
        setPrevCouponCode(null)

        serviceRefreshAndGstCalc(false, country)
    }

    const CmpltAddressModal = () => {
        // console.log('modal open')
        setCmpltAddModal(true)
        let savedAddress;
        savedAddress=fetchedDataResponse.filter(item=>{
            // console.log(item,'itemsssss',latitude,Number(item.CONSUMER_ADDRESS_LAT),latitude === Number(item.CONSUMER_ADDRESS_LAT))
            return latitude === Number(item.CONSUMER_ADDRESS_LAT)
        })
        if(savedAddress.length!==0){
            // console.log(savedAddress,'savedAddress')
            setSelectedTag(savedAddress[0].CONSUMER_ADDRESS_SELECTED_TAG)
            placesArray.map(item1=>{
                if(item1.place === savedAddress[0].CONSUMER_ADDRESS_SELECTED_TAG){
                // console.log('items yes',item.id === data.id ,item.id,data.id)
                    return setClickedValue({
                        ['clickedValue_'+ item1.id]:true   
                    })
                }
            })
            setEditAddress(savedAddress[0])
            setAllValues({
                ...allValues,
                cmptAddress:savedAddress[0].CONSUMER_ADDRESS_LINE2,
                floor:savedAddress[0].CONSUMER_ADDRESS_LINE3,
                landmark:savedAddress[0].CONSUMER_ADDRESS_LINE4
            })
            setStateOrProvince(savedAddress[0].CONSUMER_ADDRESS_STATE)
            setZipcode(savedAddress[0].CONSUMER_ADDRESS_ZIPCODE)
            setDistrict(savedAddress[0].CONSUMER_ADDRESS_DISTRICT)
            setCity(savedAddress[0].CONSUMER_ADDRESS_CITY)
            setCountry_Cust(savedAddress[0].CONSUMER_ADDRESS_COUNTRY)
            setAddressLine1(savedAddress[0].CONSUMER_ADDRESS_LINE1)
            setLatitude(Number(savedAddress[0].CONSUMER_ADDRESS_LAT))
            setLongitude(Number(savedAddress[0].CONSUMER_ADDRESS_LONG))
        }
    }
//// Save Address Modal related code

    const CompleteAddressModalClose = () => {
        setCmpltAddModal(false)
        setAllValues({
            ...allValues,
            cmptAddress: '',
            floor: '',
            landmark: '',
            status: '',
            message: '',
            addError: '',
            floorError: false,
            landError: false
        })
        placesArray.map((item,index)=>{
            if(item.id === 1){
                // console.log('place array1',item)
               return  clickedValue['clickedValue_'+ item.id]=true
                
            }else{
                // console.log('place array2',item)
               return clickedValue['clickedValue_'+ item.id]=false
                // console.log('placse array',clickedValue)
            }
        })
        
    }
    //// onClick func to active card with backgroud color
    const onPressSmallCard = (data) => {
        // console.log('values', data)
        setSelectedTag(data.place)
        placesArray.map(item=>{
            if(item.id === data.id){
            // console.log('items yes',item.id === data.id ,item.id,data.id)
                return setClickedValue({
                    ['clickedValue_'+ item.id]:true   
                })
            }
        })
        // console.log('clicked value',clickedValue)
    }
    //// Input fields functions while changing the address
    const CompleteAddressHandler = (event) => {

        let value=event.target.value;

        // if (value.trim() === '') {
        //     setAllValues({
        //         ...allValues,
        //         cmptAddress: '',
        //         addError: true,
        //         status: 'error',
        //         message: 'Please enter complete address'
        //     })
        // } else {
            setAllValues({
                ...allValues,
                cmptAddress: value,
                status: '',
                addError: false,
            })
        // }
    }
    const FloorHandler = (event) => {
        let value=event.target.value;

        setAllValues({
            ...allValues,
            floor: value
        })
    }
    const LandmarkHandler = (event) => {
        let value=event.target.value;

        setAllValues({
            ...allValues,
            landmark: value
        })
    }
    const onCompleteAddressSubmit = () => {
        if (allValues.cmptAddress.trim() === '') {
            setAllValues({
                ...allValues,
                status: 'error',
                message: 'Please enter complete address',
                addError: true
            })
        } else {
            setAllValues({ ...allValues, addError: false })
            input_Ref1.current.focus()
        }
    }
    const onFloorSubmit = () => {
        setAllValues({ ...allValues, floorError: false })
        input_Ref2.current.focus()
    }
    const onLandmarkSubmit = () => {
        setAllValues({ landError: false })
        // Keyboard.dismiss();
    }
     //// saving address into data base

     const onPressSaveAddress = async() => {
        try {
            // if (allValues.cmptAddress.trim() === '') {
            //     setAllValues({
            //         ...allValues,
            //         status: 'error',
            //         message: 'Please enter complete address',
            //         addError: true
            //     })
            // }else{
                let dataToSend={
                    merchant_id:services[0].QF_MERCHANT_PRID,
                    addLat:latitude,
                    addLong:longitude,
                    consumer_id:state.user.id,
                    merchLat:services[0].MERCHANT_LOCATION_LATITUDE,
                    merchLong:services[0].MERCHANT_LOCATION_LONGITUDE,
                    countryName:services[0].MERCHANT_PERSONNEL_ADDRESS_COUNTRY
                }
                // console.log('body', dataToSend)
                let method1 = 'POST';
                let url1 = "/website/getDistance/sv2"
                let getDistanceRes = await customFetchData(method1, dataToSend, url1, state, dispatch)
                // console.log('get distance',getDistanceRes)
                if(getDistanceRes.fetch){
                    if(getDistanceRes.fetchedData.get){
                        if(getDistanceRes.fetchedData.Servicable){
                            setNotServicable(false)
                            setErrorMessage(" ")
                            setErrorAddress(false)
                            let body;
                            if(Object.keys(editAddress).length !==0){
                                body = {
                                    totalAddress:editAddress.CONSUMER_ADDRESS,
                                    latitude: editAddress.CONSUMER_ADDRESS_LAT,
                                    longitude: editAddress.CONSUMER_ADDRESS_LONG,
                                    city: editAddress.CONSUMER_ADDRESS_CITY,
                                    country: editAddress.CONSUMER_ADDRESS_COUNTRY,
                                    state: editAddress.CONSUMER_ADDRESS_STATE,
                                    zipcode: editAddress.CONSUMER_ADDRESS_ZIPCODE,
                                    district: editAddress.CONSUMER_ADDRESS_DISTRICT,
                                    addressLine1: editAddress.CONSUMER_ADDRESS_LINE1,
                                    addressLine2: allValues.cmptAddress ? allValues.cmptAddress : null ,
                                    addressLine3: allValues.floor ? allValues.floor : null,
                                    addressLine4: allValues.landmark ? allValues.landmark : null,
                                    selectedTag: selectedTag,
                                    uid:state.user.id
                                }
                            }else{
                                body = {
                                    totalAddress: totalAddress ? totalAddress : currentAddress,
                                    latitude: latitude,
                                    longitude: longitude,
                                    city: city,
                                    country: country_Cust,
                                    state: stateOrProvince,
                                    zipcode: zipcode,
                                    district: district,
                                    addressLine1: addressLine1,
                                    addressLine2: allValues.cmptAddress ? allValues.cmptAddress : null ,
                                    addressLine3: allValues.floor ? allValues.floor : null,
                                    addressLine4: allValues.landmark ? allValues.landmark : null,
                                    selectedTag: selectedTag,
                                    uid:state.user.id
                                }
                            }
                            // console.log('body', body)
                            let method = 'POST';
                            let url = "/website/addressSave/sv2"
                            let useFetchRes = await customFetchData(method, body, url, state, dispatch)
                            // console.log('response', useFetchRes)
                            if (useFetchRes.fetch) {
                                if(useFetchRes.fetchedData.update){
                                    // setShowModalProceedToPay(true)
                                    toast(
                                        <div style={{display:'flex',justifyContent:'center'}}>
                                           <text style={{textAlign:'center'}}>address saved successfully</text>
                                        </div>
                                        , {
                                        position: "bottom-center",
                                        hideProgressBar: true,
                                        progress: undefined,
                                        closeButton:false,
                                        style:{backgroundColor:'#E0D1FF'}
                                    });
                                    setLocationPrid(useFetchRes.fetchedData.location_Prid)
                                    setCmpltAddModal(false)
                                    setChangeAddressModal(false)
                                    setMapViewModal(false)
                                    setMapViewModalWeb(false)
                                    setSaveRecentModal(false)
                                    setEditAddressModal(false)
                                    setSaveAddressModal(false)
                                    setSearchText('')
                                    setData([])
                                    setEditAddress({})
                                    // locationPrid=useFetchRes.fetchedData.location_Prid
                                    setAllValues({ ...allValues, cmptAddress: '', floor: '', landmark: '', addError: '', status: '' })
                                    placesArray.map((item,index)=>{
                                        if(item.id === 1){
                                            // console.log('place array1',item)
                                           return  clickedValue['clickedValue_'+ item.id]=true
                                            
                                        }else{
                                            // console.log('place array2',item)
                                           return clickedValue['clickedValue_'+ item.id]=false
                                            // console.log('placse array',clickedValue)
                                        }
                                    })
                                }
                            }
                        }else{
                            if(getDistanceRes.fetchedData.notServicable){
                                // setShowModalProceedToPay(false)
                                setNotServicable(true)
                                setErrorMessage("Your address is not in our service limits")
                                setErrorAddress(true)
                                setCmpltAddModal(false)
                                    setChangeAddressModal(false)
                                    setMapViewModal(false)
                                    setEditAddressModal(false)
                                    setMapViewModalWeb(false)
                                    setSaveRecentModal(false)
                                    setSearchText('')
                                    setData([])
                                    setEditAddress({})
                                    setSaveAddressModal(false)
                                    // locationPrid=useFetchRes.fetchedData.location_Prid
                                    setLocationPrid(null)
                                    setAllValues({ ...allValues, cmptAddress: '', floor: '', landmark: '', addError: '', status: '' })
                                    placesArray.map((item,index)=>{
                                        if(item.id === 1){
                                            // console.log('place array1',item)
                                           return  clickedValue['clickedValue_'+ item.id]=true
                                            
                                        }else{
                                            // console.log('place array2',item)
                                           return clickedValue['clickedValue_'+ item.id]=false
                                            // console.log('placse array',clickedValue)
                                        }
                                    })
                            }
                        }
                    }
                }
                // let body = {
                //     totalAddress: totalAddress ? totalAddress : currentAddress,
                //     latitude: state.address.latitude,
                //     longitude: state.address.longitude,
                //     city: city,
                //     country: country_Cust,
                //     state: stateOrProvince,
                //     zipcode: zipcode,
                //     district: district,
                //     addressLine1: addressLine1,
                //     addressLine2: allValues.cmptAddress,
                //     addressLine3: allValues.floor,
                //     addressLine4: allValues.landmark,
                //     selectedTag: selectedTag,
                //     uid:state.user.id
                // }
                // // console.log('body', body)
                // let method = 'POST';
                // let url = "/website/addressSave/sv2"
                // let useFetchRes = await customFetchData(method, body, url, state, dispatch)
                //     if (useFetchRes.fetch) {
                //         if(useFetchRes.fetchedData.update){
                //             setCmpltAddModal(false)
                //             // locationPrid=useFetchRes.fetchedData.location_Prid
                //             setLocationPrid(useFetchRes.fetchedData.location_Prid)
                //             setAllValues({ ...allValues, cmptAddress: '', floor: '', landmark: '', addError: '', status: '' })
                //             placesArray.map((item,index)=>{
                //                 if(item.id === 1){
                //                     // console.log('place array1',item)
                //                    return  clickedValue['clickedValue_'+ item.id]=true
                                    
                //                 }else{
                //                     // console.log('place array2',item)
                //                    return clickedValue['clickedValue_'+ item.id]=false
                //                     // console.log('placse array',clickedValue)
                //                 }
                //             })
                //             setShowModalProceedToPay(true)
                //             toast("address saved successfully", {
                //                 position: "bottom-center",
                //                 hideProgressBar: true,
                //                 progress: undefined,
                //                 style:{backgroundColor:'#E0D1FF'}
                //             });
                //         }
                //     }   
                    
                    // console.log("showModalProceedToPay",showModalProceedToPay)
            // }
        }
        catch (error) {
            toast("something went wrong", {
                position: "bottom-center",
                hideProgressBar: true,
                progress: undefined,
                style:{backgroundColor:'#E0D1FF'}
            });
        }
    }


    const onPressDeleteIcon = (item) => {
        setModalVisible(true)
        setTempDelItem(item)
    }

    const onPressMovetoWishlist = () => {
        setModalVisible(false)
    }

    let buttonDisabled=true,buttonDisabled1;
    if(allValues.addError || allValues.cmptAddress===''){
        buttonDisabled=true
    }else{
        buttonDisabled=false
    }

    if(loading){
        buttonDisabled1=true
    } 
    else if( state.cartCount && state.cart.length!==0 && locationPrid==null){
        if(services.some(eachItem=>(eachItem.category_name=="Home Care") || (eachItem.category_name=="Tutoring")))
        {
            if(fetchedDataResponse.some(eachItem1=>Number(eachItem1.CONSUMER_ADDRESS_LAT) === latitude && errorAddress === false)){
                console.log('buttonDisabled 1-1')
                buttonDisabled1=false
            }else{
                console.log('buttonDisabled 1-2')
                buttonDisabled1=true
            }
            // buttonDisabled1=true
        } else if(services.length!==0 && services.some((eachItem)=> !eachItem.hasOwnProperty('timeSlot')))
        {
            buttonDisabled1=true
        } 
        else
        {
            buttonDisabled1=false 
        }
    }
    else if(services.length!==0 && services.some((eachItem)=> !eachItem.hasOwnProperty('timeSlot'))){
        buttonDisabled1=true
    }
    else{
        if(locationPrid!=null && errorAddress === false){
            buttonDisabled1=false
        }
    }
    let buttonDisabled2 = true;
    if (addressLine1 === '' || city === '' || zipcode === '' || stateOrProvince === '' || addressLine1 === null || city === null || zipcode === null || stateOrProvince === null || addressLine1 === undefined || city === undefined || zipcode === undefined || stateOrProvince === undefined) {
        buttonDisabled2 = true
    } else {
        buttonDisabled2 = false
    }

    const onPressSlotSelect = (servicesDet,serviceItem,serviceIndex, couponApplied, couponCode,userPasswordReg,userId) => {

        if(!state.token){

            //user not loggeed in
            setModal(!modal)

        } else {

            //login user

            // console.log("Service on press", servicesDet)
            setmodalDataServices(DeepClone(servicesDet))
            setmodalDataServiceItem(serviceItem)
            setmodalDataServiceIndex(serviceIndex)
            setmodalDataCouponCode(couponCode)
            setmodalDataCouponApplied(couponApplied)
            setmodalDataUserPasswordReg(userPasswordReg)
            setmodalDataUserId(userId)


            setSlotModal(true);

        }

        
    }


    const onPressCancelSlotModal = () => {
        setSlotModal(false)
    }

    const onPressRemoveItem = () => {
        setModalVisible(false)
        removeFromCart(tempDelItem,state,dispatch)
        setTempDelItem({})
        setServices([...state.cart])
    }

    let listOfCancellationDetails;
    if(services.length !== 0){

        listOfCancellationDetails = services.map((item, index) => {
            let serviceName=(item.SERVICE_NAME).slice(0,32)
            let dots=(item.SERVICE_NAME).length > 32 ? '...' : ''
            return (
                <div style={{paddingTop:10, paddingBottom:10}}>
                    {item.SERVICE_CANCEL_ALLOWED ? 
                    
                        <div style={{display:'flex', flexDirection:'column',justifyContent:'space-around', width:'100%'}}>

                            <text className="cancelDisServiceName">{serviceName}{dots}</text>

                            <div className="inlineViews2" style={{paddingTop:5, paddingBottom:5}}>

                                <div style={{display:'flex', flexDirection:'column',justifyContent:'flex-start'}}>
                                    <div style={{display:'flex', flexDirection:'row'}}>
                                        <text className="cancelDisclaimerDesText">Cancel Before</text>
                                    </div>
                                </div>

                                <div style={{display:'flex', flexDirection:'column',justifyContent:'flex-start'}}>
                                    <div style={{display:'flex', flexDirection:'row'}}>
                                        <text className="cancelDisclaimerDesText">{item.SERVICE_CANCEL_ALLOWED_DAYS}{' '}{item.SERVICE_CANCEL_ALLOWED_DAYS > 1 ? 'days' : 'day'}</text>
                                    </div>
                                </div>

                            </div> 


                            <div className="inlineViews2">

                                <div style={{flexDirection:'row',justifyContent:'space-between'}}>
                                    <div style={{flexDirection:'row'}}>
                                        <text className="cancelDisclaimerDesText">Cancellation Fee</text>
                                    </div>
                                </div>

                                <div style={{flexDirection:'row',justifyContent:'space-between'}}>
                                    <div style={{flexDirection:'row'}}>
                                        <text className="cancelDisclaimerDesText">{item.SERVICE_CURRENCY==='Dollars' ? '$ ' : '\u20B9 '}{item.SERVICE_CANCEL_FEE}</text>
                                    </div>
                                </div>

                            </div> 

                        </div>

                        :

                        <div style={{flexDirection:'column',justifyContent:'space-around'}}>
                            <text numberOfLines={3} className="cancelDisServiceName">{serviceName}{dots}</text>

                            <div className="inlineViews2" style={{paddingVertical:5}}>

                                <div style={{flexDirection:'column',justifyContent:'flex-start'}}>
                                    <div style={{flexDirection:'row'}}>
                                        <text className="cancelDisclaimerDesText">Cancellation is not allowed for this service.</text>
                                    </div>
                                </div>

                            </div> 
                        </div>

                    }
                </div>
            )
        })

    }

    let servicesInCart;

    if(services.length !== 0){

        if(proNames.length !== 0){
            servicesInCart = proNames.map((item) => {
                return (
        
                    <div className="sericesDivStyle">
                        <div style={{paddingVertical:10}}>
                            <text className="professionalName">{item}</text>
                        </div>
        
                        {services.map((item, index) => {
                            let serviceName= (item.SERVICE_NAME).slice(0,32)
                            let dots=(item.SERVICE_NAME).length > 32 ? '...' : ''
                            return (
                                <div className="in_cart_service_details">
                                    <div className="inlineViews" style={{justifyContent:'flex-start'}}>
                                        <div style={{paddingTop:1,borderRadius:4}}>
                                            {/* <img src={{uri:item.category_image}} style={{width:80,height:80,borderRadius:4,backgroundColor:catColors[item.category_name]}}/> */}
                                            <img src={item.service_sub_category_image} style={{width:25,height:25,borderRadius:4,backgroundColor:catColors[item.category_name]}}/>
                                        </div>

                                        {(item.hasOwnProperty('timeSlot')) && (item.service_offer_price !== item.timeSlot.slot_multiplier_offer_price) ? 
                                        
                                            <div className="service_name_price_div">
                                                <text className="serviceName1" >{serviceName}{dots}</text>

                                                {item.SERVICE_OFFER_PRICE == item.timeSlot.slot_multiplier_offer_price ? 
                                                    <div style={{flexDirection:'row'}}> 
                                                        {/* <div style={{borderBottom:'1px solid #747474', display:'flex', flexDirection:'row', position:"relative", width:28, bottom:6, left:8}}></div> */}
                                                        {/* <text className="servicePrice" style={{marginLeft:10,marginTop:4, color: "#747474"}}>{item.SERVICE_CURRENCY==='Dollars' ? '$' : '\u20B9'}{item.SERVICE_OFFER_PRICE}</text> */}
                                                        <text className="servicePrice" style={{marginTop:4, color: "#8c52ff"}}>{item.SERVICE_CURRENCY==='Dollars' ? '$' : '\u20B9'}{item.timeSlot.slot_multiplier_offer_price}</text>
                                                    </div>
                                                :
                                                    <div style={{flexDirection:'row'}}>
                                                        <div style={{borderBottom:'1px solid #747474', display:'flex', flexDirection:'row', position:'relative', width:38, bottom:-15, left:-1}}></div>
                                                        <text className="servicePrice" style={{marginTop:4, color: "#747474"}}>{item.SERVICE_CURRENCY==='Dollars' ? '$' : '\u20B9'}{item.SERVICE_OFFER_PRICE}</text>
                                                        <text className="servicePrice" style={{marginLeft:20,marginTop:4, color: "#8c52ff"}}>{item.SERVICE_CURRENCY==='Dollars' ? '$' : '\u20B9'}{item.timeSlot.slot_multiplier_offer_price}</text>
                                                    </div>
                                                }
                                            </div>
                                        :
                                            <div className="service_name_price_div">
                                                <text className="serviceName1">{serviceName}{dots}</text>

                                                {item.SERVICE_OFFER_PRICE == item.service_offer_price ? 

                                                    <div style={{flexDirection:'row'}}>
                                                        {/* <div style={{borderColor:'#747474', borderBottomWidth:1, position:'absolute', width:28, bottom:6, left:8}}></div> */}
                                                        <text className="servicePrice" style={{marginTop:4, color: "#747474"}}>{item.SERVICE_CURRENCY==='Dollars' ? '$' : '\u20B9'}{item.SERVICE_OFFER_PRICE}</text>
                                                        {/* <text className="servicePrice" style={{marginLeft:10,marginTop:4, color: "#8c52ff"}}>{item.SERVICE_CURRENCY==='Dollars' ? '$' : '\u20B9'}{item.SERVICE_OFFER_PRICE}</text> */}
                                                        {/* <text className="servicePrice" style={{marginLeft:10,marginTop:4, color: "#8c52ff"}}>{'\u20B9'}2400</text> */}
                                                        {/* <text className="servicePrice" style={{marginLeft:10,marginTop:4, color: "#8c52ff"}}>{item.SERVICE_CURRENCY==='Dollars' ? '$' : '\u20B9'}{item.service_offer_price}</text> */}
                                                    </div>
                                                :
                                                    <div style={{flexDirection:'row'}}>
                                                        <div style={{borderBottom:'1px solid #747474', display:'flex', flexDirection:'row', position:'relative', width:38, bottom:-15, left:-1}}></div>
                                                        <text className="servicePrice" style={{marginTop:4, color: "#747474"}}>{item.SERVICE_CURRENCY==='Dollars' ? '$' : '\u20B9'}{item.SERVICE_OFFER_PRICE}</text>
                                                        <text className="servicePrice" style={{marginLeft:20,marginTop:4, color: "#8c52ff"}}>{item.SERVICE_CURRENCY==='Dollars' ? '$' : '\u20B9'}{item.service_offer_price}</text>
                                                    </div>

                                                }

                                            </div>
                                        
                                    }
                                    </div>

                                    <div className="inlineViews" style={{alignItems:'center',justifyContent:'flex-end'}}>
                                        <div onClick={() => onPressSlotSelect(services,item,index, couponApplied, couponCode,false,null)} className="slotSelectionButton" style={{cursor:'pointer'}}>
                                            {
                                                item.hasOwnProperty('timeSlot')
                                                ?
                                                <text className="selectDateTime">{showSelectedSlot(item,index)}</text>
                                                // <text className="selectDateTime">{"Select Date \n & Time"}</text>
                                                :
                                                <text className="selectDateTime">{"Select Date \n & Time"}</text>
                                            }
                                        </div>
                                        <div onClick={() => onPressDeleteIcon(item)} style={{cursor:'pointer'}}>
                                            <img src={DeleteIcon} />
                                        </div>

                                    </div>
                                </div>
                            )

                        })}
                    </div>
                )
            })
        }
    


        // servicesInCart = services.map((item, index) => {
        //     let serviceName= (item.SERVICE_NAME).slice(0,32)
        //     let dots=(item.SERVICE_NAME).length > 32 ? '...' : ''
        //     return (
        //         <div className="in_cart_service_details">
        //             <div className="inlineViews" style={{justifyContent:'flex-start'}}>
        //                 <div style={{paddingTop:1,borderRadius:4}}>
        //                     {/* <img src={{uri:item.category_image}} style={{width:80,height:80,borderRadius:4,backgroundColor:catColors[item.category_name]}}/> */}
        //                     <img src={item.service_sub_category_image} style={{width:25,height:25,borderRadius:4,backgroundColor:catColors[item.category_name]}}/>
        //                 </div>

        //                 {(item.hasOwnProperty('timeSlot')) && (item.service_offer_price !== item.timeSlot.slot_multiplier_offer_price) ? 
                        
        //                     <div className="service_name_price_div">
        //                         <text className="serviceName1" >{serviceName}{dots}</text>

        //                         {item.SERVICE_OFFER_PRICE == item.timeSlot.slot_multiplier_offer_price ? 
        //                             <div style={{flexDirection:'row'}}> 
        //                                 <div style={{borderBottom:'1px solid #747474', display:'flex', flexDirection:'row', position:"relative", width:28, bottom:6, left:8}}></div>
        //                                 {/* <text className="servicePrice" style={{marginLeft:10,marginTop:4, color: "#747474"}}>{item.SERVICE_CURRENCY==='Dollars' ? '$' : '\u20B9'}{item.SERVICE_OFFER_PRICE}</text> */}
        //                                 <text className="servicePrice" style={{marginLeft:10,marginTop:4, color: "#8c52ff"}}>{item.SERVICE_CURRENCY==='Dollars' ? '$' : '\u20B9'}{item.timeSlot.slot_multiplier_offer_price}</text>
        //                             </div>
        //                         :
        //                             <div style={{flexDirection:'row'}}>
        //                                 <div style={{borderBottom:'1px solid #747474', display:'flex', flexDirection:'row', position:'relative', width:35, bottom:-15, left:0}}></div>
        //                                 <text className="servicePrice" style={{marginTop:4, color: "#747474"}}>{item.SERVICE_CURRENCY==='Dollars' ? '$' : '\u20B9'}{item.SERVICE_OFFER_PRICE}</text>
        //                                 <text className="servicePrice" style={{marginLeft:10,marginTop:4, color: "#8c52ff"}}>{item.SERVICE_CURRENCY==='Dollars' ? '$' : '\u20B9'}{item.timeSlot.slot_multiplier_offer_price}</text>
        //                             </div>
        //                         }
        //                     </div>
        //                 :
        //                     <div className="service_name_price_div">
        //                         <text className="serviceName1">{serviceName}{dots}</text>

        //                         {item.SERVICE_OFFER_PRICE == item.service_offer_price ? 

        //                             <div style={{flexDirection:'row'}}>
        //                                 {/* <div style={{borderColor:'#747474', borderBottomWidth:1, position:'absolute', width:28, bottom:6, left:8}}></div> */}
        //                                 <text className="servicePrice" style={{marginLeft:10,marginTop:4, color: "#747474"}}>{item.SERVICE_CURRENCY==='Dollars' ? '$' : '\u20B9'}{item.SERVICE_OFFER_PRICE}</text>
        //                                 {/* <text className="servicePrice" style={{marginLeft:10,marginTop:4, color: "#8c52ff"}}>{item.SERVICE_CURRENCY==='Dollars' ? '$' : '\u20B9'}{item.SERVICE_OFFER_PRICE}</text> */}
        //                                 {/* <text className="servicePrice" style={{marginLeft:10,marginTop:4, color: "#8c52ff"}}>{'\u20B9'}2400</text> */}
        //                                 {/* <text className="servicePrice" style={{marginLeft:10,marginTop:4, color: "#8c52ff"}}>{item.SERVICE_CURRENCY==='Dollars' ? '$' : '\u20B9'}{item.service_offer_price}</text> */}
        //                             </div>
        //                         :
        //                             <div style={{flexDirection:'row'}}>
        //                                 <div style={{borderBottom:'1px solid #747474', display:'flex', flexDirection:'row', position:'relative', width:28, bottom:6, left:8}}></div>
        //                                 <text className="servicePrice" style={{marginTop:4, color: "#747474"}}>{item.SERVICE_CURRENCY==='Dollars' ? '$' : '\u20B9'}{item.SERVICE_OFFER_PRICE}</text>
        //                                 <text className="servicePrice" style={{marginLeft:10,marginTop:4, color: "#8c52ff"}}>{item.SERVICE_CURRENCY==='Dollars' ? '$' : '\u20B9'}{item.service_offer_price}</text>
        //                             </div>

        //                         }

        //                     </div>
                        
        //             }
        //             </div>

        //             <div className="inlineViews" style={{alignItems:'center',justifyContent:'flex-end'}}>
        //                 <div onClick={() => onPressSlotSelect(services,item,index, couponApplied, couponCode,false,null)} className="slotSelectionButton" style={{cursor:'pointer'}}>
        //                     {
        //                         item.hasOwnProperty('timeSlot')
        //                         ?
        //                         <text className="selectDateTime">{showSelectedSlot(item,index)}</text>
        //                         // <text className="selectDateTime">{"Select Date \n & Time"}</text>
        //                         :
        //                         <text className="selectDateTime">{"Select Date \n & Time"}</text>
        //                     }
        //                 </div>
        //                 <div onClick={() => onPressDeleteIcon(item)} style={{cursor:'pointer'}}>
        //                     <img src={DeleteIcon} />
        //                 </div>

        //             </div>
        //         </div>
        //     )

        // })

    }

    const onClickCouponHander = (coupon, couponData, prevCouponCode) => {

        // console.log("onClickCouponHander", coupon, couponData, prevCouponCode)

        let paramCouponCode = couponData.couponCode
        let paramPrevCode = prevCouponCode

        if(couponData.couponApplied && paramCouponCode != paramPrevCode){
            setCouponApplied(true)
            setCouponCode(paramCouponCode)
            setPrevCouponCode(paramPrevCode)
            setCouponData(couponData)
            onPressApplyCoupon(coupon, couponData, prevCouponCode)
            setCouponDiscountModal(false)
        } else if(couponData.couponApplied && (paramPrevCode != '' || paramPrevCode)){
            setCouponApplied(true)
            setCouponCode(paramPrevCode)
        } else {
            setCouponApplied(false)
            setCouponCode(null)
        }

    }
   
    const changeAddressModalOpenHandler = () =>{
        setChangeAddressModal(true)
        setCmpltAddModal(false)
    }
    const changeAddressModalCloseHandler = () =>{
        setChangeAddressModal(false)
        setCmpltAddModal(true)
        let savedAddress;
        savedAddress=fetchedDataResponse.filter(item=>{
            // console.log(item,'itemsssss',latitude,Number(item.CONSUMER_ADDRESS_LAT),latitude === Number(item.CONSUMER_ADDRESS_LAT))
            return latitude === Number(item.CONSUMER_ADDRESS_LAT)
        })
        if(savedAddress.length!==0){
            // console.log(savedAddress,'savedAddress')
            setSelectedTag(savedAddress[0].CONSUMER_ADDRESS_SELECTED_TAG)
            placesArray.map(item1=>{
                if(item1.place === savedAddress[0].CONSUMER_ADDRESS_SELECTED_TAG){
                // console.log('items yes',item.id === data.id ,item.id,data.id)
                    return setClickedValue({
                        ['clickedValue_'+ item1.id]:true   
                    })
                }
            })
            setEditAddress(savedAddress[0])
            setAllValues({
                ...allValues,
                cmptAddress:savedAddress[0].CONSUMER_ADDRESS_LINE2,
                floor:savedAddress[0].CONSUMER_ADDRESS_LINE3,
                landmark:savedAddress[0].CONSUMER_ADDRESS_LINE4
            })
            setStateOrProvince(savedAddress[0].CONSUMER_ADDRESS_STATE)
            setZipcode(savedAddress[0].CONSUMER_ADDRESS_ZIPCODE)
            setDistrict(savedAddress[0].CONSUMER_ADDRESS_DISTRICT)
            setCity(savedAddress[0].CONSUMER_ADDRESS_CITY)
            setCountry_Cust(savedAddress[0].CONSUMER_ADDRESS_COUNTRY)
            setAddressLine1(savedAddress[0].CONSUMER_ADDRESS_LINE1)
            setLatitude(Number(savedAddress[0].CONSUMER_ADDRESS_LAT))
            setLongitude(Number(savedAddress[0].CONSUMER_ADDRESS_LONG))
        }

    }
    const fetchAddress = async (latitudeValue, longitudeValue) => {
        // console.log('values lat and long',latitudeValue, longitudeValue)
        setLoadingLC(true)
        setloadingL(true)
        setCityInValid(false)
        setAddressLine1('')
        setCountry_Cust('')
        setCity('')
        setZipcode('')
        setStateOrProvince('')
        setDistrict('')
        if (latitudeValue !== null && longitudeValue !== null) {
            const LocationStr = latitudeValue + "," + longitudeValue;
            let ApiURL = "https://maps.googleapis.com/maps/api/timezone/json?"
            let params = `location=${LocationStr}&timestamp=${1331161200}&key=AIzaSyC6sHvN36SBHhSQymZ3d17MwtTeql16VbQ`; // you need to get a key
            let finalApiURL = `${ApiURL}${encodeURI(params)}`;
            let distanceFetched = '';
            getTimeZone(finalApiURL).then(val => {
                if (val === 'Asia/Calcutta') {
                    val = 'Asia/Kolkata'
                    setTimezone({
                        timezone: val
                    })
                }
                else {
                    setTimezone({
                        timezone: val
                    })
                }
            }).catch((error) => {
                console.log('error while getting time zone', error);
            })
            async function getTimeZone(finalApiURL) {
                let fetchResult = await fetch(finalApiURL);
                let Result = await fetchResult.json();
                return Result.timeZoneId;
            }
        }
        setCurrentAddress('')
        const LocationStr = latitudeValue + "," + longitudeValue;
        let ApiURL = "https://maps.googleapis.com/maps/api/geocode/json?";
        let params = `latlng=${LocationStr}&key=AIzaSyC6sHvN36SBHhSQymZ3d17MwtTeql16VbQ`;
        let finalApiURL = `${ApiURL}${encodeURI(params)}`;
        let value = await getAddressfromIP(finalApiURL).then(val => {
            // console.log('status in fetch save address', val.status,latitudeValue,longitudeValue)
            // console.log('result main', val.results[0].formatted_address)
            // console.log('results 0' ,val.results)
            setTotalAddress(val.results[0].formatted_address)
            if (val.status === 'OK') {
                setloadingL1(false)
                setloadingL(false)
                setLoadingLC(false)
                if (val.results.length !== 0) {
                    let countryCheck = '', totalAddress = val.results[0].formatted_address, city, zipcode, country, stateOrProvince, district, addressLine1;
                    // console.log('total address',totalAddress)
                    val.results[0].address_components.map((item, index) => {
                        if (item.types[0] == "route") {
                            setAddressLine1(item.long_name)
                            addressLine1 = item.long_name
                        }
                        else if (item.types[0] == "neighborhood") {
                            if (addressLine1 == '') {
                                setAddressLine1(item.long_name)
                                addressLine1 = item.long_name
                            }
                            else if (!isNaN(Number(addressLine1))) {
                                // let street_route = addressLine1.concat(' ').concat(item.long_name)
                                // setAddressLine1(prevState => ([...prevState,...street_route]))
                                setAddressLine1(item.long_name)
                                addressLine1 = item.long_name
                            }
                            else {
                                // let street_route = addressLine1.concat(', ').concat(item.long_name)
                                // setAddressLine1(prevState => ([...prevState,...street_route]))
                                setAddressLine1(item.long_name)
                                addressLine1 = item.long_name
                            }
                        }
                        else if (item.types[0] == "political") {
                            if (addressLine1 == '') {
                                setAddressLine1(item.long_name)
                                addressLine1 = item.long_name
                            }
                            else {
                                // let street_route = addressLine1.concat(', ').concat(item.long_name)
                                // setAddressLine1(prevState => ([...prevState,...street_route]))
                                setAddressLine1(item.long_name)
                                addressLine1 = item.long_name
                            }
                        } else if (item.types[0] == "administrative_area_level_3" || item.types[0] == "administrative_area_level_2") {
                            setDistrict(item.long_name)
                            district = item.long_name
                        }
                        else if (item.types[0] == "administrative_area_level_1") {
                            setStateOrProvince(item.long_name)
                            stateOrProvince = item.long_name
                            // console.log("state", item.long_name)
                        }
                        else if (item.types[0] == "country") {
                            countryCheck = item.long_name
                            setCountry_Cust(item.long_name)
                            country = item.long_name
                            // console.log("country", item.long_name)
                        }
                        else if (item.types[0] == "postal_code") {
                            setZipcode(item.long_name)
                            zipcode = item.long_name
                            // console.log("zipcode", item.long_name)
                        }
                        else {
                            console.log("else")
                        }
                    })
                    if (countryCheck.includes('India')) {
                        val.results.map(item1 => {
                            // console.log("city", item1)
                            if (item1.types[0] == "locality") {
                                setCity(item1.address_components[0].long_name)
                                city = item1.address_components[0].long_name
                                // console.log("city", item1.long_name)
                            }
                        })

                    } else {
                        val.results[0].address_components.map((item1, index) => {
                            if (item1.types[0] == "locality") {
                                setCity(item1.long_name)
                                city = item1.long_name
                                // console.log("city", item1.long_name)
                            }
                        })
                    }
                }
            }
        }).catch((error) => {
            console.log(error);
        });

        async function getAddressfromIP(finalApiURL) {
            let fetchResult = await fetch(finalApiURL); // call API
            let results = await fetchResult.json();
            return results;

        }
    }
    //// Fetching current location and same function useEffect

    const currentLocationFunc = async (fromClickCurrent) => {
        setloadingL(true)
      setloadingL1(true)
      if(fromClickCurrent) {
         setCurrentTextClick(true)
         setSaveRecentModal(false)
      } 
      // console.log('curLocationDenied',curLocationDenied)
      if(curLocationDenied){
         // setMapViewModal(false)
         setTotalAddress(state.address.totalAddress)
         // console.log('total address',state.address.totalAddress)
         setloadingL(false)
         setloadingL1(false)
         if(isMobileView){
            setChangeAddressModal(true)
         }
         // console.log('curLocationDenied',curLocationDenied)
         toast.error(
            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
               <text style={{textAlign:'center'}}>Please enable your location from your browser settings</text>
            </div>
            , {
            position: "bottom-center",
            hideProgressBar: true,
            progress: undefined,
            closeButton:false,
            style:{backgroundColor:'#E0D1FF'}
        });
         // toast.error(<text style={{textAlign:'center'}}>Please enable your location from your browser settings</text>, { position: isMobileView ? toast.POSITION.TOP_CENTER : toast.POSITION.BOTTOM_CENTER })
      }else{
         // setAddressUpDated(true)
         if(isMobileView){
            setMapViewModal(true)
         }else{
            setMapViewModalWeb(true)
         }
         let addressDetails= await FetchCurrentLocation(state,dispatch,fromClickCurrent)
         console.log('address',addressDetails)
         if(addressDetails.locationFetch){
            let {addressLine1,city,country,curr_location_latitude,curr_location_longitude,currentLocation,district,stateOrProvince,zipcode,currentAddress}=addressDetails
            let region={
               center: {
                   lat:curr_location_latitude,
                   lng:curr_location_longitude
               },
               zoom: 15
            }
            setDefaultProps(region)
            setLatitude(curr_location_latitude)
            setLongitude(curr_location_longitude)
            if (currentAddress || state.address.currentAddress) {
               setCurrentAddress(currentLocation)
            }
            setTotalAddress(currentLocation)
            setloadingL(false)
            setloadingL1(false)
            setAddressLine1(addressLine1)
            setCity(city)
            setCountry(country)
            setZipcode(zipcode)
            setStateOrProvince(stateOrProvince)
            setDistrict(district)
            // if(!isMobileView){
            //    setAddressUpDated(true)
            //    dispatch({type:'SET_ADDRESS_UPDATED',addressUpdated:!state.addressUpdated})
            // }
         }else{
            if(addressDetails.currentLocationDenied){
               let {addressLine1,city,country,curr_location_latitude,curr_location_longitude,currentLocation,district,stateOrProvince,zipcode,currentAddress}=addressDetails
               let region={
                  center: {
                      lat:curr_location_latitude,
                      lng:curr_location_longitude
                  },
                  zoom: 15
               }
               setDefaultProps(region)
               setLatitude(curr_location_latitude)
               setloadingL(false)
               setTotalAddress(currentLocation)
               setloadingL1(false)
               setAddressLine1(addressLine1)
               setCity(city)
               setCountry(country)
               setZipcode(zipcode)
               setStateOrProvince(stateOrProvince)
               setDistrict(district)
               setLongitude(curr_location_longitude)
               setCurLocationDenied(addressDetails.currentLocationDenied)
               // if (currentAddress || state.address.currentAddress) {
               //    setCurrentAddress(currentLocation)
               // }
            }
         }
      }
        // setloadingL(true)
        // let fromCheckOutCurrent=true
        // let fetchCurrentLocation = await FetchCurrentLocation(dispatch,fromCheckOutCurrent);
        // // console.log('current',fetchCurrentLocation)
        // if (fetchCurrentLocation.locationFetch) {
        //     // navigation.navigate('HomePage')
        //     setloadingL(false)
        //     let region = {
        //         center: {
        //             lat: fetchCurrentLocation.curr_location_latitude,
        //             lng: fetchCurrentLocation.curr_location_longitude
        //          },
        //          zoom: 1
        //     }
        //     // console.log("region",region)
        //         setDefaultProps(region)
        //         setChangeAddressModal(false)
        //         setCmpltAddModal(false)
        //         setMapViewModal(true)
        //         setLatitude(fetchCurrentLocation.curr_location_latitude)
        //         setLongitude(fetchCurrentLocation.curr_location_longitude)
        //         // setCityInValid(false)
        //         setCurrentAddress(fetchCurrentLocation.currentLocation)
        //         setAddressLine1(fetchCurrentLocation.addressLine1)
        //         setCity(fetchCurrentLocation.city)
        //         setCountry_Cust(fetchCurrentLocation.country)
        //         setZipcode(fetchCurrentLocation.zipcode)
        //         setStateOrProvince(fetchCurrentLocation.stateOrProvince)
        //         setDistrict(fetchCurrentLocation.district)
        //         // mapRef.current.animateToRegion(region, 10);
            
        // } else {
        //     toast.warn("Unable to fetch current location. Please try again later", {
        //         position: "bottom-center",
        //         hideProgressBar: true,
        //         progress: undefined,
        //         style:{backgroundColor:'#E0D1FF'}
        //     });
        //     // setloading(false);
        //     setloadingL(false);
        // }
    }
    //// Location updating while changing the location pin in map

    const LocationPinChangingHandler = (event) => {
        setCityInValid(false)
        // console.log("event",event)
        console.log("event",event)
        let region = {
            center: {
                lat: event.lat,
                lng: event.lng
            },
            zoom: 15
        }
        setDefaultProps(region)
        setLatitude(event.lat)
        setLongitude(event.lng)
        fetchAddress(event.lat, event.lng)
        
        // mapRef.current.animateToRegion(region);
    }

    //// fetching address from recent location list click

    const ChoosenRecentLocation = (item) => {
        setErrorAddress(false)
        setErrorMessage('')
        setCityInValid(false)
        // setChangeAddressModal(false)
        // console.log("item",item)
        const coords = item;
        let latitudeValue = coords.latitude ? coords.latitude : Number(coords.CONSUMER_ADDRESS_LAT)
        let longitudeValue = coords.longitude ? coords.longitude : Number(coords.CONSUMER_ADDRESS_LONG)
        let region = {
            center: {
                lat: latitudeValue,
                lng: longitudeValue
            },
            zoom: 15
        }
        setDefaultProps(region)
        setLatitude(latitudeValue)
        setLongitude(longitudeValue)
        fetchAddress(latitudeValue, longitudeValue)
        setCmpltAddModal(false)
        setChangeAddressModal(false)
        setMapViewModalWeb(false)
        setSaveRecentModal(!saveRecentModal)
        let dataToSend={
            merchant_id:services[0].QF_MERCHANT_PRID,
            addLat:latitudeValue,
            addLong:longitudeValue,
            consumer_id:state.user.id,
            merchLat:services[0].MERCHANT_LOCATION_LATITUDE,
            merchLong:services[0].MERCHANT_LOCATION_LONGITUDE,
            countryName:services[0].MERCHANT_PERSONNEL_ADDRESS_COUNTRY
        }
        getDistanceCheck(dataToSend,null)
        // mapRef.current.animateToRegion(region);
    }
    const getDistanceCheck=async(dataToSend,location_Prid)=>{
        let method1 = 'POST';
        let url1 = "/website/getDistance/sv2"
        let getDistanceRes = await customFetchData(method1, dataToSend, url1, state, dispatch)
        console.log('get distance',getDistanceRes)
        if(getDistanceRes.fetch){
            if(getDistanceRes.fetchedData.get){
                if(getDistanceRes.fetchedData.Servicable){
                    setNotServicable(false)
                    setErrorMessage(" ")
                    setErrorAddress(false)
                    setLocationPrid(location_Prid)
                    setShowModalProceedToPay(true)
                }else{
                    if(getDistanceRes.fetchedData.notServicable){
                        setNotServicable(true)
                        setErrorMessage("Your address is not in our service limits")
                        setErrorAddress(true)
                        setLocationPrid(null)
                        setShowModalProceedToPay(false)
                    }
                }
            }
        }
    }
    const SavedAddressesClick=async(item)=>{
        console.log('cvbnm',item,services)
        let dataToSend={
            merchant_id:services[0].QF_MERCHANT_PRID,
            addLat:item.CONSUMER_ADDRESS_LAT,
            addLong:item.CONSUMER_ADDRESS_LONG,
            consumer_id:state.user.id,
            merchLat:services[0].MERCHANT_LOCATION_LATITUDE,
            merchLong:services[0].MERCHANT_LOCATION_LONGITUDE,
            countryName:services[0].MERCHANT_PERSONNEL_ADDRESS_COUNTRY
        }

        setCityInValid(false)
        // setChangeAddressModal(false)
        // console.log("item in save address click",item)
        let latitudeValue = Number(item.CONSUMER_ADDRESS_LAT)
        let longitudeValue = Number(item.CONSUMER_ADDRESS_LONG)
        let region = {
            center: {
                lat: latitudeValue,
                lng: longitudeValue
            },
            zoom: 15
        }
        setDefaultProps(region)
        setLatitude(latitudeValue)
        setLongitude(longitudeValue)
        setTotalAddress(item.CONSUMER_ADDRESS)
        setZipcode(item.CONSUMER_ADDRESS_ZIPCODE)
        setCountry_Cust(item.CONSUMER_ADDRESS_COUNTRY)
        setCity(item.CONSUMER_ADDRESS_CITY)
        setDistrict(item.CONSUMER_ADDRESS_DISTRICT)
        setStateOrProvince(item.CONSUMER_ADDRESS_STATE)
        setAddressLine1(item.CONSUMER_ADDRESS_LINE1)
        setAllValues({
            ...allValues,
            cmptAddress:item.CONSUMER_ADDRESS_LINE2,
            floor:item.CONSUMER_ADDRESS_LINE3,
            landmark:item.CONSUMER_ADDRESS_LINE4
        })
        getDistanceCheck(dataToSend,item.QF_CONSUMER_LOCATION_PRID)
        setSelectedTag(item.CONSUMER_ADDRESS_SELECTED_TAG)
        placesArray.map(item1=>{
            if(item1.place === item.CONSUMER_ADDRESS_SELECTED_TAG){
            // console.log('items yes',item.id === data.id ,item.id,data.id)
                return setClickedValue({
                    ['clickedValue_'+ item1.id]:true   
                })
            }
        })
        setSaveRecentModal(!saveRecentModal)
        setMapViewModalWeb(false)
        setCmpltAddModal(false)
        setChangeAddressModal(false)
    }
    const EditNewAddressModalOpen = () =>{
        setAllValues({
            ...allValues,
            cmptAddress: '',
            floor: '',
            landmark: '',
            status: '',
            message: '',
            addError: '',
            floorError: false,
            landError: false
        })
        setEditAddress({})
        setEditNewAddressModal(true)
        setChangeAddressModal(false)
        setCmpltAddModal(false)
    }
    const EditNewAddressModalCloseHandler = () =>{
        if(isMobileView){
            setMapViewModal(true)
        }else{
            setMapViewModalWeb(true)
        }
        setAllValues({
            ...allValues,
            cmptAddress: '',
            floor: '',
            landmark: '',
            status: '',
            message: '',
            addError: '',
            floorError: false,
            landError: false
        })
        setEditAddress({})
        placesArray.map((item, index) => {
            if (item.id === 1) {
                // console.log('place array1',item)
                return clickedValue['clickedValue_' + item.id] = true

            } else {
                // console.log('place array2',item)
                return clickedValue['clickedValue_' + item.id] = false
                // console.log('placse array',clickedValue)
            }
        })
        setEditNewAddressModal(false)
        // setChangeAddressModal(true)
        // setCmpltAddModal(false)
    }
    const mapViewCloseModal=()=>{
        setChangeAddressModal(true)
        setEditNewAddressModal(false)
        setMapViewModal(false)
        setSearchText('')
        setData('')
        setLatitude(state.address.latitude)
        setLongitude(state.address.longitude)
        // setCityInValid(false)
        setTotalAddress(state.address.totalAddress)
        setAddressLine1(state.address.addressLine1)
        setCity(state.address.city)
        setCountry(state.address.country)
        setZipcode(state.address.zipcode)
        setStateOrProvince(state.address.stateOrProvince)
        setDistrict(state.address.district)
        setHidingRecentSavedLocs(true)
        setAllValues({...allValues})
        setSelectedTag(selectedTag)
        placesArray.map(item1=>{
            if(item1.place === selectedTag){
            // console.log('items yes',item.id === data.id ,item.id,data.id)
                return setClickedValue({
                    ['clickedValue_'+ item1.id]:true   
                })
            }
        })
        if(state.address.currentAddress){
            setCurrentAddress(state.address.totalAddress)
        }else{
            setCurrentAddress('')
        }
    }
    const handleChangeMobile = (address) =>{
        setSearchText(address)
        setTotalAddress(address)
        if (address.length > 0) {
            setHidingRecentSavedLocs(false)
        } else {
            if (address.length === 0) {
                setHidingRecentSavedLocs(true)
            }
        }
     }
     const handleSelectMobile = (address) =>{
        // console.log('address',address)
        setMapViewModal(true)
        geocodeByAddress(address)
          .then(results => {
           //   setSearchText(results[0])
           getLatLng(results[0])
             .then(latlng =>{
                 // console.log('Successfully got latitude and longitude', latlng)
                 setLatitude(latlng.lat)
                 setLongitude(latlng.lng)
                 if(latlng.lat!==null && latlng.lng!==null){
                    setSearchText(results[0].formatted_address)
                    let region = {
                       center: {
                          lat:latlng.lat,
                          lng:latlng.lng
                       },
                       zoom: 15
                    }
                    // mapRef.current.animateToRegion(region);
                    setDefaultProps(region)
                   fetchAddress(latlng.lat,latlng.lng)
                 //   saveLocationsInLocal(results[0].formatted_address,latlng.lat,latlng.lng)
                 }
              }).then(latLng => console.log('Success', latLng))
              .catch(error => console.error('Error', error)); 
           })
     }
     const saveAddressModalOpen = (item) =>{
        setSelectedTag(item.CONSUMER_ADDRESS_SELECTED_TAG)
        placesArray.map(item1=>{
            if(item1.place === item.CONSUMER_ADDRESS_SELECTED_TAG){
            // console.log('items yes',item.id === data.id ,item.id,data.id)
                return setClickedValue({
                    ['clickedValue_'+ item1.id]:true   
                })
            }
        })
        setAllValues({
            ...allValues,
            cmptAddress:item.CONSUMER_ADDRESS_LINE2,
            floor:item.CONSUMER_ADDRESS_LINE3,
            landmark:item.CONSUMER_ADDRESS_LINE4
        })
        setEditAddress(item)
        setSaveAddressModal(true)
        setStateOrProvince(item.CONSUMER_ADDRESS_STATE)
        setZipcode(item.CONSUMER_ADDRESS_ZIPCODE)
        setDistrict(item.CONSUMER_ADDRESS_DISTRICT)
        setCity(item.CONSUMER_ADDRESS_CITY)
        setCountry(item.CONSUMER_ADDRESS_COUNTRY)
        setAddressLine1(item.CONSUMER_ADDRESS_LINE1)
        setLatitude(Number(item.CONSUMER_ADDRESS_LAT))
        setLongitude(Number(item.CONSUMER_ADDRESS_LONG))
        setTotalAddress(item.CONSUMER_ADDRESS)
     }
     const saveAddressModalClose = () =>{
        setSaveAddressModal(false)
        setAllValues({
           cmptAddress: '',
           floor: '',
           landmark: '',
           status: '',
           message: '',
           addError: false,
           floorError: false,
           landError: false
        })
        setEditAddress({})
        placesArray.map((item, index) => {
           if (item.id === 1) {
               // console.log('place array1',item)
               return clickedValue['clickedValue_' + item.id] = true
     
           } else {
               // console.log('place array2',item)
               return clickedValue['clickedValue_' + item.id] = false
               // console.log('placse array',clickedValue)
           }
       })
       setStateOrProvince(state.address.stateOrProvince)
       setZipcode(state.address.zipcode)
       setDistrict(state.address.district)
       setCity(state.address.city)
       setCountry(state.address.country)
       setAddressLine1(state.address.addressLine1)
       setLatitude(state.address.latitude)
        setLongitude(state.address.longitude)
     }
     const MapViewModalCloseWeb=()=>{
        setMapViewModalWeb(false)
        setSaveRecentModal(false)
        console.log(state.address.currentAddress)
        if(state.address.currentAddress){
           setCurrentAddress(state.address.totalAddress)
        }else{
           setCurrentAddress('')
        }
        setTotalAddress(state.address.totalAddress)
        setStateOrProvince(state.address.stateOrProvince)
        setZipcode(state.address.zipcode)
        setDistrict(state.address.district)
        setCity(state.address.city)
        setCountry(state.address.country)
        setAddressLine1(state.address.addressLine1)
        setLatitude(state.address.latitude)
        setLongitude(state.address.longitude)
     }
     let recentSearchesDisplay;
if(recentSearchesArray.length!==0){
   recentSearchesDisplay=recentSearchesArray.map((item)=>{
      // console.log(item)
      return (
         <div style={{cursor:'pointer',flexDirection:'row',display:'flex',alignItems:'center',paddingTop:8,paddingBottom:8}} onClick={()=>ChoosenRecentLocation(item)}>
            <img src={RecentLocIcon} style={{width:18,height:18}}/>
            <text className='suggestion_Text' style={{paddingLeft:5}}>{isMobileView ? (item.address.length > 38 ? item.address.substr(0,38) + "..." : item.address) : (item.address.length > 35 ? item.address.substr(0,35) + "..." : item.address)  }</text>
         </div>
      )
   })
}
let savedAddressesDisplay;
if(fetchedDataResponse.length!==0){
   savedAddressesDisplay=fetchedDataResponse.map((item)=>{
      // console.log(item)
      return (
         <div style={{cursor:'pointer',flexDirection:'row',display:'flex',alignItems:'center',position:'relative',paddingTop:8,paddingBottom:8}}>
            <MdOutlineEdit style={{position:'absolute',right:5,top:14,cursor:'pointer'}} size={14} color="#747474" onClick={()=>saveAddressModalOpen(item)}/>
            <div onClick={()=>SavedAddressesClick(item)}>
               <img src={GreyLocIcon} style={{width:18,height:18}}/>
               <text className='suggestion_Text' style={{paddingLeft:5}}>{isMobileView ? (item.CONSUMER_ADDRESS.length > 38 ? item.CONSUMER_ADDRESS.substr(0,38) + "..." : item.CONSUMER_ADDRESS) : (item.CONSUMER_ADDRESS.length > 35 ? item.CONSUMER_ADDRESS.substr(0,35) + "..." : item.CONSUMER_ADDRESS)  }</text>
            </div>
         </div>
      )
   })
}
let textStyle={
    width:'100%',
    opacity: 0.4,
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left', 
    color:'#8c52ff',
 }
 const handleChange = address => {
    // searchAddRef.current.blur();
    setTotalAddress(address)
    if(address.length>2){
       setSaveRecentModal(false)
    }
 };
const handleSelect = async(address) => {
    // console.log('address',address)
    searchAddRef.current.blur();
    setMapViewModalWeb(true)
    // setAddressUpDated(true)
    setSaveRecentModal(false)
    geocodeByAddress(address)
      .then(results => {
       //   setSearchText(results[0])
       getLatLng(results[0])
         .then(latlng =>{
             // console.log('Successfully got latitude and longitude', latlng)
             setLatitude(latlng.lat)
             setLongitude(latlng.lng)
             if(latlng.lat!==null && latlng.lng!==null){
                let region = {
                   center: {
                      lat:latlng.lat,
                      lng:latlng.lng
                   },
                   zoom: 15
                }
                // mapRef.current.animateToRegion(region);
                setDefaultProps(region)
               fetchAddress(latlng.lat,latlng.lng)
             //   saveLocationsInLocal(results[0].formatted_address,latlng.lat,latlng.lng)
             }
          }).then(latLng => console.log('Success', latLng))
          .catch(error => console.error('Error', error)); 
       })
};
const saveRecentModalOpenHandler = () =>{
    setSaveRecentModal(!saveRecentModal)
    if(saveRecentModal){
        setTotalAddress(totalAddress || state.address.totalAddress)
     }else{
        setTotalAddress('')
     }
    //  setShowSearchDropDown(false)
}
// console.log('total',state.address,totalAddress,stateOrProvince,city,latitude)
    let addressFieldsContent=(
        <div>
            <div style={{ margin: '10px 16px 20px 16px' }}>
                <div>
                    <text className="cmpltText">Enter complete address</text>
                </div>
                <div className="horizontalLine" style={{ marginTop: 15 }}></div>
                {/* <div style={{ marginTop: 15, display:'flex', flexDirection:'row', justifyContent:'flex-start', alignItems:'start'}}>
                <img src={LocationIcon} width={24} height={24}/>
                <text className="addValueText">{city || zipcode || stateOrProvince ?  city + (city ? ", " :'') + (zipcode ? zipcode : '') + (zipcode ? ", " :'') + stateOrProvince : "address line 2, city, pin, state " }</text>
                </div> */}
                <div style={{ marginTop: 15, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={LocationIcon} style={{ width: 24, height: 24 }} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
                            <text className='addressLine1_TextHome' style={{ fontSize: 14 }}>{isMobileView ? (totalAddress ? (totalAddress.length > 28  ? totalAddress.substr(0,28) + '...' : totalAddress) : '') : ( cmpltAddModal ? (totalAddress ? (totalAddress.length > 30  ? totalAddress.substr(0,30) + '...' : totalAddress) :''):(totalAddress ? (totalAddress.length > 35  ? totalAddress.substr(0,35) + '...' : totalAddress) :''))}</text>
                            <text className='subText_Blw_AddressLine1'>
                                {
                                    (stateOrProvince ? stateOrProvince : '') + (stateOrProvince ? ", " : "") + (country ? country : '') + (country ? ", " : "") + (zipcode ? zipcode : '')
                                }
                            </text>
                        </div>
                    </div>
                    <div>
                        {
                            cmpltAddModal &&
                            <div className="changeBtn" onClick={isMobileView ? ()=>changeAddressModalOpenHandler() : ()=>openAddressChangeWeb()}>
                                <text className="changeBtnText">Change</text>
                            </div>
                        }
                    </div>
                </div>

                <div>
                    <div style={{ marginTop: 15 }}>
                        {
                            allValues.status === 'error' ?
                                <div>
                                    <div className="errorBox" style={{ justifyContent: "center", alignItems: "center", paddingVertical: 10 }}>
                                        <text className="errorText">{allValues.message}</text>
                                    </div>
                                </div>
                                : null
                        }
                    </div>
                    <div style={{ marginTop: 5 }}>
                        <InputComponent
                            inputWidthStyle={'100%'}
                            InputFields={{
                                type: "text",
                                // placeholderTextColor: '#8c52ff',
                                onChange: CompleteAddressHandler,
                                value: allValues.cmptAddress,
                                placeholder: "Complete Address",
                                // onKeyDown: () => onCompleteAddressSubmit(),
                                blurOnSubmit: false,
                                error: allValues.addError,
                                className: 'my-inputPlaceHolder'
                                // returnKeyType: "next"
                            }}
                            textStyle={textStyle}
                        />
                    </div>
                    <div style={{ marginTop: 15 }}>
                        <InputComponent
                            inputWidthStyle={'100%'}
                            InputFields={{
                                type: "text",
                                // placeholderTextColor: '#9d9d9d',
                                onChange: FloorHandler,
                                value: allValues.floor,
                                placeholder: "Floor (Optional)",
                                innerRef: input_Ref1,
                                // onKeyDown: () => onFloorSubmit(),
                                blurOnSubmit: false,
                                error: allValues.floorError,
                                className: 'my-inputPlaceHolder',
                                // onSubmitEditing:() => onFloorSubmit()
                                // returnKeyType: "next"
                            }}
                            textStyle={textStyle}
                        />
                    </div>
                    <div style={{ marginTop: 15 }}>
                        <InputComponent
                            inputWidthStyle={'100%'}
                            InputFields={{
                                type: "text",
                                // placeholderTextColor: '#9d9d9d',
                                onChange: LandmarkHandler,
                                value: allValues.landmark,
                                placeholder: "Nearby landmark (Optional)",
                                //   keyboardType="default"
                                // returnKeyType: "done",
                                // onKeyDown: () => onLandmarkSubmit(),
                                blurOnSubmit: false,
                                innerRef: input_Ref2,
                                error: allValues.landError,
                                className: 'my-inputPlaceHolder',
                                // onSubmitEditing:() => onLandmarkSubmit()
                            }}
                            textStyle={textStyle}
                        />
                    </div>
                    <div style={{ marginTop: 15 }}>
                        <text className="addValueText">Save address as*</text>
                    </div>
                    <div style={{ marginTop: 15, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        {
                            placesArray.map((item, index) => {
                                return (
                                    <div style={{ cursor: 'pointer' }} onClick={() => onPressSmallCard(item)}>
                                        <div className={clickedValue['clickedValue_' + item.id] ? "smallCardSelectedStyle" : "smallCardStyle"}>
                                            <text className="smallCardText" style={{ color: clickedValue['clickedValue_' + item.id] ? "#8c52ff" : '#9f9f9f' }}>{item.place}</text>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div style={{ marginTop: 25 }}>
                        <Button className='saveAdd_Btn_Home' onClick={() => onPressSaveAddress()} disabled={buttonDisabled2 ? true : false}>
                            <text className='saveAdd_Confm_Text'>
                                Save Address
                            </text>
                        </Button>
                        {/* <SubmitButtonSolid buttonTitle="Save Address" 
                        onClick={onPressSaveAddress}
                        buttonColor={"#8c52ff"}
                        buttonTextColor={"#fff"}
                    /> */}
                    </div>
                </div>
            </div>
        </div>
    )
    let backArrowBg={
        position:'absolute',
        top:30,
        left:20,
        width: 36,
        height: 36,
        zIndex:2000,
        backgroundColor: "#ffffff",
        shadowColor: "rgba(103, 52, 204, 0.1)",
        shadowOffset: {
          width: 0,
          height: 2
        },
        shadowRadius: 4,
        shadowOpacity: 1,
        borderRadius:20,
        justifyContent: 'center',
        alignItems: 'center',
        display:'flex'
    }
    const defaultMapOptions = {
        fullscreenControl: false,
        zoomControl:false
    };
    let errorBox={
        borderRadius: 4.2,
       backgroundColor: "#ffe4c5",
       shadowColor: "rgba(0, 0, 0, 0.04)",
       shadowOffset: {
         width: 0,
         height: 0
       },
       shadowRadius: 31,
       shadowOpacity: 1,
       display:"flex",
       justifyContent: "center",
       alignItems: "center", 
       paddingTop: 10,
       paddingBottom: 10, 
       marginLeft: -28, 
       marginRight: -28,
       marginBottom: 10 
    }
    let errorBoxWeb={
        borderRadius: 4.2,
       backgroundColor: "#ffe4c5",
       shadowColor: "rgba(0, 0, 0, 0.04)",
       shadowOffset: {
         width: 0,
         height: 0
       },
       shadowRadius: 31,
       shadowOpacity: 1,
       display:"flex",
       justifyContent: "center",
       alignItems: "center", 
       position:'absolute',
       top:-20,
       left:0,
       right:0,
       paddingTop:10,
       paddingBottom:10
    }
    let backArrowBgWeb={
       width: 48,
       height: 48,
       backgroundColor: "#ffffff",
       shadowColor: "rgba(103, 52, 204, 0.1)",
       shadowOffset: {
         width: 0,
         height: 2
       },
       boxShadow: '0 2px 4px 0 rgba(103, 52, 204, 0.1)',
       backgroundColor: '#fff',
       shadowRadius: 4,
       shadowOpacity: 1 ,
       borderRadius:30,
       display:'flex',
       justifyContent:'center',
       alignItems:'center',
       position:'absolute',
       left:25,
       top:25,
       zIndex:100
     }
     let currLocBgWeb={
        width: 48,
        height: 48,
        backgroundColor: "#ffffff",
        shadowColor: "rgba(103, 52, 204, 0.1)",
        shadowOffset: {
          width: 0,
          height: 2
        },
        boxShadow: '0 2px 4px 0 rgba(103, 52, 204, 0.1)',
        backgroundColor: '#fff',
        shadowRadius: 4,
        shadowOpacity: 1 ,
        borderRadius:30,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        position:'absolute',
        right:25,
        top:25,
        zIndex:100
      }
      let saveRecentModalStyle={
        position:'absolute',
        width:'100%',
        zIndex:2000,
        borderRadius:10,
        padding:'10px 10px 20px 10px',
        backgroundColor: '#fff',
        top:55,
        overflow: 'hidden',
        height: recentSearchesArray.length !== 0 && fetchedDataResponse.length !== 0 ? 250 : (recentSearchesArray.length !== 0 || fetchedDataResponse.length !== 0 ? 240 : 100)
    }
    let addressSearchMapView=(
        <Modal isOpen={mapViewModal} size={'xl'} fullscreen={'xl'}>
           {/* <div style={{padding:'20px 16px 20px 16px',display:'flex',flexDirection:'row',alignItems:'center'}}>
              <img src={arrowBack} style={{transform: 'rotate(-90deg)',width:20,height:20}} onClick={()=>MapViewModalClose()}/>
              <text className='nearByProMapText' style={{marginLeft:15,color:'#8c52ff'}}>Choose location</text>
           </div> */}
           <div style={backArrowBg}>
              <img src={arrowBack} style={{transform: 'rotate(-90deg)',width:20,height:20}} onClick={()=>mapViewCloseModal()}/>
           </div>
           <div style={{ height: '100%', width: '100%',borderRadius:30}}>
              <GoogleMapReact
                 bootstrapURLKeys={{ key: "AIzaSyC6sHvN36SBHhSQymZ3d17MwtTeql16VbQ" }}
                 center={defaultProps.center}
                 zoom={defaultProps.zoom}
                 options={defaultMapOptions}
                 ref={mapRef}
                 // yesIWantToUseGoogleMapApiInternals={true}
                 onClick={LocationPinChangingHandler}
              >
                 <div lat={defaultProps.center.lat} lng={defaultProps.center.lng}>
                    <IoLocationSharp size={40} color="red"/>
                 </div>          
              </GoogleMapReact>
           </div>
           <div style={{ position: 'absolute',zIndex: 100,height:160,bottom:0,left:0,right:0,boxShadow: '0 4px 50px 0 rgba(0, 0, 0, 0.2)',backgroundColor: '#fff',borderTopLeftRadius:15,borderTopRightRadius:15,padding:'15px 10px 15px 10px'}}>
              {
                 loadingL1 ?
                 <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',padding:50}}>
                    <ClipLoader widthUnit={"%"} size={20} loadings={loadingL1} color="#8c52ff" />
                 </div>
                 :
                 <>
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:10,marginBottom:20}}>
                       <div style={{display:'flex',flexDirection:'row'}}>
                          <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                             <img src={LocationIcon} style={{width:24,height:24}}/>
                          </div>
                          <div style={{display:'flex',flexDirection:'column',marginLeft:10}}>
                          <text className='addressLine1_TextHome'>{totalAddress ? (totalAddress.length > 28 ? totalAddress.substr(0,28) + '...' : totalAddress) : ''}</text>
                          <text className='subText_Blw_AddressLine1'>
                             {
                                (stateOrProvince ? stateOrProvince : '') + (stateOrProvince ? ", " : "") + (country ? country :'') + (country ? ", " : "") + (zipcode ? zipcode : '') 
                             }
                          </text>
                          </div>
                       </div>
                       {
                       state.token && (addressLine1 && city && country_Cust && stateOrProvince && zipcode) ?
                       <div style={{display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>EditNewAddressModalOpen()}>
                          <MdOutlineEdit size={20} color="#8c52ff"/>
                       </div> : null
                       }
                    </div>
                    <div style={{marginTop:30}}>   
                       <Button className="saveAdd_Btn_Home" onClick={()=>onPressSaveAddress()} buttonDisabled={buttonDisabled2 ? true : false}>
                          <text className='saveAdd_Confm_Text'>Confirm Location</text>
                       </Button>
                    </div>
                 </>
              }
           </div>
           <div className='currentLocationIcon_View' onClick={()=>currentLocationFunc(true)} style={{cursor:'pointer'}}>
              <img src={CurrentLocationIconRed}/>
           </div>
        </Modal>
     )
     let addressSearchMapViewWeb=(
        <div  style={{height:'80vh',position:'relative',borderRadius:15}}>
           <div style={backArrowBgWeb}>
              <img src={arrowBack} style={{ transform: 'rotate(-90deg)', width: 20, height: 20 }} onClick={() => MapViewModalCloseWeb()} />
           </div>
           <div style={currLocBgWeb}>
              <img src={CurrentLocationIconRed} style={{width:30,height:30,cursor:'pointer'}} onClick={()=>currentLocationFunc(true)}/>
           </div>
             <div style={{position:'absolute',left:90,right:100,top:25 }}>
                 <div style={{ position: 'relative', zIndex: 100 }}>
                     <PlacesAutocomplete
                         value={totalAddress}
                         onChange={handleChange}
                         onSelect={handleSelect}>
                         {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                             <div>
                                 <Input

                                    style={{ paddingLeft: 50, width: '100%' }}
                                    value={totalAddress}
                                    onClick={()=>saveRecentModalOpenHandler()}
                                    innerRef={searchAddRef}                                     
                                    {...getInputProps({
                                         // autoFocus:true,
                                         placeholder: 'Search any address',
                                         className: 'location-search-input-sv2-mobile shadow-none'
                                     })}
                                 />
                                 {/* {console.log('suggestion', suggestions)} */}
                                 <img src={searchIcon} style={{ position: 'absolute', left: 10, bottom: 11 }}  onClick={()=>saveRecentModalOpenHandler()}/>
                                 <Card style={{
                                     border: suggestions.length !== 0 ? '2px solid #fcfcfc' : 0,
                                     height: suggestions.length !== 0 ? 200 : 0,
                                     overflow: suggestions.length !== 0 ? 'scroll' : 0,
                                     top: suggestions.length !== 0 ? 55 : 0,
                                     bottom: 0,
                                     position: 'absolute',
                                     zIndex: 2000,
                                     paddingRight: 10,
                                     paddingLeft: 10,
                                     width: '100%'
                                 }}
                                    >
                                     {/* {loading && <div>Loading...</div>} */}
                                     <div style={{ marginTop: 10, marginBottom: 10 }}>
                                         {suggestions.map(suggestion => {
                                             const className = suggestion.active
                                                 ? 'suggestion-item--active'
                                                 : 'suggestion-item';
                                             // inline style for demonstration purpose
                                             const style = suggestion.active
                                                 ? { backgroundColor: '#c5c5c5', cursor: 'pointer' }
                                                 : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                             return (
                                                 <div
                                                     {...getSuggestionItemProps(suggestion, {
                                                         className,
                                                         style: { display: 'flex', flexDirection: 'row', paddingTop: 5, paddingBottom: 5 }
                                                     })}
                                                 >
                                                     {/* {this.state.showDropdown === true ? <div>{suggestion.description}</div> : null}  */}
                                                     {/* {console.log('data',suggestion.description)} */}
                                                     {/* <text className='suggestion_Text' style={{ cursor: 'pointer' }}>{suggestion.description}</text> */}
                                                     <img src={GreyLocIcon} style={{ width: 20, height: 20 }} />
                                                     <text className='suggestion_Text' style={{ paddingLeft: 10, cursor: 'pointer' }}>{suggestion.description.length > 45 ? suggestion.description.substr(0, 45) + '...' : suggestion.description}</text>
                                                 </div>
                                             );
                                         })}
                                     </div>
                                 </Card>
                                 {
                                     saveRecentModal ?
                                         <Card style={saveRecentModalStyle}>
                                             {/* */}
                                             <div style={{ overflow: 'scroll', marginTop: 5, marginBottom: 5 }}>

                                                 {
                                                     recentSearchesArray.length !== 0 ?
                                                         <>
                                                             <div style={{ marginBottom: 5 }}>
                                                                 <text className='recentLocation_Text'>Recent Locations</text>
                                                             </div>
                                                             <div style={{ marginTop: 5, overflow: 'scroll' }}>
                                                                 {recentSearchesDisplay}
                                                             </div>
                                                         </>
                                                         : null
                                                 }
                                                 {
                                                     fetchedDataResponse.length !== 0 ?
                                                         <>
                                                             <div style={{ paddingBottom: 5 }}>
                                                                 <text className='recentLocation_Text'>Saved Locations</text>
                                                             </div>
                                                             <div style={{ marginTop: 5, overflow: 'scroll' }}>
                                                                 {savedAddressesDisplay}
                                                             </div>
                                                         </>
                                                         : null
                                                 }
                                             </div>
                                         </Card>
                                         : null
                                 }
                                 {/* <div style={{
                                     border: suggestions.length !== 0 ? '2px solid #fcfcfc' : 0,
                                     height: suggestions.length !== 0 ? 250 : 0,
                                     overflow: suggestions.length !== 0 ? 'scroll' : 0,
                                     top: suggestions.length !== 0 ? 150 : 0,
                                     bottom: 0,
                                     position: 'absolute',
                                     zIndex: 2000,
                                     width: '100%',
                                     border: 'none'
                                 }}
                                 >
                                     {suggestions.map(suggestion => {
                                         const className = suggestion.active
                                             ? 'suggestion-item--active'
                                             : 'suggestion-item';
                                         // inline style for demonstration purpose
                                         const style = suggestion.active
                                             ? { backgroundColor: '#c5c5c5', cursor: 'pointer' }
                                             : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                         return (
                                             <div
                                                 {...getSuggestionItemProps(suggestion, {
                                                     className,
                                                     style: { display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: 8, paddingBottom: 8 }
                                                 })}
                                             >
                                                 
                                                 <img src={GreyLocIcon} style={{ width: 20, height: 20 }} />
                                                 <text className='suggestion_Text' style={{ paddingLeft: 10, cursor: 'pointer' }}>{suggestion.description.length > 45 ? suggestion.description.substr(0, 45) + '...' : suggestion.description}</text>
                                             </div>
                                         );
                                     })}
                                 </div> */}


                             </div>
                         )}
                     </PlacesAutocomplete>
                 </div>

             </div>
           <div style={{ height: '100%', width: '100%',borderRadius:15}}>
              <GoogleMapReact
                 bootstrapURLKeys={{ key: "AIzaSyC6sHvN36SBHhSQymZ3d17MwtTeql16VbQ" }}
                 center={defaultProps.center}
                 zoom={defaultProps.zoom}
                 options={defaultMapOptions}
                 ref={mapRef}
                 // yesIWantToUseGoogleMapApiInternals={true}
                 onClick={LocationPinChangingHandler}
              >
                 <div lat={defaultProps.center.lat} lng={defaultProps.center.lng}>
                    <IoLocationSharp size={40} color="red" />
                 </div>
              </GoogleMapReact>
           </div>
           <div style={{ position: 'absolute', zIndex: 100, height: 160, bottom: 20, left: '20%', right: '20%', boxShadow: '0 5px 17px 0 rgba(0, 0, 0, 0.25)', backgroundColor: '#fff', borderRadius: 15, padding: '20px 15px 20px 15px' }}>
              {
                 loadingL1 ?
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: 50 }}>
                       <ClipLoader widthUnit={"%"} size={20} loadings={loadingL1} color="#8c52ff" />
                    </div>
                    :
                    <>
                       <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10, marginBottom: 20 }}>
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                             <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={LocationIcon} style={{ width: 24, height: 24 }} />
                             </div>
                             <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
                                <text className='addressLine1_TextHome'>{totalAddress ? (totalAddress.length > 26 ? totalAddress.substr(0,26) + '...' : totalAddress) : (state.address.totalAddress ? (state.address.totalAddress.length > 26 ? state.address.totalAddress.substr(0,26) + '...' : state.address.totalAddress):'')}</text>
                                <text className='subText_Blw_AddressLine1'>
                                   {
                                      (stateOrProvince ? stateOrProvince : '') + (stateOrProvince ? ", " : "") + (country ?  country : '') + (country ? ", " : "") + (zipcode ? zipcode : '')
                                   }
                                </text>
                             </div>
                          </div>
                          {
                             state.token && (addressLine1 && city && country_Cust && stateOrProvince && zipcode) ?
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',cursor:'pointer' }} onClick={() => EditNewAddressModalOpen()}>
                                   <MdOutlineEdit size={20} color="#8c52ff" />
                                </div> : null
                          }
                       </div>
                       <div style={{ marginTop: 20 }}>
                          <Button className="saveAdd_Btn_Home" onClick={()=>onPressSaveAddress()} buttonDisabled={buttonDisabled2 ? true : false}>
                             <text className='saveAdd_Confm_Text'>Confirm Location</text>
                          </Button>
                       </div>
                    </>
              }
           </div>
           {/* <div className='currentLocationIcon_View' onClick={() => currentLocationFunc(true)}>
              <img src={CurrentLocationIconRed} />
           </div> */}
        </div>
     )
     const openAddressChangeWeb = () =>{
        setMapViewModalWeb(true)
        let region = {
            center: {
               lat:latitude,
               lng:longitude
            },
            zoom: 15
         }
         // mapRef.current.animateToRegion(region);
         setDefaultProps(region)
     }
    return (
        <Container fluid className="mainContainer">
            <div className="HeaderDivStyle">
                <text className="HeaderText">
                    Checkout Details
                </text>
                <hr className="HearderSeparator" />
            </div>

            {/* progress bar */}
            {/* <div>
                <Col sm={{size:6}}>

                    <div className="progressCircleDiv">
                        <div className="progressCircle">
                            <div className="progressCircleSmall"></div>
                        </div>

                        <div className="progressLine" style={{backgroundColor: '#a679ff'}}></div>

                        <div className="progressCircle">
                            <div className="progressCircleSmall"></div>
                        </div>

                        <div className="progressLine" style={{backgroundColor: '#d9d9d9'}}></div>

                        <div className="progressCircle">
                            <div className="progressCircleSmall"></div>
                        </div>
                    </div>

                    <div className="progressCircleDivText">
                        <div>
                            <text className="ProcessBartextsStyle">Account</text>
                        </div>


                        <div className="progressLineText"></div>
                        <div style={{width:'auto'}}>
                            <text className="ProcessBartextsStyle">Address Confirmation</text>
                        </div>

                        <div className="progressLineText"></div>

                        <div>
                            <text className="ProcessBartextsStyle">Payment</text>
                        </div>
                    </div>

                </Col>
            </div> */}

            {state.cart.length !== 0 ?
                <div style={{display:'flex', flexDirection:'row', justifyContent:'left'}}>

                    {/* login and address card */}

                    <Col className="col_main_div_left">
                        {
                        !state.token ?
                            <div className="loginRegCardDivLeft" >
                                <div style={{textAlign:'center'}}>
                                    <text className="loginCardText_style">If you're ready to book the service, let's get started!</text><br/>
                                    <text className="loginCardText_style" style={{color: '#a679ff'}}><a>Log in to your existing account or register now.</a></text>
                                </div>
                                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                                    <SubmitButtonSmall Radius={10} width={isTab?140:180} buttonColor='#8c52ff' buttonTitle="Register" buttonTextColor='#ffffff' borderColor='#8c52ff' onPress={()=>setModal(!modal)}/>
                                    <SubmitButtonSmall Radius={10} width={isTab?140:180} buttonColor='#ffffff' buttonTitle="Log in" buttonTextColor='#8c52ff' borderColor='#747474'onPress={()=>setModal(!modal)}/>
                                </div>
                            </div>

                        :  null
                        }

                    {/* {
                    proceedToPayCheck?
                        <View style={"buttonDivStyle"}> */}


                        { 
                        state.token ?
                            state.cartCount ? 
                                // services.some(eachItem=>(eachItem.category_name=="Home Care") || (eachItem.category_name=="Tutoring")) && !showModalProceedToPay ?

                                //     <div className="loginRegCardDivLeft_address_update" >  
                                //         <div style={{ margin: isTab? '5px 10px' : '20px 25px' }}>
                                //             <div>
                                //                 <text className="cmpltText">Enter complete address</text>
                                //             </div>
                                //             <div className="horizontalLine" style={{marginTop:15}}></div>

                                //             <div style={{ marginTop: 15, display:'flex', flexDirection:'row', justifyContent:'flex-start', alignItems:'start'}}>
                                //                 <img src={LocationIcon} width={24} height={24}/>
                                //                 <text className="addValueText">{city || zipcode || stateOrProvince ?  city + (city ? ", " :'') + (zipcode ? zipcode : '') + (zipcode ? ", " :'') + stateOrProvince : "address line 2, city, pin, state " }</text>
                                //             </div>
                                //             <div style={{ marginTop: 15 }}>
                                //                 <text className="addValueText">Save address as*</text>
                                //             </div>
                                //             <div style={{ marginTop: 15, display:'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                //                 {
                                //                     placesArray.map((item,index)=>{
                                //                         return (
                                //                             <div style={{cursor:'pointer'}} onClick={() => onPressSmallCard(item)}>
                                //                                 <div className={clickedValue['clickedValue_'+ item.id] ? "smallCardSelectedStyle" : "smallCardStyle"}>
                                //                                     <text className="smallCardText" style={{ color: clickedValue['clickedValue_'+ item.id] ? "#8c52ff" : '#9f9f9f'}}>{item.place}</text>
                                //                                 </div>
                                //                             </div>
                                //                         )
                                //                     })
                                //                 }
                                //             </div>
                                //             <div>
                                //             <div style={{ marginTop: 15 }}>
                                //                 {
                                //                     allValues.status === 'error' ?
                                //                         <div>
                                //                             <div className="errorBox" style={{ justifyContent: "center", alignItems: "center", paddingVertical: 10 }}>
                                //                                 <text className="errorText">{allValues.message}</text>
                                //                             </div>
                                //                         </div>
                                //                         : null
                                //                 }
                                //             </div>
                                //             <div style={{ marginTop: 5 }}>
                                //                 <input
                                //                     placeholderTextColor='#9d9d9d'
                                //                     onChange={(e) => CompleteAddressHandler(e)}
                                //                     value={allValues.cmptAddress}
                                //                     placeholder="Complete Address"
                                //                     // onSubmitEditing={() => onCompleteAddressSubmit()}
                                //                     // blurOnSubmit: false,
                                //                     error={allValues.addError}
                                //                     className="inputFieldStyle"
                                //                 />
                                //             </div>
                                //             <div style={{ marginTop: 15 }}>
                                //                 <input
                                //                     placeholderTextColor= '#9d9d9d'
                                //                     onChange={(e) => FloorHandler(e)}
                                //                     value={allValues.floor}
                                //                     placeholder= "Floor (Optional)"
                                //                     // ref: input_Ref1,
                                //                     onSubmitEditing={() => onFloorSubmit()}
                                //                     // blurOnSubmit: false,
                                //                     error={allValues.floorError}
                                //                     className="inputFieldStyle"
                                //                 />
                                //             </div>
                                //             <div style={{ marginTop: 15 }}>
                                //                 <input
                                //                     className="inputFieldStyle"
                                //                     onChange={(e) => LandmarkHandler(e)}
                                //                     placeholderTextColor='#9d9d9d'
                                //                     value={allValues.landmark}
                                //                     placeholder= "Nearby landmark (Optional)"
                                //                     onSubmitEditing={() => onLandmarkSubmit()}
                                //                     // blurOnSubmit: false,
                                //                     // ref: input_Ref2,
                                //                     error={allValues.landError}
                                //                 />
                                //             </div>
                                //                 <div style={{ marginTop: 25 }}>
                                //                     <SubmitButtonSolid buttonTitle="Save Address" 
                                //                         // onClick={onPressSaveAddress}
                                //                         onClick={()=>openAddressChangeWeb()}
                                //                         buttonColor={buttonDisabled ? "#f7f7f7" : "#8c52ff"}
                                //                         buttonTextColor={buttonDisabled ? "grey" : "#fff"}
                                //                     />
                                //                 </div>
                                //             </div>
                                //         </div>
                                //     </div>

                                // : 
                                // services.some(eachItem=>(eachItem.category_name=="Home Care") || (eachItem.category_name=="Tutoring")) && showModalProceedToPay ?
                                services.some(eachItem=>(eachItem.category_name=="Home Care") || (eachItem.category_name=="Tutoring")) ?
                                
                                    <div className="loginRegCardDivLeftHome" style={{position:'relative'}}>
                                        <div>
                                            {
                                                errorAddress
                                                &&
                                                <div style={errorBoxWeb}>
                                                    <text className={'errorText'}>{errorMessage}</text>
                                                </div>
                                            }
                                            <div style={{ paddingBottom: 5}}>
                                                <text className="AddressLineText">
                                                    Confirm your address
                                                </text>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', paddingBottom: 28 }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                                    <div>
                                                        <img src={LocationIcon} />
                                                    </div>
                                                    <div style={{ marginLeft: 10 }}>
                                                        <text className="AddressLineHeader">{isTab ? (totalAddress ? (totalAddress.length > 25 ? totalAddress.substr(0,25) + '...' : totalAddress):'') : (totalAddress ? (totalAddress.length > 30 ? totalAddress.substr(0,30) + '...' : totalAddress) :'')}</text><br />
                                                        <text className="AddressLineText">{city || zipcode || stateOrProvince ? city + (city ? ", " : '') + (zipcode ? zipcode : '') + (zipcode ? ", " : '') + stateOrProvince : ""}</text>
                                                    </div>
                                                </div>
                                                <div onClick={()=>CmpltAddressModal()} style={{cursor:'pointer'}}>
                                                    <img src={EditLocationIcon} />
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                                            <text className="loginCardText_style">Amount</text><br/>
                                            <text className="proceed_to_Pay_amount">{country == 'IN' ? '\u20B9' : '$'}{totalAmount}</text>
                                        </div>
                                        {proceedToPayCheck && !buttonDisabled1 ?
                                            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}} onClick={() => onPressPay()}>
                                                <SubmitButtonSmall Radius={10} width={'100%'} fontSize={16} buttonColor='#8c52ff' buttonTitle="Proceed to Pay" buttonTextColor='#ffffff' borderColor='#8c52ff'/>
                                            </div>
                                            :
                                            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                                                <SubmitButtonSmall Radius={10} width={'100%'} fontSize={16} buttonColor='#f7f7f7' buttonTitle="Proceed to Pay" buttonTextColor='#b8b8b8' borderColor='#e8e8e8'/>
                                            </div>
                                        }   
                                    </div>

                                :

                                    <div className="loginRegCardDivLeft" >
                                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                                            <text className="loginCardText_style">Amount</text><br/>
                                            <text className="proceed_to_Pay_amount">{country == 'IN' ? '\u20B9' : '$'}{totalAmount}</text>
                                        </div>
                                        {proceedToPayCheck ?
                                            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}} onClick={() => onPressPay()}>
                                                <SubmitButtonSmall Radius={10} width={'100%'} fontSize={16} buttonColor='#8c52ff' buttonTitle="Proceed to Pay" buttonTextColor='#ffffff' borderColor='#8c52ff'/>
                                            </div>
                                            :
                                            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                                                <SubmitButtonSmall Radius={10} width={'100%'} fontSize={16} buttonColor='#f7f7f7' buttonTitle="Proceed to Pay" buttonTextColor='#b8b8b8' borderColor='#e8e8e8'/>
                                            </div>
                                        }   
                                    </div>

                            :null 
                        :null
                        }

                            
                        {/* </div> */}
                    </Col>

                    {/* service checkout details */}

                    <Col>
                        <div className="scrollableColumn">
                        
                            <div className="loginRegCardDiv">

                                {servicesInCart}
                            </div>
                    
                            {/* <div className={[textDivStyle]}>
                                <image source={AddServiceIcon} />
                                <text className={addServiceTextStyle}>Add a new service</text>
                            </div> */}

                            { proceedToPayCheck ?

                            <div className="ApplyButtonDivStyle">
                                <div className="ApplyCouponDivStyle" style={{borderBottomLeftRadius: couponApplied ? 0 : 10, borderBottomRightRadius: couponApplied ? 0 : 10}} onClick={() => setCouponDiscountModal(true)}>
                                {/* <div onClick={() => navigation.navigate('ProfessionalDiscounts', {proPersonelIds,proMerchantIds,serviceIds,services, totalAmount, country, totalCPPrice, totalTSPrice, couponApplied, couponCode, navFrom:'Checkout',userPasswordReg:params.userPasswordReg?params.userPasswordReg:false,userId:params.userId?params.userId:null})}> */}

                                    <div style={{flexDirection:'row'}}>
                                        <img src={ApplyCouponIcon} />
                                        <text className="ApplyCouponTextStyle">Apply Discounts</text>
                                    </div>

                                    <div>
                                        <img src={Navigation_Icon} style={{alignSelf:'flex-end'}}/>
                                    </div>
                                </div>

                                {couponApplied ?
                                    <div style={{paddingTop:4, paddingBottom:7, paddingLeft:48, paddingRight:25, display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                                        <text className="FlatSavedText">Flat {country == 'IN' ? '\u20B9' : '$'}{couponDiscount} Saved</text>
                                        <div onClick={() => onPressCancelCoupon()} style={{cursor:'pointer'}}>
                                            <text className="FlatSavedText" style={{color:'#9b0909'}}>Remove?</text>
                                        </div>
                                    </div> 
                                : null}
                            </div> 

                            : null}

                        {loading ?

                            <div className="textDivStyle1" style={{backgroundColor:'#D8C5FC', marginBottom:10}}>
                                <LoadingSpinner loading={true}/>
                            </div>
                        :

                            <div className="textDivStyle1" style={{backgroundColor:'#D8C5FC', marginBottom:10}}>
                                {/* checkout details */}

                                <div style={{paddingBottom:15.8}}>
                                    <div style={{paddingBottom:16}}>
                                        <text className="CheckoutDetailsHeaderText">Sub Total</text>
                                    </div>

                                    <div style={{display:'flex', flexDirection:'row',justifyContent:'space-between'}}>
                                        <text className="CheckoutDetailsText">
                                            Professional Price
                                        </text>
                                            <text className="CheckoutDetailsPriceText">{country == 'IN' ? '\u20B9' : '$'}{proServicesPrice}</text> 
                                    </div>
                                    {
                                        totalCPPrice != 0 
                                        ?
                                            <div style={{display:'flex', flexDirection:'row',justifyContent:'space-between',marginTop:6}}>
                                                { totalCPPrice > 0 ? <text className="CheckoutDetailsText">Slash Fee</text> : <text className="CheckoutDetailsText">Slash Discount</text> }
                                                <text className="headerTextStyle">
                                                {
                                                    country==='IN'
                                                    ?
                                                    <text className="CheckoutDetailsPriceText">{totalCPPrice > 0 ? "+" : "-"}{'\u20B9'}{totalCPPrice?Math.abs(totalCPPrice).toFixed(2):'0.00'}</text> 
                                                    :
                                                    <text className="CheckoutDetailsPriceText">{totalCPPrice > 0 ? "+" : "-"}${totalCPPrice?Math.abs(totalCPPrice).toFixed(2):'0.00'}</text>
                                                }
                                                </text>
                                            </div>
                                        : 
                                        null
                                    }

                                    {
                                        totalTSPrice != 0
                                        ?
                                        <div style={{display:'flex', flexDirection:'row',justifyContent:'space-between',marginTop:6}}>
                                            {totalTSPrice > 0 ? <text className="CheckoutDetailsText">High Demand Surcharge</text>:<text className="CheckoutDetailsText">Low Demand Discount</text>}
                                            {/* {5 > 0 ? <text className="CheckoutDetailsText">High Demand Surcharge</text>:<text className="CheckoutDetailsText">Low Demand Discount</text>} */}
                                            <text className="headerTextStyle">
                                            {
                                                country==='IN'
                                                ?
                                                <text className="CheckoutDetailsPriceText">{totalTSPrice > 0 ? "+" : "-"}{'\u20B9'}{totalTSPrice?Math.abs(totalTSPrice).toFixed(2):'0.00'}</text> 
                                                :
                                                <text className="CheckoutDetailsPriceText">{totalTSPrice > 0 ? "+" : "-"}${totalTSPrice?Math.abs(totalTSPrice).toFixed(2):'0.00'}</text>
                                            }
                                            </text>
                                        </div>
                                        : 
                                        null
                                    }

                                    <div style={{display:'flex', flexDirection:'row',justifyContent:'space-between',marginTop:6}}>
                                        <text className="CheckoutDetailsText">Your Price</text>
                                        <text className="headerTextStyle">
                                            <text className="CheckoutDetailsPriceText">{country == 'IN' ? '\u20B9' : '$'}{yourServicesPrice}</text> 
                                        </text>
                                    </div>

                                    {couponApplied ?
                                        <div style={{display:'flex', flexDirection:'row',justifyContent:'space-between',marginTop:6}}>
                                            <text className="CheckoutDetailsText">
                                            Coupon Discount

                                            </text>
                                            <text className="headerTextStyle">
                                            <text className="CheckoutDetailsPriceText" >{country == 'IN' ? '\u20B9' : '$'}{numeral(couponDiscount).value().toFixed(2)}</text>

                                            </text>
                                        </div>
                                        : null
                                    }

                                    {
                                        country == 'IN' ?
                                            convenienceFee != 0 ?
                                                <div style={{display:'flex', flexDirection:'row',justifyContent:'space-between',marginTop:6}}>
                                                    <text className="CheckoutDetailsText">
                                                    Convenience Fee
                                                    </text>
                                                    
                                                    <text className="headerTextStyle">
                                                        {/* <text className={{fontWeight:'bold',paddingTop:10}}>{ params.nav==='IN'? '\u20B9':'$'}{this.state.convenienceFee}</text> */}
                                                        <text className="CheckoutDetailsPriceText">{country == 'IN' ? '\u20B9' : '$'}{convenienceFee}</text>
                                                    </text>
                                                </div>
                                            :null
                                        :null
                                    }
                                    
                                {
                                    country == "IN" ?
                                        <div>
                                            <div style={{display:'flex', flexDirection:'row',justifyContent:'space-between',marginTop:6}}>
                                                <text className="CheckoutDetailsText">
                                                    GST
                                                </text>
                                                
                                                <text className="headerTextStyle">
                                                    <text className="CheckoutDetailsPriceText">{country == 'IN' ? '\u20B9' : '$'}{totalGST}</text>
                                                </text>
                                            </div>
                                        </div>
                                    :
                                    null
                                }

                                </div>

                                <div style={{height: 0.5,backgroundColor: "#747474"}} />

                        
                                <div className="whiteBox" style={{paddingTop:15.8}}>
                                    <div style={{display:'flex', flexDirection:'row',justifyContent:'space-between'}}>
                                        <text style={{fontFamily: "Inter",fontSize: 16,fontWeight: "600",fontStyle: "normal",letterSpacing: 0,color: "#4d1d53"}}>Grand Total</text>
                                        <text className="grand_total_price_style">
                                            {country == 'IN' ? '\u20B9' : '$'}{totalAmount}
                                        </text>

                                    </div>
                                </div>

                            </div>
                        }
                            
                            <div onClick={() => setCancellationAllow(true)} className="CancellationDisclaimerContainer">
                                <text className="CancellationHeader">Cancellation Disclaimer</text>
                                <img src={InfoIcon} style={{width:15, height:15, marginLeft:7}}/>
                                <div style={{flexDirection:'row', alignItems:'center', paddingTop:10}}>
                                    <text className="CancellationChargeText">Extra charge may apply</text>
                                </div>
                            </div>

                            {/* Proceed to pay */}
                            {/* {proceedToPayCheck? */}
                                <div className="loginRegCardDivLeft_mobile" style={{height:'auto',paddingTop: 15,paddingBottom:15,position:'fixed',bottom: 0,left:0,right:0,cursor:'pointer'}}>
                                    { state.cartCount ? 
                                    services.some(eachItem=>(eachItem.category_name=="Home Care") || (eachItem.category_name=="Tutoring"))? 
                                    <div>
                                        {
                                            errorAddress
                                            &&
                                            <div style={errorBox}>
                                                <text className={'errorText'}>{errorMessage}</text>
                                            </div>
                                        }
                                        <div style={{paddingBottom:5}}>
                                            <text className="AddressLineText">
                                                Confirm your address
                                            </text>
                                        </div>
                                        <div style={{display:'flex',flexDirection:'row', justifyContent:'space-between', alignItems:'flex-start', paddingBottom:28}}>
                                            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between',alignItems:'center'}}>
                                                <div>
                                                    <img src={LocationIcon} />
                                                </div>
                                                <div style={{marginLeft:10}}>
                                                    <text className="AddressLineHeader">{totalAddress ? (totalAddress.length > 25 ? totalAddress.substr(0,25) + '...' : totalAddress):''}</text><br/>
                                                    {/* <text className="AddressLineText">{city || zipcode || stateOrProvince ?  city + (city ? ", " :'') + (zipcode ? zipcode : '') + (zipcode ? ", " :'') + stateOrProvince : "address line 2, city, pin, state " }</text> */}
                                                    <text className="AddressLineText">{city || zipcode || stateOrProvince ?  city + (city ? ", " :'') + (zipcode ? zipcode : '') + (zipcode ? ", " :'') + stateOrProvince : "" }</text>
                                                </div>
                                            </div>
                                            <div onClick={() => CmpltAddressModal()}>
                                                <img src={EditLocationIcon} />
                                            </div>
                                        </div> 
                                    </div>
                                    : null : null}

                                    {buttonDisabled1 ?
                                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%'}}>
                                            <SubmitButtonSolid Radius={10} fontSize={16} width={'100%'} buttonColor='#f7f7f7' buttonTitle="Proceed to Pay" buttonTextColor='#b8b8b8' borderColor='#e8e8e8'/>
                                        </div>
                                        :
                                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%'}} onClick={() => onPressPay()}>
                                            <SubmitButtonSolid Radius={10} fontSize={16} width={'100%'} buttonColor='#8c52ff' buttonTitle="Proceed to Pay" buttonTextColor='#ffffff' borderColor='#8c52ff'/>
                                        </div>
                                    }
                                </div> 
                            {/* : null} */}
                        </div>

                    </Col>  

                    <Modal isOpen={slotModal} size="lg" backdrop={() => setSlotModal(false)}>
                        <ModalBody style={{padding:0}}>
                            <div className="CrossContainer_main_div">
                                <Close_Cross onClick={() => setSlotModal(false)}/>
                            </div>
                            <SlotSelection onPressCancel={onPressCancelSlotModal} services={modalDataServices} serviceIndex={modalDataServiceIndex} serviceItem={modalDataServiceItem} couponApplied={modalDataCouponApplied} couponCode={modalDataCouponCode} userId={modalDataUserId} onSelectTimeSlot={props.onSelectTimeSlot} timezone={'Asia/Kolkata'}/>
                        </ModalBody>
                    </Modal>  


                    <Modal isOpen={modalVisible} backdrop={() => setModalVisible(false)} className="modal_align_middle" size="md" centered>
                        <ModalBody>
                            <div className="modalContainerStyle">
                                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', marginBottom:15}}>
                                    <text className="modalHeadingText">Remove from cart ?</text>
                                    <Close_Cross onClick={() => setModalVisible(false)}/>
                                </div>
                                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                                    <div onClick={() => onPressMovetoWishlist()} className="MoveButtonStyle">
                                        <text className="ButtonMoveText">No</text>
                                    </div>

                                    <div onClick={() => onPressRemoveItem()} className="RemoveButtonStyle">
                                        <text className="ButtonRemoveText">Remove</text>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>  


                    <Modal isOpen={cancellationAllow} className="modal_align_middle" size="md">
                        <div style={{backgroundColor:'#ffffff', padding:20}}>
                            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                                <text className="cancellationDisclaimerHeader">Cancellation Disclaimer</text>
                                <Close_Cross onClick={() => setCancellationAllow(false)}/>
                            </div>
                            <div>
                                {listOfCancellationDetails}
                            </div>
                        </div>
                    </Modal>

                    {/* Address Modal */}

                   {
                   cmpltAddModal 
                   &&
                    <Modal isOpen={cmpltAddModal} toggle={() => CompleteAddressModalClose()} centered>
                        <ModalBody className="loginRegCardDivLeft_address_update_mobile">
                            <div className="CrossContainer_main_div">
                                <Close_Cross onClick={() => CompleteAddressModalClose()}/>
                            </div>
                           {addressFieldsContent}
                        </ModalBody>
                    </Modal>
                    }

                    {/* Edit Address Modal */}
                   { 
                   editNewAddressModal &&
                    <Modal isOpen={editNewAddressModal} toggle={() => EditNewAddressModalCloseHandler()} centered>
                        <ModalBody className="loginRegCardDivLeft_address_update_mobile">
                            <div className="CrossContainer_main_div">
                                <Close_Cross onClick={() => EditNewAddressModalCloseHandler()}/>
                            </div>
                           {addressFieldsContent}
                        </ModalBody>
                    </Modal>
                    }
                    
                    {/* Chnage Addres Modal Mobile */}

                    <Modal isOpen={changeAddressModal} size={'xl'} fullscreen={'xl'} toggle={() => changeAddressModalCloseHandler()} style={{overflow:'hidden'}}>
                        <div style={{padding:16}}>
                            <div style={{padding: '10px 0px 10px 0px',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <img src={arrowBack} style={{transform: 'rotate(-90deg)',width:20,height:20}} onClick={() => changeAddressModalCloseHandler()}/>
                                <text className='nearByProMapText' style={{marginLeft:15,color:'#8c52ff'}}>Choose Location</text>
                            </div>
                            <div style={{ marginTop: 20 }}>
                                <div style={{ position: 'relative', zIndex: 100 }}>
                                    <PlacesAutocomplete
                                        // ref={searchAddRef}
                                        value={searchText}
                                        // autoFocus={true}
                                        onChange={handleChangeMobile}
                                        onSelect={handleSelectMobile}>
                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                            <div>
                                                <Input

                                                    style={{ paddingLeft: 50, width: '100%' }}
                                                    value={searchText}
                                                    autoFocus={true}
                                                    // innerRef={searchInput}
                                                    {...getInputProps({
                                                        // autoFocus:true,
                                                        placeholder: 'Search any address',
                                                        className: 'location-search-input-sv2-mobile shadow-none'
                                                    })}
                                                />
                                                {/* {console.log('suggestion', suggestions)} */}
                                                <img src={searchIcon} style={{ position: 'absolute', left: 10, bottom: 11 }} />
                                                <div style={{
                                                    border: suggestions.length !== 0 ? '2px solid #fcfcfc' : 0,
                                                    height: suggestions.length !== 0 ? 250 : 0,
                                                    overflow: suggestions.length !== 0 ? 'scroll' : 0,
                                                    top: suggestions.length !== 0 ? 150 : 0,
                                                    bottom: 0,
                                                    position: 'absolute',
                                                    zIndex: 2000,
                                                    width: '100%',
                                                    border: 'none'
                                                }}
                                                >
                                                    {/* {loading && <div>Loading...</div>} */}
                                                    {suggestions.map(suggestion => {
                                                        const className = suggestion.active
                                                            ? 'suggestion-item--active'
                                                            : 'suggestion-item';
                                                        // inline style for demonstration purpose
                                                        const style = suggestion.active
                                                            ? { backgroundColor: '#c5c5c5', cursor: 'pointer' }
                                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                        return (
                                                            <div
                                                                {...getSuggestionItemProps(suggestion, {
                                                                    className,
                                                                    style: { display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: 8, paddingBottom: 8 }
                                                                })}
                                                            >
                                                                {/* {this.state.showDropdown === true ? <div>{suggestion.description}</div> : null}  */}
                                                                {/* {console.log('data', suggestion.description)} */}
                                                                <img src={GreyLocIcon} style={{ width: 20, height: 20 }} />
                                                                <text className='suggestion_Text' style={{ paddingLeft: 10, cursor: 'pointer' }}>{suggestion.description.length > 45 ? suggestion.description.substr(0, 45) + '...' : suggestion.description}</text>
                                                            </div>
                                                        );
                                                    })}
                                                </div>


                                            </div>
                                        )}
                                    </PlacesAutocomplete>
                                </div>
                                <div style={{ flexDirection: 'row', display: 'flex', marginTop: 20, cursor: 'pointer' }} onClick={() => currentLocationFunc(true)}>
                                    <img src={CurrentLocationIcon} style={{ width: 20, height: 20 }} />
                                    <div style={{ flexDirection: 'column', display: 'flex', marginLeft: 16 }}>
                                        {/* <div> */}
                                        <text className='currentLoc_MainText' style={{ marginBottom: 5 }}>My Current location</text>
                                        {/* </div> */}
                                        {/* <div> */}
                                        <text className='currentLoc_subText'>{curLocationDenied ? '' : (currentAddress ? currentAddress : '')}</text>
                                        {/* </div> */}
                                    </div>
                                </div>
                                <hr />
                                {
                                   hidingRecentSavedLocs ?
                                    <div style={{ overflow: 'scroll', height: 450 }}>
                                        {
                                            (recentSearchesArray.length !== 0 ?
                                                <>
                                                    <div style={{ paddingBottom: 5 }}>
                                                        <text className='recentLocation_Text'>Recent Locations</text>
                                                    </div>
                                                    <div style={{ paddingTop: 5 }}>
                                                        {recentSearchesDisplay}
                                                    </div>
                                                </>
                                                : null)
                                        }
                                        {
                                        
                                            (fetchedDataResponse.length !== 0 ?
                                                <>
                                                    <div style={{ paddingBottom: 5 }}>
                                                        <text className='recentLocation_Text'>Saved Locations</text>
                                                    </div>
                                                    <div style={{ paddingTop: 5 }}>
                                                        {savedAddressesDisplay}
                                                    </div>
                                                </>
                                                : null)
                                        }
                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                    </Modal>

                    {/* Save Address Modal Edit*/}

                    {
                    saveAddressModal &&
                    <Modal isOpen={saveAddressModal} toggle={() => saveAddressModalClose()} centered>
                        <ModalBody className="loginRegCardDivLeft_address_update_mobile">
                            <div className="CrossContainer_main_div">
                                <Close_Cross onClick={() => saveAddressModalClose()}/>
                            </div>
                           {addressFieldsContent}
                        </ModalBody>
                    </Modal>
                    }

                    {/* Map View Modal */}

                    {mapViewModal && addressSearchMapView}

                    {/* Address search modal for web */}
                    {
                        mapViewModalWeb &&
                        <Modal isOpen={mapViewModalWeb} size={'xl'} centered style={{ width: isTab ? '100%' : '50%' }} toggle={() => MapViewModalCloseWeb()}>
                            <div style={{padding:5}}>
                                {addressSearchMapViewWeb}
                            </div>
                        </Modal>
                    }
                    {/* Login Modal */}
                    <Modal isOpen={isMobileView ? state.token ? false : true : false}>
                        <div className="CrossContainer_main_div">
                            <Close_Cross onClick={() => navigate(-1)}/>
                        </div>
                        <ModalBody className="loginRegCardDivLeft_mobile_login">
                            <div>
                                <div style={{textAlign:'center'}}>
                                    <text className="loginCardText_style">If you're ready to book the service, let's get started!</text><br/>
                                    <text className="loginCardText_style" style={{color: '#a679ff'}}><a>Log in to your existing account or register now.</a></text>
                                </div>
                                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                                    <SubmitButtonSmall Radius={10} width={isTab?140:180} buttonColor='#8c52ff' buttonTitle="Register" buttonTextColor='#ffffff' borderColor='#8c52ff' onPress={()=>setModal(!modal)}/>
                                    <SubmitButtonSmall Radius={10} width={isTab?140:180} buttonColor='#ffffff' buttonTitle="Log in" buttonTextColor='#8c52ff' borderColor='#747474'onPress={()=>setModal(!modal)}/>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>


                    <Modal isOpen={couponDiscountModal} size="md">
                        <ModalBody style={{padding:0}}>
                            <div className="CrossContainer_coupon_main_div">
                                <text className="HeaderText">Apply Discounts</text>
                                <Close_Cross onClick={() => setCouponDiscountModal(false)}/>
                            </div>
                            <div>
                                <CouponDiscountPage onClick={(coupon, couponData, prevCouponCode) => onClickCouponHander(coupon, couponData, prevCouponCode)} proPersonelIds={proPersonelIds} proMerchantIds={proMerchantIds} serviceIds={serviceIds} services={services}  totalAmount={totalAmount}  country={country}  totalCPPrice={totalCPPrice} totalTSPrice={totalTSPrice} couponApplied={couponApplied} couponCode={couponCode} navFrom='Checkout' userId={params.userId?params.userId:null}/>
                            </div>
                        </ModalBody>
                    </Modal>            
                </div>
                : 
                !state.token ?
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'left', height:'69vh'}}>
                        <div className="loginRegCardDivLeft">
                            <div style={{textAlign:'center'}}>
                                <text className="loginCardText_style">If you're ready to book the service, let's get started!</text><br/>
                                <text className="loginCardText_style" style={{color: '#a679ff'}}><a>Log in to your existing account or register now.</a></text>
                            </div>
                            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                                <SubmitButtonSmall Radius={10} width={isTab?140:180} buttonColor='#8c52ff' buttonTitle="Register" buttonTextColor='#ffffff' borderColor='#8c52ff' onPress={()=>setModal(!modal)}/>
                                <SubmitButtonSmall Radius={10} width={isTab?140:180} buttonColor='#ffffff' buttonTitle="Log in" buttonTextColor='#8c52ff' borderColor='#747474'onPress={()=>setModal(!modal)}/>
                            </div>
                        </div>
                    </div>

                    :

                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', padding:50, height:'69vh'}}>
                        {loading ?
                            <LoadingSpinner loading={true}/>
                        :
                            <text className="CancellationChargeText">No services in cart</text>
                        }
                    </div>
                }
            { modal && <RegisstrAndLogin_India closeModal={()=>setModal(!modal)}  />}
            <ToastContainer />
        </Container>
    )
}